/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeStaffUserPassword200ResponseSchema } from '../models/ChangeStaffUserPassword200ResponseSchema';
import type { ChangeStaffUserPasswordRequestSchema } from '../models/ChangeStaffUserPasswordRequestSchema';
import type { ConfirmStaffUserMFASecret200ResponseSchema } from '../models/ConfirmStaffUserMFASecret200ResponseSchema';
import type { ConfirmStaffUserMFASecretRequestSchema } from '../models/ConfirmStaffUserMFASecretRequestSchema';
import type { CreateStaffUser200ResponseSchema } from '../models/CreateStaffUser200ResponseSchema';
import type { CreateStaffUserRequestSchema } from '../models/CreateStaffUserRequestSchema';
import type { DeleteStaffUser200ResponseSchema } from '../models/DeleteStaffUser200ResponseSchema';
import type { DeleteStaffUserRequestSchema } from '../models/DeleteStaffUserRequestSchema';
import type { GenerateStaffUserMFASecret200ResponseSchema } from '../models/GenerateStaffUserMFASecret200ResponseSchema';
import type { GenerateStaffUserMFASecretRequestSchema } from '../models/GenerateStaffUserMFASecretRequestSchema';
import type { GetStaffUser200ResponseSchema } from '../models/GetStaffUser200ResponseSchema';
import type { GetStaffUserRequestSchema } from '../models/GetStaffUserRequestSchema';
import type { ListStaffUsers200ResponseSchema } from '../models/ListStaffUsers200ResponseSchema';
import type { ListStaffUsersRequestSchema } from '../models/ListStaffUsersRequestSchema';
import type { MGetStaffUsers200ResponseSchema } from '../models/MGetStaffUsers200ResponseSchema';
import type { MGetStaffUsersRequestSchema } from '../models/MGetStaffUsersRequestSchema';
import type { MGetStaffUsersV2200ResponseSchema } from '../models/MGetStaffUsersV2200ResponseSchema';
import type { MGetStaffUsersV2RequestSchema } from '../models/MGetStaffUsersV2RequestSchema';
import type { RefreshStaffUserSession200ResponseSchema } from '../models/RefreshStaffUserSession200ResponseSchema';
import type { RemoveStaffUserMFAToken200ResponseSchema } from '../models/RemoveStaffUserMFAToken200ResponseSchema';
import type { RemoveStaffUserMFATokenRequestSchema } from '../models/RemoveStaffUserMFATokenRequestSchema';
import type { RequestResetStaffUserPassword200ResponseSchema } from '../models/RequestResetStaffUserPassword200ResponseSchema';
import type { RequestResetStaffUserPasswordRequestSchema } from '../models/RequestResetStaffUserPasswordRequestSchema';
import type { ResetStaffUserPassword200ResponseSchema } from '../models/ResetStaffUserPassword200ResponseSchema';
import type { ResetStaffUserPasswordRequestSchema } from '../models/ResetStaffUserPasswordRequestSchema';
import type { StaffLogin200ResponseSchema } from '../models/StaffLogin200ResponseSchema';
import type { StaffLoginRequestSchema } from '../models/StaffLoginRequestSchema';
import type { StaffLogout200ResponseSchema } from '../models/StaffLogout200ResponseSchema';
import type { UpdateStaffUser200ResponseSchema } from '../models/UpdateStaffUser200ResponseSchema';
import type { UpdateStaffUserRequestSchema } from '../models/UpdateStaffUserRequestSchema';
import type { VerifyStaffUserMFAToken200ResponseSchema } from '../models/VerifyStaffUserMFAToken200ResponseSchema';
import type { VerifyStaffUserMFATokenRequestSchema } from '../models/VerifyStaffUserMFATokenRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StaffUsersService {

  /**
   * staffLogin
   * Returns a valid session given user credentials (if user has MFA set, session will be valid only for MFA token verification)
   * @param requestBody
   * @returns StaffLogin200ResponseSchema StaffLogin200Response
   * @throws ApiError
   */
  public static staffLogin(
    requestBody?: StaffLoginRequestSchema,
  ): CancelablePromise<StaffLogin200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/staffLogin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `StaffLogin400Response`,
        429: `StaffLogin429Response`,
        500: `StaffLogin500Response`,
      },
    });
  }

  /**
   * staffLogout
   * Staff logout
   * @returns StaffLogout200ResponseSchema StaffLogout200Response
   * @throws ApiError
   */
  public static staffLogout(): CancelablePromise<StaffLogout200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/staffLogout',
      errors: {
        400: `StaffLogout400Response`,
        401: `StaffLogout401Response`,
        403: `StaffLogout403Response`,
        429: `StaffLogout429Response`,
        500: `StaffLogout500Response`,
      },
    });
  }

  /**
   * refreshStaffUserSession
   * Refresh staff session
   * @returns RefreshStaffUserSession200ResponseSchema RefreshStaffUserSession200Response
   * @throws ApiError
   */
  public static refreshStaffUserSession(): CancelablePromise<RefreshStaffUserSession200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/refreshStaffUserSession',
      errors: {
        400: `RefreshStaffUserSession400Response`,
        401: `RefreshStaffUserSession401Response`,
        403: `RefreshStaffUserSession403Response`,
        429: `RefreshStaffUserSession429Response`,
        500: `RefreshStaffUserSession500Response`,
      },
    });
  }

  /**
   * generateStaffUserMFASecret
   * Generate a new MFA secret
   * @param requestBody
   * @returns GenerateStaffUserMFASecret200ResponseSchema GenerateStaffUserMFASecret200Response
   * @throws ApiError
   */
  public static generateStaffUserMfaSecret(
    requestBody?: GenerateStaffUserMFASecretRequestSchema,
  ): CancelablePromise<GenerateStaffUserMFASecret200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/generateStaffUserMFASecret',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `GenerateStaffUserMFASecret400Response`,
        401: `GenerateStaffUserMFASecret401Response`,
        403: `GenerateStaffUserMFASecret403Response`,
        429: `GenerateStaffUserMFASecret429Response`,
        500: `GenerateStaffUserMFASecret500Response`,
      },
    });
  }

  /**
   * confirmStaffUserMFASecret
   * Confirm a pending MFA secret
   * @param requestBody
   * @returns ConfirmStaffUserMFASecret200ResponseSchema ConfirmStaffUserMFASecret200Response
   * @throws ApiError
   */
  public static confirmStaffUserMfaSecret(
    requestBody?: ConfirmStaffUserMFASecretRequestSchema,
  ): CancelablePromise<ConfirmStaffUserMFASecret200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/confirmStaffUserMFASecret',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ConfirmStaffUserMFASecret400Response`,
        401: `ConfirmStaffUserMFASecret401Response`,
        403: `ConfirmStaffUserMFASecret403Response`,
        429: `ConfirmStaffUserMFASecret429Response`,
        500: `ConfirmStaffUserMFASecret500Response`,
      },
    });
  }

  /**
   * verifyStaffUserMFAToken
   * Verify MFA token for users with an existing secret
   * @param requestBody
   * @returns VerifyStaffUserMFAToken200ResponseSchema VerifyStaffUserMFAToken200Response
   * @throws ApiError
   */
  public static verifyStaffUserMfaToken(
    requestBody?: VerifyStaffUserMFATokenRequestSchema,
  ): CancelablePromise<VerifyStaffUserMFAToken200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/verifyStaffUserMFAToken',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `VerifyStaffUserMFAToken400Response`,
        401: `VerifyStaffUserMFAToken401Response`,
        403: `VerifyStaffUserMFAToken403Response`,
        429: `VerifyStaffUserMFAToken429Response`,
        500: `VerifyStaffUserMFAToken500Response`,
      },
    });
  }

  /**
   * getStaffUser
   * Returns customer user info
   * @param requestBody
   * @returns GetStaffUser200ResponseSchema GetStaffUser200Response
   * @throws ApiError
   */
  public static getStaffUser(
    requestBody?: GetStaffUserRequestSchema,
  ): CancelablePromise<GetStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/getStaffUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `GetStaffUser400Response`,
        401: `GetStaffUser401Response`,
        403: `GetStaffUser403Response`,
        429: `GetStaffUser429Response`,
        500: `GetStaffUser500Response`,
      },
    });
  }

  /**
   * mGetStaffUsers
   * Get multiple users data
   * @param requestBody
   * @returns MGetStaffUsers200ResponseSchema MGetStaffUsers200Response
   * @throws ApiError
   */
  public static mGetStaffUsers(
    requestBody?: MGetStaffUsersRequestSchema,
  ): CancelablePromise<MGetStaffUsers200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/mGetStaffUsers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `MGetStaffUsers400Response`,
        401: `MGetStaffUsers401Response`,
        403: `MGetStaffUsers403Response`,
        429: `MGetStaffUsers429Response`,
        500: `MGetStaffUsers500Response`,
      },
    });
  }

  /**
   * mGetStaffUsersV2
   * Get multiple staff users
   * @param requestBody
   * @returns MGetStaffUsersV2200ResponseSchema MGetStaffUsersV2200Response
   * @throws ApiError
   */
  public static mGetStaffUsersV2(
    requestBody?: MGetStaffUsersV2RequestSchema,
  ): CancelablePromise<MGetStaffUsersV2200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/mGetStaffUsersV2',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `MGetStaffUsersV2400Response`,
        401: `MGetStaffUsersV2401Response`,
        403: `MGetStaffUsersV2403Response`,
        429: `MGetStaffUsersV2429Response`,
        500: `MGetStaffUsersV2500Response`,
      },
    });
  }

  /**
   * listStaffUsers
   * Retrieve all staff users
   * @param requestBody
   * @returns ListStaffUsers200ResponseSchema ListStaffUsers200Response
   * @throws ApiError
   */
  public static listStaffUsers(
    requestBody?: ListStaffUsersRequestSchema,
  ): CancelablePromise<ListStaffUsers200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/listStaffUsers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ListStaffUsers400Response`,
        401: `ListStaffUsers401Response`,
        403: `ListStaffUsers403Response`,
        429: `ListStaffUsers429Response`,
        500: `ListStaffUsers500Response`,
      },
    });
  }

  /**
   * createStaffUser
   * Create new staff user
   * @param requestBody
   * @returns CreateStaffUser200ResponseSchema CreateStaffUser200Response
   * @throws ApiError
   */
  public static createStaffUser(
    requestBody?: CreateStaffUserRequestSchema,
  ): CancelablePromise<CreateStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/createStaffUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `CreateStaffUser400Response`,
        401: `CreateStaffUser401Response`,
        403: `CreateStaffUser403Response`,
        429: `CreateStaffUser429Response`,
        500: `CreateStaffUser500Response`,
      },
    });
  }

  /**
   * deleteStaffUser
   * Delete a staff user
   * @param requestBody
   * @returns DeleteStaffUser200ResponseSchema DeleteStaffUser200Response
   * @throws ApiError
   */
  public static deleteStaffUser(
    requestBody?: DeleteStaffUserRequestSchema,
  ): CancelablePromise<DeleteStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/deleteStaffUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `DeleteStaffUser400Response`,
        401: `DeleteStaffUser401Response`,
        403: `DeleteStaffUser403Response`,
        429: `DeleteStaffUser429Response`,
        500: `DeleteStaffUser500Response`,
      },
    });
  }

  /**
   * updateStaffUser
   * Updates an existing staff user
   * @param requestBody
   * @returns UpdateStaffUser200ResponseSchema UpdateStaffUser200Response
   * @throws ApiError
   */
  public static updateStaffUser(
    requestBody?: UpdateStaffUserRequestSchema,
  ): CancelablePromise<UpdateStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/updateStaffUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `UpdateStaffUser400Response`,
        401: `UpdateStaffUser401Response`,
        403: `UpdateStaffUser403Response`,
        429: `UpdateStaffUser429Response`,
        500: `UpdateStaffUser500Response`,
      },
    });
  }

  /**
   * changeStaffUserPassword
   * Change staff user password
   * @param requestBody
   * @returns ChangeStaffUserPassword200ResponseSchema ChangeStaffUserPassword200Response
   * @throws ApiError
   */
  public static changeStaffUserPassword(
    requestBody?: ChangeStaffUserPasswordRequestSchema,
  ): CancelablePromise<ChangeStaffUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/changeStaffUserPassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ChangeStaffUserPassword400Response`,
        401: `ChangeStaffUserPassword401Response`,
        403: `ChangeStaffUserPassword403Response`,
        429: `ChangeStaffUserPassword429Response`,
        500: `ChangeStaffUserPassword500Response`,
      },
    });
  }

  /**
   * requestResetStaffUserPassword
   * Request mail to reset password
   * @param requestBody
   * @returns RequestResetStaffUserPassword200ResponseSchema RequestResetStaffUserPassword200Response
   * @throws ApiError
   */
  public static requestResetStaffUserPassword(
    requestBody?: RequestResetStaffUserPasswordRequestSchema,
  ): CancelablePromise<RequestResetStaffUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/requestResetStaffUserPassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `RequestResetStaffUserPassword400Response`,
        429: `RequestResetStaffUserPassword429Response`,
        500: `RequestResetStaffUserPassword500Response`,
      },
    });
  }

  /**
   * resetStaffUserPassword
   * Reset password of a staff user
   * @param requestBody
   * @returns ResetStaffUserPassword200ResponseSchema ResetStaffUserPassword200Response
   * @throws ApiError
   */
  public static resetStaffUserPassword(
    requestBody?: ResetStaffUserPasswordRequestSchema,
  ): CancelablePromise<ResetStaffUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/resetStaffUserPassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ResetStaffUserPassword400Response`,
        401: `ResetStaffUserPassword401Response`,
        403: `ResetStaffUserPassword403Response`,
        429: `ResetStaffUserPassword429Response`,
        500: `ResetStaffUserPassword500Response`,
      },
    });
  }

  /**
   * removeStaffUserMFAToken
   * Remove MFA token for specified user
   * @param requestBody
   * @returns RemoveStaffUserMFAToken200ResponseSchema RemoveStaffUserMFAToken200Response
   * @throws ApiError
   */
  public static removeStaffUserMfaToken(
    requestBody?: RemoveStaffUserMFATokenRequestSchema,
  ): CancelablePromise<RemoveStaffUserMFAToken200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/removeStaffUserMFAToken',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `RemoveStaffUserMFAToken400Response`,
        401: `RemoveStaffUserMFAToken401Response`,
        403: `RemoveStaffUserMFAToken403Response`,
        429: `RemoveStaffUserMFAToken429Response`,
        500: `RemoveStaffUserMFAToken500Response`,
      },
    });
  }

}
