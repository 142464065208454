import { FunctionComponent, useContext, useEffect, useState } from "react"
import { getUser, GetUser200ResponseSchema, UserPrice as UserPriceType } from "@polarity-dev/minosse-api-sdk"
import { MinosseContext } from "../contexts/MinosseContext"
import { Button, Form, InputGroup } from "react-bootstrap"
import Icon from "./Icon"
import SuggestionsDropdown from "./SuggestionsDropdown"
import { handleApiCall } from "../helpers/api"

type UserPriceProps = {
  updateUserPrice: (userPrice: UserPriceType) => void
  userPrice?: UserPriceType
  removeUserPrice?: () => void
}

const UserPrice: FunctionComponent<UserPriceProps> = ({ userPrice, updateUserPrice, removeUserPrice }) => {
  const { activeUserList, userMapping } = useContext(MinosseContext)

  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false)
  const [textboxValue, setTextboxValue] = useState(userPrice ? userMapping[userPrice?.userId]?.username : "")
  const [localUserPrice, setLocalUserPrice] = useState<UserPriceType>(userPrice || { userId: "", pricePerHour: 0, isOwner: false })

  useEffect(() => {
    if (userPrice) {
      updateUserPrice(localUserPrice)
    }
  }, [localUserPrice])

  const [priceLoading, setPriceLoading] = useState<boolean>(false)

  return <div className={"position-relative mb-2"}>
    <SuggestionsDropdown
      textboxValue={textboxValue}
      shouldShowSuggestions={shouldShowSuggestions}
      itemList={activeUserList}
      onItemClick={async(user): Promise<void> => {
        setLocalUserPrice(localUserPrice => ({ ...localUserPrice, pricePerHour: 0 }))
        setTextboxValue(user.username)
        setShouldShowSuggestions(false)

        setPriceLoading(true)
        const { data: { basePrice } } = await handleApiCall(getUser, { handler: { userId: user.userId } }) as GetUser200ResponseSchema
        setPriceLoading(false)

        const newUserPrice = {
          userId: user.userId,
          pricePerHour: basePrice,
          isOwner: localUserPrice.isOwner
        }

        if (userPrice) {
          setLocalUserPrice(newUserPrice)
        } else {
          updateUserPrice(newUserPrice)
          setTextboxValue("")
        }
      }}
      itemTextFunction={({ username }): string => username}
      itemMatchKey={"username"}
    >
      <InputGroup>
        <InputGroup.Text>{userPrice ? "Name" : <Icon name={"plus"} />}</InputGroup.Text>
        <Form.Control
          type={"text"}
          autoComplete={"off"}
          value={textboxValue}
          disabled={priceLoading}
          onFocus={(): void => setShouldShowSuggestions(true)}
          onBlur={(): void => setShouldShowSuggestions(false)}
          className={"blur-on-scroll disabled-lighter"}
          onChange={(event): void => setTextboxValue(event.target.value)}
        />
        {
          userPrice && <>
            <InputGroup.Text>€</InputGroup.Text>
            <Form.Control
              type="number"
              disabled={priceLoading}
              value={localUserPrice.pricePerHour}
              onChange={(event): void => {
                setLocalUserPrice({
                  ...localUserPrice,
                  pricePerHour: parseFloat(event.target.value)
                })
              }}
              min={0}
              isInvalid={isNaN(localUserPrice.pricePerHour)}
              className={"project-form__price-per-hour"}
            />

            <Button variant={"secondary"} onClick={removeUserPrice}>
              <Icon name={"x-lg"}/>
            </Button>
          </>
        }
      </InputGroup>
    </SuggestionsDropdown>
  </div>
}

export default UserPrice