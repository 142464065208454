import React from "react"
import { Layout } from "react-grid-layout"

import { addDays } from "date-fns"
import { startOfISOWeek } from "date-fns/fp"

import { HolidayData, MonthConfig } from "../types/planner"

import dayjs from "dayjs"
import weekOfYear from "dayjs/plugin/weekOfYear"
import weekYear from "dayjs/plugin/weekYear"
import weekday from "dayjs/plugin/weekday"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import isoWeek from "dayjs/plugin/isoWeek"
import isLeapYear from "dayjs/plugin/isLeapYear"
import "dayjs/locale/it"
import { getCustomYearHolidays } from "./time"
import { handleException } from "./logger"

dayjs.extend(weekYear)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)
dayjs.extend(isLeapYear)
dayjs.locale("it")

const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const checkIfLayoutItemChanged = (oldItem: Layout, newItem: Layout): boolean => {
  return (["x", "y", "w", "h"] as (keyof Layout)[])
    .some(key => oldItem[key] !== newItem[key])
}

export const LightenDarkenColor = (color: string, amount: number, opacity: number = 1): string | undefined => {
  if (!color) {
    return undefined
  }

  opacity = Math.round(opacity * 255)

  let usePound = false

  if (color[0] === "#") {
    color = color.slice(1)
    usePound = true
  }

  const num = parseInt(color, 16)

  let r = (num >> 16) + amount

  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }

  let b = ((num >> 8) & 0x00FF) + amount

  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }

  let g = (num & 0x0000FF) + amount

  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16) + (opacity ? opacity.toString(16) : "")

}

export const getLocalMousePosition = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): { x: number, y: number } => {
  // @ts-ignore
  const rect = event.target.getBoundingClientRect()
  const x = event.clientX - rect.left   // x position within the element.
  const y = event.clientY - rect.top    // y position within the element.
  return { x, y }
}

export const mouseToPlannerBlock = (mouse: { x: number, y: number }): { x: number, y: number } => {
  const { x, y } = mouse
  const width = 137
  const height = 50
  const xBlock = Math.floor(x / width)
  const yBlock = Math.floor(y / height)
  return { x: xBlock, y: yBlock }
}

export const mouseEventToPlannerBlock = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): { x: number, y: number } => {
  const mouse = getLocalMousePosition(event)
  return mouseToPlannerBlock(mouse)
}

export const cleanDate = (date: number | Date): Date => {
  return typeof date === "number"
    ? new Date(date)
    : date
}

export const getYFromTimestamp = (timestamp: number | Date, offset: number = 0): number => {
  const date = typeof timestamp === "number"
    ? new Date(timestamp)
    : timestamp

  return (date.getUTCHours() - 9) + (offset * 9)
}

export const getTimestampFromXY = (selectedWeek: Date, x: number, y: number): number => {
  const date = addDays(selectedWeek, x)
  date.setUTCHours(9 + (y % 9))
  return date.getTime()
}

export const getClosestMonday = (date: Date | number): Date => {
  const dayDate = dayjs(date).hour(0).minute(0).second(0).millisecond(0)
  const day = dayDate.weekday()

  if (day === 0) {
    return dayDate.toDate()
  } else if (day > 4) {
    return dayDate.weekday(7).toDate()
  } else {
    return dayDate.weekday(-7).toDate()
  }
}

/**
 * Returns the day of the week for the given date.
 * @param date The date to get the day of the week for.
 */
export const getDayOfWeekFixed = (date: Date | number): number => {
  return dayjs(date).weekday()
}

export const getMonday = (date: Date | number, clearTime: boolean = true): Date => {
  const newDate = startOfISOWeek(date)
  if (clearTime) {
    newDate.setUTCHours(0, 0, 0)
  }
  return newDate
}

export const isSameUTCDay = (date1: Date | number, date2: Date | number): boolean => {
  const trueDate1 = cleanDate(date1)
  const trueDate2 = cleanDate(date2)

  return trueDate1.getUTCFullYear() === trueDate2.getUTCFullYear() &&
    trueDate1.getUTCMonth() === trueDate2.getUTCMonth() &&
    trueDate1.getUTCDate() === trueDate2.getUTCDate()
}

/**
 * Returns `true` if the `date` fits in the week **starting with** day `startOfWeekDate`, `false` otherwise
 * @param startOfWeekDate **The monday** of the week
 * @param date The date that needs to be checked
 */
export const fitsInWeek = (startOfWeekDate: Date, date: Date | number): boolean => {
  const trueDate = cleanDate(date)
  const nextWeek = dayjs(startOfWeekDate).utc().weekday(6).hour(23).toDate()

  return (trueDate >= startOfWeekDate)
    && (trueDate < nextWeek)
}


export const getStartOfWeek = (): Date => dayjs().utc().startOf("isoWeek").toDate()
export const getEndOfWeek = (): Date => dayjs().utc().endOf("isoWeek").toDate()

export const parseWeek = (weekParam: string | null): Date => {
  if (!weekParam) {
    return getStartOfWeek()
  }

  const matches = weekParam.match(/^(\d{4})-W(\d{2})$/)
  if (matches?.length !== 3) {
    return getStartOfWeek()
  }

  const year = parseInt(matches[1])
  const week = parseInt(matches[2])

  return dayjs().utc().year(year).isoWeek(week).startOf("isoWeek").toDate()
}

export const getWeekText = (date: Date): string => {
  const dayjsDate = dayjs(date)
  return `${dayjsDate.year()}-W${dayjsDate.isoWeek().toString().padStart(2, "0")}`
}


// ============= NEW STUFF =============

export const getMonthsDifference = (dateFrom: Date, dateTo: Date): number => {
  return 12 * (dateTo.getFullYear() - dateFrom.getFullYear()) + (dateTo.getMonth() - dateFrom.getMonth())
}
export const getMonthDaysCount = (month: number, year: number): number => {
  if (month === 1 && dayjs(year).isLeapYear()) {
    return monthDays[1] + 1
  }
  return monthDays[month]
}

// direction must be +1 or -1
export const getAdjacentMonth = (month: MonthConfig, direction: number): MonthConfig => {
  const today = new Date()
  const date = new Date(month.year, month.index + direction)
  const index = date.getMonth()
  const year = date.getFullYear()
  const days = getMonthDaysCount(index, year)
  return {
    date,
    index,
    year,
    days,
    isCurrent: today.getFullYear() === year && today.getMonth() === index,
    left: month.left + days * direction
  }
}

type GetMonthOptions = {
  initialMonth: MonthConfig
  year: number
  month: number
}
export const getMonth = ({ initialMonth, year, month }: GetMonthOptions): MonthConfig => {
  const today = new Date()
  const date = new Date(year, month)
  const index = date.getMonth()
  const days = getMonthDaysCount(index, year)

  let left = 0
  let loopMonth = initialMonth
  const diff = getMonthsDifference(initialMonth.date, date)
  const isBefore = diff < 0
  for (let i = 0; i < Math.abs(diff); i++) {
    left += loopMonth.days
    loopMonth = getAdjacentMonth(loopMonth, isBefore ? -1 : 1)
  }
  left *= Math.sign(diff)

  return {
    date,
    index,
    year,
    days,
    isCurrent: today.getFullYear() === year && today.getMonth() === index,
    left
  }
}

export const getToday = (): Date => {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const getMonthKey = (month: MonthConfig | number): string => {
  if (typeof month === "number") {
    const date = new Date(month)
    return `${date.getFullYear()}-${date.getMonth()}`
  } else {
    return `${month.year}-${month.index}`
  }
}

export const getCurrentMonth = (): MonthConfig => {
  const today = new Date()
  const date = new Date(today.getFullYear(), today.getMonth())
  const index = today.getMonth()
  const year = today.getFullYear()
  return {
    date,
    index,
    year,
    days: getMonthDaysCount(index, year),
    left: 0,
    isCurrent: true
  }
}

type ShiftDateOptions = {
  date: string | Date
  days: number
}
export const shiftDate = ({ days, date }: ShiftDateOptions): Date => {
  const d = new Date(date)
  return new Date(d.getFullYear(), d.getMonth(), d.getDate() + days, d.getHours(), d.getMinutes())
}

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
}

export const isToday = (date: Date): boolean => {
  return isSameDay(date, new Date())
}

export const loadHolidayData = async(year: number): Promise<HolidayData[]> => {
  const URL = `https://date.nager.at/api/v3/PublicHolidays/${year}/it`
  try {
    const response = await fetch(URL)
    const data = await response.json() as { date: string, localName: string }[]
    const mappedHolidays = data.map(holiday => {
      const date = new Date(holiday.date)
      return {
        date,
        name: holiday.localName
      }
    })

    return [...mappedHolidays, ...getCustomYearHolidays(year)]
  } catch (ex) {
    handleException(ex as Error)
    return []
  }
}
