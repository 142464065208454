import { EmailHandler, NullableId, Recipient, TicketPriority, UserHandler } from "../sdk/minosse-ticketing-api"
import { CustomerUser, StaffUser as User, Customer } from "../sdk/minosse-principals-api"
import { Mapping } from "./utils"

export type CC = Mapping<Recipient>

export type SelectedTicketData = { ticketId: string, customerId: string, supportPlanId: NullableId }

export const generateTicketHandler = (ticket: SelectedTicketData): SelectedTicketData => {
  return {
    ticketId: ticket.ticketId,
    customerId: ticket.customerId,
    supportPlanId: ticket.supportPlanId === "null" ? null : ticket.supportPlanId
  }
}

export const getCCKey = (u: Recipient): string => (u as UserHandler).userId
  ? `UID#${(u as UserHandler).userId}#CID#${(u as UserHandler).customerId}#EMAIL#undefined`
  : `UID#${(u as UserHandler).userId}#CID#${(u as UserHandler).customerId}#EMAIL#${(u as EmailHandler).email}`

export const buildRecipient = (u: Recipient): Recipient => {
  if ((u as UserHandler).userId) {
    return {
      userId: (u as UserHandler).userId,
      customerId: (u as UserHandler).customerId
    }
  } else {
    return {
      email: (u as EmailHandler).email
    }
  }
}

type RenderUserTextFunction = (handler: Recipient, type: "email" | "company" | "companyEmailOnly") => string
export const generateRenderUserText = (userMapping: Mapping<User>, customerMapping: Mapping<Customer>, customerData?: Mapping<CustomerUser>): RenderUserTextFunction => {
  return (handler: Recipient, type: "email" | "company" | "companyEmailOnly"): string => {
    const user = handler as UserHandler
    if ((handler as EmailHandler).email && !user.userId && !user.customerId) {
      return (handler as EmailHandler).email as string
    } else {
      if (type === "email") {
        return user.customerId && customerData
          ? `${customerData?.[user.userId]?.username} (${customerData?.[user.userId]?.email})`
          : `${userMapping?.[user.userId!]?.username} (${userMapping?.[user.userId!]?.email})`
      } else if (type === "companyEmailOnly") {
        return user.customerId && customerData
          ? `${customerData?.[user.userId]?.username} (${customerData?.[user.userId]?.email})`
          : `${userMapping?.[user.userId!]?.username}`
      } else {
        return user.customerId && customerData
          ? `${customerData?.[user.userId]?.username} (${customerMapping[user.customerId]?.customerName})`
          : userMapping?.[user.userId!]?.username
      }
    }
  }
}

export const getPriorityName = (priority: TicketPriority, uppercase: boolean = false): string => {
  const prioText = {
    LOW: "General guidance",
    MEDIUM: "System impaired",
    HIGH: "Production system impaired",
    CRITICAL: "Production system down"
  }[priority]

  return uppercase ? prioText.toUpperCase() : prioText
}
