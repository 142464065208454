import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Form, Modal, Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { MinosseContext } from "../contexts/MinosseContext"
import { getPricePerHour, groupProjects } from "../helpers/projects"
import {
  getReadableDuration,
  getTimestampFromTimestring,
  getTimestring, MINUTE,
  parseDurationInput
} from "../helpers/time"
import { filterProjects } from "../helpers/transformers"
import {
  createTimeEntry,
  deleteTimeEntry,
  getUser,
  GetUser200ResponseSchema,
  TimeEntry,
  updateTimeEntry,
  UpdateTimeEntry200ResponseSchema
} from "@polarity-dev/minosse-api-sdk"
import EntryDetails from "./EntryDetails"
import Icon from "./Icon"
import ProjectSearchbox from "./ProjectSearchbox"
import { filterTimeEntries } from "../helpers/entries"
import { addDays } from "date-fns"
import { BsFillExclamationTriangleFill, BsTrashFill } from "react-icons/bs"
import { MdSave } from "react-icons/md"
import { handleError } from "../helpers/utils"
import { handleException } from "../helpers/logger"
import UserSimpleDropdown from "./UserSimpleDropdown"
import { handleApiCall } from "../helpers/api"
import AuthContext from "../contexts/AuthContext"

type TimeEntryFormProps = {
  selectedEntry?: TimeEntry
  entries: TimeEntry[]
  onSubmit: (id: string, entry: TimeEntry) => void
  onDelete: (id: string) => void
  close: () => void
  showPricePerHour?: boolean
  showUserPicker?: boolean
}

const getTimestampWithDay = (timestring: string, day: Date): number => {
  const dateWithDay = new Date(getTimestampFromTimestring(timestring, "hh:mm:ss"))
  dateWithDay.setDate(day.getDate())
  dateWithDay.setMonth(day.getMonth())
  dateWithDay.setFullYear(day.getFullYear())

  return dateWithDay.getTime()
}

const TimeEntryForm: FunctionComponent<TimeEntryFormProps> = ({ selectedEntry, entries, onSubmit, onDelete, close, showPricePerHour, showUserPicker }) => {
  const defaultStart = Date.now() - MINUTE
  const defaultStop = Date.now()
  const { register, handleSubmit, control, formState: { errors, isSubmitted, isDirty }, getValues, setValue, watch, trigger } = useForm({
    defaultValues: selectedEntry ? {
      description: selectedEntry.description || "",
      start: getTimestring(selectedEntry.start!),
      stop: getTimestring(selectedEntry.stop!),
      customerId: selectedEntry.customerId,
      projectId: selectedEntry.projectId,
      taskId: selectedEntry.taskId,
      day: new Date(selectedEntry.start!),
      ...showPricePerHour && { pricePerHour: selectedEntry.pricePerHour },
      ...showUserPicker && { userId: selectedEntry.userId }
    } : {
      description: "",
      start: getTimestring(defaultStart),
      stop: getTimestring(defaultStop),
      day: new Date(),
      ...showPricePerHour && { pricePerHour: 0 },
      ...showUserPicker && { userId: null }
    }
  })

  const [start, stop, description, day, pricePerHour, customerId, projectId, taskId, userId] = watch(["start", "stop", "description", "day", "pricePerHour", "customerId", "projectId", "taskId", "userId"])
  const [durationInMs, setDurationInMs] = useState<number>(selectedEntry ? selectedEntry.duration as number : (defaultStop - defaultStart))
  const [duration, setDuration] = useState<string>(getReadableDuration(selectedEntry?.duration! ?? 0))
  const [isLoadingPricePerHour, setIsLoadingPricePerHour] = useState(false)

  const [startDate, setStartDate] = useState<Date>(new Date(selectedEntry?.start! ?? defaultStart))
  const [stopDate, setStopDate] = useState<Date>(new Date(selectedEntry?.stop! ?? defaultStop))

  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [searchFilter, setSearchFilter] = useState<string>("")
  const searchFilterRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { activeProjectList, projectMapping, customerMapping } = useContext(MinosseContext)
  const { user } = useContext(AuthContext)
  const filteredProjects = useMemo(() => groupProjects(filterProjects(activeProjectList, customerMapping, searchFilter, { excludeAbsences: true })), [activeProjectList, searchFilter, customerMapping])
  const filteredEntries = useMemo(() => filterTimeEntries(description, entries, projectMapping, customerMapping), [description, entries, projectMapping, customerMapping])
  const descriptionInputRef = useRef(null as HTMLInputElement | null)
  const [showDescSuggestions, setShowDescSuggestions] = useState<boolean>(false)

  const expectedPricePerHour = useMemo(() => {
    if (!selectedEntry) {
      return undefined
    }
    if (selectedEntry.projectId && selectedEntry.taskId) {
      return projectMapping[selectedEntry.projectId]?.tasks.find(({ taskId }) => taskId === selectedEntry.taskId)?.userPrices.find(({ userId }) => userId === selectedEntry.userId)?.pricePerHour
    } else {
      return undefined
    }
  }, [selectedEntry])

  const onFormSubmit = async(data: {
    description: string
    start: string
    stop: string
    customerId: string | null
    projectId: string | null
    taskId: string | null
    day?: Date
    pricePerHour?: number
    userId?: string
  }): Promise<void> => {
    if (!user) {
      throw new Error("User not set")
    }

    const start = startDate.getTime()
    const stop = stopDate.getTime()

    if (isLoadingPricePerHour) {
      return
    }

    delete data.day
    let pricePerHour: number
    if (showPricePerHour) {
      pricePerHour = data.pricePerHour ?? user.basePrice
    } else if (data.projectId && data.taskId) {
      const updatedPricePerHour = projectMapping[data.projectId]?.tasks.find(({ taskId }) => taskId === data.taskId)?.userPrices.find(({ userId }) => userId === user.userId)?.pricePerHour
      pricePerHour = updatedPricePerHour ?? user.basePrice
    } else {
      pricePerHour = user.basePrice
    }

    setIsLoading(true)
    const loader = toast.loading("Updating entry...")
    try {
      const { userId, ...payload } = data
      if (selectedEntry) {
        const { status, data: entryData } = await updateTimeEntry({
          handler: { id: selectedEntry.id!, userId: selectedEntry.userId! },
          tags: selectedEntry.tags,
          ...payload,
          pricePerHour,
          description: data.description || null,
          start,
          stop,
          // @ts-ignore
          userId
        })
        if (status === 200) {
          onSubmit(selectedEntry.id!, (entryData as UpdateTimeEntry200ResponseSchema).data)
          toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Entry updated successfully" })
          close()
        } else {
          throw entryData
        }
      } else {
        // @ts-ignore
        delete payload.userId
        const { status, data: entryData } = await createTimeEntry({
          handler: { userId: userId! },
          ...payload,
          tags: [],
          pricePerHour: data.pricePerHour!,
          customerId: payload?.customerId || null,
          projectId: payload?.projectId || null,
          taskId: payload?.taskId || null,
          start,
          stop
        })
        if (status === 200) {
          onSubmit(entryData.data.id!, (entryData as UpdateTimeEntry200ResponseSchema).data)
          toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Entry created successfully" })
          close()
        } else {
          throw entryData
        }
      }
    } catch (error) {
      handleError(error, loader)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!showSuggestions) {
      setSearchFilter("")
      if (searchFilterRef.current) {
        searchFilterRef.current.value = ""
      }
    }
  }, [showSuggestions])

  useEffect(() => {
    if (isSubmitted) {
      void trigger()
    }
  }, [start, stop])

  useEffect(() => {
    setValue("start", getTimestring(startDate.getTime()))
  }, [startDate])

  useEffect(() => {
    setValue("stop", getTimestring(stopDate.getTime()))
  }, [stopDate])

  useEffect(() => {
    setDuration(getReadableDuration(durationInMs))
  }, [durationInMs])

  const updatePricePerHour = (userId: string): void => {
    if (userId /* && showPricePerHour && showUserPicker */) {
      setIsLoadingPricePerHour(true)
      ;(handleApiCall(getUser, { handler: { userId } }) as Promise<GetUser200ResponseSchema>)
        .then(({ data: user }) => setValue("pricePerHour", getPricePerHour(projectId, taskId, projectMapping, user)))
        .catch(handleException)
        .finally(() => setIsLoadingPricePerHour(false))
    }
  }

  useEffect(() => {
    let newDate = day
    if (!day || day?.toString() === "Invalid Date") {
      setValue("day", new Date())
      newDate = new Date()
    }
    const newStart = startDate
    newStart.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
    setStartDate(newStart)
    setStopDate(new Date(newStart.getTime() + durationInMs))
  }, [day])

  return <Modal centered show onHide = {(): void => {
    if (isLoading) {
      return
    }

    if (
      isDirty
      || durationInMs !== selectedEntry?.duration
      || startDate.getTime() !== selectedEntry?.start
      || customerId !== selectedEntry?.customerId
      || projectId !== selectedEntry?.projectId
      || taskId !== selectedEntry?.taskId
    ) {
      if (confirm("Are you sure you want to discard your changes?")) {
        close()
      }
    } else {
      close()
    }
  }} className = { "time-entry-form" }>
    <Modal.Header closeButton>
      <Modal.Title>
        {selectedEntry ? "Edit" : "Create"} time entry
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        {showUserPicker && <Form.Group>
          <Form.Label>User</Form.Label>
          <Controller
            name={"userId"}
            control={control}
            render={({ field }): JSX.Element => (
              <UserSimpleDropdown
                userId={field.value ?? null}
                updateUserId={(userId): void => {
                  field.onChange(userId)
                  field.onBlur()
                  updatePricePerHour(userId)
                }}
              />
            )}
          />
        </Form.Group>}
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Controller
            name = { "description" }
            control = { control }
            defaultValue = { selectedEntry?.description || "" }
            render = { ({ field }): JSX.Element => (
              <Form.Control
                type = { "text" }
                { ...field }
                onFocus = { (): void => setShowDescSuggestions(true) }
                onBlur = { (): void => setShowDescSuggestions(false) }
                onKeyDown = { (e): void => {
                  if (e.key === "Escape") {
                    e.stopPropagation()
                    setShowDescSuggestions(false)
                    e.currentTarget.blur()
                  }
                }}
                ref = { descriptionInputRef }
              />
            )}
          />
          <Overlay
            show = { showDescSuggestions && !!description && !!filteredEntries.length }
            placement = { "bottom-start" }
            rootClose
            rootCloseEvent = { "mousedown" }
            onHide = { (): void => setShowSuggestions(false) }
            target = { descriptionInputRef }
          >
            <Popover className = { "project-searchbox__wrapper" } style ={{ minWidth: descriptionInputRef.current?.offsetWidth }}>
              <ProjectSearchbox
                timeEntries = { filteredEntries }
                onItemClick = { (customerId, projectId, taskId, description): void => {
                  setValue("customerId", customerId)
                  setValue("projectId", projectId)
                  setValue("taskId", taskId)
                  setValue("description", description || "")
                  setShowDescSuggestions(false)
                  descriptionInputRef.current?.blur()
                }}
              />
            </Popover>
          </Overlay>
        </Form.Group>
        <Form.Group>
          <Form.Label>Project</Form.Label>
          <div className = { "position-relative" }>
            {
              !showSuggestions &&
                <span className = { "time-entry-form__details" }>
                  <EntryDetails
                    customerId = { getValues("customerId") }
                    projectId = { getValues("projectId") }
                    taskId = { getValues("taskId") }
                  />
                </span>
            }
            <Form.Control type = { "text" }
              ref = { searchFilterRef }
              onFocus = { (): void => setShowSuggestions(true) }
              onBlur = { (): void => setShowSuggestions(false) }
              onChange = { (event): void => setSearchFilter(event.target.value) }
            />
          </div>
          <Overlay
            show = { showSuggestions && !!searchFilter && !!Object.keys(filteredProjects).length }
            placement = { "bottom-start" }
            target = { searchFilterRef }
          >
            <Popover className = { "project-searchbox__wrapper" } style = {{ minWidth: searchFilterRef.current?.offsetWidth }}>
              <ProjectSearchbox
                projects = { filteredProjects }
                onItemClick = {  (customerId, projectId, taskId): void => {
                  setValue("customerId", customerId)
                  setValue("projectId", projectId)
                  setValue("taskId", taskId)
                  setShowSuggestions(false)
                  searchFilterRef.current?.blur()
                }}
              />
            </Popover>
          </Overlay>
        </Form.Group>
        <div className = { "time-entry-form__duration-row" }>
          <Form.Group className = { "time-entry-form__start" }>
            <Form.Label>Start</Form.Label>
            <Form.Control
              type = { "time" }
              isInvalid = { !!errors.start }
              step={1}
              {...register("start", {
                validate: (value: string): boolean => /^\d{2}:\d{2}(:\d{2})?$/.test(value)
              })}
              onBlur={(event): void => {
                const value = new Date(parseDurationInput(start))
                if (!value || !event.target.value) {
                  setValue("start", getTimestring(startDate.getTime()))
                  event.target.value = getTimestring(startDate.getTime())
                  return
                }
                const newStart = startDate.toString() === "Invalid Date" ? new Date(getTimestampWithDay(start, day)) : startDate
                newStart.setHours(value.getUTCHours(), value.getUTCMinutes(), value.getUTCSeconds())
                if (newStart >= stopDate) {
                  // Invalid Date, assuming previous day...
                  setStopDate(new Date(newStart.getTime() + MINUTE))
                  setValue("stop", getTimestring(newStart.getTime() + MINUTE))
                  setDurationInMs(MINUTE)
                  return
                }
                setDurationInMs(stopDate.getTime() - newStart.getTime())
                setStartDate(newStart)
                setValue("start", getTimestring(newStart.getTime()))
                setValue("day", newStart)
              }}
            />
          </Form.Group>
          <Form.Group className = { "time-entry-form__stop" }>
            <Form.Label>Stop</Form.Label>
            <Form.Control
              type = { "time" }
              isInvalid = { !!errors.stop }
              step={1}
              {...register("stop", {
                validate: (value: string): boolean => /^\d{2}:\d{2}(:\d{2})?$/.test(value)
              })}
              onBlur={(event): void => {
                const value = new Date(parseDurationInput(stop))
                if (!value || !event.target.value) {
                  setValue("stop", getTimestring(stopDate.getTime()))
                  event.target.value = getTimestring(stopDate.getTime())
                  return
                }
                let newStop = stopDate.toString() === "Invalid Date" ? new Date(getTimestampWithDay(stop, day)) : stopDate
                newStop.setHours(value.getUTCHours(), value.getUTCMinutes(), value.getUTCSeconds())
                if (newStop < startDate) {
                  // Invalid Date, assuming next day...
                  newStop = addDays(newStop, 1)
                }
                const ms = newStop.getTime() - startDate.getTime()
                setDurationInMs(ms)
              }}
            />
          </Form.Group>
          <Icon name = { "arrow-right-short" }/>
          <Form.Group className = { "time-entry-form__duration" }>
            <Form.Label>Duration</Form.Label>
            <Form.Control
              type = { "text" }
              value={duration !== "NaN:NaN:NaN" ? duration : "invalid"}
              onChange={(e): void => setDuration(e.target.value)}
              onKeyDown={(e): void => {
                if (e.key === "Enter") {
                  e.currentTarget.blur()
                }
              }}
              onBlur={(): void => {
                const value = parseDurationInput(duration)
                if (!value) {
                  setDuration(getReadableDuration(durationInMs, ":"))
                  return
                }
                const newEndDate = new Date(startDate.getTime() + value)
                setDurationInMs(value)
                setStopDate(newEndDate)
                setValue("stop", `${("0" + newEndDate.getHours()).slice(-2)}:${("0" + newEndDate.getMinutes()).slice(-2)}`)
              }}
            />
          </Form.Group>
        </div>
        <Form.Group>
          <Form.Label>Day</Form.Label>
          <Controller
            control = { control }
            name = { "day" }
            render = {({ field: { onChange, onBlur, value } }): JSX.Element => (
              <ReactDatePicker
                onChange = { onChange }
                onBlur = { onBlur }
                selected = { value }
                dateFormat = { "dd/MM/yyyy" }
                locale = { "en-GB" }
                maxDate = { new Date() }
              />
            )}
          />
        </Form.Group>
        {
          showPricePerHour && <Form.Group>
            <Form.Label>Price per hour</Form.Label>
            <div className={"time-entry-form__price-per-hour"}>
              <Form.Control
                type={"number"}
                {...register("pricePerHour", { valueAsNumber: true, disabled: isLoadingPricePerHour })}
              />
              {
                expectedPricePerHour !== undefined && expectedPricePerHour !== pricePerHour && <>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement={"bottom"}
                    overlay={
                      <Tooltip>
                        Entry price per hour is different from the expected one set in task. Press "adjust" to align the price.
                      </Tooltip>
                    }
                  >
                    <div className={"time-entry-form__price-per-hour--warning"}>
                      <BsFillExclamationTriangleFill width={29} height={29}/>
                    </div>
                  </OverlayTrigger>
                  <Button
                    variant={"outline-dark"}
                    onClick={(): void => setValue("pricePerHour", expectedPricePerHour)}
                  >
                    Adjust
                  </Button>
                </>
              }
            </div>
          </Form.Group>
        }
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <div className={"d-flex"}>
        {selectedEntry && <Button
          variant = { "danger" }
          className={"me-2"}
          onClick = { async(): Promise<void> => {
            if (selectedEntry.duration! < 60 * 1000 || confirm("Are you sure you want to delete this entry")) {
              setIsLoading(true)
              try {
                const { status, data } = await deleteTimeEntry({ handler: { id: selectedEntry.id!, userId: selectedEntry.userId! } })
                if (status === 200) {
                  onDelete(selectedEntry.id!)
                  close()
                } else {
                  throw data
                }
              } catch (error) {
                handleException(error as Error)
                toast.error("There's been an error deleting this entry")
              }
              setIsLoading(false)
            }
          }}
          disabled = { isLoading }
        >
          <BsTrashFill className={"iconfix iconfix--translate1 me-2"} />Delete
        </Button>}
        <Button onClick = { handleSubmit(onFormSubmit) } disabled = { isLoading || isLoadingPricePerHour }>
          <MdSave className={"iconfix iconfix--translate1 me-2"} />Save
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
}

export default TimeEntryForm