import React, { useCallback, useEffect, useRef, useState } from "react"
import { KeysMatching } from "../helpers/utils"
import { OverlayTrigger } from "react-bootstrap"
import classNames from "classnames"
import Fuse from "fuse.js"

export function defaultFilterFunction<T>(itemList: T[], textboxValue: string, itemMatchKey: KeysMatching<T, string>, options: Fuse.IFuseOptions<T> | undefined = {}): T[] {
  if (!textboxValue) {
    return itemList
  }

  const fuse = new Fuse(itemList, {
    keys: [itemMatchKey as string],
    threshold: 0.3,
    ...options
  })

  return fuse.search(textboxValue).map(({ item }) => item as T) as T[]
}

type SuggestionsDropdownProps<ItemType> = {
  textboxValue: string
  shouldShowSuggestions: boolean
  itemList: ItemType[]
  onItemClick: (item: ItemType) => void
  itemTextFunction: (item: ItemType) => string
  itemMatchKey: KeysMatching<ItemType, string>
  children: React.ReactNode
  textFilterFunction?: (str: string) => boolean
  onTextSubmit?: (str: string) => void
  onTextSubmitPlaceholder?: React.ReactNode
  onTextSubmitPlaceholderForce?: React.ReactNode
}

/**
 * A component that displays a list of suggestions in a dropdown when the user types in a textbox.
 *
 * @param textboxValue The value of the textbox that the user is typing in.
 * @param shouldShowSuggestions Whether or not the suggestions dropdown should be shown.
 * @param itemList The list of items to display in the suggestions dropdown.
 * @param onItemClick A function that is called when the user clicks on an item in the suggestions dropdown.
 * @param itemTextFunction A function that returns the text to display for an item in the suggestions dropdown.
 * @param itemMatchKey The key of the item that should be matched against the textbox value.
 * @param children The children of this component. This usually is the textbox that the user is typing in.
 * @param textFilterFunction A function that returns whether or not the textbox value should be "returned raw" from the textbox.
 * @param onTextSubmit A function that is called when the user presses enter in the textbox and textFilterFunction returns true.
 * @param onTextSubmitPlaceholder The placeholder to display in the textbox when onTextSubmit is defined.
 * @param onTextSubmitPlaceholderForce The placeholder to display in the textbox when onTextSubmit is defined.
 */
const SuggestionsDropdown = <ItemType, >({
  textboxValue,
  shouldShowSuggestions,
  itemList,
  onItemClick,
  itemTextFunction,
  itemMatchKey,
  children,
  textFilterFunction,
  onTextSubmit,
  onTextSubmitPlaceholder,
  onTextSubmitPlaceholderForce
}: SuggestionsDropdownProps<ItemType>): JSX.Element => {
  const [selectionIndex, setSelectionIndex] = useState(0)

  const filteredItems = defaultFilterFunction(itemList, textboxValue, itemMatchKey)

  const showPlaceholder = onTextSubmit && textFilterFunction !== undefined && textFilterFunction(textboxValue) && !!onTextSubmitPlaceholder

  const textboxContainerRef = useRef<HTMLSpanElement>(null)
  const dropdownRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const lockKeyDown = useRef<boolean>(false)

  const keyHandler = useCallback((event: KeyboardEvent): void | boolean => {
    if (lockKeyDown.current) {
      return
    }

    lockKeyDown.current = true

    const item = filteredItems[selectionIndex] as ItemType
    switch (event.key) {
      case "Enter":
        if (onTextSubmit && textFilterFunction && textFilterFunction(textboxValue) && !event.shiftKey && textboxValue.length > 0) {
          return onTextSubmit?.(textboxValue)
        } else if (item) {
          onItemClick(item)
        }
        return (document.activeElement as HTMLElement).blur()
      case "Escape":
        return (document.activeElement as HTMLElement).blur()
      case "ArrowDown":
        return setSelectionIndex(selectionIndex => selectionIndex < filteredItems.length - 1 ? selectionIndex + 1 : selectionIndex)
      case "ArrowUp":
        return setSelectionIndex(selectionIndex => selectionIndex > 0 ? selectionIndex - 1 : selectionIndex)
    }
  }, [selectionIndex, filteredItems])

  const keyPress = useCallback((event: KeyboardEvent): void | boolean => {
    if (event.key === "Enter" && event.shiftKey && textboxValue.length > 0) {
      return onTextSubmit?.(textboxValue)
    }
  }, [textboxValue])

  useEffect(() => {
    if (shouldShowSuggestions || showPlaceholder) {
      document.addEventListener("keydown", keyHandler)
      document.addEventListener("keypress", keyPress)
      document.addEventListener("keyup", () => lockKeyDown.current = false)
    }

    return (): void => {
      document.removeEventListener("keydown", keyHandler)
      document.removeEventListener("keypress", keyPress)
      document.removeEventListener("keyup", () => lockKeyDown.current = false)
    }
  }, [shouldShowSuggestions, showPlaceholder, keyHandler, keyPress])

  useEffect(() => {
    dropdownRef.current?.children[selectionIndex]?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
  }, [selectionIndex])

  useEffect(() => {
    if (selectionIndex) {
      setSelectionIndex(0)
    }
  }, [textboxValue])

  return <OverlayTrigger
    trigger = { "focus" }
    show={(shouldShowSuggestions && !!filteredItems.length) || showPlaceholder}
    placement = { "bottom-start" }
    flip = { false }
    overlay = {
      <div
        className = { "search-dropdown" }
        style = {{ maxWidth: textboxContainerRef.current?.children?.[0]?.clientWidth }}
        onMouseLeave = { (): void => setSelectionIndex(0) }
      >
        {!textFilterFunction?.(textboxValue) && onTextSubmitPlaceholderForce && <div className={"mb-3"}>{onTextSubmitPlaceholderForce}</div>}
        {showPlaceholder && <div>{onTextSubmitPlaceholder}</div>}
        <span ref = { dropdownRef }>
          {
            filteredItems.map((item: ItemType, index: number) =>
              <div
                className = { classNames("project no-italics", { selected: index === selectionIndex }) }
                onMouseDown= { (): void => onItemClick(item) }
                key = { index }
                onMouseEnter = { (): void => setSelectionIndex(index) }
              >
                { itemTextFunction(item) }
              </div>
            )
          }
        </span>
      </div>
    }
  >
    <span ref = { textboxContainerRef }>
      { children }
    </span>
  </OverlayTrigger>
}

export default SuggestionsDropdown