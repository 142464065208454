import { getReadableDay } from "./time"
import { Customer } from "../sdk/minosse-principals-api"
import Fuse from "fuse.js"
import { Project, TimeEntry } from "@polarity-dev/minosse-api-sdk"

const nullable = (string: string): string | null => string === "null" ? null : string

export const groupTimeEntries = (timeEntries: TimeEntry[]): {
  date: string
  dailyDuration: number
  groups: {
    totalDuration: number
    description: string | null
    customerId: string | null
    projectId: string | null
    taskId: string | null
    entries: TimeEntry[]
  }[]
}[] => {
  return Object.entries(timeEntries
    .sort((a, b) => b.start! - a.start!)
    .reduce((acc: { [key: string]: { [key: string]: TimeEntry[] } }, e) => {
      const start = getReadableDay(e.start!)
      const key = `${e.description}#${e.customerId}#${e.projectId}#${e.taskId}`

      if (!acc[start]) {
        acc[start] = {}
      }

      if (!acc[start][key]) {
        acc[start][key] = []
      }

      acc[start][key].push(e)

      return acc
    }, {}))
    .map(([date, groups]) => {
      let dailyDuration = 0
      const parsedGroups = Object.entries(groups).map(([key, group]) => {
        const [description, customerId, projectId, taskId] = key.split("#")

        let totalDuration = 0
        group.map((entry) => {
          totalDuration += entry.duration!
          dailyDuration += entry.duration!

          return entry
        })

        return {
          totalDuration,
          description: nullable(description),
          customerId: nullable(customerId),
          projectId: nullable(projectId),
          taskId: nullable(taskId),
          entries: group
        }
      })

      return {
        date,
        dailyDuration,
        groups: parsedGroups
      }
    })
}

export const filterProjects = (projects: Project[], customersMapping: { [key: string]: Customer }, filter: string, options?: { excludeAbsences?: boolean, showInactive?: boolean }): Project[] => {
  const processedProjects = projects
    .filter((project) =>
      (!options?.excludeAbsences || (project.projectId !== process.env.REACT_APP_ABSENCES_PROJECT_ID))
      && (options?.showInactive || (customersMapping[project.customerId]?.active && project.active))
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((project) => ({
      ...project,
      customerName: customersMapping[project.customerId]?.customerName,
      tasks: project.tasks.filter(({ active }) => options?.showInactive || active)
    }) as Project)

  const fuse = new Fuse(processedProjects, {
    keys: ["projectName", "customerName", "tasks.taskName"],
    threshold: 0.3
  })

  if (filter) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return fuse.search(filter).map(({ item }) => item as Project) as Project[]
  } else {
    return processedProjects
  }
}