import React, { useContext } from "react"
import { PlanReportCustomer } from "@polarity-dev/minosse-api-sdk"
import { MinosseContext } from "../../contexts/MinosseContext"
import { getFormattedHMDuration } from "../../helpers/time"
import { getDayOfWeekFixed } from "../../helpers/plan"
import { BsFillExclamationTriangleFill } from "react-icons/bs"

export const getTextColor = (value: number, total: number, type: "planned" | "tracked"): string => {
  const black = "text-black"
  const red = "text-danger"
  const green = "text-success"

  const today = getDayOfWeekFixed(new Date())

  const PERFECT_THRESHOLD = 0.05
  const MIN_VALUE_PERFECT = total - total * PERFECT_THRESHOLD
  const MAX_VALUE_PERFECT = total + total * PERFECT_THRESHOLD

  const DAY_INCREASE = 0.20

  const MIN_VALUE_OK = Math.min(MIN_VALUE_PERFECT, total * (today * DAY_INCREASE))

  if (type === "planned") {
    return value > total ? red : black
  } else {
    if (total === 0 && value === 0) {
      return black
    }

    if (value > MAX_VALUE_PERFECT || value < MIN_VALUE_OK) {
      return red
    } else if (value >= MIN_VALUE_PERFECT) {
      return green
    } else {
      return black
    }
  }
}

const PlannerCustomerView: React.FunctionComponent<{
  customer: PlanReportCustomer
}> = ({ customer }): JSX.Element => {
  const { customerMapping, projectMapping } = useContext(MinosseContext)

  return <div>
    <strong>{customerMapping[customer.customerId]?.customerName}: <span className={getTextColor(customer.trackedTime, customer.plannedTime, "tracked")}>{getFormattedHMDuration(customer.trackedTime)}/{getFormattedHMDuration(customer.plannedTime)}</span></strong>
    {
      customer.projects.map((project, i, arr) => {
        return <div style={{ marginLeft: 15, padding: i !== arr.length - 1 ? "5px 0" : 0 }}>
          <span style = {{ background: projectMapping[project.projectId]?.color }} className = { "project-colored-dot" }></span>
          {projectMapping[project.projectId]?.projectName}: <span className={getTextColor(project.trackedTime, project.plannedTime, "tracked")}>{getFormattedHMDuration(project.trackedTime)}/{getFormattedHMDuration(project.plannedTime)}</span>
          {project.hangingTime > 0 && <div style={{ marginLeft: 30 }}>
            No tasks: {getFormattedHMDuration(project.hangingTime)}
            <span><BsFillExclamationTriangleFill className={"ms-1"} style={{ transform: "translateY(3px)", marginTop: -3 }} /></span>
          </div>}
          {
            project.tasks.map(task => {
              return <div style={{ marginLeft: 30 }}>
                {projectMapping[project.projectId]?.tasks.find(({ taskId }) => taskId === task.taskId)?.taskName}: <span className={getTextColor(task.trackedTime, task.plannedTime, "tracked")}>{getFormattedHMDuration(task.trackedTime)}/{getFormattedHMDuration(task.plannedTime)}</span>
              </div>
            })
          }
        </div>
      })
    }
  </div>
}

export default PlannerCustomerView