import React from "react"
import ReactDOM from "react-dom"
import "./styles/index.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import { OpenAPI as PrincipalsApi } from "./sdk/minosse-principals-api"
import { OpenAPI as CoreApi } from "./sdk/minosse-api"
import { OpenAPI as TicketingApi } from "./sdk/minosse-ticketing-api"
import App from "./App"
import { AuthProvider } from "./contexts/AuthContext"

import { setup } from "@polarity-dev/minosse-api-sdk"
import axios from "axios"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isLeapYear from "dayjs/plugin/isLeapYear"
import isoWeek from "dayjs/plugin/isoWeek"
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear"
import weekOfYear from "dayjs/plugin/weekOfYear"
import weekYear from "dayjs/plugin/weekYear"
import weekday from "dayjs/plugin/weekday"
import duration from "dayjs/plugin/duration"
import "dayjs/locale/it"

import * as Sentry from "@sentry/react"

// @ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Rome")
dayjs.extend(advancedFormat)
dayjs.extend(isLeapYear)
dayjs.extend(isoWeek)
dayjs.extend(isoWeeksInYear)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(weekday)
dayjs.extend(duration)

// Set up SDK with required parameters
setup({
  axios,
  env: process.env.REACT_APP_ENV || "local"
})

PrincipalsApi.BASE = process.env.REACT_APP_ENV ? `https://api.${process.env.REACT_APP_ENV === "staging" ? "staging." : ""}principals.polarity.dev/v1` : "http://localhost:5000/v1"
CoreApi.BASE = process.env.REACT_APP_ENV ? `https://api.${process.env.REACT_APP_ENV === "staging" ? "staging." : ""}minosse.polarity.dev/v1` : "http://localhost:8000/v1"
TicketingApi.BASE = process.env.REACT_APP_ENV ? `https://api.${process.env.REACT_APP_ENV === "staging" ? "staging." : ""}ticketing.polarity.dev/v1` : "http://localhost:7000/v1"

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://b34ca5fae78595e30f51dd2c7f99b7b8@o430323.ingest.sentry.io/4505958524190720",
    environment: process.env.REACT_APP_ENV
  })
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App/>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
)