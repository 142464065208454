import classNames from "classnames"
import { FC, useContext, useEffect, useMemo, useRef } from "react"
import { MdDragIndicator } from "react-icons/md"
import { fitsInWeek, LightenDarkenColor } from "../../helpers/plan"
import { MinosseContext } from "../../contexts/MinosseContext"
import CalendarAnimator from "../../helpers/calendarAnimator"
import { CalendarEntry } from "../../types/planner"
import AuthContext from "../../contexts/AuthContext"
import { isColorTooDarkForBlackText } from "../../helpers/utils"
import dayjs from "dayjs"

type Props = {
  planItem: CalendarEntry,
  animator: CalendarAnimator,
  isSelected?: boolean,
  isCut?: boolean,
  activeWeek: Date
}

const PlanItem: FC<Props> = ({
  planItem,
  animator,
  isSelected = false,
  isCut = false,
  activeWeek
}) => {
  const { projectMapping, customerMapping } = useContext(MinosseContext)
  const { user, isLogged } = useContext(AuthContext)

  const isDarkColor = isColorTooDarkForBlackText(projectMapping[planItem.projectId]?.color.slice(1))

  const containerRef = useRef<HTMLDivElement | null>(null)
  const textRef = useRef<HTMLDivElement | null>(null)
  const taskName = useMemo(() => projectMapping?.[planItem.projectId]?.tasks?.find(t => t.taskId === planItem.taskId)?.taskName || null, [projectMapping, planItem])

  useEffect(() => {
    if (animator.initialized) {
      animator.initPlanItem(planItem)
      return (): void => animator.destroyPlanItem(planItem.uuid)
    }
  }, [animator.initialized])

  /* useEffect(() => {
    if (!containerRef.current || !textRef.current) {
      return
    }

    const heightPercent = containerRef.current.getBoundingClientRect().height / textRef.current.getBoundingClientRect().height
    const widthPercent = containerRef.current.getBoundingClientRect().width / textRef.current.getBoundingClientRect().width
    for (const child of Array.from(textRef.current.children)) {
      (child as HTMLDivElement).style.fontSize = `${Math.min((heightPercent + widthPercent) / 2 * 0.8, 1.15)}rem`
    }
  }, [planItem, containerRef.current, textRef.current]) */

  const draggable = isLogged && user?.isAdmin

  const aw = useMemo(() => dayjs(activeWeek).utc(true).toDate(), [activeWeek])
  const startDate = useMemo(() => dayjs(planItem.start).utc(true).toDate(), [planItem.start])
  const stopDate = useMemo(() => dayjs(planItem.stop).utc(true).toDate(), [planItem.stop])
  const fogOfWar = useMemo(() => !fitsInWeek(aw, startDate) && !fitsInWeek(aw, stopDate), [aw, startDate, stopDate])

  return (
    <div
      data-planentry={planItem.uuid}
      className={classNames("new-planner__plan-entry position-absolute absolute cursor-grab", {
        "opacity-50": (isCut || fogOfWar) && !(isCut && fogOfWar),
        "opacity-25": fogOfWar && isCut
      })}
      ref={containerRef}
      style={{ backgroundColor: LightenDarkenColor(projectMapping[planItem.projectId]?.color!, 70, 0.95) }}
    >
      <div
        className={classNames("w-100 h-100 position-absolute", {
          "new-planner__plan-entry--selected": isSelected
        })}
      />
      {/* TOP */}
      <div className={classNames("fw-normal d-flex flex-column justify-content-center align-items-center h-100", {
        "px-4": planItem.dailyHours < 2,
        "px-2": planItem.dailyHours >= 2
      })}>
        <div ref={textRef} style={{
          maxWidth: "100%",
          maxHeight: "100%",
          lineHeight: taskName && planItem.dailyHours === 1 ? "1.2rem" : "1.5rem"
        }}>
          <div className={classNames("fw-bold text-center new-planner__plan-entry-text text-truncate", {
            "text-white": isDarkColor
          })}>{customerMapping[planItem.customerId]?.customerName}</div>
          <div className={classNames("text-center new-planner__plan-entry-text text-truncate", {
            "text-white": isDarkColor
          })}>{projectMapping[planItem.projectId]?.projectName}</div>
          {taskName && <div className={classNames("text-center new-planner__plan-entry-text text-truncate", {
            "text-white": isDarkColor
          })}>{taskName}</div>}
        </div>
        {/* <div className="text-center small">{format(planItem.start, "yyyy/MM/dd HH:mm")}</div>
        <div className="text-center small">{format(planItem.stop, "yyyy/MM/dd HH:mm")}</div>
        <div className="text-center small"><pre>{planItem.uuid}</pre></div> */}
        {/* RESIZE */}
        {[
          { handle: "SE", cursor: "cursor-nwse-resize", icon: <MdDragIndicator />, classes: "bottom-0 end-0" },
          { handle: "NE", cursor: "cursor-nesw-resize", icon: <MdDragIndicator />, classes: "top-0 end-0" },
          { handle: "NW", cursor: "cursor-nwse-resize", icon: <MdDragIndicator />, classes: "top-0 start-0" },
          { handle: "SW", cursor: "cursor-nesw-resize", icon: <MdDragIndicator />, classes: "bottom-0 start-0" }
        ].map(({ handle, cursor, icon, classes }) => <div
          style={{ margin: ".0625rem .125rem" }}
          className={classNames(
            "position-absolute",
            classes,
            draggable ? cursor : "opacity-0"
          )}
          data-drag-handle={handle}
        >
          {icon}
        </div>)}
      </div>
    </div>
  )
}

export default PlanItem
