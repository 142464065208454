import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import { MinosseContext } from "../contexts/MinosseContext"
import SuggestionsDropdown, { defaultFilterFunction } from "./SuggestionsDropdown"
import { handleApiCall } from "../helpers/api"
import { toast } from "react-toastify"
import { Customer as PrincipalsCustomer, CustomersService } from "../sdk/minosse-principals-api"
import { handleException, logError } from "../helpers/logger"
import { getCustomer, GetCustomer200ResponseSchema, Customer as CoreCustomer, upsertCustomer } from "@polarity-dev/minosse-api-sdk"
import { alphabetize } from "../helpers/utils"
import { CustomersService as TicketingCustomersService, Customer as TicketingCustomer } from "../sdk/minosse-ticketing-api"

type Props = {
  customerId: string | null
  updateCustomerId: (customerId: string) => void
  excludeIds?: string[]
  isInvalid?: boolean
  clearAfterInsertion?: boolean
  className?: string
  readOnly?: boolean
  allowCreation?: boolean
  onCreatingCustomerChange?: (isCreatingCustomer: boolean) => void
}

const CustomerSimpleDropdown: FunctionComponent<Props> = ({
  customerId,
  updateCustomerId,
  excludeIds,
  isInvalid = false,
  clearAfterInsertion = false,
  className,
  readOnly = false,
  allowCreation = false,
  onCreatingCustomerChange
}) => {
  const { customerMapping, activeCustomerList, actions: { setCustomerList } } = useContext(MinosseContext)

  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false)
  const [textboxValue, setTextboxValue] = useState("")

  const inputRef = useRef<HTMLInputElement>(null)

  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false)
  useEffect(() => onCreatingCustomerChange?.(isCreatingCustomer), [isCreatingCustomer])

  const handleCustomerCreation = async(text: string): Promise<void> => {
    if (isCreatingCustomer) {
      return
    }
    setIsCreatingCustomer(true)
    try {
      const toastId = toast.loading("Creating customer...")
      let customerId: string
      try {
        customerId = (await CustomersService.createCustomer({
          customerName: text
        })).data.customerId
        await Promise.all([
          handleApiCall(upsertCustomer, { handler: { customerId }, updates: { awsAccounts: [], color: "#000000", iamAlerts: [] } }),
          TicketingCustomersService.upsertCustomer({
            handler: { customerId },
            updates: {
              supportPlans: [],
              childCustomers: []
            }
          })
        ])
      } catch (ex) {
        handleException(ex as Error)
        toast.error("Unable to create customer", { toastId })
        throw ex
      }
      let principalsCustomer: PrincipalsCustomer
      let coreCustomer: CoreCustomer
      let ticketingCustomer: TicketingCustomer
      try {
        principalsCustomer = (await CustomersService.getCustomer({ handler: { customerId } })).data
        coreCustomer = (await handleApiCall(getCustomer, { handler: { customerId } }) as GetCustomer200ResponseSchema).data
        ticketingCustomer = (await TicketingCustomersService.getCustomer({ handler: { customerId } })).data
      } catch (ex) {
        handleException(ex as Error)
        toast.error("Unable to fetch new customer", { toastId })
        throw ex
      }
      toast.update(toastId, { type: "success", render: "Customer created!", isLoading: false, autoClose: 3000 })
      const customer = { ...principalsCustomer, ...coreCustomer, ...ticketingCustomer }
      setCustomerList(cl => cl.concat(customer).sort((a, b) => alphabetize(a.customerName, b.customerName)))
      updateCustomerId(customerId)
      setShouldShowSuggestions(false)
    } catch (ex) {
      handleException(ex as Error)
      logError(ex)
    }
    setIsCreatingCustomer(false)
  }

  useEffect(() => {
    if (shouldShowSuggestions) {
      setTextboxValue("")
    } else {
      setTextboxValue(customerId ? customerMapping[customerId]?.customerName : "")
    }
  }, [customerId, shouldShowSuggestions])

  const itemList = useMemo(() => activeCustomerList.filter(({ customerId }) => !excludeIds?.includes(customerId)), [customerId, excludeIds, activeCustomerList])
  const filtered = useMemo(() => defaultFilterFunction(itemList, textboxValue, "customerName") as CoreCustomer[], [itemList, textboxValue])

  return <SuggestionsDropdown
    textboxValue={textboxValue}
    shouldShowSuggestions={shouldShowSuggestions}
    itemList={itemList}
    onItemClick={async({ customerId, customerName }): Promise<void> => {
      setShouldShowSuggestions(false)
      setTextboxValue(clearAfterInsertion ? "" : customerName)
      updateCustomerId(customerId)
    }}
    textFilterFunction={allowCreation ? ((): boolean => filtered.length === 0) : undefined}
    onTextSubmit={allowCreation ? (text: string): void => void handleCustomerCreation(text) : undefined}
    onTextSubmitPlaceholder={allowCreation ? <i>Pressing <code>ENTER</code> will create a new customer.</i> : undefined}
    onTextSubmitPlaceholderForce={allowCreation ? <i>Pressing <code>SHIFT+ENTER</code> will create a new customer.</i> : undefined}
    itemTextFunction={({ customerName }): string => customerName}
    itemMatchKey={"customerName"}
  >
    <div className="position-relative">
      <Form.Control
        type={"text"}
        autoComplete={"off"}
        value={textboxValue}
        ref={inputRef}
        isInvalid={isInvalid}
        disabled={isCreatingCustomer}
        readOnly={readOnly}
        placeholder={readOnly ? "No customer" : "Select customer..."}
        onFocus={(): void => {
          if (!readOnly) {
            setShouldShowSuggestions(true)
          }
        }}
        onBlur={(): void => setShouldShowSuggestions(false)}
        className={"blur-on-scroll disabled-lighter " + className ?? ""}
        onChange={(event): void => setTextboxValue(event.target.value)}
      />
      {isCreatingCustomer && <div className="d-flex w-100 h-100 position-absolute top-0 left-0 align-items-center justify-content-end pe-3">
        <Spinner animation={"border"} size="sm" />
      </div>}
    </div>
  </SuggestionsDropdown>
}

export default CustomerSimpleDropdown