import React, { FunctionComponent, useContext, useEffect, useRef, useState } from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import { MinosseContext } from "../contexts/MinosseContext"
import Icon from "./Icon"
import SuggestionsDropdown from "./SuggestionsDropdown"
import { StaffUser } from "../sdk/minosse-principals-api"
import { Mapping } from "../helpers/utils"

type Props = {
  userId: string | null,
  updateUserId: (userId: string) => void,
  resetUserId?: () => void,
  isInvalid?: boolean,
  readOnly?: boolean,
  placeholder?: string,
  customUserMapping?: Mapping<StaffUser>
}

const UserSimpleDropdown: FunctionComponent<Props> = ({ userId, updateUserId, resetUserId, isInvalid = false, readOnly = false, placeholder, customUserMapping }) => {
  const { userMapping: userMap } = useContext(MinosseContext)

  const userMapping = customUserMapping ?? userMap
  const activeUserList = Object.values(userMapping).filter(u => u.active)

  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false)
  const [textboxValue, setTextboxValue] = useState("")

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!shouldShowSuggestions) {
      setTextboxValue(userId ? userMapping[userId]?.username : "")
    }
  }, [userId, shouldShowSuggestions])

  return <SuggestionsDropdown
    textboxValue={textboxValue}
    shouldShowSuggestions={shouldShowSuggestions}
    itemList={activeUserList}
    onItemClick={async({ userId, username }): Promise<void> => {
      setShouldShowSuggestions(false)
      setTextboxValue(username)
      updateUserId(userId)
    }}
    itemTextFunction={({ username }): string => username}
    itemMatchKey={"username"}
  >
    <InputGroup>
      <Form.Control
        type={"text"}
        autoComplete={"off"}
        value={textboxValue}
        ref={inputRef}
        readOnly={readOnly}
        isInvalid={isInvalid}
        placeholder={readOnly ? "No user" : placeholder ?? "Select user..."}
        onFocus={(): void => {
          if (!readOnly) {
            setShouldShowSuggestions(true)
            setTextboxValue("")
          }
        }}
        onBlur={(): void => {
          setTextboxValue(userId ? userMapping[userId]?.username : "")
          setShouldShowSuggestions(false)
        }}
        className={"blur-on-scroll disabled-lighter"}
        onChange={(event): void => setTextboxValue(event.target.value)}
      />
      {!!resetUserId && <Button variant={"secondary"} onClick={resetUserId}><Icon name={"x-lg"}/></Button>}
    </InputGroup>
  </SuggestionsDropdown>
}

export default UserSimpleDropdown