import React, { HTMLProps } from "react"

const CustomStatusToggle = React.forwardRef<HTMLSpanElement, HTMLProps<HTMLSpanElement>>(({ children, onClick }, ref) => (
  <span
    className={"position-relative cursor-pointer"}
    ref={ref}
    onClick={(e): void => {
      e.preventDefault()
      onClick?.(e)
    }}
  >
    {children}
  </span>
))

export default CustomStatusToggle