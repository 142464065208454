import {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { toast } from "react-toastify"
import {
  Customer as PrincipalsCustomer,
  CustomersService as PrincipalsCustomersService,
  StaffUser,
  StaffUsersService
} from "../sdk/minosse-principals-api"
import AuthContext from "./AuthContext"
import { alphabetize, handleError, mapArrayByKey, Mapping } from "../helpers/utils"
import { baseTimeEntry } from "../constants"
import { getReadableDuration, now } from "../helpers/time"
import {
  Customer as CoreCustomer,
  getRunningTimeEntry,
  Lead,
  listLeads,
  ListLeads200ResponseSchema,
  listProjects,
  ListProjects200ResponseSchema,
  mGetCustomers,
  MGetCustomers200ResponseSchema,
  Project
} from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"
import { handleException } from "../helpers/logger"

export type Customer = PrincipalsCustomer & CoreCustomer

export type   TrackerTimeEntry = {
  id: string | null
  userId: string
  customerId: string | null
  projectId: string | null
  taskId: string | null
  pricePerHour: number
  start: number | null
  description: string | null
  tags: string[]
  generated: boolean
}

type MinosseContextValue = {
  dataReady: boolean
  customerList: Customer[]
  activeCustomerList: Customer[]
  customerMapping: Mapping<Customer>
  projectList: Project[]
  activeProjectList: Project[]
  projectMapping: Mapping<Project>
  userList: StaffUser[]
  activeUserList: StaffUser[]
  userMapping: Mapping<StaffUser>
  currentEntry: TrackerTimeEntry
  leadList: Lead[]
  leadMapping: Mapping<Lead>
  actions: {
    setCustomerList: Dispatch<SetStateAction<Customer[]>>
    setProjectList: Dispatch<SetStateAction<Project[]>>
    setUserList: Dispatch<SetStateAction<StaffUser[]>>
    setLeadList: Dispatch<SetStateAction<Lead[]>>
    setCurrentEntry: Dispatch<SetStateAction<TrackerTimeEntry>>
  }
}

const setFavicon = (path: string): void => {
  (document.getElementById("favicon") as HTMLAnchorElement)!.href = path
}

export const MinosseContext = createContext<MinosseContextValue>({} as MinosseContextValue)

const MinosseProvider: FunctionComponent = ({ children }) => {
  const { user, initialized } = useContext(AuthContext)

  const [dataReady, setDataReady] = useState<boolean>(false)

  const [customerList, setCustomerList] = useState<Customer[]>([])
  const activeCustomerList = useMemo(() => customerList.filter(c => c.active), [customerList])
  const customerMapping = useMemo<Mapping<Customer>>(() => mapArrayByKey(customerList, "customerId"), [customerList])

  const [projectList, setProjectList] = useState<Project[]>([])
  const activeProjectList = useMemo(() => projectList.filter(p => p.active), [projectList])
  const projectMapping = useMemo<Mapping<Project>>(() => mapArrayByKey(projectList, "projectId"), [projectList])

  const [userList, setUserList] = useState<StaffUser[]>([])
  const activeUserList = useMemo(() => userList.filter(u => u.active), [userList])
  const userMapping = useMemo<Mapping<StaffUser>>(() => mapArrayByKey(userList, "userId"), [userList])

  const [leadList, setLeadList] = useState<Lead[]>([])
  const leadMapping = useMemo<Mapping<Lead>>(() => mapArrayByKey(leadList, "leadId"), [leadList])

  const titleInterval = useRef<NodeJS.Timeout | null>(null)
  const [currentEntry, setCurrentEntry] = useState<TrackerTimeEntry>(baseTimeEntry)

  useEffect(() => {
    if (titleInterval.current) {
      clearInterval(titleInterval.current)
    }

    if (currentEntry.start) {
      titleInterval.current = setInterval(function updateDuration(): () => void {
        document.title = `${getReadableDuration(now() - currentEntry.start)} • Minosse Time Tracker`
        setFavicon("/tracking.ico")
        return updateDuration
      }(), 1000)
    } else {
      document.title = "Minosse Time Tracker"
      setFavicon("/favicon.ico")
    }
  }, [currentEntry.start])

  useEffect(() => {
    if (initialized && user) {
      let partialCustomers: PrincipalsCustomer[] = []
      void Promise.allSettled([
        handleApiCall(listProjects, {}),
        PrincipalsCustomersService.listCustomers({}),
        StaffUsersService.listStaffUsers({ unsafeUserName: true }),
        getRunningTimeEntry({ handler: { userId: user.userId } }),
        user.isAdmin ? handleApiCall(listLeads, { open: true }) : undefined
      ]).then(([projects, customers, users, entry, leads]) => {
        if (projects.status === "rejected") {
          handleError(projects.reason)
          return
        }
        if (customers.status === "rejected") {
          handleError(customers.reason)
          return
        }
        if (users.status === "rejected") {
          handleError(users.reason)
          return
        }
        if (leads?.status === "rejected") {
          handleError(leads.reason)
          return
        }

        setProjectList((projects.value as ListProjects200ResponseSchema).data.sort((a, b) => alphabetize(a.projectName, b.projectName)))
        partialCustomers = customers.value.data.sort((a, b) => alphabetize(a.customerName, b.customerName))
        setUserList(users.value.data.sort((a, b) => alphabetize(a.username, b.username)))
        if (leads?.value) {
          setLeadList((leads?.value as ListLeads200ResponseSchema).data)
        }

        if (entry.status === "fulfilled") {
          if (entry.value.status === 200) {
            const { id, userId, customerId, projectId, taskId, pricePerHour, start, description, tags, generated } = entry.value.data.data
            setCurrentEntry({ id, userId, customerId, projectId, taskId, pricePerHour, start, description, tags, generated })
          } else if (entry.value.status === 404) {
            setCurrentEntry(entry => ({
              ...entry,
              userId: user.userId,
              pricePerHour: user.basePrice
            }))
          }
        } else {
          handleError((entry as PromiseRejectedResult).reason)
        }

        return handleApiCall(mGetCustomers, { handler: { customerIds: partialCustomers.map(({ customerId }) => customerId) } }) as Promise<MGetCustomers200ResponseSchema>
      })
        .then(res => {
          if (res) {
            const customerMap = mapArrayByKey(res.data, "customerId")
            setCustomerList(partialCustomers.map((customer) => ({ ...customer, ...customerMap[customer.customerId] })).sort((a, b) => alphabetize(a.customerName, b.customerName)))
          }
        })
        .catch(error => {
          handleException(error as Error)
          toast.error(error.message)
        })
        .finally(() => setDataReady(true))
    }
  }, [initialized, user?.userId])

  return (
    <MinosseContext.Provider
      value={{
        dataReady,
        customerList,
        activeCustomerList,
        customerMapping,
        projectList,
        activeProjectList,
        projectMapping,
        userList,
        activeUserList,
        userMapping,
        leadList,
        leadMapping,
        currentEntry,
        actions: {
          setCustomerList,
          setProjectList,
          setUserList,
          setLeadList,
          setCurrentEntry
        }
      }}
    >
      {children}
    </MinosseContext.Provider>
  )
}

export default MinosseProvider
