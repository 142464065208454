import { FunctionComponent, useContext } from "react"
import { MinosseContext } from "../contexts/MinosseContext"
import { toast } from "react-toastify"
import { millisecondsToHours } from "date-fns"
import { Button, Card } from "react-bootstrap"
import { ReportItem } from "../types"
import { getTimestring, timestampToDate } from "../helpers/time"
import { BsTrashFill } from "react-icons/bs"
import { handleApiCall } from "../helpers/api"
import { AxiosDeleteReportErrorResponse, deleteReport as deleteReportApi } from "@polarity-dev/minosse-api-sdk"
import { AxiosError } from "axios"
import { handleException } from "../helpers/logger"

const ReportCard: FunctionComponent<{ report: ReportItem, onDelete: () => void }> = ({ report, onDelete }) => {
  const { projectMapping } = useContext(MinosseContext)

  const deleteReport = async(): Promise<void> => {
    if (!confirm("Are you sure you want to delete this report?")) {
      return
    }

    try {
      await toast.promise(handleApiCall(deleteReportApi, { id: report.id }), {
        pending: "Deleting report...",
        success: "Report deleted",
        error: "Failed to delete report"
      })
      onDelete()
    } catch (error) {
      handleException(error as Error)
      toast.error(((error as AxiosError).response as AxiosDeleteReportErrorResponse)?.data.message)
    }
  }

  const daysCount = Math.floor(millisecondsToHours((report.until - report.since)) / 24) + 1
  const color = projectMapping[report.projectId!]?.color

  return <Card
    className = { "project-list__project d-flex justify-content-between report-card" }
    onClick = { (): void => window.open(report.url, "_blank")!.focus() }
  >
    <div className = { "project__details" }>
      <div>
        {
          report.projectId ?
            <span style = {{ color }}>
              { color && <span className = { "project__colored-dot" } style={{ backgroundColor: color }}/> }
              { projectMapping[report.projectId!]?.projectName }
            </span>
            :
            <span className = { "fst-italic" }>Complete report</span>
        } • created at { getTimestring(report.createdAt, false) } of { timestampToDate(report.createdAt) }
      </div>
      <div className = { "mt-2" }>{ timestampToDate(report.since) }{ daysCount !== 1 && ` - ${timestampToDate(report.until)}` }</div>
    </div>
    <Button
      variant = { "danger" }
      className={"report-card__delete-button"}
      onClick = { (event): void => {
        event.stopPropagation()
        void deleteReport()
      }}
    >
      <BsTrashFill className={"iconfix iconfix--translate1 me-2"} />Delete
    </Button>
  </Card>
}

export default ReportCard
