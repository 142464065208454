import React, { MutableRefObject, useEffect, useState } from "react"
import { BsPencil } from "react-icons/bs"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"
import { MdSave } from "react-icons/md"
import { Form } from "react-bootstrap"

type MarkdownEditorProps = {
  text: string,
  setText: (notes: string) => void,
  state?: "rendered" | "editor",
  setState?: (state: "rendered" | "editor") => void,
  className?: string,
  style?: React.CSSProperties,
  onUserStateChange?: (state: "rendered" | "editor") => void
}

const MarkdownEditor: React.FunctionComponent<MarkdownEditorProps> = ({
  text, setText, state, setState, className, style = {}, onUserStateChange
}: MarkdownEditorProps) => {
  const [notesEditorState, setNotesEditorState] = useState<"rendered" | "editor">(state ?? "rendered")

  useEffect(() => {
    if (state) {
      setNotesEditorState(state)
    }
  }, [state])

  useEffect(() => {
    setState?.(notesEditorState)
  }, [notesEditorState])

  const editorRef = React.useRef<HTMLTextAreaElement>()
  const scrollbarWidth = editorRef.current ? (editorRef.current?.offsetWidth - editorRef.current?.clientWidth) : 0

  return notesEditorState === "rendered"
    ? <div className={`p-3 rounded border position-relative ${className}`} style={style}>
      <span className={"position-absolute project__tasks-notes-editor-button"}>
        <BsPencil onClick={(): void => {
          setNotesEditorState("editor")
          onUserStateChange?.("editor")
        }} className={"text-black-50 cursor-pointer"} />
      </span>
      <ReactMarkdown
        className={classNames("project__tasks-notes-rendered", { "text-black-50": !text })}
        linkTarget={"_blank"}
      >
        {text || "*no text*"}
      </ReactMarkdown>
    </div>
    : <div className={`position-relative ${className}`} style={style}>
      <span className={"position-absolute project__tasks-notes-editor-button"} style={{
        right: `calc(1rem + ${scrollbarWidth}px)`
      }}>
        <MdSave onClick={(): void => {
          setNotesEditorState("rendered")
          onUserStateChange?.("rendered")
        }} className={"text-black-50 cursor-pointer"} />
      </span>
      <Form.Control
        as="textarea"
        ref={editorRef as MutableRefObject<HTMLTextAreaElement>}
        className={"project__tasks-notes-editor"}
        value={text}
        onChange={(e): void => setText(e.target.value)}
      />
    </div>
}

export default MarkdownEditor
