import { FunctionComponent, ReactNode, useContext, useEffect, useState } from "react"
import { Button, Dropdown, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { MinosseContext } from "../contexts/MinosseContext"
import classNames from "classnames"
import CustomerSimpleDropdown from "./CustomerSimpleDropdown"
import UserSimpleDropdown from "./UserSimpleDropdown"
import {
  addActionToLead,
  AddActionToLead200ResponseSchema,
  AddActionToLeadRequestSchema,
  createLead, deleteLead,
  EstimateTemplate,
  generateLeadEstimate,
  GenerateLeadEstimate200ResponseSchema,
  getLead,
  GetLead200ResponseSchema,
  Lead,
  LeadAction,
  LeadActionType,
  LeadEstimateRange,
  LeadEstimateSingleValue,
  LeadSource,
  LeadStatus,
  LeadWorkloadType,
  NullableId,
  reopenLead,
  ReopenLead200ResponseSchema,
  updateLead
} from "@polarity-dev/minosse-api-sdk"
import { toast } from "react-toastify"
import { handleError } from "../helpers/utils"
import AuthContext from "../contexts/AuthContext"
import { handleException, logError } from "../helpers/logger"
import dayjs from "dayjs"
import { MdSave } from "react-icons/md"
import { BsArrowUpSquare, BsClipboard, BsTrashFill } from "react-icons/bs"
import Icon from "./Icon"
import CustomStatusToggle from "./CustomStatusToggle"
import TicketTimedText from "./tickets/TicketTimedText"
import { ApiError } from "../sdk/minosse-principals-api"
import { BiChevronDown } from "react-icons/bi"
import {
  RiAlarmFill,
  RiChatCheckLine,
  RiChatDeleteLine,
  RiClipboardFill,
  RiEditLine,
  RiFileUploadLine,
  RiMailLine,
  RiMailSendFill,
  RiPhoneFill
} from "react-icons/ri"
import { FiExternalLink } from "react-icons/fi"
import { IoCreateOutline } from "react-icons/io5"
import { handleApiCall } from "../helpers/api"

const getOptionLabel = (value: string): string => value.split("_").join(" ")
export const statusColors: Record<LeadStatus, string> = {
  PENDING_SF: "orange",
  PENDING_CUSTOMER: "primary",
  PENDING_CALL: "info",
  CONVERTED_TO_PROJECT: "success",
  CLOSED_LOST: "dark"
}

const defaultActionNextStates: Record<LeadActionType, LeadStatus> =  {
  SET_REMINDER: "PENDING_SF",
  ADD_NOTE: "PENDING_SF",
  EMAIL_SENT: "PENDING_CUSTOMER",
  EMAIL_RECEIVED: "PENDING_SF",
  CALL: "PENDING_SF",
  ESTIMATE_SENT: "PENDING_CUSTOMER",
  ESTIMATE_ACCEPTED: "CONVERTED_TO_PROJECT",
  ESTIMATE_DECLINED: "CLOSED_LOST",
  ESTIMATE_IN_PROGRESS: "PENDING_SF"
}

const leadActionIcons: Record<LeadActionType, ReactNode> = {
  ADD_NOTE: <RiClipboardFill/>,
  CALL: <RiPhoneFill/>,
  ESTIMATE_IN_PROGRESS: <RiEditLine/>,
  ESTIMATE_SENT: <RiFileUploadLine/>,
  ESTIMATE_DECLINED: <RiChatDeleteLine/>,
  ESTIMATE_ACCEPTED: <RiChatCheckLine/>,
  EMAIL_RECEIVED: <RiMailLine/>,
  EMAIL_SENT: <RiMailSendFill/>,
  SET_REMINDER: <RiAlarmFill/>
}

type Props = {
  leadId?: string | null
  show: boolean
  close: () => void
  triggerListUpdate: () => Promise<void>
}

type CreateLeadRequestSchema = {
  name: string
  description: string
  customerId: string
  source: LeadSource | null
  accountManager: string
  technicalManager: NullableId
  workloadType: LeadWorkloadType
  commercialContact: string
  technicalContact: string
  estimateRange: LeadEstimateRange | LeadEstimateSingleValue
}

const LeadForm: FunctionComponent<Props> = ({ leadId, show, close, triggerListUpdate }) => {
  const { user } = useContext(AuthContext)
  const { userMapping } = useContext(MinosseContext)

  const leadForm = useForm<CreateLeadRequestSchema>({ defaultValues: { workloadType: { AWS: false, DEVELOPMENT: false }, source: undefined } })
  const addActionForm = useForm<Omit<AddActionToLeadRequestSchema, "handler">>({
    defaultValues: {
      nextStatus: "PENDING_SF",
      action: {
        actionType: "ADD_NOTE",
        actionTimestamp: Date.now(),
        notes: "",
        operator: user?.userId,
        reminderTimestamp: null
      }
    },
    mode: "onChange"
  })

  const [isFetchingLead, setIsFetchingLead] = useState(false)
  const [leadStatus, setLeadStatus] = useState<LeadStatus>("PENDING_SF")
  const [leadEstimateUrl, setLeadEstimateUrl] = useState<string | null>(null)
  const [leadActions, setLeadActions] = useState<LeadAction[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isActionsBoxOpen, setIsActionsBoxOpen] = useState(false)
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false)

  useEffect(() => {
    setIsLoading(false)
    setIsActionsBoxOpen(false)
    addActionForm.reset()

    if (leadId) {
      const handleError = (error: ApiError): void => {
        logError(error)
        handleException(error)
        toast.error("There's been an error while fetching the lead")
        close()
      }

      let lead: Lead

      setIsFetchingLead(true)

      ;(handleApiCall(getLead, { handler: { leadId, open: true } }) as Promise<GetLead200ResponseSchema>)
        .then((data) => {
          lead = data
        })
        .catch((error: ApiError) => {
          if (error.status === 404) {
            return handleApiCall(getLead, { handler: { leadId, open: false } }) as Promise<GetLead200ResponseSchema>
          } else {
            handleError(error)
          }
        })
        .then(data => {
          if (data) {
            lead = data
          }
        })
        .catch(handleError)
        .finally(() => {
          leadForm.reset({
            name: lead.name,
            description: lead.description,
            customerId: lead.customerId,
            source: lead.source,
            accountManager: lead.accountManager,
            technicalManager: lead.technicalManager,
            workloadType: lead.workloadType,
            commercialContact: lead.commercialContact,
            technicalContact: lead.technicalContact,
            estimateRange: lead.estimateRange
          })

          setLeadActions(lead.actions ?? [])
          setLeadEstimateUrl(lead.estimateUrl)
          setLeadStatus(lead.status)
          setIsFetchingLead(false)
        })
    } else {
      leadForm.reset({
        name: "",
        description: "",
        source: null,
        technicalManager: null,
        workloadType: { AWS: false, DEVELOPMENT: false },
        commercialContact: "",
        technicalContact: "",
        estimateRange: { type: "RANGE", value: { min: undefined, max: undefined } }
      })
    }
  }, [leadId])

  const leadEstimateType = leadForm.watch("estimateRange.type")
  const [leadActionType, leadNextStatus] = addActionForm.watch(["action.actionType", "nextStatus"])
  const actionShouldHaveReminder = !["CLOSED_LOST", "CONVERTED_TO_PROJECT"].includes(leadNextStatus)

  const isUpdateLeadFormDirty = Object.keys(leadForm.formState.dirtyFields).length > 0
  const readOnly = ["CLOSED_LOST", "CONVERTED_TO_PROJECT"].includes(leadStatus)

  useEffect(() => {
    addActionForm.setValue("nextStatus", defaultActionNextStates[leadActionType], { shouldDirty: true })
  }, [leadActionType])

  const handleUpdateLead = leadForm.handleSubmit(async(data) => {
    setIsLoading(true)
    const loader = toast.loading(leadId ? "Updating lead..." : "Creating lead...")

    if (data.source === null) {
      return
    }

    data.customerId = data.customerId.toString() as string

    try {
      if (leadId) {
        const updatedFields = Object.keys(leadForm.formState.dirtyFields) as Array<keyof CreateLeadRequestSchema>

        await handleApiCall(updateLead, {
          handler: {
            leadId
          },
          updates: updatedFields.reduce((acc: Partial<CreateLeadRequestSchema>, key) => {
            if (data[key]) {
              // Work around for TypeScript limitation with the type inference in the .reduce() method
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              acc[key] = data[key] as any
            }
            return acc
          }, {}) as (Lead & { source: LeadSource })
        })
      } else {
        await handleApiCall(createLead, data as (Lead & { source: LeadSource }))
      }

      await triggerListUpdate()
      toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: `Lead ${leadId ? "updated" : "created"} successfully` })
      close()
    } catch (error) {
      handleError(error, loader)
    } finally {
      setIsLoading(false)
    }
  })

  const handleAddAction = addActionForm.handleSubmit(async(data) => {
    if (!leadId) {
      return
    }

    setIsLoading(true)
    const loader = toast.loading("Adding action...")

    try {
      const { data: action } = await handleApiCall(addActionToLead, { handler: { leadId }, ...data }) as AddActionToLead200ResponseSchema

      setIsActionsBoxOpen(false)
      addActionForm.reset()
      setLeadActions(actions => [action, ...actions])
      setLeadStatus(data.nextStatus)
      toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Action added successfully" })
    } catch (error) {
      handleError(error, loader)
    } finally {
      setIsLoading(false)
    }
  })

  const [showGenerateEstimateModal, setShowGenerateEstimateModal] = useState(false)
  const [selectedEstimateTemplate, setSelectedEstimateTemplate] = useState<EstimateTemplate>("BASE")
  const [isEstimateGenerationLoading, setIsEstimateGenerationLoading] = useState(false)

  return <>
    <Modal
      show={show}
      centered
      scrollable
      animation={false}
      size={leadId ? "xl" : undefined}
      onHide={(): void => {
        if (isCreatingCustomer) {
          return
        }
        if ((isUpdateLeadFormDirty || Object.keys(addActionForm.formState.dirtyFields).length > 0)) {
          if (confirm("Unsaved changes. Are you sure you want to close?")) {
            close()
          }
        } else {
          close()
        }
      }}
      className={"lead-editor"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isFetchingLead ? "Loading lead..." : (leadId ? "Edit lead" : "New lead")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          isFetchingLead ?
            // Spinner
            <div className={"w-100 d-flex justify-content-center align-items-center"} style={{ height: "80vh" }}>
              <Spinner animation={"border"} variant={"dark"}/>
            </div>
            :
            <>
              {/* Loading overlay */}
              <div className={classNames("tickets-modal__opaque-sheet", { "tickets-modal__opaque-sheet--active": isLoading })}/>
              {/* Lead form */}
              <div className={"row h-100 overflow-hidden"}>
                <div className={classNames("h-100 py-lg-3 leads-form__side overflow-scroll", { "col-12 col-lg-6": !!leadId, "col-12": !leadId })}>
                  <Form>
                    {
                      !!leadId && <div className={"d-flex align-items-end mb-3 gap-3"}>
                        {/* Lead ID */}
                        <Form.Group className={"flex-grow-1 w-100"}>
                          <Form.Label>Lead ID</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={"text"}
                              value={leadId}
                              disabled
                              className={"input__disabled-lighter"}
                            />
                            <BsClipboard
                              className={"reports__clear-icon cursor-pointer"}
                              onClick={(): void => {
                                void navigator.clipboard.writeText(leadId)
                                toast.info("Copied to clipboard")
                              }}
                            />
                          </InputGroup>
                        </Form.Group>
                        <div>
                          {
                            leadEstimateUrl ?
                              <Button variant={"outline-dark"} className={"text-nowrap"} as={"a"} href={leadEstimateUrl} target={"_blank"}>
                                <FiExternalLink className={"iconfix iconfix--translate1"}/> View estimate
                              </Button>
                              :
                              !["CLOSED_LOST", "CONVERTED_TO_PROJECT"].includes(leadStatus) &&
                                <Button variant={"outline-dark"} className={"text-nowrap"} onClick={(): void => setShowGenerateEstimateModal(true)}>
                                  <IoCreateOutline className={"iconfix iconfix--translate1"}/> Generate estimate
                                </Button>
                          }
                        </div>
                      </div>
                    }

                    <div className={"row"}>
                      {/* Name */}
                      <Form.Group className={classNames(leadId && "col-7")}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type={"text"}
                          placeholder={"Add lead name..."}
                          readOnly={readOnly}
                          isInvalid={!!leadForm.formState.errors.name}
                          {...leadForm.register("name", { required: true, setValueAs: (value: string) => value.trim() })}
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide a lead name.
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* Status */}
                      {
                        !!leadId &&
                          <Form.Group className={"col-5"}>
                            <Form.Label>Status</Form.Label>
                            <div className={"position-relative"}>
                              <FormControl
                                type={"text"}
                                className={`tickets-modal__state-select fw-bold bg-white disabled-white text-${statusColors[leadStatus]}`}
                                value={getOptionLabel(leadStatus)}
                              />
                              <span
                                className={`project-colored-dot tickets-modal__state-select-dot bg-${statusColors[leadStatus]}`}
                              ></span>
                            </div>
                          </Form.Group>
                      }
                    </div>

                    {/* Description */}
                    <Form.Group className={"mt-3"}>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as={"textarea"}
                        style={{ minHeight: "8rem" }}
                        placeholder={"Add description..."}
                        readOnly={readOnly}
                        {...leadForm.register("description", { setValueAs: (value: string) => value.trim() })}
                      />
                    </Form.Group>

                    {/* Estimate range */}
                    <Form.Group className={"mt-3"}>
                      <Form.Label>Cost estimate</Form.Label>
                      <div className={"d-flex gap-3 align-items-center"}>
                        <Form.Select style={{ maxWidth: "33%" }} disabled={readOnly} {...leadForm.register("estimateRange.type")}>
                          <option value={"RANGE"}>RANGE</option>
                          <option value={"SINGLE_VALUE"}>SINGLE VALUE</option>
                        </Form.Select>
                        <span>—</span>
                        {
                          leadEstimateType === "RANGE" ? <>
                            <InputGroup>
                              <InputGroup.Text>€</InputGroup.Text>
                              <Form.Control
                                type={"number"}
                                placeholder={"Min"}
                                step={1}
                                min={1}
                                readOnly={readOnly}
                                isInvalid={!!(leadForm.formState.errors.estimateRange as LeadEstimateRange | undefined)?.value.min}
                                {
                                  ...leadForm.register("estimateRange.value.min", {
                                    required: true,
                                    min: 0,
                                    valueAsNumber: true
                                  })
                                }
                              />
                            </InputGroup>
                            <Icon name={"arrow-right-short"}/>
                            <InputGroup>
                              <InputGroup.Text>€</InputGroup.Text>
                              <Form.Control
                                type={"number"}
                                placeholder={"Max"}
                                step={1}
                                min={1}
                                readOnly={readOnly}
                                isInvalid={!!(leadForm.formState.errors.estimateRange as LeadEstimateRange | undefined)?.value.max}
                                {
                                  ...leadForm.register("estimateRange.value.max", {
                                    required: true,
                                    min: 0,
                                    valueAsNumber: true
                                  })
                                }
                              />
                            </InputGroup>
                          </> : <InputGroup>
                            <InputGroup.Text>€</InputGroup.Text>
                            <Form.Control
                              type={"number"}
                              placeholder={"Value"}
                              step={1}
                              min={1}
                              readOnly={readOnly}
                              isInvalid={!!leadForm.formState.errors.estimateRange?.value}
                              {
                                ...leadForm.register("estimateRange.value", {
                                  required: true,
                                  min: 0,
                                  valueAsNumber: true
                                })
                              }
                            />
                          </InputGroup>
                        }
                      </div>
                      {leadForm.formState.errors.estimateRange?.value && <div className="text-danger small">Please set a{leadEstimateType === "RANGE" ? " min and max" : ""} price</div>}
                    </Form.Group>

                    <div className={classNames("mt-3", { row: !!leadId })}>
                      {/* Customer */}
                      <Form.Group className={classNames(leadId ? "col-6" : "mb-3")}>
                        <Form.Label>Customer</Form.Label>
                        <Controller
                          control={leadForm.control}
                          name={"customerId"}
                          rules={{ required: "Please select a customer." }}
                          render={({ field: { value, onChange } }): JSX.Element => {
                            return <CustomerSimpleDropdown
                              customerId={value ?? null}
                              updateCustomerId={onChange}
                              isInvalid={!!leadForm.formState.errors.customerId}
                              readOnly={readOnly}
                              allowCreation
                              onCreatingCustomerChange={(status): void => setIsCreatingCustomer(status)}
                            />
                          }}
                        />
                        {leadForm.formState.errors.customerId && <div className="text-danger small">{leadForm.formState.errors.customerId?.message}</div>}
                      </Form.Group>
                      {/* Source */}
                      <Form.Group className={classNames(!!leadId && "col-6")}>
                        <Form.Label>Source</Form.Label>
                        <Form.Select
                          disabled={readOnly}
                          placeholder={"-- select an option --"}
                          defaultValue={undefined}
                          {...leadForm.register("source", {
                            required: "Please select a source."
                          })}
                          isInvalid={!!leadForm.formState.errors.source}
                        >
                          <option disabled value={undefined} selected={true}>-- select an option --</option>
                          {
                            ["AWS", "CONTACT_FORM", "YOUR_SHORTLIST", "UPSELLING", "WORD_OF_MOUTH"].map(source => {
                              return <option value={source} key={source}>
                                {getOptionLabel(source)}
                              </option>
                            })
                          }
                        </Form.Select>
                        {leadForm.formState.errors.source && <div className="text-danger small">{leadForm.formState.errors.source?.message}</div>}
                      </Form.Group>
                    </div>

                    <div className={classNames("mt-3", { row: !!leadId })}>
                      {/* Account manager */}
                      <Form.Group className={classNames(leadId ? "col-6" : "mb-3")}>
                        <Form.Label>Account manager</Form.Label>
                        <Controller
                          control={leadForm.control}
                          name={"accountManager"}
                          rules={{ required: "Please select an account manager." }}
                          render={({ field: { value, onChange } }): JSX.Element => {
                            return <UserSimpleDropdown
                              userId={value ?? null}
                              updateUserId={onChange}
                              isInvalid={!!leadForm.formState.errors.accountManager}
                              readOnly={readOnly}
                            />
                          }}
                        />
                        {leadForm.formState.errors.accountManager && <div className="text-danger small">{leadForm.formState.errors.accountManager?.message}</div>}
                      </Form.Group>
                      {/* Technical manager */}
                      <Form.Group className={classNames(!!leadId && "col-6")}>
                        <Form.Label>Technical manager</Form.Label>
                        <Controller
                          control={leadForm.control}
                          name={"technicalManager"}
                          render={({ field: { value, onChange } }): JSX.Element => {
                            return <UserSimpleDropdown
                              userId={value ?? null}
                              updateUserId={onChange}
                              resetUserId={(): void => onChange(null)}
                              readOnly={readOnly}
                            />
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div className={"row"}>
                      <Form.Group className={"mt-3 col-6"}>
                        <Form.Label>Commercial Contact</Form.Label>
                        <Form.Control
                          type={"text"}
                          placeholder={"Add commercial contact..."}
                          readOnly={readOnly}
                          {...leadForm.register("commercialContact", { setValueAs: (value: string) => value.trim() })}
                        />
                      </Form.Group>
                      <Form.Group className={"mt-3 col-6"}>
                        <Form.Label>Technical Contact</Form.Label>
                        <Form.Control
                          type={"text"}
                          placeholder={"Add technical contact..."}
                          readOnly={readOnly}
                          {...leadForm.register("technicalContact", { setValueAs: (value: string) => value.trim() })}
                        />
                      </Form.Group>
                    </div>
                    <Controller
                      control={leadForm.control}
                      name={"workloadType"}
                      rules={{
                        validate: (value: LeadWorkloadType): boolean | string => (value.AWS || value.DEVELOPMENT) ?? "Please select at least one workload type"
                      }}
                      render={({ field: { value, onChange }, formState: { errors } }): JSX.Element => {
                        return <>
                          <Form.Group className={classNames("d-flex gap-3 mt-3 align-items-center", { "pe-none": readOnly })}>
                            <Form.Label className={"mb-0 me-1"}>Workload type:</Form.Label>
                            {
                              Object.entries(value).map(([tag, status]) => {
                                return <div
                                  key={tag}
                                  className={classNames("tag", { "tag--disabled": !status })}
                                  onClick={(): void => onChange({ ...value, [tag]: !status })}
                                >
                                  {tag}
                                </div>
                              })
                            }
                          </Form.Group>
                          {errors.workloadType && <div className="text-danger small">Please select at least one workload type</div>}
                        </>
                      }}
                    />
                  </Form>
                </div>

                {/* Actions */}
                {
                  !!leadId && <div className={"col-12 col-lg-6 mt-3 mt-lg-0 h-100 mh-100 overflow-scroll leads-form__side py-lg-3"}>
                    <Form.Label>Actions</Form.Label>
                    {!isActionsBoxOpen && <Button
                      className={"w-100"}
                      onClick={(): void => {
                        addActionForm.reset()
                        setIsActionsBoxOpen(true)
                      }}
                      variant="outline-dark"
                    >
                      <span className={"w-100 d-flex justify-content-center"}>
                        <Icon name={"plus"} className={"iconfix iconfix--translate1 me-1"}/>
                        Add action
                      </span>
                    </Button>}
                    {isActionsBoxOpen && <div className={"position-relative border rounded p-2"}>
                      <div className="position-absolute top-0 end-0 me-2 mt-2" onClick={(): void => {
                        setIsActionsBoxOpen(false)
                        addActionForm.reset()
                      }}>
                        <Icon name={"x-lg"}/>
                      </div>

                      {/* Action type */}
                      <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Select {...addActionForm.register("action.actionType")}>
                          {
                            [
                              "ADD_NOTE",
                              "SET_REMINDER",
                              "EMAIL_SENT",
                              "EMAIL_RECEIVED",
                              "CALL",
                              "ESTIMATE_IN_PROGRESS",
                              "ESTIMATE_SENT",
                              "ESTIMATE_ACCEPTED",
                              "ESTIMATE_DECLINED"
                            ].map(actionType => {
                              return <option value={actionType} key={actionType}>
                                {getOptionLabel(actionType)}
                              </option>
                            })
                          }
                        </Form.Select>
                      </Form.Group>

                      <div className={"row mt-3"}>
                        {/* Operator */}
                        <Form.Group className={"col-6"}>
                          <Form.Label>Operator</Form.Label>
                          <Controller
                            control={addActionForm.control}
                            name={"action.operator"}
                            render={({ field: { value, onChange } }): JSX.Element => {
                              return <UserSimpleDropdown
                                userId={value}
                                updateUserId={onChange}
                              />
                            }}
                          />
                        </Form.Group>

                        {/* Next status */}
                        <Form.Group className={"col-6"}>
                          <Form.Label>Next status</Form.Label>

                          <Controller
                            control={addActionForm.control}
                            name={"nextStatus"}
                            render={({ field: { value, onChange } }): JSX.Element => {
                              const color = statusColors[value]
                              return <Dropdown>
                                <Dropdown.Toggle as={CustomStatusToggle}>
                                  <span className={"position-relative"}>
                                    <Form.Control
                                      type={"text"}
                                      className={`tickets-modal__state-select cursor-pointer fw-bold bg-white text-${color}`}
                                      value={getOptionLabel(value)}
                                    />
                                    <span
                                      className={`project-colored-dot tickets-modal__state-select-dot bg-${color}`}
                                    />
                                  </span>
                                </Dropdown.Toggle>

                                <span
                                  className={"position-absolute tickets-modal__state-select-dot tickets-modal__status-select-chevron"}
                                >
                                  <BiChevronDown/>
                                </span>

                                <Dropdown.Menu className={"w-100"}>
                                  {
                                    ([
                                      "PENDING_SF",
                                      "PENDING_CALL",
                                      "PENDING_CUSTOMER",
                                      "CLOSED_LOST",
                                      "CONVERTED_TO_PROJECT"
                                    ] as LeadStatus[])
                                      .filter(status => status !== value)
                                      .map((status) => {
                                        return <Dropdown.Item
                                          key={status} onClick={(): void => onChange(status)}
                                          className={"tickets-modal__status-select-item text-uppercase"}
                                        >
                                          <span className={`project-colored-dot bg-${statusColors[status]}`}></span>
                                          <span className={`text-${statusColors[status]}`}>{getOptionLabel(status)}</span>
                                        </Dropdown.Item>
                                      })
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            }}
                          />
                        </Form.Group>
                      </div>

                      <div className={"row mt-3"}>
                        {/* Action timestamp */}
                        <Form.Group className={"col-6"}>
                          <Form.Label>
                            Action date
                          </Form.Label>
                          <Controller
                            control={addActionForm.control}
                            name={"action.actionTimestamp"}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, ...props } }): JSX.Element => {
                              return <Form.Control
                                {...props}
                                isInvalid={!!addActionForm.formState.errors.action?.actionTimestamp}
                                type={"datetime-local"}
                                value={value ? dayjs(value).format("YYYY-MM-DDTHH:mm") : ""}
                                onChange={({ target: { value } }): void => onChange(value ? dayjs(value).valueOf() : undefined)}
                              />
                            }}
                          />
                        </Form.Group>

                        <Form.Group className={"col-6"}>
                          {/* Reminder timestamp */}
                          <Form.Label>Reminder date</Form.Label>
                          <Controller
                            control={addActionForm.control}
                            name={"action.reminderTimestamp"}
                            render={({ field: { value, onChange, ...props } }): JSX.Element => {
                              return <Form.Control
                                {...props}
                                type={"datetime-local"}
                                disabled={!actionShouldHaveReminder}
                                value={value ? dayjs(value).format("YYYY-MM-DDTHH:mm") : ""}
                                onChange={({ target: { value } }): void => onChange(value ? dayjs(value).valueOf() : null)}
                              />
                            }}
                          />
                        </Form.Group>

                        {/* Notes */}
                        <Form.Group className={"mt-3"}>
                          <Form.Label>Notes</Form.Label>
                          <Form.Control
                            as={"textarea"}
                            rows={5}
                            {...addActionForm.register("action.notes", {
                              required: true,
                              setValueAs: (value: string): string => value.trim()
                            })}
                          />
                        </Form.Group>

                        <div className={"w-100 mt-2 d-flex justify-content-end"}>
                          <Button
                            className={"ms-auto"}
                            disabled={!addActionForm.formState.isValid || isLoading || readOnly}
                            onClick={handleAddAction}
                          >
                            <span className={"d-flex"}>
                              <Icon name={"plus"} className={"iconfix iconfix--translate1 me-1"}/>
                              Add action
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>}
                    {
                      leadActions.length === 0 && <div className={"text-center strike text-muted mt-3"}>
                        <span className="fst-italic">No recent actions</span>
                      </div>
                    }
                    {
                      leadActions.map(action => {
                        return <div className={"position-relative border rounded mt-3 p-3 overflow-hidden light-gradient-bg"}>
                          <div className={"d-flex justify-content-between align-items-baseline mb-1"}>
                            <div className={"fw-500 fs-4 d-flex align-items-center"}>{leadActionIcons[action.actionType]}&nbsp;{getOptionLabel(action.actionType)}</div>
                          </div>

                          <div className={"mb-1"}>created by <span className={"fw-semibold"}>{userMapping[action.operator]?.username}</span> on <TicketTimedText deadline={action.actionTimestamp} type={"timepassed"} warn={false} bold={false} className={"d-inline fw-semibold"} /></div>

                          {action.reminderTimestamp && <span className={"mb-1"}>reminder set ⏰ <TicketTimedText deadline={action.reminderTimestamp} type={"countdown"} warn={false} className={"d-inline fw-semibold"}/></span>}
                          <hr/>
                          <div className={"mt-2 mb-0"} style={{ whiteSpace: "pre-wrap" }}>{action.notes}</div>
                        </div>
                      })
                    }
                  </div>
                }
              </div>
            </>
        }
      </Modal.Body>
      <Modal.Footer>
        {
          leadId && leadStatus === "CLOSED_LOST" && <>
            <Button
              disabled={isLoading}
              variant={"danger"}
              onClick={async(): Promise<void> => {
                if (confirm("Are you sure you want to delete this lead?")) {
                  setIsLoading(true)
                  const loader = toast.loading("Deleting lead...")
                  try {
                    await handleApiCall(deleteLead, { handler: { leadId } })
                    await triggerListUpdate()
                    toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Lead deleted successfully" })
                    close()
                  } catch (error) {
                    handleException(error as Error)
                    setIsLoading(false)
                  }
                }
              }}
            >
              <BsTrashFill className={"iconfix iconfix--translate1 me-2"} />Delete
            </Button>

            <Button
              disabled={isLoading}
              onClick={async(): Promise<void> => {
                if (confirm("Are you sure you want to re-open this lead?")) {
                  setIsLoading(true)
                  const loader = toast.loading("Re-opening lead...")
                  try {
                    const { data: { status } } = await handleApiCall(reopenLead, { handler: { leadId } }) as ReopenLead200ResponseSchema
                    setLeadStatus(status)
                    toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Lead re-opened successfully" })
                  } catch (error) {
                    handleError(error, loader)
                  } finally {
                    setIsLoading(false)
                  }
                }
              }}
            >
              <BsArrowUpSquare className={"me-2 iconfix iconfix--translate1"}/>Re-open
            </Button>
          </>
        }

        {
          (!leadId || ["PENDING_SF", "PENDING_CUSTOMER", "PENDING_CALL"].includes(leadStatus)) &&
            <Button
              disabled={isLoading || !isUpdateLeadFormDirty}
              variant={"primary"}
              onClick={handleUpdateLead}
            >
              <MdSave className={"iconfix iconfix--translate1"}/> Save
            </Button>
        }
      </Modal.Footer>
    </Modal>

    {/* Estimate generation modal */}
    {
      !!leadId && <Modal
        show={showGenerateEstimateModal}
        centered
        className={"tickets-modal__backdrop"}
        onHide={(): void => setShowGenerateEstimateModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create estimate document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              Select a template
            </Form.Label>
            <Form.Select value={selectedEstimateTemplate} onChange={(event): void => setSelectedEstimateTemplate(event.target.value as EstimateTemplate)}>
              {
                [
                  "BASE",
                  "BUSINESS_PROPOSAL",
                  "INFRASTRUCTURE_SUPPORT",
                  "INFRASTRUCTURE",
                  "MIGRATION",
                  "STUDY",
                  "DEVELOPMENT",
                  "WAFR"
                ].map(estimateTemplate => {
                  return <option value={estimateTemplate} key={estimateTemplate}>
                    {getOptionLabel(estimateTemplate)}
                  </option>
                })
              }
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isEstimateGenerationLoading}
            onClick={async(): Promise<void> => {
              setIsEstimateGenerationLoading(true)
              const loader = toast.loading("Generating lead estimate...")

              try {
                const { data: { estimateUrl } } = await handleApiCall(generateLeadEstimate, { handler: { leadId }, estimateTemplate: selectedEstimateTemplate }) as GenerateLeadEstimate200ResponseSchema
                setLeadEstimateUrl(estimateUrl)
                toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Lead estimate generated successfully" })
              } catch (error) {
                handleError(error, loader)
              } finally {
                setIsEstimateGenerationLoading(false)
                setShowGenerateEstimateModal(false)
              }
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    }
  </>
}

export default LeadForm