import classNames from "classnames"
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Card, Dropdown, FormControl, Modal, Spinner } from "react-bootstrap"
import { BsClipboard, BsFillExclamationTriangleFill, BsArrowUpSquare, BsReplyFill } from "react-icons/bs"
import { BiChevronDown, BiQuestionMark } from "react-icons/bi"
import ReactLinkify from "react-linkify"
import { toast } from "react-toastify"
import SparkMD5 from "spark-md5"
import AuthContext from "../../contexts/AuthContext"
import { MinosseContext } from "../../contexts/MinosseContext"
import { handleException, logError, logInfo } from "../../helpers/logger"
import {
  SelectedTicketData,
  buildRecipient,
  generateRenderUserText,
  generateTicketHandler,
  getCCKey, getPriorityName
} from "../../helpers/tickets"
import { MINUTE, getCountdownText, getFormattedDateTime } from "../../helpers/time"
import { Mapping, getByteSize } from "../../helpers/utils"
import useDragNDrop from "../../hooks/useDragNDrop"
import { getColorFromPriority, getColorFromStatus } from "../../pages/Tickets"
import { CustomerUser, CustomerUsersService } from "../../sdk/minosse-principals-api"
import {
  EmailHandler,
  Recipient,
  Ticket,
  TicketStatus,
  TicketsService,
  UserHandler
} from "../../sdk/minosse-ticketing-api"
import CustomStatusToggle from "../CustomStatusToggle"
import Icon from "../Icon"
import MarkdownEditor from "../MarkdownEditor"
import SuggestionsDropdown from "../SuggestionsDropdown"
import TicketCCPicker from "./TicketCCPicker"
import TicketGuideModal from "./TicketGuideModal"
import TicketTimedText from "./TicketTimedText"

type TicketModalProps = {
  selectedTicket: SelectedTicketData | null
  setSelectedTicket: Dispatch<SetStateAction<SelectedTicketData | null>>
  reloadTicketList: () => Promise<void>
}

const TicketModal: React.FunctionComponent<TicketModalProps> = ({ selectedTicket, setSelectedTicket, reloadTicketList }) => {
  const { userMapping, activeUserList, customerMapping } = useContext(MinosseContext)
  const { user } = useContext(AuthContext)
  const [notesState, setNotesState] = useState<"rendered" | "editor">("rendered")

  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
  const [modalData, setModalData] = useState<Ticket | null>(null)
  const [shouldBlankModal, setShouldBlankModal] = useState(false)

  const [isGuideModalOpen, setIsGuideModalOpen] = useState(false)

  const [assignedStaffText, setAssignedStaffText] = useState<string>("")
  const [assignedStaffShowSuggestions, setAssignedStaffShowSuggestions] = useState<boolean>(false)

  const [customerData, setCustomerData] = useState<Mapping<CustomerUser> | undefined>(undefined)

  const [assignedStaffUserId, setAssignedStaffUserId] = useState<string | undefined>(undefined)

  const [notes, setNotes] = useState<string>("")
  const [notesAttachments, setNotesAttachments] = useState<string[]>([])
  const notesAttachmentsDivRef = useRef<HTMLDivElement>(null)

  const [isNextStateModalOpen, setIsNextStateModalOpen] = useState<boolean>(false)
  const [nextState, setNextState] = useState<TicketStatus | undefined>("PENDING_CUSTOMER")

  const baseNotesEqual = notes === (modalData?.notes?.body || "")
    && notesAttachments.length === (modalData?.notes?.attachments.length ?? 0)

  const renderUserText = generateRenderUserText(userMapping, customerMapping, customerData)

  const userCCKey = user ? getCCKey({ userId: user.userId }) : ""

  // I hate Safari. https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
  const windowOpenReference = window.open

  const [replyData, setReplyData] = useState<{
    isLoading: boolean
    text: string
    CC: Mapping<Recipient>
    CCshowSuggestions: boolean
    CCsearch: string
    attachments: { file: File | null, id: string, name: string }[]
  }>({
    isLoading: false,
    text: "",
    CC: {},
    CCshowSuggestions: false,
    CCsearch: "",
    attachments: []
  })

  const reloadData = useCallback(async(loadType: "initial" | "blank" | "silent" | null = "initial", shouldResetReply = false): Promise<boolean> => {
    if (selectedTicket) {
      if (loadType === "initial") {
        setIsModalLoading(true)
      } else if (loadType === "blank") {
        setShouldBlankModal(true)
      }
      try {
        const response = await TicketsService.getTicket({
          ticketHandler: generateTicketHandler(selectedTicket)
        })
        const customerResponse = await CustomerUsersService.listCustomerUsers({ customerId: selectedTicket.customerId, active: true })
        setCustomerData(customerResponse.data[0]?.users.reduce((acc: Mapping<CustomerUser>, user: CustomerUser) => {
          acc[user.userId] = user
          return acc
        }, {} as Mapping<CustomerUser>))

        const oldData = { ...modalData }

        setModalData(response.data)
        setAssignedStaffUserId(response.data.assignedStaff?.userId)

        if (loadType === "silent") {
          if (!baseNotesEqual) {
            toast.warning("Notes have changed, please reload to see changes")
          } else {
            setNotes(response.data.notes?.body ?? "")
            setNotesAttachments(response.data.notes?.attachments ?? [])
          }
          if (response.data.assignedStaff) {
            setAssignedStaffUserId(response.data.assignedStaff?.userId)
            setAssignedStaffText(userMapping[response.data.assignedStaff?.userId ?? ""]?.username ?? "")
          }
          if (replyData.text.length > 0 && oldData.status !== "SOLVED" && response.data.status === "SOLVED") {
            toast.warning("Reply text has been copied in the clipboard")
          }
          setReplyData(data => {
            const ccs = response.data.draftRecipients.reduce((cc: Mapping<Recipient>, recipient: Recipient) => {
              cc[getCCKey(recipient)] = buildRecipient(recipient)
              return cc
            }, {})

            return { ...data!, CC: ccs }
          })
        } else {
          setNotes(response.data.notes?.body ?? "")
          setNotesAttachments(response.data.notes?.attachments ?? [])
          setAssignedStaffText(userMapping[response.data.assignedStaff?.userId!]?.username ?? "")

          const ccs = response.data.draftRecipients.reduce((cc: Mapping<Recipient>, recipient: Recipient) => {
            cc[getCCKey(recipient)] = buildRecipient(recipient)
            return cc
          }, {})

          if (shouldResetReply || loadType === "initial") {
            setReplyData({
              isLoading: false,
              text: "",
              CC: ccs,
              CCshowSuggestions: false,
              CCsearch: "",
              attachments: []
            })
          }
          setModalData(response.data)
        }
      } catch (error) {
        handleException(error as Error)
        toast.error("An error occurred while fetching ticket details")
        close()
        logError(error)
        return false
      }
      if (loadType === "initial") {
        setIsModalLoading(false)
      } else if (loadType === "blank") {
        setShouldBlankModal(false)
      }
    }
    return true
  }, [selectedTicket, assignedStaffShowSuggestions, baseNotesEqual, replyData])

  useEffect(() => {
    if (selectedTicket) {
      setNotesState("rendered")
    }
    void reloadData()
  }, [selectedTicket])
  const close = (): void => setSelectedTicket(null)

  useEffect(() => {
    if (!selectedTicket || isModalLoading) {
      return
    }

    const hasSeenTutorialOnce = localStorage.getItem("ticketModal.hasSeenTutorialOnce")
    if (hasSeenTutorialOnce === null) {
      setIsGuideModalOpen(true)
      localStorage.setItem("ticketModal.hasSeenTutorialOnce", "true")
    }
  }, [selectedTicket, isModalLoading])

  const replyToTicket = async(replyType: "replyAndClose" | undefined = undefined): Promise<void> => {
    const hasAttachments = replyData.attachments.length > 0
    let attachmentsToast: React.ReactText | undefined

    setShouldBlankModal(true)
    if (hasAttachments) {
      attachmentsToast = toast.loading(`Uploading attachments... (1/${replyData.attachments.length})`, { autoClose: false, progress: 1 / replyData.attachments.length })
    } else {
      attachmentsToast = toast.loading("Replying to ticket...")
    }

    try {
      let attachments: string[] = []
      if (hasAttachments) {
        let count = 1
        attachments = await Promise.all(replyData.attachments.map(async attachment => {
          try {
            const urlResponse = await TicketsService.getAttachmentUrl({
              action: "PUT",
              ticketHandler: generateTicketHandler(modalData!),
              filename: attachment.name ?? attachment.id
            })
            await fetch(urlResponse.url, {
              method: "PUT",
              body: attachment.file
            })
            count += 1
            toast.update(attachmentsToast!, {
              render: `Uploading attachments... (${count}/${replyData.attachments.length})`,
              progress: count / replyData.attachments.length
            })
            return attachment.name
          } catch (ex) {
            logError(ex)
            handleException(ex as Error)
            toast.update(attachmentsToast!, { autoClose: 3, render: "Error uploading attachments", type: "error", isLoading: false })
            throw ex
          }
        }))
        toast.update(attachmentsToast!, {
          render: "Replying to ticket..."
        })
      }

      const newMessage = await TicketsService.addMessageToTicket({
        handler: {
          userId: user!.userId
        },
        attachments,
        ticketHandler: generateTicketHandler(modalData!),
        body: replyData.text,
        nextStatus: (replyType === "replyAndClose"
          ? ["PENDING_CUSTOMER", "WORK_IN_PROGRESS", "IDLE"].includes(modalData!.status) ? modalData!.status : "IDLE"
          : nextState
        ) as "PENDING_CUSTOMER" | "WORK_IN_PROGRESS" | "IDLE"
      })

      if (replyType === "replyAndClose") {
        toast.update(attachmentsToast!, {
          type: "info",
          isLoading: true,
          render: "Closing ticket..."
        })
        try {
          await TicketsService.closeTicket({
            ticketHandler: generateTicketHandler(modalData!),
            handler: {
              userId: user!.userId
            }
          })
          toast.update(attachmentsToast!, {
            autoClose: 3,
            render: "Ticket closed!",
            type: "success",
            isLoading: false
          })
          setModalData(data => ({
            ...data!,
            messages: [newMessage.data, ...data?.messages || []],
            status: "SOLVED"
          }))
        } catch (ex) {
          handleException(ex as Error)
          toast.update(attachmentsToast!, {
            autoClose: 3,
            render: "Unable to close ticket",
            type: "error",
            isLoading: false
          })
          return
        }
      } else {
        toast.update(attachmentsToast!, {
          autoClose: 3,
          render: "Replied to ticket!",
          type: "success",
          isLoading: false
        })
        setModalData(data => ({
          ...data!,
          messages: [newMessage.data, ...data?.messages || []]
        }))
      }

      setIsNextStateModalOpen(false)
      const reloadToast = toast.loading("Reloading ticket...", { autoClose: false })
      const result = await reloadData("blank", true)
      if (result) {
        toast.update(reloadToast, { render: "Ticket reloaded", type: "success", isLoading: false, autoClose: 3 })
      } else {
        toast.update(reloadToast, { render: "Error reloading ticket", type: "error", isLoading: false, autoClose: 3 })
      }
    } catch (ex) {
      logError(ex)
      handleException(ex as Error)
      setShouldBlankModal(false)
      toast.update(attachmentsToast!, {
        autoClose: 3,
        render: "Error replying to ticket",
        type: "error",
        isLoading: false
      })
    }
  }

  useEffect(() => {
    const interval = setInterval(async() => {
      try {
        if (modalData && selectedTicket) {
          logInfo("[tickets/modal] Checking for new ticket data...")
          const { lastUpdateTimestamp } = await TicketsService.getLastUpdateTimestamp({
            ticketHandler: generateTicketHandler(modalData),
            customerId: modalData.customerId
          })
          if (lastUpdateTimestamp > modalData.lastUpdateTimestamp) {
            logInfo("[tickets/modal] New ticket data found! Updating...")
            await toast.promise(reloadData("silent"), {
              pending: "New data found! Updating ticket...",
              success: "Ticket updated!",
              error: "An error occurred while updating ticket. Please reload."
            })
          }
        }
      } catch (ex) {
        logError("[tickets/modal] Unable to poll for new tickets", ex)
        handleException(ex as Error)
      }
    }, MINUTE)

    return (): void => clearInterval(interval)
  }, [modalData, reloadData, selectedTicket])

  const handleTicketStatusChange = async(status: TicketStatus): Promise<void> => {
    if (!modalData || shouldBlankModal) {
      return
    }

    if (!confirm(`Are you sure you want to change the status of this ticket to ${status.replaceAll(/_/g, " ")}?`)) {
      return
    }

    const oldStatus = modalData.status
    setShouldBlankModal(true)
    setModalData({
      ...modalData,
      status
    })
    try {
      if (status === "SOLVED") {
        await toast.promise(TicketsService.closeTicket({
          ticketHandler: generateTicketHandler(modalData),
          handler: { userId: user!.userId }
        }), {
          pending: "Closing ticket...",
          error: "Failed to close ticket",
          success: "Ticket closed!"
        })
      } else {
        if (oldStatus === "SOLVED") {
          await toast.promise(TicketsService.reopenTicket({
            ticketHandler: generateTicketHandler(modalData),
            handler: { userId: user!.userId }
          }), {
            pending: "Reopening ticket...",
            error: "Failed to reopen ticket",
            success: "Ticket reopened!"
          })
        }
        await toast.promise(TicketsService.switchTicketStatus({
          ticketHandler: generateTicketHandler(modalData),
          status: status as "IDLE" | "WORK_IN_PROGRESS",
          handler: { userId: user!.userId }
        }), {
          pending: "Updating status...",
          error: "Failed to update status",
          success: "Status updated!"
        })
        setModalData({
          ...modalData,
          status
        })
      }

      await reloadData("blank")
    } catch (ex) {
      handleException(ex as Error)
      logError(ex)
      setModalData({
        ...modalData,
        status: oldStatus as TicketStatus
      })
    }
    setShouldBlankModal(false)
  }

  const ticketTitle = `${modalData?.title?.slice(0, 100).trim()}${((modalData?.title?.length ?? 0) > 100 ? "..." : "")}`

  const sortedCCs = useMemo(() => {
    const ccs = Object.entries(replyData.CC).reduce((acc, [key, handler]) => {
      if (!modalData) {
        return acc
      }

      const lastMessage = modalData?.messages?.find(message => !message.private)
      const cc = handler as UserHandler

      if ((handler as EmailHandler).email && !cc.customerId && !cc.userId) {
        acc.removable[key] = { email: (handler as EmailHandler).email }
      } else {
        if (cc.userId === assignedStaffUserId
          || (cc.userId === modalData.author.userId && cc.customerId === modalData.author.customerId)
          || (cc.userId === lastMessage?.author.userId && cc.customerId === lastMessage?.author.customerId)) {
          acc.permanent[key] = cc
        } else {
          acc.removable[key] = { userId: cc.userId, customerId: cc.customerId }
        }
      }

      return acc
    }, { permanent: {}, removable: {}, temp: {} } as { permanent: Mapping<UserHandler>, removable: Mapping<Recipient>, temp: Mapping<Recipient> })
    if (!(userCCKey in ccs.permanent) && !(userCCKey in ccs.temp) && user) {
      if (userCCKey in ccs.removable) {
        delete ccs.removable[userCCKey]
        ccs.permanent[userCCKey] = { userId: user!.userId }
      } else {
        ccs.temp[userCCKey] = { userId: user!.userId }
      }
    }
    return ccs
  }, [replyData, modalData, assignedStaffUserId, user])

  const isDirty = useMemo(() => {
    return replyData.text !== ""
      || replyData.attachments.length > 0
      || !baseNotesEqual
  }, [replyData, notesState, baseNotesEqual])

  const slaRespectedTime = useMemo(() => {
    if (!modalData?.messages || !modalData.firstInteractionTimestamp) {
      return undefined
    }

    const firstMessageTimestamp = ([...modalData.messages]
      .sort((a, b) => a.timestamp - b.timestamp)
      .find(message => message.author.customerId === undefined)?.timestamp)

    if (firstMessageTimestamp) {
      return modalData.firstInteractionTimestamp - firstMessageTimestamp
    } else {
      return undefined
    }
  }, [modalData])

  const handleFile = async(file: File): Promise<void> => {
    try {
      const md5 = SparkMD5.ArrayBuffer.hash(await file.arrayBuffer())

      if (replyData.attachments.some(item => item.id === md5)) {
        toast.error("File already attached")
        return
      }

      let name = file.name
      let seriesNumber = 1
      const filesDict = replyData.attachments.reduce((acc, item) => {
        acc[item.name] = item
        return acc
      }, {} as Record<string, { name: string, id: string, file: File | null }>)
      while (filesDict[name] && seriesNumber <= 10000) {
        name = `(${seriesNumber}) ${file.name}`
        seriesNumber++
      }

      setReplyData(data => ({
        ...data!,
        attachments: data!.attachments.concat([{ file, id: md5, name }])
      }))
    } catch (ex) {
      handleException(ex as Error)
      toast.error("Error reading file")
      return
    }
  }

  const { element, handleDrag } = useDragNDrop({
    elementClassName: "tickets-modal",
    handleFile
  })

  return <>
    <Modal
      show={selectedTicket}
      centered
      className="tickets-modal"
      scrollable
      size="xl"
      onHide = {(): void => {
        if (!shouldBlankModal && !replyData.isLoading) {
          if (isDirty) {
            if (confirm("You have unsaved changes. Are you sure you want to close this ticket?")) {
              close()
            }
          } else {
            close()
          }
        }
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className={"w-100"}>
          <span className="d-flex justify-content-between">
            {isModalLoading ? <span className="fst-italic">Loading ticket...</span> : <span><span className="fw-bold">{customerMapping[modalData?.customerId || ""]?.customerName}</span> - {ticketTitle}</span>}

            <span className="cursor-pointer" onClick={(): void => setIsGuideModalOpen(true)}>
              <BiQuestionMark className={"iconfix--translate1 me-2 tickets-modal__question-mark"} />
            </span>
          </span>
        </Modal.Title>
      </Modal.Header>
      {isModalLoading || !modalData ?
        <div className={"w-100 d-flex justify-content-center align-items-center"} style={{ height: "80vh" }}>
          <Spinner animation="border" variant="dark" />
        </div> :
        <>
          <Modal.Body className={"tickets-modal__body pb-0"}>
            <div onTransitionEnd={(): void => {}} className={classNames("tickets-modal__opaque-sheet", {
              "tickets-modal__opaque-sheet--active": shouldBlankModal
            })} />

            <div className="d-flex flex-column h-100">
              <div className="d-flex h-100 flex-column">
                <div className={"row"}>
                  <div className={"col-4"}>
                    <div className={"form-label fw-bold"}>AUTHOR</div>
                    <FormControl type="text" value={modalData.author.customerId ? `${customerData?.[modalData.author.userId].username} (${customerMapping[modalData?.author?.customerId!]?.customerName})` : userMapping[modalData.author.userId].username} readOnly className="bg-white" />
                  </div>
                  <div className={"col-4"}>
                    <div className={"form-label fw-bold"}>CREATED AT</div>
                    <div className={"form-control"}>
                      <TicketTimedText deadline={modalData.creationTimestamp} warn={false} type="timepassed" bold={false} />
                    </div>
                  </div>
                  {modalData.status !== "SOLVED" && <div className={"col-4"}>
                    <div className={"form-label fw-bold"}>DEADLINE</div>
                    {slaRespectedTime
                      ? <FormControl
                        type="text" className="bg-white"
                        value={getFormattedDateTime(modalData.slaDeadlineTimestamp!)
                          + (slaRespectedTime > 0
                            ? ` (SLA achieved ${getCountdownText(slaRespectedTime, undefined, undefined, "HOUR")})`
                            : " (SLA not achieved)")
                        }
                        readOnly
                      />
                      : <div className={"form-control"}>
                        {modalData.slaDeadlineTimestamp ? <TicketTimedText deadline={modalData.slaDeadlineTimestamp ?? 0} bold={false} limitAt="HOUR" /> : <span className={"text-muted"}>No deadline</span>}
                      </div>
                    }
                  </div>}
                  {modalData.status === "SOLVED" && modalData.resolutionTimestamp && <div className={"col-4"}>
                    <div className={"form-label fw-bold"}>RESOLUTION</div>
                    <div className={"form-control"}>
                      <TicketTimedText deadline={modalData.resolutionTimestamp} warn={false} type="timepassed" bold={false} limitAt="HOUR" />
                    </div>
                  </div>}
                </div>

                <div className="row mt-3">
                  <div className={"col-4"}>
                    <div className={"form-label fw-bold"}>STATUS</div>
                    {["WORK_IN_PROGRESS", "IDLE", "PENDING_CUSTOMER", "UNASSIGNED", "PENDING_FIRST_INTERACTION"].includes(modalData.status)
                      ? <Dropdown>
                        <Dropdown.Toggle as={CustomStatusToggle}>
                          <span className={"position-relative"}>
                            <span className={`project-colored-dot tw-z-40 tickets-modal__state-select-dot bg-${getColorFromStatus(modalData.status)}`}></span>
                            <FormControl type="text" className={`tickets-modal__state-select cursor-pointer bg-white text-${getColorFromStatus(modalData.status)}`} value={modalData.status.replaceAll(/_/g, " ")} readOnly />
                          </span>
                        </Dropdown.Toggle>

                        <span className={"position-absolute tickets-modal__state-select-dot tickets-modal__status-select-chevron"}><BiChevronDown /></span>

                        <Dropdown.Menu>
                          {["WORK_IN_PROGRESS", "IDLE", "PENDING_CUSTOMER", "SOLVED"]
                            .filter(status => (!["UNASSIGNED", "PENDING_FIRST_INTERACTION"].includes(modalData.status) && status !== modalData.status) || (["UNASSIGNED", "PENDING_FIRST_INTERACTION"].includes(modalData.status) && status === "SOLVED"))
                            .map(status => {
                              const color = getColorFromStatus(status as TicketStatus)
                              const disabled = status === modalData.status || (["UNASSIGNED", "PENDING_FIRST_INTERACTION"].includes(modalData.status) && status !== "SOLVED")

                              return <Dropdown.Item disabled={disabled} eventKey={status} onClick={(): void => void handleTicketStatusChange(status as TicketStatus)} className={disabled ? "tickets-modal__status-select-item opacity-25" : "tickets-modal__status-select-item"}>
                                <span className={`project-colored-dot bg-${color}`}></span><span className={`text-${color}`}>{status.replaceAll(/_/g, " ")}</span>
                              </Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                      : <span className="position-relative">
                        <span><FormControl type="text" className={`tickets-modal__state-select bg-white text-${getColorFromStatus(modalData.status)}`} value={modalData.status.replaceAll(/_/g, " ")} readOnly /></span>
                        <span className={`project-colored-dot tickets-modal__state-select-dot bg-${getColorFromStatus(modalData.status)}`}></span>
                      </span>
                    }
                  </div>
                  <div className="col-4">
                    <div className={"form-label fw-bold"}>PRIORITY</div>
                    <div className="position-relative">
                      {modalData.priority === "CRITICAL"
                        ? <span className="position-absolute tickets-modal__priority-select-triangle">
                          <BsFillExclamationTriangleFill fill={"var(--bs-red)"} />
                        </span>
                        : <span className={`position-absolute project-colored-dot tickets-modal__priority-select-dot bg-${getColorFromPriority(modalData.priority)}`}></span>
                      }
                      <FormControl
                        type="text"
                        value={getPriorityName(modalData.priority, true)}
                        readOnly
                        style={{ paddingLeft: modalData.priority === "CRITICAL" ? "2.30rem" : "1.75rem" }}
                        className={`bg-white text-${getColorFromPriority(modalData.priority)}`}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className={"form-label fw-bold"}>ASSIGNED STAFF</div>
                    <SuggestionsDropdown
                      textboxValue={assignedStaffText}
                      shouldShowSuggestions={assignedStaffShowSuggestions}
                      itemList={activeUserList}
                      itemMatchKey={"username"}
                      itemTextFunction={(item): string => item.username}
                      onItemClick={async(item): Promise<void> => {
                        setAssignedStaffShowSuggestions(false)
                        setAssignedStaffUserId(item.userId)
                        setAssignedStaffText(item.username)
                        setShouldBlankModal(true)

                        try {
                          await toast.promise(TicketsService.assignStaffToTicket({
                            ticketHandler: generateTicketHandler(modalData),
                            staff: {
                              userId: item.userId
                            }
                          }), {
                            pending: "Assigning staff...",
                            success: "Staff assigned successfully",
                            error: "An error occurred while assigning staff. Please try again"
                          })
                          setReplyData(data => {
                            const CC = data!.CC
                            CC[getCCKey(item)] = { userId: item.userId }
                            return { ...data!, CC }
                          })
                          await reloadTicketList()
                          await reloadData("silent")
                        } catch (ex) {
                          handleException(ex as Error)
                          logError(ex)
                        }

                        setShouldBlankModal(false)
                      }}
                    >
                      <FormControl
                        type={"text"}
                        className={"disabled-lighter"}
                        value={assignedStaffText || (modalData.status === "SOLVED" ? "No staff assigned" : "")}
                        disabled={modalData.status === "SOLVED"}
                        onChange={(e): void => {
                          setAssignedStaffText(e.target.value)
                          setAssignedStaffShowSuggestions(true)
                        }}
                        onFocus={(): void => {
                          setAssignedStaffText("")
                        }}
                        onBlur={(): void => {
                          setAssignedStaffText(userMapping[assignedStaffUserId ?? ""]?.username ?? "")
                          setAssignedStaffShowSuggestions(false)
                        }}
                      />
                    </SuggestionsDropdown>
                  </div>
                </div>

                <div className={"row align-items-lg-stretch flex-grow-1 h-100"}>
                  <div className={classNames("col-xl-8 col-lg-6 col-md-12 pb-3", {
                    "opacity-50": replyData.isLoading
                  })} style={{ pointerEvents: replyData.isLoading ? "none" : undefined }}>
                    <div className={"form-label mt-3 fw-bold"}>MESSAGES</div>

                    {!["SOLVED", "UNASSIGNED"].includes(modalData?.status) && <span>
                      <Card className={"mt-2 py-2 p-3 position-relative"} onDragEnter={handleDrag}>
                        {element}
                        <div className={"form-label fw-bold"}>REPLY</div>

                        <div className="tickets-modal__alignment-area mb-2">
                          <TicketCCPicker
                            wrapped={false}
                            setCC={async(func): Promise<boolean> => {
                              setShouldBlankModal(true)
                              try {
                                const newCC = func(replyData.CC)

                                await TicketsService.updateDraftRecipients({
                                  ticketHandler: generateTicketHandler(modalData),
                                  draftRecipients: Object.values(newCC).map(item => buildRecipient(item))
                                })

                                setReplyData(data => ({
                                  ...data!,
                                  CC: newCC
                                }))
                                setShouldBlankModal(false)
                                return true
                              } catch (ex) {
                                handleException(ex as Error)
                                logError(ex)
                                setShouldBlankModal(false)
                                return false
                              }
                            }}
                            customerData={customerData}
                            tempCCs={sortedCCs.temp}
                            permanentCCs={sortedCCs.permanent}
                            removableCCs={sortedCCs.removable}
                          />

                          <label className="mb-2 mt-3">Attachments:&nbsp;</label>
                          <span className={"w-100 d-inline-flex align-items-center flex-wrap mt-3"}>
                            {replyData.attachments.map(attachment =>
                              <span className="d-inline-flex fs-6 me-2 mb-2 border border-dark py-1 px-2 rounded">
                                <span>{attachment.name} ({getByteSize(attachment.file?.size || 0)})</span>&nbsp;
                                <Icon name="trash-fill" className="iconfix--translate1" onClick={(): void => {
                                  setReplyData(data => ({
                                    ...data!,
                                    attachments: data!.attachments.filter(item => item.id !== attachment.id)
                                  }))
                                }} />
                              </span>
                            )}

                            <label className={"btn btn-sm btn-outline-dark d-inline-flex fs-6 me-2 mb-2 py-1 px-2"} htmlFor="test">
                              <Icon name="plus" className="iconfix--translate1" />&nbsp;Add Attachment
                            </label>

                            <input type="file" className="d-none" id={"test"} onChange={(e): void => {
                              // @ts-ignore
                              const file = e.target.files[0]
                              if (!file) {
                                toast.error("No file selected")
                              }

                              void handleFile(file)
                            }} />
                          </span>
                        </div>

                        <FormControl
                          as="textarea"
                          className={"project__tasks-notes-editor"}
                          placeholder={"Reply here"}
                          value={replyData.text}
                          onChange={(e): void => setReplyData(r => ({ ...r!, text: e.target.value }))}
                        />

                        <Button disabled={(shouldBlankModal || replyData.text.trim().length === 0)} variant="primary" className="ms-auto mt-2" onClick={async(): Promise<void> => {
                          setNextState("PENDING_CUSTOMER")
                          setIsNextStateModalOpen(true)
                        }}>
                          <span className="d-flex">
                            <BsReplyFill className="me-2" />
                            <span>Reply</span>
                          </span>
                        </Button>
                      </Card>
                    </span>}

                    {modalData.status === "UNASSIGNED" && <span>
                      <Card className={"mt-2 py-2 p-3"}>
                        <div className={"fst-italic text-black-50 w-100 text-center"}>
                        Assign the ticket to a user before replying
                        </div>
                      </Card>
                    </span>}

                    {modalData.messages?.map(message => <>
                      <Card className={classNames("mt-2 p-2 pt-2", {
                        "tickets-modal__message--private": message.private,
                        "tickets-modal__message--customer": !message.private && message.author.customerId !== undefined,
                        "tickets-modal__message--staff": !message.private && message.author.customerId === undefined
                      })}>
                        <div className="d-flex mb-3 justify-content-between" style={{ marginTop: "-0.2rem" }}>
                          <div>
                            <div>{message.private ? "Operator" : "From"}: <b>{renderUserText(message.author, "companyEmailOnly")}</b></div>
                            {message.recipients?.length > 0 && <div>
                              Recipients:&nbsp;{message.recipients.map((cc, i, arr) => <span>
                                <b>{renderUserText(cc, "company")}</b>{i !== arr.length - 1 && <>,&nbsp;</>}
                              </span>)}
                            </div>}
                            <div>Date: <span className={"d-inline-block"}><TicketTimedText deadline={message.timestamp} type="timepassed" warn={false} /></span></div>
                          </div>
                          {message.private && <div className="fst-italic opacity-75">System message</div>}
                        </div>
                        {message.attachments.length > 0 && <div className={"mb-3"}>
                          <div className={"fw-bold text-uppercase"}>Attachments</div>
                          {message.attachments.map(attachment => <div className={""}>
                            –&nbsp;<span className={"cursor-pointer text-decoration-underline"} onClick={async(): Promise<void> => {
                              try {
                                const file = await TicketsService.getAttachmentUrl({
                                  action: "GET",
                                  filename: attachment,
                                  ticketHandler: generateTicketHandler(modalData)
                                })
                                windowOpenReference?.(file.url, "_blank")?.focus()
                              } catch (ex) {
                                handleException(ex as Error)
                                logError(ex)
                                toast.error("Error opening attachment")
                              }
                            }}>{attachment}</span>
                          </div>)}
                        </div>}
                        <div className={message.private ? "fst-italic" : ""}>
                          <ReactLinkify
                            componentDecorator={(decoratedHref, decoratedText, key): React.ReactNode => <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>}
                          >
                            {message.body.split("\n").map((line, i, lines) => <>
                              <span key={i} className={"mb-0"}>{line}</span>
                              {i !== lines.length - 1 && <br />}
                            </>)}
                          </ReactLinkify>
                        </div>
                      </Card>
                    </>)}
                  </div>

                  <div className={"col-xl-4 col-lg-6 col-sm-12 pb-3 tickets-modal__text-editor-fix d-flex flex-column"}>
                    <div className="mt-3">
                      <div className="form-label fw-bold">TICKET ID</div>
                      <div className="position-relative">
                        <FormControl type="text" value={modalData.ticketId} readOnly className="bg-white" />
                        <BsClipboard
                          className={"reports__clear-icon cursor-pointer text-black-50"}
                          onClick={(): void => {
                            void navigator.clipboard.writeText(modalData.ticketId)
                              .then(() => toast.info("Copied to clipboard"))
                          }}
                        />
                      </div>
                    </div>
                    <div className={"form-label mt-3 fw-bold text-uppercase"}>NOTES</div>
                    <MarkdownEditor
                      text={notes}
                      state={notesState}
                      setState={setNotesState}
                      setText={(notes): void => setNotes(notes)}
                      className="tickets-modal__text-editor flex-grow-1"
                      onUserStateChange={async(state): Promise<void> => {
                        if (state === "rendered" && !baseNotesEqual) {
                          try {

                            await toast.promise(TicketsService.updateTicketNotes({
                              notes: {
                                body: notes,
                                attachments: notesAttachments
                              },
                              ticketHandler: generateTicketHandler(modalData)
                            }), {
                              pending: "Updating notes...",
                              success: "Notes updated",
                              error: "Error updating notes"
                            })

                            setModalData(data => ({
                              ...data!,
                              notes: {
                                body: notes,
                                attachments: notesAttachments
                              }
                            }))
                          } catch (ex) {
                            handleException(ex as Error)
                            logError(ex)
                          }
                        }
                      }}
                    />
                    <div ref={notesAttachmentsDivRef} className={"pt-3"}>
                      <div className={"form-label fw-bold text-uppercase"}>NOTES ATTACHMENTS</div>
                      <div>
                        {notesAttachments.map((attachment) =>
                          <span className={"d-inline-flex fs-6 me-2 mb-2 mt-1 border border-dark py-1 px-2 rounded"}>
                            <span className={"text-decoration-underline cursor-pointer"} onClick={async(): Promise<void> => {
                              try {
                                const file = await toast.promise(TicketsService.getAttachmentUrl({
                                  action: "GET",
                                  filename: attachment,
                                  ticketHandler: generateTicketHandler(modalData)
                                }), {
                                  pending: "Loading attachment URL...",
                                  success: "Attachment URL loaded...",
                                  error: "Error loading attachment URL"
                                })
                                window?.open?.(file.url, "_blank")?.focus()
                              } catch (ex) {
                                handleException(ex as Error)
                                logError(ex)
                              }
                            }}>{attachment}</span>
                            &nbsp;
                            <Icon name="trash-fill" className="iconfix--translate1" onClick={async(): Promise<void> => {
                              try {
                                await toast.promise(TicketsService.updateTicketNotes({
                                  notes: {
                                    body: modalData.notes?.body ?? "",
                                    attachments: notesAttachments.filter(a => a !== attachment)
                                  },
                                  ticketHandler: generateTicketHandler(modalData)
                                }), {
                                  pending: "Deleting attachment...",
                                  success: "Attachment deleted",
                                  error: "Error deleting attachment"
                                })
                                setModalData(data => ({
                                  ...data!,
                                  notes: {
                                    body: modalData.notes?.body ?? "",
                                    attachments: notesAttachments.filter(a => a !== attachment)
                                  }
                                }))
                                setNotesAttachments(na => na.filter(a => a !== attachment))
                              } catch (ex) {
                                handleException(ex as Error)
                                logError(ex)
                              }
                            }} />
                          </span>
                        )}
                      </div>
                      <div>

                        <label className={"btn btn-default btn-outline-dark d-flex justify-content-center mt-2"} htmlFor="test2">
                          <Icon name="plus" />&nbsp;Add Attachment
                          <input type="file" className="d-none" id={"test2"} onChange={async(e): Promise<void> => {
                            // @ts-ignore
                            const file = e.target.files[0]
                            if (!file) {
                              return
                            }

                            const toastId = toast.loading("Uploading attachment...")

                            try {
                              let name = file.name
                              let seriesNumber = 1
                              const filesDict = notesAttachments.reduce((acc, item) => {
                                acc[item] = item
                                return acc
                              }, {} as Record<string, string>)
                              while (filesDict[name] && seriesNumber <= 10000) {
                                name = `(${seriesNumber}) ${file.name}`
                                seriesNumber++
                              }

                              const urlResponse = await TicketsService.getAttachmentUrl({
                                action: "PUT",
                                ticketHandler: generateTicketHandler(modalData),
                                filename: name
                              })
                              await fetch(urlResponse.url, {
                                method: "PUT",
                                body: file
                              })

                              await TicketsService.updateTicketNotes({
                                notes: {
                                  body: modalData.notes?.body ?? "",
                                  attachments: [...notesAttachments, name]
                                },
                                ticketHandler: generateTicketHandler(modalData)
                              })

                              toast.update(toastId, {
                                autoClose: 3,
                                render: "Attachment uploaded!",
                                type: "success",
                                isLoading: false
                              })

                              setNotesAttachments([...notesAttachments, name])
                              setModalData(data => ({
                                ...data!,
                                notes: {
                                  body: modalData.notes?.body ?? "",
                                  attachments: [...notesAttachments, name]
                                }
                              }))
                            } catch (ex) {
                              handleException(ex as Error)
                              toast.update(toastId, {
                                autoClose: 3,
                                render: "Failed to upload attachment",
                                type: "error",
                                isLoading: false
                              })
                              return
                            }
                          }} />
                        </label>
                      </div>
                    </div>
                    {!baseNotesEqual && <div className={"text-danger small mt-2 ms-auto"}>Unsaved changes!</div>}
                  </div>
                </div>
              </div>
            </div>

            {/* <div style={{ marginBottom: "auto" }}></div> */}
          </Modal.Body>
          {modalData.status === "SOLVED" && <Modal.Footer>
            <Button disabled={shouldBlankModal} onClick={async(): Promise<void> => {
              try {
                await toast.promise(TicketsService.reopenTicket({
                  handler: { userId: user!.userId },
                  ticketHandler: generateTicketHandler(modalData!)
                }), {
                  pending: "Reopening ticket...",
                  success: "Ticket reopened",
                  error: "Error reopening ticket"
                })
                await reloadData("blank")
              } catch (ex) {
                handleException(ex as Error)
                logError(ex)
              }
            }}
            >
              <span className="d-flex">
                <BsArrowUpSquare className="me-2 iconfix--translate1" />Reopen ticket
              </span>
            </Button>
          </Modal.Footer>}
        </>}
    </Modal>
    <Modal
      show={!!(selectedTicket && modalData && isNextStateModalOpen)}
      centered
      scrollable
      className="tickets-modal__backdrop"
      onHide = {(): void => {
        if (!shouldBlankModal) {
          setIsNextStateModalOpen(false)
        }
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Next ticket status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={classNames({ "opacity-50 pointer-events-none": shouldBlankModal })}>
          {["WORK_IN_PROGRESS", "IDLE", "PENDING_CUSTOMER", "SOLVED"].map(status => {
            const color = getColorFromStatus(status as TicketStatus)

            return <div id={status} onClick={(): void => void setNextState(status as TicketStatus)} className={classNames("tickets-modal__next-status-item", {
              "tickets-modal__next-status-item--selected": status === nextState
            })}>
              <span className={`project-colored-dot bg-${color}`}></span><span className={`text-${color}`}>{status.replaceAll(/_/g, " ")}</span>
            </div>
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(): void => {
          setIsNextStateModalOpen(false)
        }} disabled={shouldBlankModal}>Cancel</Button>
        <Button variant="primary" onClick={(): void => {
          if (nextState === "SOLVED") {
            void replyToTicket("replyAndClose")
          } else {
            void replyToTicket()
          }
        }} disabled={shouldBlankModal}>Submit</Button>
      </Modal.Footer>
    </Modal>
    {selectedTicket !== undefined && !isModalLoading && <TicketGuideModal show={isGuideModalOpen} setShow={setIsGuideModalOpen}/>}
  </>
}

export default TicketModal
