import React, { useContext, useEffect, useState } from "react"
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap"
import { BsArrowRepeat, BsCheckLg } from "react-icons/bs"
import AuthContext, { setSDKSession } from "../contexts/AuthContext"
import { handleError } from "../helpers/utils"
import { StaffUsersService } from "../sdk/minosse-principals-api"
import Icon from "./Icon"

type MFAFormProps = {
  open: boolean
  close: () => void
  onSubmit?: (code: string) => void
  onCancel?: () => void
}

const MFAForm: React.FunctionComponent<MFAFormProps> = ({ open, close }) => {
  const { user, setUser } = useContext(AuthContext)

  const [state, setState] = useState<"loading" | "warning" | "setup" | "done" | "error">("loading")
  const [generated, setGenerated] = useState<{ uri: string, qr: string } | null>(null)
  const [token, setToken] = useState("")

  useEffect(() => {
    if (user && open) {
      setState(user.hasMFA ? "warning" : "loading")
      setGenerated(null)
      setToken("")

      if (!user.hasMFA) {
        void generateMFA()
      }
    }
  }, [open])

  const generateMFA = async(): Promise<void> => {
    setState("loading")
    try {
      const { data } = await StaffUsersService.generateStaffUserMfaSecret({ handler: { userId: user!.userId } })
      setGenerated(data)
    } catch (error) {
      handleError(error)
    } finally {
      setState("setup")
    }
  }

  const confirmMFA = async(): Promise<void> => {
    setState("loading")
    try {
      const { session } = await StaffUsersService.confirmStaffUserMfaSecret({ handler: { userId: user!.userId }, token })
      setSDKSession(session)
      if (user) {
        setUser({ ...user, hasMFA: true })
      }
      setState("done")
    } catch (error) {
      handleError(error)
      setState("error")
    }
  }

  const removeMFA = async(): Promise<void> => {
    setState("loading")
    try {
      await StaffUsersService.removeStaffUserMfaToken({ handler: { userId: user!.userId } })
      setUser({ ...user!, hasMFA: false })
      setState("done")
    } catch (error) {
      handleError(error)
      setState("warning")
    }
  }

  return <Modal centered show={open} onHide={close} className="time-entry-form">
    <Modal.Header closeButton>
      <Modal.Title>
        MFA Setup
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {state === "loading" && <span className={"d-flex justify-content-center my-5"}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </span>}

      {state === "warning" && <>
        <p>You already have MFA enabled.</p>
        <p>Are you <i>sure</i> you want to pair your MFA generator again? Your current generator will stop being valid as soon as you verify the new one.</p>
        <div className={"tw-grid tw-grid-cols-2 tw-gap-2"}>
          <Button className={"w-100"} variant="dark" onClick={generateMFA}>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <BsArrowRepeat />Pair again
            </div>
          </Button>
          <Button className={"w-100"} variant="danger" onClick={removeMFA}>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <Icon name={"trash-fill"} />Remove MFA
            </div>
          </Button>
        </div>
      </>}

      {(state === "setup" || state === "error") && !!generated && <>
        <p className={"d-flex justify-content-center"}>
          <img src={generated.qr} alt={"qrcode"} className={"w-75 h-auto me-0"}/>
        </p>
        <p>Use your preferred MFA app and your device's camera to scan the QR code.</p>
        <p>Otherwise, you can copy the following URI in your password manager:</p>
        <p className={"mfa-code"}><code>{generated.uri}</code></p>

        <InputGroup>
          <Form.Control
            isInvalid={state === "error"}
            type="text"
            inputMode="numeric"
            pattern="[0-9]{6}"
            autoComplete="one-time-code"
            maxLength={6}
            placeholder="Token"
            value={token}
            onChange={(e): void => {
              if (state === "error") {
                setState("setup")
              }
              setToken(e.target.value)
            }}
          />
          <Button variant="primary" onClick={confirmMFA}>Verify</Button>
          <FormControl.Feedback type="invalid">
            Please enter a valid code.
          </FormControl.Feedback>
        </InputGroup>
      </>}

      {state === "done" && <span className={"d-flex align-items-center flex-column"}>
        <BsCheckLg className={"mt-3 text-success"} style={{ width: 32, height: 32 }} />
        <p className={"mt-3 mb-5"}>Your MFA has been successfully {user?.hasMFA ? "enabled" : "disabled"}.</p>
        <Button variant="dark" className={"w-100"} onClick={close}>Close</Button>
      </span>}
    </Modal.Body>
  </Modal>
}

export default MFAForm