import React, { FunctionComponent } from "react"
import { Col, Dropdown, ListGroup, OverlayTrigger, Popover, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { getFormattedDate } from "../helpers/time"
import { ButtonVariant } from "react-bootstrap/types"
import dayjs from "dayjs"

type StatisticsCalendarProps = {
  startDate: Date | null
  endDate: Date | null
  showAllTime?: boolean
  onDateChange: (dates: [Date | null, Date | null]) => void
  variant?: ButtonVariant
  render?: React.ReactElement
}

const StatisticsCalendar: FunctionComponent<StatisticsCalendarProps> = ({
  startDate,
  endDate,
  showAllTime = false,
  onDateChange,
  variant = "outline-dark",
  render
}) => {

  return <div className="calendar-div">
    <OverlayTrigger trigger="click" placement="bottom" rootClose rootCloseEvent="mousedown" overlay={
      <Popover className = { "statistics-calendar__date_range-popover" } >
        <Popover.Body>
          <Row className = "statistics-calendar__row">
            <Col>
              <ListGroup>
                {showAllTime && <ListGroup.Item className= "list-option cursor-pointer" onClick={(): void => onDateChange([null, null])}>
                  All time
                </ListGroup.Item>}
                <ListGroup.Item className="list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz()
                  onDateChange([date.startOf("day").toDate(), date.endOf("day").toDate()])
                }}>
                  Today
                </ListGroup.Item>
                <ListGroup.Item className="list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz().subtract(1, "day")
                  onDateChange([date.startOf("day").toDate(), date.endOf("day").toDate()])
                }}>
                  Yesterday
                </ListGroup.Item>
                <ListGroup.Item className= "list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz()
                  onDateChange([date.startOf("isoWeek").startOf("day").toDate(), date.endOf("isoWeek").endOf("day").toDate()])
                }}>
                  This week
                </ListGroup.Item>
                <ListGroup.Item className="list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz().subtract(1, "week")
                  onDateChange([date.startOf("isoWeek").startOf("day").toDate(), date.endOf("isoWeek").endOf("day").toDate()])
                }}>
                  Last Week
                </ListGroup.Item>
                <ListGroup.Item className="list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz()
                  onDateChange([date.startOf("month").startOf("day").toDate(), date.endOf("month").endOf("day").toDate()])
                }}>
                  This Month
                </ListGroup.Item>
                <ListGroup.Item className="list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz().subtract(1, "month")
                  onDateChange([date.startOf("month").startOf("day").toDate(), date.endOf("month").endOf("day").toDate()])
                }}>
                  Last Month
                </ListGroup.Item>
                <ListGroup.Item className="list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz()
                  onDateChange([date.startOf("year").startOf("day").toDate(), date.endOf("year").endOf("day").toDate()])
                }}>
                  This Year
                </ListGroup.Item>
                <ListGroup.Item className="list-option cursor-pointer" onClick={(): void => {
                  const date = dayjs().tz().subtract(1, "year")
                  onDateChange([date.startOf("year").startOf("day").toDate(), date.endOf("year").endOf("day").toDate()])
                }}>
                  Last Year
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col>
              <DatePicker
                dateFormat={"dd/MM/yyyy"}
                locale={"en-GB"}
                minDate={new Date(1, 0, 2019, 0, 0, 0, 0)}
                selected={startDate}
                onChange={(([start, end]): void => {
                  if (start) {
                    start = dayjs(start).tz().startOf("day").toDate()
                  }
                  if (end) {
                    end = dayjs(end).tz().endOf("day").toDate()
                  }
                  return onDateChange([start, end])
                })}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    } >
      {((render !== undefined && render !== null) ? render : <Dropdown.Toggle variant={variant}>
        Date:&nbsp;<b>{showAllTime && !startDate && !endDate ? "All time" : `${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`}</b>
      </Dropdown.Toggle>) as React.ReactElement}
    </OverlayTrigger>
  </div>
}

export default StatisticsCalendar