import { TrackerTimeEntry } from "../contexts/MinosseContext"
import { Project, TimeEntry } from "@polarity-dev/minosse-api-sdk"
import { Customer } from "../sdk/minosse-principals-api"
import { Mapping } from "./utils"
import Fuse from "fuse.js"

export const generateTimeEntryId = (entry: TrackerTimeEntry & { stop: number }): string => [
  "CUSTOMER", entry.customerId,
  "PID", entry.projectId,
  "TID", entry.taskId,
  "UID", entry.userId,
  "START", entry.start,
  "STOP", entry.stop
].join("#")

export const filterTimeEntries = (filter: string, timeEntries: TimeEntry[], projectMapping: Mapping<Project>, customerMapping: Mapping<Customer>): TimeEntry[] => {
  timeEntries = timeEntries.reduce((acc, timeEntry) => {
    const customer = timeEntry.customerId ? customerMapping[timeEntry.customerId] : null
    const project = timeEntry.projectId ? projectMapping[timeEntry.projectId] : null

    if ((!customer || customer?.active) && (!project || project?.active)) {
      acc.push({
        ...timeEntry,
        customerName: customer?.customerName,
        projectName: project?.projectName,
        taskName: project?.tasks.find(({ taskId, active }) => taskId === timeEntry.taskId && active)?.taskName
      })
    }

    return acc
  }, [] as (TimeEntry & { customerName?: string, projectName?: string, taskName?: string })[])

  const fuse = new Fuse(timeEntries, {
    keys: ["description", "projectName", "taskName", "customerName"],
    threshold: 0.3
  })

  return fuse.search(filter).map(({ item }) => item as TimeEntry) as TimeEntry[]
}