import { useContext, useMemo } from "react"
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts"
import { GetStatistics200ResponseSchema, GetStatisticsRequestSchema } from "@polarity-dev/minosse-api-sdk"
import { MinosseContext } from "../../contexts/MinosseContext"
import { formatDuration } from "../../helpers/utils"
import { BsFillExclamationTriangleFill } from "react-icons/bs"
import { Card } from "react-bootstrap"

type Props = {
  data: GetStatistics200ResponseSchema["data"],
  primaryGroup: GetStatisticsRequestSchema["primaryGroup"],
  selectedKey: string | null,
  setSelectedKey: (key: string | null) => void
}

const HoursPieChart = ({
  data,
  primaryGroup,
  selectedKey,
  setSelectedKey
}: Props): JSX.Element => {
  const { customerMapping, userMapping, projectMapping } = useContext(MinosseContext)

  const mappingByGroup = {
    customers: customerMapping,
    projects: projectMapping,
    users: userMapping
  } as const

  const pieData = useMemo(() => {
    return Object.entries(data.primaryGroup).map(([key, { clockedDuration }]) => {
      return {
        name: key,
        value: clockedDuration
      }
    })
  }, [data.primaryGroup])

  return <>
    <Card className={"p-4"}>
      <h5 className={"fw-normal d-flex flex-column"}>
        <div className={"mb-3 text-center"}>
          Clocked hours:&nbsp;<b>{formatDuration(data.totalClockedDuration)}</b>
        </div>
        <div className={"mb-3 text-center"}>
          Billed hours (ƒ):&nbsp;<b>{formatDuration(data.totalBilledDuration)}</b>
        </div>
        <div className={"text-center"}>
          Total price:&nbsp;<b>{data.totalPrice}€</b>
        </div>
      </h5>
    </Card>
    <ResponsiveContainer width={"100%"} height={250}>
      <PieChart>
        <Pie
          cursor={"pointer"}
          dataKey={"value"}
          nameKey={"name"}
          innerRadius={50}
          data={pieData}
        >
          {
            pieData.map((cell, index) => {
              return <Cell
                key={index}
                fill={mappingByGroup[primaryGroup][cell.name]?.color}
                opacity={!selectedKey || selectedKey === cell.name ? 1 : 0.3}
                onClick={(event): void => {
                  event.stopPropagation()
                  if (selectedKey === cell.name) {
                    setSelectedKey(null)
                  } else {
                    setSelectedKey(cell.name)
                  }
                }}
              />
            })
          }
        </Pie>
        <Tooltip<number, string>
          cursor={false}
          shared={false}
          allowEscapeViewBox={{ x: false, y: true }}
          offset={40}
          wrapperClassName={"bg-white p-3 border border-dark rounded-3"}
          content={({ payload }): JSX.Element | null => {
            if (!payload?.[0]?.name || !payload[0].value) {
              return null
            }

            const { name, value } = payload[0]
            const color = mappingByGroup[primaryGroup][name]?.color || "black"

            return <div className={"bg-white p-3 border border-dark rounded-3"}>
              <span className={"project-colored-dot"} style={{ backgroundColor: color }}></span>
              {
                name === "HANGING" ? <>
                  <span className={"fw-semibold position-relative"}>
                    <BsFillExclamationTriangleFill className={"position-absolute top-0"}/>
                    <span style={{ marginLeft: 25 }}>NO {name.slice(0, -1).toUpperCase()}</span>
                  </span>
                </> : <>
                  <span className={"fw-bold"} style={{ color }}>
                    {primaryGroup === "customers" && (customerMapping[name]?.customerName || "Deleted customer")}
                    {
                      primaryGroup === "projects" && <>
                        <span style={{ color }}>{projectMapping[name]?.projectName || "Deleted project"}</span>
                        <span className={"fw-normal"}>
                          &nbsp;•&nbsp;{customerMapping[mappingByGroup[primaryGroup][name].customerId]?.customerName || "Deleted customer"}
                        </span>
                      </>
                    }
                    {primaryGroup === "users" && (userMapping[name]?.username || "Deleted user")}
                  </span>
                </>
              }
              { !!value && <div className={"mt-2"}>Duration: <b>{formatDuration(value)}</b></div>}
            </div>
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  </>
}

export default HoursPieChart