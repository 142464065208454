import { Layout } from "react-grid-layout"
import { GetPlanReport200ResponseSchema, PlanEntry } from "@polarity-dev/minosse-api-sdk"

export type Pixels = number
export type Units = number

export type PlanReport = GetPlanReport200ResponseSchema["data"] | null

export type LayoutItem = Layout & Pick<PlanEntry, "projectId" | "customerId" | "taskId" | "userId" | "start" | "stop">
export type CalendarEntry = PlanEntry & { uuid: string }

export type ClipboardData = { item: LayoutItem, type: "copy" | "cut" }

export type HolidayData = { date: Date, name: string }

export enum ResizeDirection {
  NE = "NE",
  NW = "NW",
  SE = "SE",
  SW = "SW"
}

export type MonthConfig = {
  date: Date,
  index: number,
  year: number,
  days: number,
  // in unità (giorni), non pixel
  left: Units,
  isCurrent: boolean
}

export type Bounds = {
  min: number,
  max: number
}
export type HorizontalBounds = {
  left: Pixels,
  right: Pixels
}
export type VerticalBounds = {
  top: Pixels,
  bottom: Pixels
}
export type Position = {
  top: Pixels,
  left: Pixels
}
export type Sizes = {
  width: Pixels,
  height: Pixels
}
export type Translate = {
  x: Pixels,
  y: Pixels
}