import { Project, User } from "@polarity-dev/minosse-api-sdk"
import { Mapping } from "../helpers/utils"

export const groupProjects = (projects: Project[], active: boolean | "both" = true): Mapping<Project[]> => {
  return projects.reduce((acc: Mapping<Project[]>, project) => {
    if (active === "both" || project.active === active) {
      if (!acc[project.customerId]) {
        acc[project.customerId] = []
      }

      acc[project.customerId].push(project)
    }
    return acc
  }, {})
}

export const getPricePerHour = (projectId: string | undefined | null, taskId: string | undefined | null, projectMapping: Mapping<Project>, user: User): number =>  {
  if (projectId && taskId) {
    const pricePerHour = projectMapping[projectId]?.tasks.find(task => taskId === task.taskId)?.userPrices.find(({ userId }) => userId === user?.userId)?.pricePerHour
    if (pricePerHour) {
      return pricePerHour
    } else {
      return user.basePrice
    }
  } else {
    return user.basePrice
  }
  // return (projectId && taskId)
  //   ? (projectMapping[projectId]?.tasks.find(task => taskId === task.taskId)?.userPrices.find(({ userId }) => userId === user?.userId)?.pricePerHour || user.basePrice)
  //   : user.basePrice
}