/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomer200ResponseSchema } from '../models/CreateCustomer200ResponseSchema';
import type { CreateCustomerRequestSchema } from '../models/CreateCustomerRequestSchema';
import type { DeleteCustomer200ResponseSchema } from '../models/DeleteCustomer200ResponseSchema';
import type { DeleteCustomerRequestSchema } from '../models/DeleteCustomerRequestSchema';
import type { GetCustomer200ResponseSchema } from '../models/GetCustomer200ResponseSchema';
import type { GetCustomerRequestSchema } from '../models/GetCustomerRequestSchema';
import type { ListCustomers200ResponseSchema } from '../models/ListCustomers200ResponseSchema';
import type { ListCustomersRequestSchema } from '../models/ListCustomersRequestSchema';
import type { UpdateCustomer200ResponseSchema } from '../models/UpdateCustomer200ResponseSchema';
import type { UpdateCustomerRequestSchema } from '../models/UpdateCustomerRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersService {

  /**
   * listCustomers
   * Retrieve all customers available
   * @param requestBody
   * @returns ListCustomers200ResponseSchema ListCustomers200Response
   * @throws ApiError
   */
  public static listCustomers(
    requestBody?: ListCustomersRequestSchema,
  ): CancelablePromise<ListCustomers200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customers/listCustomers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ListCustomers400Response`,
        401: `ListCustomers401Response`,
        403: `ListCustomers403Response`,
        429: `ListCustomers429Response`,
        500: `ListCustomers500Response`,
      },
    });
  }

  /**
   * getCustomer
   * Get customer data
   * @param requestBody
   * @returns GetCustomer200ResponseSchema GetCustomer200Response
   * @throws ApiError
   */
  public static getCustomer(
    requestBody?: GetCustomerRequestSchema,
  ): CancelablePromise<GetCustomer200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customers/getCustomer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `GetCustomer400Response`,
        401: `GetCustomer401Response`,
        403: `GetCustomer403Response`,
        429: `GetCustomer429Response`,
        500: `GetCustomer500Response`,
      },
    });
  }

  /**
   * createCustomer
   * Create a customer
   * @param requestBody
   * @returns CreateCustomer200ResponseSchema CreateCustomer200Response
   * @throws ApiError
   */
  public static createCustomer(
    requestBody?: CreateCustomerRequestSchema,
  ): CancelablePromise<CreateCustomer200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customers/createCustomer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `CreateCustomer400Response`,
        401: `CreateCustomer401Response`,
        403: `CreateCustomer403Response`,
        429: `CreateCustomer429Response`,
        500: `CreateCustomer500Response`,
      },
    });
  }

  /**
   * deleteCustomer
   * Delete a customer
   * @param requestBody
   * @returns DeleteCustomer200ResponseSchema DeleteCustomer200Response
   * @throws ApiError
   */
  public static deleteCustomer(
    requestBody?: DeleteCustomerRequestSchema,
  ): CancelablePromise<DeleteCustomer200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customers/deleteCustomer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `DeleteCustomer400Response`,
        401: `DeleteCustomer401Response`,
        403: `DeleteCustomer403Response`,
        429: `DeleteCustomer429Response`,
        500: `DeleteCustomer500Response`,
      },
    });
  }

  /**
   * updateCustomer
   * Update a customer in the database
   * @param requestBody
   * @returns UpdateCustomer200ResponseSchema UpdateCustomer200Response
   * @throws ApiError
   */
  public static updateCustomer(
    requestBody?: UpdateCustomerRequestSchema,
  ): CancelablePromise<UpdateCustomer200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customers/updateCustomer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `UpdateCustomer400Response`,
        401: `UpdateCustomer401Response`,
        403: `UpdateCustomer403Response`,
        429: `UpdateCustomer429Response`,
        500: `UpdateCustomer500Response`,
      },
    });
  }

}
