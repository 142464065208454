import { toast } from "react-toastify"
import { ApiError } from "../sdk/minosse-api"
import { ReactText } from "react"
import * as Sentry from "@sentry/react"

export type Mapping<T> = { [key: string]: T }

export type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T]

export const mapArrayByKey = <T, >(array: T[], key: string): Mapping<T> => {
  return array
    .reduce((acc: Mapping<T>, e: T) => {
      // @ts-expect-error e[key] is unknown
      acc[e[key]] = e

      return acc
    }, {} as Mapping<T>)
}

export const mapArrayByKeyCherryPick = <T extends Record<string, string>, V>(array: T[], key: string, cherryPick: (object: T) => V): Mapping<V> => {
  return array
    .reduce((acc: Mapping<V>, e: T) => {
      acc[e[key]] = cherryPick(e)
      return acc
    }, {} as Mapping<V>)
}

export const validPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@\\[\]^_{|}~]).{8,}$/

export const isValidColor = (inputString: string): boolean => /^#[0-9A-Fa-f]{6}$/g.test(inputString)

export const escapeRegExp = (str: string): string => {
  return str?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export const alphabetize = (a: string, b: string): number => a > b ? 1 : -1

export const stringToColor = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"
  for (let i = 0; i < 3; i++) {
    color += ("00" + ((hash >> (i * 8)) & 0xFF).toString(16)).substr(-2)
  }

  return color
}

export const isColorTooDarkForBlackText = (color: string): boolean => {
  let r, g, b
  const md = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
  if (md) {
    r = parseInt(md[1], 10)
    g = parseInt(md[2], 10)
    b = parseInt(md[3], 10)
  } else {
    r = parseInt(color.substr(0, 2), 16)
    g = parseInt(color.substr(2, 2), 16)
    b = parseInt(color.substr(4, 2), 16)
  }
  return ((0.299 * r + 0.587 * g + 0.114 * b) / 255) < 0.2
}

export const getSK = (item: { [key: string]: string | number | undefined | null }): string => {
  return Object.entries(item).map(([key, value]) => ([key, value].join("#"))).join("#")
}

export const withoutTimeZone = (date: Date): Date => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}

export const getRandomColor = (): string => "#" + ((1 << 24) * Math.random() | 0).toString(16)


export const stopEventPropagation = (event: never): boolean => {
  if ("stopPropagation" in event) {
    // @ts-expect-error stopPropagation is never
    event.stopPropagation()
  }
  return false
}

export const capitalizeFirst = (str: string): string =>
  str[0].toUpperCase() + str.slice(1).toLowerCase()

// eslint-disable-next-line @typescript-eslint/ban-types
export const handleError = (error: unknown, id?: ReactText): void => {
  if (process.env.REACT_APP_ENV === "production") {
    Sentry.captureException(error)
  }
  const payload = capitalizeFirst((error as ApiError).body?.error.message || (error as Error).message || "Error")
  if (id) {
    toast.update(id, { type: "error", isLoading: false, render: payload, autoClose: 5000 })
  } else {
    toast.error(payload)
  }
}

export const getByteSize = (bytes: number): string => {
  const labels = [" B", " KB", " MB", " GB"]
  for (let i = 0; i < labels.length; i++) {
    if (bytes < Math.pow(1000, i + 1)) {
      return Math.round(bytes / Math.pow(1000, i) * 10) / 10 + labels[i]
    }
  }
  return ""
}

export const getCountableText = (count: number, single: string, multiple: string): string => {
  return count === 1 ? `${count} ${single}` : `${count} ${multiple}`
}

export const objectToQueryString = (obj: Mapping<string | number | undefined | null>): string => {
  return Object.entries(obj).map(([key, value]) => ([key, value].join("="))).join("&")
}

export const priceInGrands = (price: number): number => {
  return Math.floor(price / 100) / 10
}

export const formatDuration = (milliseconds: number): string => {
  let minutes = Math.floor(milliseconds / (1000 * 60))
  const hours = Math.floor(minutes / 60)

  minutes = minutes % 60

  return `${hours}h ${minutes}m`
}