import { FunctionComponent, useContext, useState } from "react"
import { Button, Card, Form, InputGroup, Modal } from "react-bootstrap"
import { BsClipboard, BsTrashFill } from "react-icons/bs"
import { MdSave } from "react-icons/md"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { MinosseContext } from "../contexts/MinosseContext"
import UserDropdown from "./UserAwsDropdown"
import { getRandomColor, handleError, isValidColor } from "../helpers/utils"
import clone from "just-clone"
import { isEqual } from "lodash"
import { getUserAWSRoles, upsertCustomer, UpsertCustomer200ResponseSchema, AwsAccount, IamRole } from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"
import { logError } from "../helpers/logger"

type AwsAccountFormProps = {
  customerId?: string
  awsAccount?: AwsAccount
  show: boolean
  close: () => void
}

const getRoleName = (arn: string): string => {
  const splittedArn = arn.split("/")
  splittedArn.shift()
  return splittedArn.join("/")
}

type FormData = Omit<AwsAccount, "iamRoles"> & { iamRoles: { arn: string, users: string[] }[] }

const AwsAccountForm: FunctionComponent<AwsAccountFormProps> = ({
  customerId,
  awsAccount,
  show,
  close
}) => {
  const { customerMapping, actions: { setCustomerList } } = useContext(MinosseContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, setValue, watch, resetField, clearErrors } = useForm<FormData>({
    defaultValues: awsAccount ? {
      ...awsAccount,
      iamRoles: awsAccount.iamRoles.map(role => ({
        arn: getRoleName(role.arn),
        users: role.users
      }))
    } : {
      accountColor: getRandomColor(),
      iamRoles: []
    }
  })

  const [accountId, accountColor, iamRoles] = watch(["accountId", "accountColor", "iamRoles"])

  const onFormSubmit = async(data: AwsAccount): Promise<void> => {
    if (!customerId) {
      return close()
    }

    const iamRoles = data.iamRoles.map((role: IamRole) => ({
      ...role,
      arn: `arn:aws:iam::${awsAccount?.accountId ?? accountId}:role/${role.arn}`
    }))

    setIsSubmitting(true)
    const loader = toast.loading("Updating customer...")
    try {
      const updatedAwsAccounts = awsAccount ?
        customerMapping[customerId].awsAccounts.map(account => account.accountId === awsAccount.accountId ? { ...account, ...data, iamRoles } : account)
        :
        [...customerMapping[customerId].awsAccounts, { ...data, iamRoles }]

      const { data: updatedCustomer } = await handleApiCall(upsertCustomer, {
        handler: { customerId },
        updates: { awsAccounts: updatedAwsAccounts, color: customerMapping[customerId].color }
      }) as UpsertCustomer200ResponseSchema

      setCustomerList(customerList => customerList.map(customer => {
        if (customer.customerId === customerId) {
          return { ...clone(customer), ...updatedCustomer }
        }
        return customer
      }))

      if (updatedCustomer.iamAlerts.length) {
        toast.update(loader, { type: "warning", isLoading: false, autoClose: 5000, render: `Customer updated with ${updatedCustomer.iamAlerts.length} alert(s)` })
        resetField("iamRoles", { defaultValue: updatedCustomer.awsAccounts.find(account => account.accountId === (awsAccount?.accountId ?? accountId))?.iamRoles.map(role => ({ ...role, arn: getRoleName(role.arn) } as IamRole)) || [] })
        setIsSubmitting(false)
      } else {
        toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Customer updated successfully" })
        close()
      }

      if (dirtyFields.accountAlias || dirtyFields.notes || (awsAccount && accountColor !== awsAccount.accountColor)) {
        const userIds = Array.from(new Set(iamRoles.map(({ users }) => users).flat()))
        void Promise.all(userIds.map(userId => handleApiCall(getUserAWSRoles, { handler: { userId }, forceRefresh: true })))
      }

    } catch (error) {
      handleError(error, loader)
      setIsSubmitting(false)
      close()
    }
  }

  return <Modal
    show={show}
    centered
    scrollable
    onHide ={(): void => {
      if (isSubmitting) {
        return
      }

      if (
        isDirty
        || awsAccount && !isEqual(iamRoles, awsAccount.iamRoles.map(role => ({ arn: getRoleName(role.arn), users: role.users })))
      ) {
        if (confirm("Unsaved changes. Are you sure you want to close?")) {
          close()
        }
      } else {
        close()
      }
    }}
    className={"project-editor"}
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>{awsAccount ? `Editing account "${awsAccount.accountId}"` : "New AWS account"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group>
          <Form.Label>Account ID</Form.Label>
          <InputGroup>
            <Form.Control
              type={"text"}
              {...awsAccount ? {
                disabled: true,
                value: awsAccount.accountId,
                className: "input__disabled-lighter"
              } : {
                ...register("accountId", { required: true, pattern: /^[0-9]{12}$/ }),
                isInvalid: !!errors.accountId
              }}
            />
            {
              awsAccount && <BsClipboard
                className={"reports__clear-icon cursor-pointer"}
                onClick={(): void => {
                  void navigator.clipboard.writeText(awsAccount.accountId)
                    .then(() => toast.info("Copied to clipboard"))
                }}
              />
            }
            {
              !awsAccount && <Form.Control.Feedback type={"invalid"}>
                {errors.accountId?.type === "required" ? "You must specify an AWS account ID." : "AWS account ID must be a 12 digits number."}
              </Form.Control.Feedback>
            }
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>Account alias</Form.Label>
          <Form.Control
            type={"text"}
            placeholder={"Add account alias..."}
            {...register("accountAlias", { setValueAs: (value: string | null) => value?.trim() || null })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Notes</Form.Label>
          <Form.Control
            type={"text"}
            placeholder={"Add notes..."}
            {...register("notes", { setValueAs: (value: string | null) => value?.trim() || null })}
          />
          <Form.Text className={"text-muted mt-0 mb-2"}>
            Use this field if the account doesn't have an AWS alias (will be shown in Chrome extension)
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Color</Form.Label>
          <InputGroup>
            <Form.Control
              type={"text"}
              placeholder={"Add a color..."}
              isInvalid={!!errors.accountColor}
              {...register("accountColor", { required: false, validate: value => value === "" || isValidColor(value!) })}
            />
            <Form.Control
              type={"color"}
              className={"project-form__color-picker"}
              onChange={(event): void => {
                setValue("accountColor", event.target.value)
                clearErrors("accountColor")
              }}
              value={accountColor}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>Cross account role ARN</Form.Label>
          <Form.Control
            type={"text"}
            placeholder={"Add cross account role ARN..."}
            {...register("crossAccountRoleArn", { setValueAs: (value: string | null) => value?.trim() || null })}
          />
          <Form.Text className={"text-muted mt-0 mb-2"}>
            Make sure the role can be assumed by Polarity account
          </Form.Text>
        </Form.Group>
        <Form.Group className={"d-flex gap-3"}>
          <Form.Label>Watch costs <i>(enables Pluto)</i></Form.Label>
          <Form.Check {...register("plutoEnabled")}/>
        </Form.Group>
        <Form.Group>
          <Form.Label>IAM Roles</Form.Label>
          {
            iamRoles.map(({ users }, i) => {
              return <Card style={{ padding: 5, marginBottom: 10 }} key={`role-${i}`}>
                <InputGroup>
                  <InputGroup.Text>Role name</InputGroup.Text>
                  <Form.Control
                    type={"text"}
                    placeholder={"Add role..."}
                    isInvalid={!!errors.iamRoles?.[i]?.arn}
                    {...register(`iamRoles.${i}.arn`, {
                      setValueAs: (value: string) => value.trim(),
                      required: true,
                      pattern: /[\w+=,.@-]+/
                    })}
                  />
                  <Button
                    variant={"outline-danger"}
                    onClick={(): void => {
                      if (confirm("Are you sure you want to remove this role?")) {
                        setValue("iamRoles", iamRoles.filter((_, index) => i !== index))
                      }
                    }}
                  >
                    Remove
                  </Button>
                </InputGroup>
                <Form.Label style={{ marginTop: 5, marginBottom: 5 }}>Users</Form.Label>
                <div className={"aws-account-form__dropdowns"}>
                  {
                    users.map((userId, j) => {
                      return <UserDropdown
                        key={`user-${userId}`}
                        updateUserId={(userId): void => setValue(`iamRoles.${i}.users.${j}`, userId)}
                        removeUserId={(): void => setValue(`iamRoles.${i}.users`, users.filter(id => id !== userId))}
                        userId={userId}
                        error={!!customerId && customerMapping[customerId]?.iamAlerts.some(alert => alert.userId === userId)}
                        excludeUserIds={iamRoles[i].users}
                        disableSubmit={setIsSubmitting}
                      />
                    })
                  }
                  <UserDropdown
                    updateUserId={(userId): void => setValue(`iamRoles.${i}.users.${iamRoles[i].users.length}`, userId)}
                    excludeUserIds={iamRoles[i].users}
                    disableSubmit={setIsSubmitting}
                  />
                </div>
              </Card>
            })
          }
          <Button
            style={{ width: "100%" }}
            variant={"outline-primary"}
            onClick={(): void => {
              setValue(`iamRoles.${iamRoles.length}`, { arn: "", users: [] })
            }}
          >
            Add role
          </Button>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <div className={"w-100 d-flex justify-content-end"}>
        {
          !!awsAccount && !!customerId && !!customerMapping[customerId].iamAlerts.length &&
            <Button
              variant={"outline-dark"}
              className={"ms-2"}
              style={{ marginRight: "auto" }}
              disabled={isSubmitting}
              type={"submit"}
              onClick={async(): Promise<void> => {
                setIsSubmitting(true)
                const loader = toast.loading("Clearing alerts...")
                try {
                  const { data: newCustomer } = await handleApiCall(upsertCustomer, {
                    handler: { customerId },
                    updates: { awsAccounts: customerMapping[customerId].awsAccounts, iamAlerts: [], color: customerMapping[customerId].color }
                  }) as UpsertCustomer200ResponseSchema
                  setCustomerList(customerList => customerList.map(customer => {
                    if (customer.customerId === customerId) {
                      return { ...clone(customer), ...newCustomer }
                    }
                    return customer
                  }))
                  toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Alerts successfully cleared" })
                } catch (error) {
                  handleError(error, loader)
                } finally {
                  setIsSubmitting(false)
                }
              }}
            >
              Clear alerts
            </Button>
        }
        <Button
          variant={"danger"}
          disabled={isSubmitting}
          onClick={async(): Promise<void> => {
            if (!customerId) {
              return
            }

            if (confirm("Are you sure you want to delete this AWS Account?")) {
              setIsSubmitting(true)
              try {
                const { data: updatedCustomer } = await toast.promise(handleApiCall(upsertCustomer, {
                  handler: { customerId },
                  updates: {
                    awsAccounts: customerMapping[customerId].awsAccounts.filter(account => account.accountId !== awsAccount?.accountId)
                  }
                }), {
                  pending: "Deleting AWS Account...",
                  success: "AWS Account deleted successfully",
                  error: "Unable to delete AWS Account"
                }) as UpsertCustomer200ResponseSchema

                setCustomerList(customerList => customerList.map(customer => {
                  if (customer.customerId === customerId) {
                    return { ...clone(customer), ...updatedCustomer }
                  }
                  return customer
                }))
                close()
              } catch (ex) {
                handleError(ex)
                logError(ex)
              } finally {
                setIsSubmitting(false)
              }
            }
          }}
          className={"d-flex align-items-center align-text-bottom ms-2"}
        >
          <BsTrashFill className={"iconfix me-2"} />Delete
        </Button>
        <Button
          variant={"primary"}
          className={"ms-2"}
          disabled={isSubmitting}
          type={"submit"}
          onClick={handleSubmit(onFormSubmit)}
        >
          <MdSave className={"iconfix iconfix--translate1 me-2"} />Save
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
}

export default AwsAccountForm
