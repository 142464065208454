/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMessageToTicket200ResponseSchema } from '../models/AddMessageToTicket200ResponseSchema';
import type { AddMessageToTicketRequestSchema } from '../models/AddMessageToTicketRequestSchema';
import type { AssignStaffToTicket200ResponseSchema } from '../models/AssignStaffToTicket200ResponseSchema';
import type { AssignStaffToTicketRequestSchema } from '../models/AssignStaffToTicketRequestSchema';
import type { AssignSupportPlan200ResponseSchema } from '../models/AssignSupportPlan200ResponseSchema';
import type { AssignSupportPlanRequestSchema } from '../models/AssignSupportPlanRequestSchema';
import type { CloseTicket200ResponseSchema } from '../models/CloseTicket200ResponseSchema';
import type { CloseTicketRequestSchema } from '../models/CloseTicketRequestSchema';
import type { CreateTicket200ResponseSchema } from '../models/CreateTicket200ResponseSchema';
import type { CreateTicketRequestSchema } from '../models/CreateTicketRequestSchema';
import type { GetAttachmentUrl200ResponseSchema } from '../models/GetAttachmentUrl200ResponseSchema';
import type { GetAttachmentUrlRequestSchema } from '../models/GetAttachmentUrlRequestSchema';
import type { GetLastUpdateTimestamp200ResponseSchema } from '../models/GetLastUpdateTimestamp200ResponseSchema';
import type { GetLastUpdateTimestampRequestSchema } from '../models/GetLastUpdateTimestampRequestSchema';
import type { GetTicket200ResponseSchema } from '../models/GetTicket200ResponseSchema';
import type { GetTicketCount200ResponseSchema } from '../models/GetTicketCount200ResponseSchema';
import type { GetTicketCountRequestSchema } from '../models/GetTicketCountRequestSchema';
import type { GetTicketRequestSchema } from '../models/GetTicketRequestSchema';
import type { ListTickets200ResponseSchema } from '../models/ListTickets200ResponseSchema';
import type { ListTicketsRequestSchema } from '../models/ListTicketsRequestSchema';
import type { ReopenTicket200ResponseSchema } from '../models/ReopenTicket200ResponseSchema';
import type { ReopenTicketRequestSchema } from '../models/ReopenTicketRequestSchema';
import type { SwitchTicketStatus200ResponseSchema } from '../models/SwitchTicketStatus200ResponseSchema';
import type { SwitchTicketStatusRequestSchema } from '../models/SwitchTicketStatusRequestSchema';
import type { UpdateDraftRecipients200ResponseSchema } from '../models/UpdateDraftRecipients200ResponseSchema';
import type { UpdateDraftRecipientsRequestSchema } from '../models/UpdateDraftRecipientsRequestSchema';
import type { UpdateTicketNotes200ResponseSchema } from '../models/UpdateTicketNotes200ResponseSchema';
import type { UpdateTicketNotesRequestSchema } from '../models/UpdateTicketNotesRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TicketsService {

  /**
   * createTicket
   * Create ticket
   * @param requestBody
   * @returns CreateTicket200ResponseSchema CreateTicket200Response
   * @throws ApiError
   */
  public static createTicket(
    requestBody?: CreateTicketRequestSchema,
  ): CancelablePromise<CreateTicket200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/createTicket',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * listTickets
   * List tickets
   * @param requestBody
   * @returns ListTickets200ResponseSchema ListTickets200Response
   * @throws ApiError
   */
  public static listTickets(
    requestBody?: ListTicketsRequestSchema,
  ): CancelablePromise<ListTickets200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/listTickets',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * getTicket
   * Get ticket
   * @param requestBody
   * @returns GetTicket200ResponseSchema GetTicket200Response
   * @throws ApiError
   */
  public static getTicket(
    requestBody?: GetTicketRequestSchema,
  ): CancelablePromise<GetTicket200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/getTicket',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * getAttachmentUrl
   * Get attachment url
   * @param requestBody
   * @returns GetAttachmentUrl200ResponseSchema GetAttachmentUrl200Response
   * @throws ApiError
   */
  public static getAttachmentUrl(
    requestBody?: GetAttachmentUrlRequestSchema,
  ): CancelablePromise<GetAttachmentUrl200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/getAttachmentUrl',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * getTicketCount
   * Get ticket count
   * @param requestBody
   * @returns GetTicketCount200ResponseSchema GetTicketCount200Response
   * @throws ApiError
   */
  public static getTicketCount(
    requestBody?: GetTicketCountRequestSchema,
  ): CancelablePromise<GetTicketCount200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/getTicketCount',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * addMessageToTicket
   * Add message to ticket
   * @param requestBody
   * @returns AddMessageToTicket200ResponseSchema AddMessageToTicket200Response
   * @throws ApiError
   */
  public static addMessageToTicket(
    requestBody?: AddMessageToTicketRequestSchema,
  ): CancelablePromise<AddMessageToTicket200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/addMessageToTicket',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * assignStaffToTicket
   * Assign staff to ticket
   * @param requestBody
   * @returns AssignStaffToTicket200ResponseSchema AssignStaffToTicket200Response
   * @throws ApiError
   */
  public static assignStaffToTicket(
    requestBody?: AssignStaffToTicketRequestSchema,
  ): CancelablePromise<AssignStaffToTicket200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/assignStaffToTicket',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * assignSupportPlan
   * Assign support plan
   * @param requestBody
   * @returns AssignSupportPlan200ResponseSchema AssignSupportPlan200Response
   * @throws ApiError
   */
  public static assignSupportPlan(
    requestBody?: AssignSupportPlanRequestSchema,
  ): CancelablePromise<AssignSupportPlan200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/assignSupportPlan',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * closeTicket
   * Close ticket
   * @param requestBody
   * @returns CloseTicket200ResponseSchema CloseTicket200Response
   * @throws ApiError
   */
  public static closeTicket(
    requestBody?: CloseTicketRequestSchema,
  ): CancelablePromise<CloseTicket200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/closeTicket',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * reopenTicket
   * Reopen ticket
   * @param requestBody
   * @returns ReopenTicket200ResponseSchema ReopenTicket200Response
   * @throws ApiError
   */
  public static reopenTicket(
    requestBody?: ReopenTicketRequestSchema,
  ): CancelablePromise<ReopenTicket200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/reopenTicket',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * updateTicketNotes
   * Update ticket notes
   * @param requestBody
   * @returns UpdateTicketNotes200ResponseSchema UpdateTicketNotes200Response
   * @throws ApiError
   */
  public static updateTicketNotes(
    requestBody?: UpdateTicketNotesRequestSchema,
  ): CancelablePromise<UpdateTicketNotes200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/updateTicketNotes',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * updateDraftRecipients
   * Update draft recipients
   * @param requestBody
   * @returns UpdateDraftRecipients200ResponseSchema UpdateDraftRecipients200Response
   * @throws ApiError
   */
  public static updateDraftRecipients(
    requestBody?: UpdateDraftRecipientsRequestSchema,
  ): CancelablePromise<UpdateDraftRecipients200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/updateDraftRecipients',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * switchTicketStatus
   * Switch ticket status
   * @param requestBody
   * @returns SwitchTicketStatus200ResponseSchema SwitchTicketStatus200Response
   * @throws ApiError
   */
  public static switchTicketStatus(
    requestBody?: SwitchTicketStatusRequestSchema,
  ): CancelablePromise<SwitchTicketStatus200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/switchTicketStatus',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * getLastUpdateTimestamp
   * Get last update timestamp
   * @param requestBody
   * @returns GetLastUpdateTimestamp200ResponseSchema GetLastUpdateTimestamp200Response
   * @throws ApiError
   */
  public static getLastUpdateTimestamp(
    requestBody?: GetLastUpdateTimestampRequestSchema,
  ): CancelablePromise<GetLastUpdateTimestamp200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tickets/getLastUpdateTimestamp',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
