import { FunctionComponent, useContext, useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { toast } from "react-toastify"
import PageTemplate from "../components/PageTemplate"
import { useForm } from "react-hook-form"
import AuthContext, { login, USER_ROLES } from "../contexts/AuthContext"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { handleError } from "../helpers/utils"
import { StaffLoginRequestSchema, StaffUsersService } from "../sdk/minosse-principals-api"
import classNames from "classnames"
import loading from "../assets/loading.gif"
import { authenticateCliStore } from "../stores/authenticateCli"
import { getUser, GetUser200ResponseSchema } from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"

const Login: FunctionComponent = () => {
  const { initialized, user, setUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<StaffLoginRequestSchema>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const uuid = searchParams.get("osse-uuid")
    if (uuid) {
      authenticateCliStore.setState({ uuid })
    }
  }, [])

  const onSubmit = async(data: StaffLoginRequestSchema): Promise<void> => {
    setIsLoading(true)
    const loader = toast.loading("Logging in...")
    try {
      const session = await login(data)

      if (session.roles.includes(USER_ROLES.PENDING_MFA)) {
        navigate("/mfa", { state: session })
        toast.dismiss(loader)
      } else {
        const [{ data: user }, { data: userExtra }] = await Promise.all([
          StaffUsersService.getStaffUser({ handler: { userId: session.userId }, unsafeUserName: true }),
          handleApiCall(getUser, { handler: { userId: session.userId } }) as Promise<GetUser200ResponseSchema>
        ])
        setUser({ ...user, ...userExtra })

        navigate("/")
        toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: `Hi ${user.username}!` })
      }
    } catch (error) {
      handleError(error, loader)
      setIsLoading(false)
    }
  }

  if (user) {
    return <Navigate to={"/"} replace/>
  }

  return <PageTemplate issueTag={"login"}>
    {
      <div
        className = { classNames("loading-pane", { visible: !initialized }) }
        onTransitionEnd = { (event): void => event.currentTarget.remove() }
      >
        <img src = { loading }/>
      </div>
    }
    <Form onSubmit={handleSubmit(onSubmit)} className={"login max-content-width"}>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control {...register("email")} required autoComplete={"email"} placeholder={"Enter email"}/>
      </Form.Group>

      <Form.Group >
        <Form.Label>Password</Form.Label>
        <Form.Control {...register("password")} required autoComplete={"current-password"} type={"password"} placeholder={"Password"}/>
      </Form.Group>

      <Button variant={"primary"} type={"submit"} disabled={isLoading}>Login</Button>
      <Button
        className={"ms-3"}
        variant={"outline-dark"}
        type={"button"}
        disabled={isLoading}
        onClick={(): void => navigate("/reset-password") }
      >
        Reset password
      </Button>
    </Form>
  </PageTemplate>
}

export default Login