import { FunctionComponent, useContext, useMemo, useState } from "react"
import { Accordion, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import EntryDetails from "./EntryDetails"
import GroupEntry from "./GroupEntry"
import { getReadableDuration } from "../helpers/time"
import { groupTimeEntries } from "../helpers/transformers"
import { TimeEntry } from "@polarity-dev/minosse-api-sdk"
import TimeEntryForm from "./TimeEntryForm"
import Icon from "./Icon"
import { BsExclamationTriangleFill } from "react-icons/bs"
import { getPricePerHour } from "../helpers/projects"
import { MinosseContext } from "../contexts/MinosseContext"
import AuthContext from "../contexts/AuthContext"

type TimeEntriesProps = {
  entries: TimeEntry[]
  updateEntry: (id: string, updatedEntry: TimeEntry) => void
  deleteEntry: (id: string) => void
}

const TimeEntries: FunctionComponent<TimeEntriesProps> = ({ entries, updateEntry, deleteEntry }) => {
  const groupedEntries = useMemo(() => groupTimeEntries(entries), [entries])
  const { projectMapping } = useContext(MinosseContext)
  const { user } = useContext(AuthContext)

  const [selectedEntry, setSelectedEntry] = useState<TimeEntry | null>(null)

  return <div className = { "time-entries" }>
    {
      !!selectedEntry && <TimeEntryForm
        selectedEntry = { selectedEntry }
        entries = { entries }
        onSubmit = { (id: string, entry: TimeEntry): void => updateEntry(id, entry) }
        onDelete = { (id: string): void => deleteEntry(id) }
        close = { (): void => setSelectedEntry(null) }
      />
    }
    {
      groupedEntries.map(({ date, dailyDuration, groups }) => (
        <Card className = { "time-entries__day" } key = { date }>
          <strong>{ date }<span className = { "time-entries__daily-duration" }>{ getReadableDuration(dailyDuration) }</span></strong>
          {
            groups.map(({ totalDuration, customerId, projectId, taskId, description, entries }, groupIndex) => {
              if (entries.length > 1) {
                return <Accordion key = { `${date}-w${groupIndex}` }>
                  <Accordion.Item eventKey = { `${date}-${groupIndex}` }>
                    <Accordion.Header>
                      <span className = { "group-entry__count" }>
                        { entries.length }
                      </span>
                      <div>
                        <div className = { "group-entry__description" }>
                          { description || <span className="position-relative">
                            No description
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement={"right"}
                              overlay={<Tooltip>Entry has no description</Tooltip>}
                            >
                              <span>
                                <BsExclamationTriangleFill className={"iconfix iconfix--translate1 ms-2"} />
                              </span>
                            </OverlayTrigger>
                          </span> }
                        </div>
                        <EntryDetails customerId = { customerId } projectId = { projectId } taskId = { taskId } showIsHanging />
                      </div>
                      <span className = { "group-entry-duration" }>
                        { getReadableDuration(totalDuration) }
                        <Icon
                          name = { "play-fill" }
                          onClick = { (event): void => {
                            event.stopPropagation()
                            document.dispatchEvent(new CustomEvent("resumeEntry", { detail: { description, customerId, projectId, taskId, pricePerHour: getPricePerHour(projectId, taskId, projectMapping, user!), tags: entries[0].tags } }))
                          }}
                        />
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                        entries.map((entry, entryIndex) => (
                          <GroupEntry key = { `${groupIndex}-${entryIndex}` } entry = { entry } stack onClick = { (): void => setSelectedEntry(entry) }/>
                        ))
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              } else {
                return <GroupEntry key = { `${groupIndex}-0` } entry = { entries[0] } onClick = { (): void => setSelectedEntry(entries[0]) }/>
              }
            })
          }
        </Card>
      ))
    }
  </div>
}

export default TimeEntries