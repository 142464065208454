import React, { FunctionComponent, useContext } from "react"
import classNames from "classnames"
import { TimeEntry } from "@polarity-dev/minosse-api-sdk"
import EntryDetails from "./EntryDetails"
import Icon from "./Icon"
import { getReadableDuration } from "../helpers/time"
import { MinosseContext } from "../contexts/MinosseContext"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { BsExclamationTriangleFill } from "react-icons/bs"
import { getPricePerHour } from "../helpers/projects"
import AuthContext from "../contexts/AuthContext"

type GroupEntryProps = {
  entry: TimeEntry
  onClick: () => void
  stack?: boolean
  allowResume?: boolean
  showUser?: boolean
}

const GroupEntry: FunctionComponent<GroupEntryProps> = ({
  entry: { description, duration, customerId, projectId, userId, taskId, tags },
  onClick,
  stack = false,
  allowResume = true,
  showUser = false
}) => {
  const { projectMapping, userMapping } = useContext(MinosseContext)
  const { user } = useContext(AuthContext)

  return <div className = { classNames("group-entry", { stack }) } onClick = { onClick }>
    <div>
      <div className = { "group-entry__description" }>
        { description || <span className="position-relative">
          No description
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement={"right"}
            overlay={<Tooltip>Entry has no description</Tooltip>}
          >
            <span>
              <BsExclamationTriangleFill className={"iconfix iconfix--translate1 ms-2"} />
            </span>
          </OverlayTrigger>
        </span> }
      </div>
      <EntryDetails customerId = { customerId } projectId = { projectId } taskId = { taskId } showIsHanging />
    </div>
    { showUser && <span className = { "group-entry-user" }>{ userMapping[userId!]?.username }</span> }
    <span className = { "group-entry-duration" }>
      { getReadableDuration(duration!) }
      {
        allowResume && <Icon
          name = { "play-fill" }
          onClick = { (event): void => {
            event.stopPropagation()
            document.dispatchEvent(new CustomEvent("resumeEntry", { detail: { description, customerId, projectId, taskId, pricePerHour: getPricePerHour(projectId, taskId, projectMapping, user!), tags } }))
          }}
        />
      }
    </span>
  </div>
}

export default GroupEntry