import ms from "ms"
import { format, subMinutes } from "date-fns"
import { HolidayData } from "../types/planner"
import minutesToMilliseconds from "date-fns/minutesToMilliseconds"

// import dayjs from "dayjs"
export const SECOND = 1000
export const MINUTE = SECOND * 60
export const HOUR = MINUTE * 60
export const DAY = HOUR * 24
export const WEEK = DAY * 7
export const MONTH = WEEK * 4
export const YEAR = MONTH * 12

export type TimeUnit = "YEAR" | "MONTH" | "WEEK" | "DAY" | "HOUR" | "MINUTE" | "SECOND"

export const getCustomYearHolidays = (year: number): HolidayData[] => [
  {
    date: getDateAccountingForTimezone(year, 10, 24),
    name: "San Prospero"
  }
]

export type TimeSpan = {
  start: number,
  stop: number
}

export const getTimeSpan = (days: number, from?: number): TimeSpan  => {
  if (!from) {
    from = new Date().getTime()
  }
  return {
    start: from - days * DAY,
    stop: from
  }
}

export const getReadableDuration = (timestamp: number, format: ":" | "hmin" = ":"): string => {
  const hours = Math.floor(timestamp / HOUR)
  const minutes = Math.floor(((timestamp - hours * HOUR) / MINUTE))
  const seconds = Math.floor(((timestamp - hours * HOUR - minutes * MINUTE) / SECOND))

  switch (format) {
    case ":": {
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
    }
    case "hmin": {
      return `${hours ? `${hours} hours ` : ""}${minutes} minutes`
    }
  }
}

export const getReadableDay = (timestamp: number): string => {
  const date = new Date(timestamp)
  const today = new Date()

  if (isSameDay(date, today)) {
    return "Today"
  } else {
    const yesterday = new Date().setDate(today.getDate() - 1)

    if (isSameDay(date, yesterday)) {
      return "Yesterday"
    } else {
      return date.toLocaleDateString("en-GB", { weekday: "short", day: "2-digit", month: "2-digit", year: "numeric" })
    }
  }
}

export const isSameDay = (a: Date | number, b: Date | number): boolean => {
  if (typeof a === "number") {
    a = new Date(a)
  }

  if (typeof b === "number") {
    b = new Date(b)
  }

  return a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
}

export const parseDurationInput = (string: string): number => {
  if (string === "") {
    return 0
  }

  string = string.trim()

  if (string.match(/^\d+$/)) {
    return parseInt(string) * MINUTE
  }

  if (string.includes(":")) {
    const [hours, minutes, seconds] = string.split(":")

    return ((seconds ? parseInt(seconds) * SECOND : 0) + parseInt(minutes) * MINUTE + parseInt(hours) * HOUR)
  } else if (string.includes(" ") && string.split(" ").every(part => part.match(/^\d+$/))) {
    const [hours, minutes, seconds] = string.split(" ")

    return ((seconds ? parseInt(seconds) * SECOND : 0) + parseInt(minutes) * MINUTE + parseInt(hours) * HOUR)
  } else {
    const matches = string
      .replace(/\s+/g, "")
      .match(/(?:(\d+h) ?(?:[a-z]+)?)?(?:(\d+m) ?(?:[a-z]+)?)?(?:(\d+s))?/i)
    matches!.shift()

    const res: { [key: string]: number } = {}
    Array.from(matches!).filter(match => match).forEach((match) => {
      res[match[match.length - 1]] = ms(match)
    })

    return Object.values(res).reduce((acc, e) => acc + e, 0) || NaN
  }
}

export const now = (): number => new Date().getTime()

export const getTimestampFromTimestring = (timestring: string, format: "hh:mm" | "hh:mm:ss" = "hh:mm"): number => {
  switch (format) {
    case "hh:mm:ss": {
      const [hours, minutes, seconds] = timestring.split(":")
      return new Date().setHours(parseInt(hours), parseInt(minutes), parseInt(seconds), 0)
    }
    default: {
      const [hours, minutes] = timestring.split(":")
      return new Date().setHours(parseInt(hours), parseInt(minutes), 0, 0)
    }
  }
}

export const getTimestring = (timestamp: number, withSeconds: boolean = true): string => new Date(timestamp).toLocaleTimeString("it-IT", { hour: "2-digit", minute: "2-digit", second: withSeconds ? "2-digit" : undefined })

export const timestampToDate = (timestamp: number): string => new Date(timestamp).toLocaleDateString("en-GB", { timeZone: "Europe/Rome", weekday: "short", day: "2-digit", month: "2-digit", year: "numeric" })

export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate()
}

export const getFormattedDate = (date: Date | number | null): string => {
  if (date === null) {
    return "dd/mm/yyyy"
  }
  if (typeof date === "number") {
    date = new Date(date)
  }
  return date.toLocaleDateString("it-IT", { day: "2-digit", month: "2-digit", year: "numeric" })
}

export const getFormattedDateTime = (date: Date | number | null, weekday?: "long" | "short" | "narrow"): string => {
  if (date === null) {
    return "dd/mm/yyyy"
  }
  if (typeof date === "number") {
    date = new Date(date)
  }
  const timeZone = "Europe/Rome"
  const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", timeZone }
  let weekdayString = ""
  if (weekday) {
    weekdayString = date.toLocaleString("en-US", { weekday, timeZone })
  }
  return `${weekdayString} ${date.toLocaleString("it-IT", options)}`
}

export const getFormattedHMDuration = (duration: number, leadingZeroes: boolean = false, alwaysIncludeMinutes: boolean = false): string => {
  const hours = Math.floor(duration / HOUR)
  const minutes = Math.floor((duration - hours * HOUR) / MINUTE)

  let hourText: string, minuteText: string
  if (leadingZeroes) {
    hourText = String(hours).padStart(2, "0")
    minuteText = String(minutes).padStart(2, "0")
  } else {
    hourText = `${hours}`
    minuteText = `${minutes}`
  }

  if (alwaysIncludeMinutes || minutes !== 0) {
    return `${hourText}h${minuteText}m`
  } else {
    return `${hourText}h`
  }
}

export const getDateAccountingForTimezone = (year: number, month: number, date: number): Date => {
  const d = new Date(year, month, date)
  return subMinutes(d, d.getTimezoneOffset())
}

export const getCountdownText = (
  startTime: Date | number,
  endTime?: Date | number,
  type: "countdown" | "timepassed" = "countdown",
  limitAt?: TimeUnit
): string => {
  if (typeof startTime === "object") {
    startTime = startTime.getTime()
  }
  if (typeof endTime === "object") {
    endTime = endTime.getTime()
  }

  let duration = endTime ? (endTime - startTime) : startTime

  let prefix: string = "", suffix: string = ""
  if (type === "countdown") {
    if (duration < 0) {
      prefix = "Expired "
      suffix = " ago"
      duration = -duration
    } else {
      prefix = "in "
    }
  } else {
    if (duration < 0) {
      return "In the future"
    }
    suffix = " ago"
  }

  const denominator = limitAt ? { YEAR, MONTH, WEEK, DAY, HOUR, MINUTE, SECOND }[limitAt] : -1

  if (limitAt && Math.abs(duration) >= denominator) {
    const amount = Math.floor(duration / denominator)
    const text = limitAt.toLowerCase()

    return `${prefix}${amount} ${text}${duration === 1 ? "" : "s"}${suffix}`
  } else {
    if (duration > YEAR) {
      const amount = Math.floor(duration / YEAR)
      return `${prefix}${amount} year${duration === 1 ? "" : "s"}${suffix}`
    } else if (duration > MONTH) {
      const amount = Math.floor(duration / MONTH)
      return `${prefix}${amount} month${duration === 1 ? "" : "s"}${suffix}`
    } else if (duration > WEEK) {
      const amount = Math.floor(duration / WEEK)
      return `${prefix}${amount} week${duration === 1 ? "" : "s"}${suffix}`
    } else if (duration > DAY) {
      const amount = Math.floor(duration / DAY)
      return `${prefix}${amount} day${duration === 1 ? "" : "s"}${suffix}`
    } else if (duration > HOUR) {
      const amount = Math.floor(duration / HOUR)
      return `${prefix}${amount} hour${duration === 1 ? "" : "s"}${suffix}`
    } else if (duration > MINUTE) {
      const amount = Math.floor(duration / MINUTE)
      return `${prefix}${amount} minute${duration === 1 ? "" : "s"}${suffix}`
    } else {
      const amount = Math.floor(duration / SECOND)
      return `${prefix}${amount} second${duration === 1 ? "" : "s"}${suffix}`
    }
  }
}

export const getGoogleCalendarCompatibleDateTime = (timestamp: number): string => {
  // 2022-12-30 01:06:00 => 20221230T010600Z
  const dateWithoutTimezone = new Date(timestamp)
  const date = new Date(timestamp + minutesToMilliseconds(dateWithoutTimezone.getTimezoneOffset()))
  return `${date.getFullYear()}${date.getMonth()}${("" + date.getDate()).padStart(2, "0")}T${date.toLocaleTimeString().replaceAll(":", "")}Z`
}

export function getDaysBetween(startTimestamp: number, endTimestamp: number): [string[], string[]] {
  // create an array to store the dates of the days between the timestamps
  const days = [] as string[]
  const daysNoYear = [] as string[]

  // calculate the difference between the start and end dates
  const diff = endTimestamp - startTimestamp
  const diffDays = Math.ceil(diff / DAY)

  const when = Math.floor(diffDays > 30
    ? (diffDays / 8) + 1
    : 1)

  // add the dates of all of the days between the start and end dates to the array
  for (let i = 0; i < diffDays; i++) {
    const newDate = new Date(startTimestamp)
    newDate.setDate(newDate.getDate() + i)
    days.push(format(newDate, "yyyy-MM-dd"))
    if (i % when === 0) {
      daysNoYear.push(format(newDate, "dd/MM"))
    }
  }

  // return the array of days
  return [days, daysNoYear]
}
