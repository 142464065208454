import { FunctionComponent, ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Dropdown, DropdownButton, Form, Row, Spinner } from "react-bootstrap"
import PageTemplate from "../components/PageTemplate"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { MinosseContext } from "../contexts/MinosseContext"
import AuthContext from "../contexts/AuthContext"
import loading from "../assets/loading.gif"
import classNames from "classnames"
import StatisticsCalendar from "../components/StatisticsCalendar"
import StatisticsTimeEntries from "../components/StatisticsTimeEntries"
import HoursStackedBarChart from "../components/charts/HoursStackedBarChart"
import ListFilter from "../components/ListFilter"
import { getCountableText, handleError } from "../helpers/utils"
import dayjs from "dayjs"
import produce from "immer"
import HoursPieChart from "../components/charts/HoursPieChart"
import NotFoundPage from "./NotFoundPage"
import { StaffUser, Customer } from "../sdk/minosse-principals-api"
import {
  getStatistics,
  GetStatistics200ResponseSchema,
  GetStatisticsRequestSchema,
  Project,
  Task
} from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"
import { handleException } from "../helpers/logger"
import { BooleanOption } from "../types"
import Icon from "../components/Icon"
import TimeEntryForm from "../components/TimeEntryForm"

type Granularity = GetStatisticsRequestSchema["granularity"]

const primaryGroups = ["users", "projects", "customers"] as const
const secondaryGroups = {
  users: ["projects", "customers", "tasks"],
  projects: ["users", "tasks"],
  customers: ["users", "projects", "tasks"]
} as const

const Statistics: FunctionComponent = () => {
  const { user, initialized } = useContext(AuthContext)
  const {
    userList,
    activeUserList,
    projectList,
    activeProjectList,
    customerList,
    activeCustomerList,
    customerMapping,
    projectMapping,
    dataReady
  } = useContext(MinosseContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const [showArchived, setShowArchived] = useState(searchParams.get("showArchived") === "true" || false)
  const [excludeGenerated, setExcludeGenerated] = useState(searchParams.get("excludeGenerated") === "true" || false)
  const [statistics, setStatistics] = useState<GetStatistics200ResponseSchema["data"] | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const [createTimeEntryFormOpen, setCreateTimeEntryFormOpen] = useState(false)

  // Filters
  const [userFilter, setUserFilter] = useState(searchParams.get("userFilter")?.split(",") || [])
  const [{ customerFilter, projectFilter, taskFilter }, setFilters] = useState({
    customerFilter: searchParams.get("customerFilter")?.split(",") || [],
    projectFilter: searchParams.get("projectFilter")?.split(",") || [],
    taskFilter: searchParams.get("taskFilter")?.split(",") || []
  })
  const [granularity, setGranularity] = useState<Granularity>(searchParams.get("granularity") as Granularity || "auto")
  const [timeSpan, setTimeSpan] = useState((): { since: Date | null, until: Date | null } => {
    const date = dayjs().tz()
    const parsedSince = parseInt(searchParams.get("since") || ""), parsedUntil = parseInt(searchParams.get("until") || "")
    return {
      since: parsedSince ? new Date(parsedSince) : date.startOf("isoWeek").toDate(),
      until: parsedUntil ? new Date(parsedUntil) : date.endOf("isoWeek").toDate()
    }
  })

  // Grouping
  const [{ primaryGroup, secondaryGroup }, setGroups] = useState({
    primaryGroup: searchParams.get("primaryGroup") as GetStatisticsRequestSchema["primaryGroup"] || "users",
    secondaryGroup: searchParams.get("secondaryGroup") as GetStatisticsRequestSchema["secondaryGroup"] || "projects"
  })

  // Chart selections
  const [selectedPrimaryKey, setSelectedPrimaryKey] = useState<string | null>(null)

  const [lastUsedPayload, setLastUsedPayload] = useState<GetStatisticsRequestSchema | null>()

  const reloadData = (): void => {
    if (!lastUsedPayload) {
      return
    }
    setIsLoading(true)
    handleApiCall(getStatistics, lastUsedPayload)
      .then(data => setStatistics((data as GetStatistics200ResponseSchema).data))
      .catch(error => {
        handleException(error as Error)
        setStatistics(null)
        handleError(error)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect((): void => {
    reloadData()
  }, [lastUsedPayload])

  useEffect(() => {
    if (!initialized || !user?.isAdmin) {
      return
    }

    const since = timeSpan.since?.getTime()
    const until = timeSpan.until?.getTime()

    if (!since || !until) {
      return
    }

    const payload: GetStatisticsRequestSchema = {
      since,
      until,
      primaryGroup,
      secondaryGroup,
      granularity,
      withBarChartData: true,
      excludeGenerated
    }

    const searchParams = new URLSearchParams({
      primaryGroup,
      secondaryGroup,
      granularity,
      since: since.toString(),
      until: until.toString(),
      showArchived: showArchived ? "true" : "false",
      excludeGenerated: excludeGenerated ? "true" : "false"
    })

    if (userFilter.length > 0) {
      payload.userFilter = userFilter
      searchParams.append("userFilter", userFilter.join(","))
    }

    if (customerFilter.length > 0) {
      payload.customerFilter = customerFilter
      searchParams.append("customerFilter", customerFilter.join(","))

    }

    if (projectFilter.length > 0) {
      payload.projectFilter = projectFilter
      searchParams.append("projectFilter", projectFilter.join(","))
    }

    if (taskFilter.length > 0) {
      payload.taskFilter = taskFilter
      searchParams.append("taskFilter", taskFilter.join(","))
    }

    setSearchParams(searchParams)
    setLastUsedPayload(payload)
  }, [
    initialized,
    user,
    userFilter,
    customerFilter,
    projectFilter,
    taskFilter,
    timeSpan,
    granularity,
    primaryGroup,
    secondaryGroup,
    excludeGenerated
  ])

  const filteredProjects = useMemo(() => {
    return (showArchived ? projectList : activeProjectList).filter(({ customerId }) => customerFilter.includes(customerId))
  }, [customerFilter, projectList, activeProjectList, showArchived])

  const filteredTasks = useMemo(() => {
    return filteredProjects.reduce((acc: Array<Task & { projectId: string }>, { projectId, tasks }) => {
      if (projectFilter.includes(projectId)) {
        acc.push(...tasks.map(task => ({ ...task, projectId })))
      }

      return acc
    }, []) as Array<Task & { projectId: string }>
  }, [filteredProjects, projectFilter, showArchived])

  const activeTasks = useMemo(() => {
    return filteredTasks.reduce((acc: Record<string, boolean>, { taskId, active }) => {
      if (taskId) {
        acc[taskId] = active
      }
      return acc
    }, {})
  }, [filteredTasks])

  const firstLoad = useRef(true)
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
      return
    }

    if (!showArchived) {
      setFilters(filters => ({
        customerFilter: filters.customerFilter.filter(customerId => customerMapping[customerId]?.active),
        projectFilter: filters.projectFilter.filter(projectId => projectMapping[projectId]?.active),
        taskFilter: filters.taskFilter.filter(taskId => activeTasks[taskId])
      }))
    }
  }, [showArchived])

  useEffect(() => {
    const handler = (): void => {
      setSelectedPrimaryKey(null)
    }

    document.addEventListener("click", handler)

    return (): void => {
      document.removeEventListener("click", handler)
    }
  }, [])

  if (initialized && !user) {
    return <Navigate to={"/login"} replace/>
  }

  if (initialized && user && !user.isAdmin) {
    return <NotFoundPage/>
  }

  return <PageTemplate issueTag={"statistics"}>
    <div
      className={classNames("loading-pane", { visible: !initialized || !dataReady }) }
      onTransitionEnd={(event): void => event.currentTarget.remove()}
    >
      <img src={loading}/>
    </div>

    {
      createTimeEntryFormOpen && <TimeEntryForm
        entries={[]}
        onSubmit={(): void => {
          reloadData()
        }}
        onDelete={(): void => reloadData()}
        close={(): void => setCreateTimeEntryFormOpen(false)}
        showPricePerHour
        showUserPicker
      />
    }

    <div className={"max-content-width mt-4 pb-4"}>
      <div className={"d-flex align-items-center justify-content-between mb-3"}>
        <div className={"filter-bar d-flex gap-2 tw-flex-wrap"}>
          <ListFilter
            hideAll
            data={showArchived ? userList : activeUserList}
            selectedIds={userFilter}
            onItemClick={(item: StaffUser | null): void => {
              if (item) {
                setUserFilter(produce(draft => {
                  const index = draft.indexOf(item.userId)
                  if (index !== -1) {
                    draft.splice(index, 1)
                  } else {
                    draft.push(item.userId)
                  }
                }))
              }
            }}
            multiple
            itemKey={({ userId }: StaffUser): string => userId}
            itemText={({ username }: StaffUser): string => username}
            matchKey="username"
            itemId={({ userId }): string => userId}
            clearSelection={(): void => setUserFilter([])}
          >
            Users:&nbsp;<b>{userFilter.length > 0 ? getCountableText(userFilter.length, "user", "users") : "All"}</b>
          </ListFilter>

          <ListFilter
            hideAll
            data={showArchived ? customerList : activeCustomerList}
            selectedIds={customerFilter}
            onItemClick={(item: Customer | null): void => {
              if (item) {
                setFilters(produce(draft => {
                  const index = draft.customerFilter.indexOf(item.customerId)
                  if (index !== -1) {
                    draft.customerFilter.splice(index, 1)
                  } else {
                    draft.customerFilter.push(item.customerId)
                  }

                  draft.projectFilter = filteredProjects.reduce((acc: string[], { projectId, customerId }) => {
                    if (draft.projectFilter.includes(projectId) && draft.customerFilter.includes(customerId)) {
                      acc.push(projectId)
                    }
                    return acc
                  }, [])
                }))
              }
            }}
            multiple
            itemKey={({ customerId }: Customer): string => customerId}
            itemText={({ customerName }: Customer): string => customerName}
            matchKey="customerName"
            itemId={({ customerId }: Customer): string => customerId}
            clearSelection={(): void => setFilters(filters => ({ customerFilter: [], projectFilter: [], taskFilter }))}
          >
            Customers:&nbsp;<b>{customerFilter.length > 0 ? getCountableText(customerFilter.length, "customer", "customers") : "All"}</b>
          </ListFilter>

          <ListFilter

            hideAll
            disabled={!filteredProjects.length}
            data={filteredProjects}
            selectedIds={projectFilter}
            onItemClick={(item: Project | null): void => {
              if (item) {
                setFilters(produce(draft => {
                  const index = draft.projectFilter.indexOf(item.projectId)
                  if (index !== -1) {
                    draft.projectFilter.splice(index, 1)
                  } else {
                    draft.projectFilter.push(item.projectId)
                  }

                  draft.taskFilter = filteredTasks.reduce((acc: string[], { taskId, projectId }) => {
                    if (taskId && draft.taskFilter.includes(taskId) && draft.projectFilter.includes(projectId)) {
                      acc.push(taskId)
                    }
                    return acc
                  }, [])
                }))
              }
            }}
            multiple
            itemKey={({ projectId }: Project): string => projectId}
            itemText={({ projectName, color, customerId }): ReactNode => {
              return <span className={"text-truncate"} style={{ color }}>
                <span className={"project-colored-dot"} style={{ backgroundColor: color }}></span>
                <span>{projectName}</span> • {customerMapping[customerId]?.customerName}
              </span>
            }}
            matchKey="projectName"
            itemId={({ projectId }): string => projectId}
            clearSelection={(): void => setFilters(filters => ({ ...filters, projectFilter: [], taskFilter: [] }))}
          >
            Projects:&nbsp;<b>{projectFilter.length > 0 ? getCountableText(projectFilter.length, "project", "projects") : "All"}</b>
          </ListFilter>

          <ListFilter
            hideAll
            disabled={!filteredTasks.length}
            data={filteredTasks}
            selectedIds={taskFilter}
            onItemClick={(item): void => {
              if (item) {
                setFilters(produce(draft => {
                  const index = draft.taskFilter.indexOf(item.taskId!)
                  if (index !== -1) {
                    draft.taskFilter.splice(index, 1)
                  } else {
                    draft.taskFilter.push(item.taskId!)
                  }
                }))
              }
            }}
            multiple
            itemKey={({ taskId }: Task): string => taskId!}
            itemText={({ taskName }: Task): string => taskName}
            matchKey="taskName"
            itemId={({ taskId }: Task): string => taskId!}
            clearSelection={(): void => setFilters(filters => ({ ...filters, taskFilter: [] }))}
          >
            Tasks:&nbsp;<b>{taskFilter.length > 0 ? getCountableText(taskFilter.length, "task", "tasks") : "All"}</b>
          </ListFilter>

          <span>
            <StatisticsCalendar
              startDate={timeSpan.since}
              endDate={timeSpan.until}
              onDateChange={([since, until]): void => {
                until?.setHours(23, 59, 59, 999)
                setTimeSpan({ since, until })
              }}
            />
          </span>

          <Dropdown drop={"down"} className={"d-inline"}>
            <Dropdown.Toggle variant={"outline-dark"}>
              Granularity:&nbsp;<b>{granularity}</b>
            </Dropdown.Toggle>

            <Dropdown.Menu className={"w-100"}>
              {
                (["auto", "days", "weeks", "months", "years"] as Granularity[]).map(granularity => {
                  return <Dropdown.Item
                    key={granularity}
                    onClick={(): void => {
                      if (granularity === "auto" || confirm("Are you sure you want to force a granularity change? This could cause lag and issues while rendering the charts.")) {
                        setGranularity(granularity)
                      }
                    }}
                  >
                    {granularity}
                  </Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>

          <ListFilter
            data={[{ id: "true", name: "Yes" }, { id: "false", name: "No" }] as BooleanOption[]}
            selectedIds={showArchived ? ["true"] : ["false"]}
            onItemClick={(item: BooleanOption | null): void => {
              setShowArchived(item?.id === "true")
            }}
            hideAll
            matchKey="name"
            itemId={({ id }): string => id}
            itemKey={({ id }): string => id}
            itemText={({ name }): string => name}
          >
            Show archived:&nbsp;<b>{showArchived ? "Yes" : "No"}</b>
          </ListFilter>

          <ListFilter
            data={[{ id: "true", name: "Yes" }, { id: "false", name: "No" }] as BooleanOption[]}
            selectedIds={excludeGenerated ? ["true"] : ["false"]}
            onItemClick={(item: BooleanOption | null): void => {
              setExcludeGenerated(item?.id === "true")
            }}
            hideAll
            matchKey="name"
            itemId={({ id }): string => id}
            itemKey={({ id }): string => id}
            itemText={({ name }): string => name}
          >
            Exclude generated:&nbsp;<b>{excludeGenerated ? "Yes" : "No"}</b>
          </ListFilter>
        </div>

        <div className={"tw-self-start tw-min-w-fit ms-2"}>
          <Button variant={"dark"} className={"d-flex align-items-center"} onClick={(): void => {
            setCreateTimeEntryFormOpen(true)
          }}>
            <Icon name={"plus"} />&nbsp;Add new
          </Button>
        </div>
      </div>

      {
        !!statistics && statistics.totalClockedDuration > 0 && !isLoading ? <div className={classNames("mt-4", isLoading && "opacity-25 pointer-events-none")}>
          <HoursStackedBarChart
            data={statistics}
            primaryGroup={primaryGroup}
            selectedKey={selectedPrimaryKey}
            setSelectedKey={setSelectedPrimaryKey}
          />

          <div className={"grouping-bar mt-2"}>
            <DropdownButton variant={"outline-dark"} title={<>Group by <b>{primaryGroup}</b></>}>
              {
                primaryGroups.map(group => {
                  return <Dropdown.Item key={group} onClick={(): void => setGroups({ primaryGroup: group, secondaryGroup: secondaryGroups[group][0] })}>
                    {group}
                  </Dropdown.Item>
                })
              }
            </DropdownButton>
            <DropdownButton variant={"outline-dark"} title={<>and <b>{secondaryGroup}</b></>}>
              {
                secondaryGroups[primaryGroup].map(group => {
                  return <Dropdown.Item key={group} onClick={(): void => setGroups(groups => ({ ...groups, secondaryGroup: group }))}>
                    {group}
                  </Dropdown.Item>
                })
              }
            </DropdownButton>
          </div>

          <Row className={"mt-4"}>
            <Col lg={9} md={8} xs={12}>
              <StatisticsTimeEntries
                data={statistics}
                primaryGroup={primaryGroup}
                secondaryGroup={secondaryGroup}
                selectedKey={selectedPrimaryKey}
                setSelectedKey={setSelectedPrimaryKey}
                onEntryClick={(timeEntryId: string): void => {
                  if (!timeSpan.since || !timeSpan.until) {
                    return
                  }

                  const timeEntryData = timeEntryId.split("#")

                  // TODO: enhance /entries page
                  const searchParams = new URLSearchParams({
                    primary: primaryGroup,
                    secondary: secondaryGroup,
                    startDate: timeSpan.since.getTime().toString(),
                    endDate: timeSpan.until.getTime().toString(),
                    customerId: timeEntryData[1],
                    projectId: timeEntryData[3],
                    taskId: timeEntryData[5],
                    userId: timeEntryData[7]
                  })

                  navigate(`/filtered-entries?${searchParams.toString()}`)
                }}
              />
            </Col>
            <Col lg={3} md={4} xs={12}>
              <HoursPieChart
                data={statistics}
                primaryGroup={primaryGroup}
                selectedKey={selectedPrimaryKey}
                setSelectedKey={setSelectedPrimaryKey}
              />
            </Col>
          </Row>
        </div> : <div className={"text-center w-100"} style={{ marginTop: "4rem" }}>
          {
            isLoading ? <>
              <Spinner animation={"border"}/>
            </> : <h5>
              No data found for the selected filters
            </h5>
          }
        </div>
      }
    </div>
  </PageTemplate>
}

export default Statistics
