import React, { useEffect, useMemo } from "react"
import { Button, Modal } from "react-bootstrap"
import { Task } from "@polarity-dev/minosse-api-sdk"
import TaskEditor from "./TaskEditor"
import { BsBoxArrowInDown, BsBoxArrowUp, BsTrashFill } from "react-icons/bs"
import { MdSave } from "react-icons/md"

type TaskModalProps = {
  task?: Task
  show: boolean
  setTask: (task: Task) => Promise<boolean>
  deleteTask: () => Promise<boolean>
  close: () => void
}

const TaskModal: React.FunctionComponent<TaskModalProps> = ({
  task,
  show,
  setTask,
  deleteTask,
  close
}: TaskModalProps) => {

  const [isLoading, setIsLoading] = React.useState(false)
  const [taskData, setTaskData] = React.useState<Task>(task!)
  const [originalTaskData, setOriginalTaskData] = React.useState<Task>(task!)
  const isNewTask = taskData.taskId?.startsWith("NEWTASK#")

  useEffect(() => {
    if (task) {
      setOriginalTaskData(task!)
    }
  }, [task])

  const isDirty = useMemo(() => {
    return taskData.taskName !== originalTaskData.taskName
      || taskData.section !== originalTaskData.section
      || taskData.notes !== originalTaskData.notes
      || !taskData.userPrices.every((userPrice, index) => {
        const original = originalTaskData.userPrices[index]
        return userPrice?.userId === original?.userId
          && userPrice?.pricePerHour === original?.pricePerHour
          && userPrice?.isOwner === original?.isOwner
      })
  }, [taskData, originalTaskData])

  return <Modal
    show = { show }
    centered
    scrollable
    onHide = {(): void => {
      if (!isLoading) {
        if (isDirty) {
          if (confirm("Unsaved changes. Are you sure you want to close?")) {
            close()
          }
        } else {
          close()
        }
      }
    }}
    className = { "project-editor" }
    keyboard = { false }
  >
    <Modal.Header closeButton>
      <Modal.Title>{!isNewTask ? `Editing task "${task?.taskName}"` : <i>New task</i>}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TaskEditor
        task={taskData}
        setTask={setTaskData}
        isLoading={isLoading}
        noFooter
      />
    </Modal.Body>
    <Modal.Footer>
      <div className={"w-100 d-flex justify-content-end"}>
        {isNewTask ? <>
          <Button
            variant={"danger"}
            disabled={isLoading}
            onClick={async(): Promise<void> => {
              if (confirm("Are you sure you want to delete this task?")) {
                close()
              }
            }}
            className="d-flex align-items-center align-text-bottom ms-2"
          ><BsTrashFill className={"iconfix me-2"} />Delete</Button>
        </> : <>
          <div className={"d-flex"}>
            {!taskData.active ? <>
              <Button
                variant={"outline-dark"}
                disabled={isLoading || taskData.taskName.trim().length === 0}
                onClick={async(): Promise<void> => {
                  if (confirm("Are you sure you want to unarchive this task?")) {
                    setIsLoading(true)
                    if (!await setTask({ ...taskData, active: true })) {
                      setIsLoading(false)
                    }
                  }
                }}
              ><BsBoxArrowUp className={"iconfix me-2"} />Unarchive</Button>

              <Button
                variant={"danger"}
                disabled={isLoading}
                className="d-flex align-items-center align-text-bottom ms-2"
                onClick={async(): Promise<void> => {
                  if (confirm("Are you sure you want to delete this task?")) {
                    setIsLoading(true)
                    if (!await deleteTask()) {
                      setIsLoading(false)
                    }
                  }
                }}
              ><BsTrashFill className={"iconfix me-2"} />Delete</Button>
            </> : <>
              <Button
                variant={"outline-danger"}
                disabled={isLoading || taskData.taskName.trim().length === 0}
                onClick={async(): Promise<void> => {
                  if (confirm("Are you sure you want to archive this task?")) {
                    setIsLoading(true)
                    if (!await setTask({ ...taskData, active: false })) {
                      setIsLoading(false)
                    }
                  }
                }}
              ><BsBoxArrowInDown className={"iconfix me-2"} /><span>Archive</span></Button>
            </>}
          </div>
        </>}

        <Button
          variant={"primary"}
          className={"ms-2"}
          disabled={isLoading || taskData.taskName.trim().length === 0}
          type={"submit"}
          onClick={async(): Promise<void> => {
            setIsLoading(true)
            if (!await setTask(taskData)) {
              setIsLoading(false)
            }
          }}
        >
          <MdSave className={"iconfix iconfix--translate1 me-2"} />Save
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
}

export default TaskModal
