import { FunctionComponent, useContext } from "react"
import { MinosseContext } from "../contexts/MinosseContext"
import { BsExclamationTriangleFill } from "react-icons/bs"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

type EntryDetailsProps = {
  customerId: string | null
  projectId: string | null
  taskId: string | null
  hideCustomer?: boolean
  showIsHanging?: boolean
}

const EntryDetails: FunctionComponent<EntryDetailsProps> = ({ customerId, projectId, taskId, hideCustomer = false, showIsHanging = false }) => {
  const { projectMapping, customerMapping } = useContext(MinosseContext)

  return <>
    { projectId && <span style = {{ background: projectMapping[projectId]?.color }} className = { "project-colored-dot" }></span> }
    <span>
      {
        projectId ?
          <span style={{ textOverflow: "ellipsis" }}>
            {
              taskId
                ? <><span style={{ color: projectMapping[projectId]?.color }}>{projectMapping[projectId]?.projectName ?? "Deleted project"}: </span>{projectMapping[projectId]?.tasks.find(task => task.taskId === taskId)?.taskName ?? "Deleted task"}</>
                : <span style={{ color: projectMapping[projectId]?.color }}>{projectMapping[projectId]?.projectName ?? "Deleted project"}</span>
            }
          </span>
          :
          "No project"
      }
      {!hideCustomer && <>&nbsp;<span>•</span>&nbsp;<span>{ customerId ? (customerMapping[customerId]?.customerName ?? "Deleted customer") : "No customer" }</span></>}
      {
        showIsHanging && (!projectId || !taskId) &&
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement={"right"}
            overlay={
              <Tooltip>
                Entry has no {projectId ? "task" : "project"}
              </Tooltip>
            }
          >
            <span>
              <BsExclamationTriangleFill className={"iconfix iconfix--translate1 ms-2"} fill={projectId ? projectMapping[projectId]?.color : undefined} />
            </span>
          </OverlayTrigger>
      }
    </span>
  </>
}

export default EntryDetails