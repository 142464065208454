import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { MINUTE, getFormattedHMDuration } from "../helpers/time"
import AuthContext from "../contexts/AuthContext"
import { getEndOfWeek, getStartOfWeek } from "../helpers/plan"
import { CancelError } from "../sdk/minosse-api"
import dayjs from "dayjs"
import { AxiosError } from "axios"
import { handleException, logInfo } from "../helpers/logger"
import { Spinner } from "react-bootstrap"
import { GetPlanReport200ResponseSchema, getPlanReport } from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"

// TODO: POC, to be replaced with a proper API call
/* const getColor = (tracked: number, target: number, atRisk: boolean): string => {
  if (atRisk) {
    return tracked >= target ? "text-black" : "text-danger"
  } else {
    return tracked >= target ? "text-black" : "text-warning"
  }
}

const getText = (tracked: number, target: number): string => {
  const hours = Math.abs(tracked - target)

  if (tracked >= target) {
    return `(${getFormattedHMDuration(hours)} hours ahead)`
  } else {
    return `(${getFormattedHMDuration(hours)} hours behind)`
  }
} */

type BottomBarData = {
  week: { trackedTime: number, trackableTime: number }
  month: { trackedTime: number, trackableTime: number }
}

const getSavedData = (): BottomBarData | undefined => {
  const data = localStorage.getItem("bottomBarData")
  try {
    return JSON.parse(data!) as BottomBarData
  } catch (ex) {
    handleException(ex as Error)
    return undefined
  }
}

const BottomBar: React.FunctionComponent = () => {
  const { user } = useContext(AuthContext)

  const bottomBarRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const bottomBarPlaceholderRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const weekReqAbortController = useRef() as React.MutableRefObject<AbortController>
  const monthReqAbortController = useRef() as React.MutableRefObject<AbortController>

  const [isLoading, setIsLoading] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [error, setError] = useState<string>()
  const [trackedTimeData, setTrackedTimeData] = useState<BottomBarData | undefined>(getSavedData())

  useEffect(() => {
    if (!user) {
      return
    }

    const ro = new ResizeObserver((entries) => {
      const entry = entries[0]
      bottomBarPlaceholderRef.current.style.height = `${entry.contentRect.bottom}px`
    })

    ro.observe(bottomBarRef.current)
    return (): void => ro.disconnect()
  }, [bottomBarPlaceholderRef.current])

  const loadData = useCallback(async(): Promise<void> => {
    if (!user) {
      return
    }

    if (!isFirstLoad) {
      if (weekReqAbortController.current) {
        weekReqAbortController.current.abort()
      }
      if (monthReqAbortController.current) {
        monthReqAbortController.current.abort()
      }
    }


    setIsLoading(true)
    try {
      weekReqAbortController.current = new AbortController()
      monthReqAbortController.current = new AbortController()
      const weekReq = handleApiCall(getPlanReport, {
        userFilter: [user.userId],
        start: getStartOfWeek().getTime(),
        stop: getEndOfWeek().getTime()
      }, {
        signal: weekReqAbortController.current.signal
      })
      const monthReq = handleApiCall(getPlanReport, {
        userFilter: [user.userId],
        start: dayjs().utc().startOf("month").toDate().getTime(),
        stop: dayjs().utc().endOf("month").toDate().getTime()
      }, {
        signal: monthReqAbortController.current.signal
      })

      const [weekData, monthData] = await Promise.all([weekReq, monthReq]) as Array<GetPlanReport200ResponseSchema>

      const built = {
        week: {
          trackedTime: weekData.data.users[user.userId].totalTrackedTime,
          trackableTime: weekData.data.users[user.userId].totalPlannedTime
        },
        month: {
          trackedTime: monthData.data.users[user.userId].totalTrackedTime,
          trackableTime: monthData.data.users[user.userId].totalPlannableTime
        }
      }

      setTrackedTimeData(built)
      localStorage.setItem("bottomBarData", JSON.stringify(built))
      setIsFirstLoad(false)
      setError(undefined)
    } catch (ex) {
      const err = ex as AxiosError
      // check if cancelerror
      if (err.isAxiosError && (ex as CancelError).isCancelled) {
        logInfo("[bottombar/fetch] request was aborted")
        return
      } else {
        handleException(err)
      }
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }, [weekReqAbortController, monthReqAbortController, user])

  useEffect(() => {
    const handler = async(): Promise<void> => {
      logInfo("[bottombar/fetch] timeEntriesChanged fired, refetching")
      void loadData()
    }

    document.addEventListener("timeEntriesChanged", handler)
    return (): void => document.removeEventListener("timeEntriesChanged", handler)
  }, [loadData])

  useEffect(() => {
    if (!user) {
      return
    }

    void loadData()
  }, [user])

  useEffect(() => {
    const handler = (): void => {
      logInfo("[bottombar/timer] timer fired, refetching")
      void loadData()
    }
    const timer = setInterval(handler, MINUTE)
    return (): void => clearTimeout(timer)
  }, [loadData])


  // TODO: POC, to be replaced with a proper API call
  // const now = new Date()
  // const dow = getDayOfWeekFixed(now)
  // const idealHoursYesterday = useMemo(() => user?.dailyHours.reduce((acc, dh, i) => {
  //   if (i < dow) {
  //     acc += dh
  //   }
  //   return acc
  // }, 0) || 0, [user])

  if (!user) {
    return <span></span>
  }

  return <span>
    <div className={"bottom-bar__placeholder"} ref={bottomBarPlaceholderRef}></div>
    <div className={"bottom-bar"} ref={bottomBarRef}>
      <div className={"bottom-bar__contents max-content-width"}>
        {error && <span className={"fst-italic text-danger"}>Error: <span className="font-monospace">{error}</span></span>}
        {(trackedTimeData && !error) && <>
          <span className={"d-flex align-items-center mw-fit-content"}><b>Week</b>: {getFormattedHMDuration(trackedTimeData?.week?.trackedTime || 0, false, false)}/{getFormattedHMDuration(trackedTimeData?.week?.trackableTime || 0, false, false)}</span>
          <span className={"d-flex align-items-center mw-fit-content"}><b>Month</b>: {getFormattedHMDuration(trackedTimeData?.month?.trackedTime || 0, false, false)}/{getFormattedHMDuration(trackedTimeData?.month?.trackableTime || 0, false, false)}</span>
        </>}
        {isLoading && <span>
          <span className="opacity-50">
            <Spinner animation="border" size="sm" /><span className="ms-2 opacity-75">Updating..</span>
          </span>
        </span>}
      </div>
    </div>
  </span>
}

export default BottomBar
