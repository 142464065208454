import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react"
import classNames from "classnames"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import PageTemplate from "../components/PageTemplate"
import AuthContext from "../contexts/AuthContext"
import { MinosseContext } from "../contexts/MinosseContext"
import NotFoundPage from "./NotFoundPage"
import loading from "../assets/loading.gif"
import TimeEntryForm from "../components/TimeEntryForm"
import { handleException, logError } from "../helpers/logger"
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import EntryDetails from "../components/EntryDetails"
import { getReadableDay, getReadableDuration } from "../helpers/time"
import { HiOutlineChevronLeft } from "react-icons/hi"
import {
  getTimeEntriesInRange,
  GetTimeEntriesInRange200ResponseSchema,
  TimeEntry,
  updateTimeEntry,
  UpdateTimeEntry200ResponseSchema
} from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"
import Icon from "../components/Icon"

type LocationState = {
  entries: TimeEntry[]
  primaryGrouping: string
  secondaryGrouping: string
  dateSpan: string
  selectedPrimaryKey?: string
  userIds?: string[]
  customerIds?: string[]
  projectIds?: string[]
  taskIds?: string[]
  granularity?: string
}

const FilteredEntries: FunctionComponent = () => {
  const { initialized, user } = useContext(AuthContext)
  const { dataReady, userMapping, customerMapping, projectMapping } = useContext(MinosseContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)

  const [showAdjustModal, setShowAdjustModal] = useState(false)
  const [isAdjustModalLoading, setIsAdjustModalLoading] = useState(false)
  const [adjustModalValue, setAdjustModalValue] = useState<number>()

  const [createTimeEntryModalOpen, setCreateTimeEntryModalOpen] = useState(false)

  const start = parseInt(searchParams.get("startDate") || "NaN")
  const stop = parseInt(searchParams.get("endDate") || "NaN")
  const customerId = searchParams.get("customerId")
  const userId = searchParams.get("userId") || ""
  const projectId = searchParams.get("projectId")
  const taskId = searchParams.get("taskId")
  const primaryGrouping = searchParams.get("primary")
  const secondaryGrouping = searchParams.get("secondary")

  if (initialized && !user) {
    return <Navigate to = { "/login" } replace/>
  }

  if (initialized && !user?.isAdmin) {
    return <NotFoundPage/>
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [entries, setEntries] = useState<TimeEntry[]>([])

  const loadData = async(): Promise<void> => {
    if (!searchParams.has("startDate")
      || !searchParams.has("endDate")
      || !searchParams.has("userId")
      || !searchParams.has("primary")
      || !searchParams.has("secondary")
      || !searchParams.has("customerId")
      || !searchParams.has("projectId")
      || !searchParams.has("taskId")
    ) {
      navigate("/statistics", { replace: true })
    }

    setIsLoading(true)

    try {
      const data = await handleApiCall(getTimeEntriesInRange, {
        start,
        stop,
        customerFilter: {
          customerId,
          projectId: ([primaryGrouping, secondaryGrouping].includes("projects") || secondaryGrouping === "tasks") ? projectId : undefined
        },
        userFilter: [primaryGrouping, secondaryGrouping].includes("users") ? {
          userId
        } : undefined
      })
      const entries = (data as GetTimeEntriesInRange200ResponseSchema).data
      if (secondaryGrouping === "tasks") {
        setEntries(entries.timeEntries.filter(entry => entry.taskId === taskId))
      } else {
        setEntries(entries.timeEntries)
      }
    } catch (ex) {
      handleException(ex as Error)
      logError(ex)
      navigate("/statistics")
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!user?.isAdmin) {
      return
    }

    void loadData()
  }, [searchParams, user, start, stop, userId, customerId, projectId, taskId, primaryGrouping, secondaryGrouping])

  const primaryGroupingText = useMemo(() => {
    switch (primaryGrouping) {
      case "customers":
        return customerMapping[customerId || ""]?.customerName || "unable to load customer name"
      case "projects":
        return projectMapping[projectId || ""]?.projectName || "unable to load project name"
      case "users":
        return userMapping[userId || ""]?.username || "unable to load user name"
      default:
        return "n/a"
    }
  }, [primaryGrouping, customerMapping, projectMapping, userMapping, user, searchParams])

  const secondaryGroupingText = useMemo(() => {
    const customerName = customerMapping[customerId || ""]?.customerName
    const project = projectMapping[projectId || ""]

    switch (secondaryGrouping) {
      case "customers":
        return customerName || "unable to load customer name"
      case "projects":
        return project
          ? `[${customerMapping[customerId || ""]?.customerName}] ${project?.projectName}`
          : "unable to load project name"
      case "users":
        return userMapping[userId || ""]?.username || "unable to load user name"
      case "tasks":
        return project?.tasks.find?.(t => t.taskId === taskId)?.taskName || "unable to load user name"
      default:
        return "n/a"
    }
  }, [secondaryGrouping, customerMapping, projectMapping, userMapping, user, searchParams])

  useEffect(() => {
    if (entries.length) {
      window.history.replaceState({}, document.title)
    }
  }, [entries])

  const [selectedEntry, setSelectedEntry] = useState<TimeEntry | null>(null)

  const adjustPrices = async(): Promise<void> => {
    setIsAdjustModalLoading(true)
    const id = toast.loading("Adjusting prices...")
    try {
      // TODO: adjust all prices
      await Promise.all(entries.map(async(entry): Promise<UpdateTimeEntry200ResponseSchema> => {
        return await handleApiCall(updateTimeEntry, {
          handler: {
            id: entry.id,
            userId: entry.userId
          },
          tags: entry.tags,
          taskId: entry.taskId,
          description: entry.description,
          start: entry.start,
          projectId: entry.projectId,
          customerId: entry.customerId,
          stop: entry.stop,
          pricePerHour: adjustModalValue!
        }) as UpdateTimeEntry200ResponseSchema
      }))
      toast.update(id, {
        render: "Prices adjusted",
        type: "success",
        isLoading: false,
        autoClose: 3000
      })
      setShowAdjustModal(false)
      await loadData()
    } catch (ex) {
      handleException(ex as Error)
      logError(ex)
      toast.update(id, {
        render: "Failed to adjust prices",
        type: "error",
        isLoading: false,
        autoClose: 3000
      })
    }
    setIsAdjustModalLoading(false)
  }

  return <PageTemplate>
    {
      <div
        className = { classNames("loading-pane", { visible: !dataReady }) }
        onTransitionEnd = { (event): void => event.currentTarget.remove() }
      >
        <img src = { loading }/>
      </div>
    }
    {
      (!!selectedEntry || createTimeEntryModalOpen) && <TimeEntryForm
        selectedEntry={selectedEntry ?? undefined}
        entries={entries || []}
        onSubmit = {(): void => {
          void loadData()
        }}
        onDelete={(id: string): void => setEntries(entries => entries.filter(entry => id !== entry.id))}
        close={(): void => selectedEntry ? setSelectedEntry(null) : setCreateTimeEntryModalOpen(false)}
        showPricePerHour
        showUserPicker
      />
    }
    <Modal show={showAdjustModal} onHide={(): void => {
      if (!isAdjustModalLoading) {
        setShowAdjustModal(false)
      }
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Adjust all prices per hour</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label className={"text-uppercase fw-bolder"}>Price per hour</Form.Label>
          <Form.Control
            disabled={isAdjustModalLoading}
            type="number"
            step="0.01"
            min="0"
            value={adjustModalValue}
            onChange={(e): void => {
              setAdjustModalValue(parseFloat(e.target.value))
            }}
            onKeyDown={(event): void => {
              if (event.code === "Enter") {
                void adjustPrices()
              }
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={adjustPrices}
          disabled={isAdjustModalLoading || !adjustModalValue}
        >Adjust all</Button>
      </Modal.Footer>
    </Modal>
    <div className="max-content-width">
      <div className = { "entries px-0" }>
        <div className={"d-flex align-items-center justify-content-between mb-3 "}>
          <span
            className={"cursor-pointer"}
            onClick={(): void => navigate(-1)}
          >
            <HiOutlineChevronLeft className="iconfix iconfix--large iconfix--chevron me-1" />
              Back to statistics
          </span>

          <span className={"flex flex-row gap-3"}>
            <Button
              variant="outline-dark"
              onClick={(): void => {
                setAdjustModalValue(undefined)
                setShowAdjustModal(true)
              }}
            >
            Adjust prices per hour
            </Button>
            <Button variant={"dark"} className={"ms-2 d-inline-flex align-items-center"} onClick={(): void => {
              setCreateTimeEntryModalOpen(true)
            }}>
              <Icon name={"plus"} />&nbsp;Add new
            </Button>
          </span>
        </div>
        <div className={"d-flex align-items-center justify-content-between mb-3 px-2"}>
          <div className={"d-flex flex-column justify-content-between"}>
            <span>Viewing results for <strong>{ primaryGroupingText }</strong> { ">" } <strong>{ secondaryGroupingText }</strong></span>
          </div>
        </div>
        {isLoading || !dataReady
          ? <div className={"d-flex justify-content-center"}>
            <Spinner animation="border" />
          </div>
          : <Table hover>
            <thead>
              <tr>
                <td>Entry</td>
                <td>Date</td>
                <td>User</td>
                <td>Duration</td>
              </tr>
            </thead>
            <tbody>
              {entries.map(entry => <tr onClick={(): void => setSelectedEntry(entry)}>
                <td>
                  <div>
                    <div className={"group-entry__description"}>{entry.description || "No description"}</div>
                    <EntryDetails customerId={entry.customerId} projectId={entry.projectId} taskId={entry.taskId}/>
                  </div>
                </td>
                <td className="vertical-align-middle" style={{ width: "1%", whiteSpace: "nowrap" }}>
                  <span>{entry.start ? getReadableDay(entry.start) : "n/a"}</span>
                </td>
                <td className="vertical-align-middle" style={{ width: "1%", whiteSpace: "nowrap" }}>
                  <span>{userMapping[entry.userId]?.username || "n/a"}</span>
                </td>
                <td className="vertical-align-middle" style={{ width: "1%", whiteSpace: "nowrap" }}>
                  {getReadableDuration(entry.duration || NaN)}
                </td>
              </tr>)}
              {entries.length === 0 && <tr>
                <td colSpan={4} className={"text-center fst-italic"}>No entries found</td>
              </tr>}
            </tbody>
          </Table>
        }
      </div>
    </div>
  </PageTemplate>
}

export default FilteredEntries