import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { Button, Dropdown, FormCheck, FormControl, Overlay, Popover } from "react-bootstrap"
import { ButtonVariant } from "react-bootstrap/types"
import classNames from "classnames"
import { escapeRegExp } from "../helpers/utils"
import Fuse from "fuse.js"

type MultipleListFilterProps<T> = {
  multiple?: true
  selectedIds: string[]
  itemId: (item: T) => string
  clearSelection: () => void
}

type ListFilterProps<T> = {
  disabled?: boolean
  variant?: ButtonVariant
  className?: string
  children: ReactNode
  data: T[]
  hideAll?: boolean
  widthOverride?: number
  onItemClick: (item: T | null) => void
  itemKey: (item: T) => string
  itemText: (item: T) => string | ReactNode
  matchKey: string
} & ({ multiple?: false } | MultipleListFilterProps<T>)

const ListFilter = <T, >({
  disabled = false,
  variant = "outline-dark",
  className = "",
  children,
  data,
  hideAll = false,
  widthOverride,
  onItemClick,
  itemKey,
  itemText,
  matchKey,
  multiple = false,
  ...multipleProps
}: ListFilterProps<T>): JSX.Element => {
  const {
    selectedIds,
    itemId,
    clearSelection
  } = multipleProps as ListFilterProps<T> & MultipleListFilterProps<T>

  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const btnRef = useRef<HTMLButtonElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [text, setText] = useState<string>("")
  const [show, setShow] = useState<boolean>(false)
  const [onlyShowSelected, setOnlyShowSelected] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const filteredData = useMemo(() => {
    if (!text.length) {
      if (onlyShowSelected) {
        return data.filter(item => selectedIds.includes(itemKey(item)))
      } else {
        return data
      }
    }
    const fuse = new Fuse(data, {
      keys: [matchKey],
      threshold: 0.3
    })
    const filtered = fuse.search(text).map(result => result.item as T)
    if (onlyShowSelected) {
      return filtered.filter(item => selectedIds.includes(itemId(item))) as T[]
    } else {
      return filtered as T[]
    }
  }, [data, text, onlyShowSelected, show])

  useEffect(() => {
    if (filteredData.length === 0) {
      setOnlyShowSelected(false)
    }
  }, [filteredData])

  const hideAllItem = hideAll || text.length > 0

  useEffect(() => {
    setSelectedIndex(0)
  }, [filteredData.length, text])

  useEffect(() => {
    setSelectedIndex(0)
    setText("")
    if (show) {
      inputRef.current?.focus()
    }
  }, [show])

  useEffect(() => {
    setSelectedIndex(idx => Math.max(Math.min(idx, filteredData.length - 1), 0))
  }, [selectedIndex])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShow(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return (): void => document.removeEventListener("mousedown", handleClickOutside)
  }, [wrapperRef.current])

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (!show) {
        return
      }

      switch (event.key) {
        case "ArrowDown": {
          event.preventDefault()
          setSelectedIndex(selectedIndex => (selectedIndex - (filteredData.length - 1) < 1) ? selectedIndex + 1 : selectedIndex)
          break
        }
        case "ArrowUp": {
          event.preventDefault()
          setSelectedIndex(selectedIndex => selectedIndex > 0 ? selectedIndex - 1 : selectedIndex)
          break
        }
        case "Enter": {
          event.preventDefault()
          if (selectedIndex === 0 && !hideAllItem) {
            onItemClick(null)
            setShow(false)
          } else if (filteredData[selectedIndex - (hideAllItem ? 0 : 1)]) {
            onItemClick(filteredData[selectedIndex - (hideAllItem ? 0 : 1)])
            setShow(false)
          }
          break
        }
        default: {
          setSelectedIndex(0)
        }
      }

      wrapperRef.current?.querySelector(".selected")?.scrollIntoView(false)
    }

    document.addEventListener("keydown", handler)

    return (): void => document.removeEventListener("keydown", handler)
  }, [selectedIndex, filteredData])

  return <span className={className}>
    <Dropdown.Toggle disabled={disabled} variant={variant} ref={btnRef} onClick={(): void => {
      setShow(show => !show)
    }}>
      {children}
    </Dropdown.Toggle>
    <Overlay show={show} placement="bottom" rootClose rootCloseEvent="mousedown" target={btnRef} ref={wrapperRef}>
      <Popover className = { "statistics-calendar__date_range-popover" }>
        <Popover.Body>
          {
            multiple && <div className={"d-flex justify-content-between mb-3"}>
              <FormCheck disabled={!selectedIds.length} checked={onlyShowSelected} onChange={(e): void => setOnlyShowSelected(e.target.checked)} label={"Only show selected"} style={{ transform: "translateY(4px)" }} />
              <Button disabled={!selectedIds.length} size={"sm"} onClick={clearSelection} variant={"outline-dark"}>Clear all</Button>
            </div>
          }
          <FormControl type = { "text" }
            value = { text }
            className = { Object.keys(filteredData).length ? "shift" : undefined }
            autoFocus
            ref={inputRef}
            onChange = { (event): void => {
              setText(event.target.value)
            }}
          />
          <div style={{ maxHeight: 300, width: widthOverride ?? 250, overflow: "scroll" }}>
            {!hideAllItem && <div
              onMouseEnter={(): void => setSelectedIndex(0)}
              onMouseDown={(): void => {
                onItemClick(null)
                if (!multiple) {
                  setShow(false)
                }
              }}
              className={classNames("project", { selected: selectedIndex === 0 })}
            >All</div>}
            {filteredData.map((item, index) => {
              return <div
                id={itemKey(item)}
                key={itemKey(item)}
                className={classNames("project cursor-pointer", { selected: (index + (hideAllItem ? 0 : 1)) === selectedIndex })}
                onMouseEnter={(): void => setSelectedIndex(index + (hideAllItem ? 0 : 1))}
                onMouseDown={(): void => {
                  onItemClick(item)
                  if (!multiple) {
                    setShow(false)
                  }
                }}
              >
                <span className="d-flex align-items-center gap-2">
                  {multiple && <FormCheck checked={selectedIds?.includes(itemId(item))} />}{itemText(item)}
                </span>
              </div>
            })}
            {filteredData.length === 0 && <div className={"text-center mt-3 fst-italic text-muted"}>No items</div>}
          </div>
        </Popover.Body>
      </Popover>
    </Overlay>
  </span>
}

export default ListFilter
