import { FunctionComponent, useContext, useEffect, useState } from "react"
import { Button, Form, InputGroup, Modal, Spinner, Table } from "react-bootstrap"
import { RegisterOptions, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { BsBoxArrowInDown, BsClipboard, BsTrashFill } from "react-icons/bs"
import {
  deleteUser,
  getUser,
  GetUser200ResponseSchema,
  upsertUser,
  UpsertUser200ResponseSchema,
  User
} from "@polarity-dev/minosse-api-sdk"
import { MdSave } from "react-icons/md"
import {
  StaffUser,
  StaffUsersService,
  UpdateStaffUser200ResponseSchema,
  UpdateStaffUserRequestSchema
} from "../sdk/minosse-principals-api"
import { handleError, isValidColor } from "../helpers/utils"
import { MinosseContext } from "../contexts/MinosseContext"
import { isEqual } from "lodash"
import { handleApiCall } from "../helpers/api"

type UserFormProps = {
  user?: StaffUser,
  show: boolean,
  close: () => void
}

const dailyHoursRegisteredOptions = {
  valueAsNumber: true,
  required: true,
  min: 0,
  max: 8
} as RegisterOptions

const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]

type FormData =
  & Pick<StaffUser, "username" | "email" | "isAdmin" | "color">
  & Pick<User, "basePrice" | "awsUsername" | "telegramUsername" | "telegramId" | "dailyHours" | "hiddenFromPlanner">

const UserForm: FunctionComponent<UserFormProps> = ({
  user,
  close,
  show
}) => {
  const { userMapping, actions: { setUserList } } = useContext(MinosseContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isLoadingExtra, setIsLoadingExtra] = useState<boolean>(false)
  const [userExtra, setUserExtra] = useState<User | null>(null)

  const { register, handleSubmit, formState: { errors, dirtyFields }, reset, getValues, setValue, clearErrors, watch } = useForm<FormData>()

  const color = watch("color")

  useEffect(() => {
    if (user) {
      setIsLoadingExtra(true)
      void handleApiCall(getUser, { handler: { userId: user.userId } })
        .then(data => setUserExtra((data as GetUser200ResponseSchema).data))
        .catch(handleError)
        .finally(() => setIsLoadingExtra(false))
    }
  }, [])

  useEffect(() => {
    reset({
      ...user && {
        username: user.username,
        email: user.email,
        isAdmin: user.isAdmin,
        color: user.color
      },
      ...userExtra && {
        basePrice: userExtra.basePrice,
        awsUsername: userExtra.awsUsername,
        telegramUsername: userExtra.telegramUsername,
        telegramId: userExtra.telegramId,
        dailyHours: userExtra.dailyHours ?? [0, 0, 0, 0, 0],
        hiddenFromPlanner: userExtra.hiddenFromPlanner ?? false
      }
    })

  }, [user, userExtra])

  const onFormSubmit = async(data: FormData): Promise<void> => {
    const { username, email, isAdmin, color, ...upsertPayload } = data

    if (typeof upsertPayload.hiddenFromPlanner === "undefined") {
      upsertPayload.hiddenFromPlanner = false
    }

    if (typeof upsertPayload.telegramId === "number" && isNaN(upsertPayload.telegramId)) {
      upsertPayload.telegramId = null
    }

    setIsSubmitting(true)
    const loader = toast.loading(user ? "Updating user..." : "Creating user...")

    try {
      if (user) {
        const promises: Promise<UpsertUser200ResponseSchema | UpdateStaffUser200ResponseSchema>[] = [
          handleApiCall(upsertUser, {
            handler: { userId: user.userId },
            updates: upsertPayload
          }) as Promise<UpsertUser200ResponseSchema>
        ]

        const updates: UpdateStaffUserRequestSchema["updates"] = {}
        if (dirtyFields.email) {
          updates.email = email
        }
        if (dirtyFields.username) {
          updates.username = username
        }
        if (dirtyFields.isAdmin) {
          updates.isAdmin = isAdmin
        }
        if (dirtyFields.color) {
          updates.color = color
        }
        if (Object.keys(updates).length > 0) {
          promises.push(StaffUsersService.updateStaffUser({ handler: { userId: user.userId }, updates }))
        }

        await Promise.all(promises)

        setUserList(userList => userList.map(updatedUser => {
          if (updatedUser.userId === user.userId) {
            return { ...updatedUser, ...data, isAdmin }
          }
          return updatedUser
        }))
      } else {
        const { data: { userId } } = await StaffUsersService.createStaffUser({ email, username, password: "Ciaomare123!", color })
        await handleApiCall(upsertUser, { handler: { userId }, updates: upsertPayload })

        setUserList(userList => [...userList, {
          userId,
          email,
          username,
          isAdmin,
          active: true,
          hasMFA: false,
          color
        }])
      }

      toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: `User ${user ? "updated" : "created"} successfully` })
      close()
    } catch (error) {
      handleError(error, loader)
      setIsSubmitting(false)
    }
  }

  return <Modal
    show={show}
    centered
    scrollable
    className={"project-editor"}
    onHide={(): void => {
      if (isSubmitting) {
        return
      }

      if (
        Object.keys(dirtyFields).length > 0
        || userExtra?.dailyHours && !isEqual(userExtra.dailyHours, getValues("dailyHours"))
      ) {
        if (confirm("Unsaved changes. Are you sure you want to close?")) {
          close()
        }
      } else {
        close()
      }
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{user ? `Edit ${userMapping[user.userId]?.username}` : "Add new user"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        {
          user && <Form.Group>
            <Form.Label>User ID</Form.Label>
            <InputGroup>
              <div className={"position-relative w-100"}>
                <Form.Control
                  type={"text"}
                  value={user.userId}
                  disabled
                  className={"input__disabled-lighter"}
                />
                <BsClipboard
                  className={"reports__clear-icon cursor-pointer"}
                  onClick={(): void => {
                    void navigator.clipboard.writeText(user.userId).then(() => toast.info("Copied to clipboard"))
                  }}
                />
              </div>
            </InputGroup>
          </Form.Group>
        }
        <Form.Group>
          <Form.Label>User name</Form.Label>
          <Form.Control
            type={"text"}
            placeholder={"Add user name..."}
            isInvalid={!!errors.username}
            {...register("username", { required: true, setValueAs: (value: string) => value.trim() })}
          />
          <Form.Control.Feedback type={"invalid"}>
            Please choose a user name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type={"text"}
            placeholder={"Add an email address..."}
            isInvalid={!!errors.email}
            {...register("email", { required: true })}
          />
          <Form.Control.Feedback type={"invalid"}>
            Please choose an email address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>User type</Form.Label>
          <Form.Select {...register("isAdmin", { setValueAs: (v): boolean => v === "true" })} >
            <option value={"false"}>Normal user</option>
            <option value={"true"}>Administrator</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Color</Form.Label>
          <InputGroup>
            <Form.Control
              type = { "text" }
              placeholder = { "Add a color..." }
              isInvalid = { !!errors.color }
              { ...register("color", { required: false, validate: value => value === "" || isValidColor(value!) }) }
            />
            <Form.Control
              type = { "color" }
              className={"project-form__color-picker"}
              onChange={(event): void => {
                setValue("color", event.target.value, {
                  shouldDirty: true
                })
                clearErrors("color")
              }}
              value={color}
            />
          </InputGroup>
          <Form.Control.Feedback type = { "invalid" }>
            Please input a color.
          </Form.Control.Feedback>
        </Form.Group>
        {
          isLoadingExtra ? <div className={"spinner"}>
            <Spinner animation={"border"}/>
          </div> : <>
            <Form.Group>
              <Form.Label>Base price</Form.Label>
              <Form.Control
                type={"number"}
                placeholder={"Add a base price..."}
                isInvalid={!!errors.basePrice}
                defaultValue={"50"}
                min={0}
                {...register("basePrice", { required: true, valueAsNumber: true })}
              />
              <Form.Control.Feedback type = { "invalid" }>
                Please input a base price.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Telegram ID</Form.Label>
              <Form.Control
                type={"text"}
                placeholder={"Add a Telegram ID..."}
                { ...register("telegramId", { valueAsNumber: true }) }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Telegram Username</Form.Label>
              <Form.Control
                type = { "text" }
                placeholder = { "Add a Telegram username..." }
                { ...register("telegramUsername", { setValueAs: (value: string | null) => value?.trim() || null }) }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>AWS Username</Form.Label>
              <Form.Control
                type = { "text" }
                placeholder = { "Add an AWS username..." }
                { ...register("awsUsername", { setValueAs: (value: string | null) => value?.trim() || null }) }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Daily hours</Form.Label>
              <Table borderless className={"user-form__table"}>
                <thead>
                  <tr>
                    {DAYS.map(day => <th>{day}</th>)}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {
                      DAYS.map((_, index) => <td>
                        <Form.Control
                          type={"number"}
                          step={1}
                          isInvalid={!!errors.dailyHours?.[index]}
                          {...register(`dailyHours.${index}`, dailyHoursRegisteredOptions)}
                        />
                      </td>)
                    }
                  </tr>
                </tbody>
              </Table>
            </Form.Group>
          </>
        }
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {
        !!user && <Button
          variant={user.active ? "outline-danger" : "danger"}
          disabled={isSubmitting || isLoadingExtra}
          className={"ms-auto"}
          onClick={async(): Promise<void> => {
            if (confirm(`Are you sure you want to ${user.active ? "disable" : "delete"} this user?`)) {
              setIsSubmitting(true)
              const loader = toast.loading(`${user.active ? "Disabling" : "Deleting"} user...`)

              try {
                if (user.active) {
                  await StaffUsersService.updateStaffUser({
                    handler: { userId: user.userId },
                    updates: { active: false }
                  })
                  setUserList(userList => userList.map(updatedUser => {
                    if (updatedUser.userId === user.userId) {
                      return { ...updatedUser, active: false }
                    }
                    return updatedUser
                  }))
                } else {
                  await Promise.all([
                    StaffUsersService.deleteStaffUser({ handler: { userId: user.userId } }),
                    handleApiCall(deleteUser, { handler: { userId: user.userId } })
                  ])
                  setUserList(userList => userList.filter(({ userId }) => user.userId !== userId))
                }

                close()
                toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: `User ${user.active ? "disabled" : "deleted"} successfully` })
              } catch (error) {
                handleError(error, loader)
                setIsSubmitting(false)
              }
            }
          }}
        >
          { user.active ? <><BsBoxArrowInDown className={"iconfix me-2"} />Disable</> : <><BsTrashFill className={"iconfix iconfix--translate1 me-2"} />Delete</> }
        </Button>
      }
      <Button
        variant={"primary"}
        disabled={isSubmitting || isLoadingExtra}
        type={"submit"}
        onClick={handleSubmit(onFormSubmit)}
      >
        <MdSave className={"iconfix iconfix--translate1 me-2"}/>Save
      </Button>
    </Modal.Footer>
  </Modal>
}

export default UserForm