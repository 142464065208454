import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import PageTemplate from "../components/PageTemplate"
import useCalendar from "../hooks/useCalendar"
import { CalendarEntry, HolidayData, MonthConfig, PlanReport } from "../types/planner"
import { ClipboardData, dayWidth, PlanEditorData, rowHeight } from "../helpers/calendarAnimator"
import MonthHeader from "../components/newplanner/MonthHeader"
import MonthDays from "../components/newplanner/MonthDays"
import PlanItem from "../components/newplanner/PlanItem"
import { alphabetize, Mapping } from "../helpers/utils"
import { MinosseContext } from "../contexts/MinosseContext"
import AuthContext from "../contexts/AuthContext"
import PlannerCustomerView, { getTextColor } from "../components/planner/PlannerCustomerView"
import { getFormattedHMDuration } from "../helpers/time"
import {
  Button,
  ButtonGroup,
  Form,
  FormControl,
  Modal,
  Overlay,
  OverlayTrigger,
  Popover,
  Spinner,
  Tooltip
} from "react-bootstrap"
import {
  BsClipboard,
  BsFillExclamationTriangleFill,
  BsLayoutTextWindow,
  BsPaintBucket,
  BsScissors,
  BsTrashFill,
  BsX
} from "react-icons/bs"
import { groupProjects } from "../helpers/projects"
import { filterProjects } from "../helpers/transformers"
import ProjectSearchbox from "../components/ProjectSearchbox"
import Draggable, { DraggableEvent } from "react-draggable"
import classNames from "classnames"
import { ResizableBox } from "react-resizable"
import { getWeekText, parseWeek } from "../helpers/plan"
import { format } from "date-fns"
import UserSimpleDropdown from "../components/UserSimpleDropdown"
import { Style } from "react-style-tag"
import {
  mGetUsers,
  MGetUsers200ResponseSchema,
  User
} from "@polarity-dev/minosse-api-sdk"
import { toast } from "react-toastify"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import "dayjs/locale/it"
import gsap from "gsap"
import { StaffUser } from "../sdk/minosse-principals-api"
import { useNavigate, useSearchParams } from "react-router-dom"
import { BiX } from "react-icons/bi"
import SuggestionsDropdown from "../components/SuggestionsDropdown"
import { handleApiCall } from "../helpers/api"
import { handleException } from "../helpers/logger"

dayjs.extend(utc)
dayjs.locale("it")


export const headingHeight = 96
// const rowsCount2 = 50

const NewPlanner: React.FC = () => {
  const { dataReady, userList: baseUserList, customerMapping, projectList, userMapping } = useContext(MinosseContext)
  const { initialized, user } = useContext(AuthContext)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [planItems, setPlanItems] = useState<CalendarEntry[]>([])
  const [planReport, setPlanReport] = useState<PlanReport | null>(null)
  const [months, setMonths] = useState<MonthConfig[]>([]) // TODO: start with filled months (?)
  const [activeWeek, setActiveWeek] = useState<Date>(new Date())
  const [activeSelectionUuid, setActiveSelectionUuid] = useState<string | null>(null)
  const left = months[0]?.left || 0

  const [minosseUsers, setMinosseUsers] = useState<Record<string, User>>({})

  const [weekInput, setWeekInput] = useState<string>(getWeekText(activeWeek))
  useEffect(() => {
    setWeekInput(activeWeek ? getWeekText(activeWeek) : "")
  }, [activeWeek])

  const [holidayMap, setHolidays] = useState<Mapping<HolidayData>>({})
  const addHolidays = (holidays: HolidayData[]): void => {
    setHolidays(old => ({
      ...old,
      ...holidays.reduce((acc: Mapping<HolidayData>, holiday: HolidayData) => ({
        ...acc,
        [format(holiday.date, "dd/MM/yyyy")]: holiday
      }), {} as Mapping<HolidayData>)
    }))
  }

  const [userModalOpen, setUserModalOpen] = useState<boolean>(false)
  const [userModalData, setUserModalData] = useState<Mapping<true>>({})
  const [userModalTextInput, setUserModalTextInput] = useState<string>("")
  const [userModalShowSuggestions, setUserModalShowSuggestions] = useState<boolean>(false)
  const resetUserModal = (): void => {
    setUserModalOpen(false)
    setUserModalData({})
    setUserModalTextInput("")
    setUserModalShowSuggestions(false)
  }
  const openUserModal = (): void => {
    setUserModalData(Object.keys(searchParamsUsers || {}).reduce((acc, id) => ({ ...acc, [id]: true }), {}))
    setUserModalOpen(true)
  }

  const [isGlobalReportPinned, setIsGlobalReportPinned] = useState(false)
  const [globalReportSearchText, setGlobalReportSearchText] = useState("")
  const [globalReportHeaderHeight, setGlobalReportHeaderHeight] = useState(101)
  const globalReportHeaderRef = useRef<HTMLDivElement>()
  useEffect(() => {
    if (globalReportHeaderRef.current?.getBoundingClientRect().height) {
      setGlobalReportHeaderHeight(Math.max(globalReportHeaderRef.current?.getBoundingClientRect().height, 101))
    }
  }, [isGlobalReportPinned, globalReportHeaderRef.current?.getBoundingClientRect().height])

  const [planEditorData, setPlanEditorData] = useState<PlanEditorData>({ open: false })

  const [clipboard, setClipboard] = useState<ClipboardData | null>(null)
  useEffect(() => {
    animator.clipboard = clipboard
  }, [clipboard])

  const filteredGlobalProjects = useMemo(() => {
    if (globalReportSearchText.length === 0 || planReport === null) {
      return null
    }
    const filtered = groupProjects(filterProjects(projectList, customerMapping, globalReportSearchText))
    const validCustomers = Object.keys(filtered)
    const validProjects = Object.values(filtered).flat().map(project => project.projectId)
    const validTasks = Object.values(filtered).flat().flatMap(project => project.tasks.flatMap(task => task.taskId)).flat()

    return planReport.global.customers
      .filter(customer => validCustomers.includes(customer.customerId))
      .map(customer => ({
        ...customer,
        projects: customer.projects
          .filter(project => validProjects.includes(project.projectId))
          .map(project => ({
            ...project,
            tasks: project.tasks.filter(task => validTasks.includes(task.taskId))
          }))
      }))
  }, [globalReportSearchText, planReport])

  const [shouldHidePlanner, setShouldHidePlanner] = useState<boolean>(true)

  console.log("rerender")

  useEffect(() => {
    if (!userMapping || Object.keys(userMapping).length === 0) {
      return
    }
    void (async(): Promise<void> => {
      try {
        const minosseUsers = await handleApiCall(mGetUsers, { handler: { userIds: Object.keys(userMapping) } })
        setMinosseUsers((minosseUsers as MGetUsers200ResponseSchema).data.reduce((acc: Record<string, User>, user: User) => ({ ...acc, [user.userId]: user }), {}))
      } catch (e) {
        handleException(e as Error)
        console.error(e)
        toast.error("Error while fetching minosse users")
      }
    })()
  }, [userMapping])

  const searchParamsUsers = useMemo<Mapping<StaffUser> | undefined>(() => {
    if (!searchParams.get("users") || !baseUserList) {
      return undefined
    }

    return decodeURIComponent(searchParams.get("users")!).split("#").reduce((acc: Record<string, StaffUser>, userId: string) => {
      if (userMapping[userId] !== undefined) {
        return { ...acc, [userId]: userMapping[userId] }
      }
      return acc
    }, {})
  }, [baseUserList, searchParams.get("users")])

  const userList = useMemo(() => {
    if (!initialized || Object.keys(minosseUsers).length === 0) {
      return []
    }

    if (user!.isAdmin) {
      const spUserCount = Object.values(searchParamsUsers ?? {}).length

      if (spUserCount > 0) {
        const result = baseUserList.filter(u => (
          u.active
          && !minosseUsers[u.userId]?.hiddenFromPlanner
          && searchParamsUsers?.[u.userId] !== undefined
        )).sort((a, b) => alphabetize(a.username, b.username))
        if (result.length === 0) {
          navigate("/planner")
          navigate(0)
        }
        return result
      } else {
        const list = baseUserList.filter(u => (
          u.userId !== user?.userId
          && u.active
          && !minosseUsers[u.userId]?.hiddenFromPlanner
        )).sort((a, b) => alphabetize(a.username, b.username))
        return [user!, ...list]
      }
    } else {
      return [user!]
    }
  }, [baseUserList, user?.userId, minosseUsers])

  const enabledUserMapping = useMemo(() => {
    if (!userList || userList.length === 0 || !minosseUsers || Object.keys(minosseUsers).length === 0) {
      return {}
    }

    return userList.reduce((acc, item) => {
      if (!minosseUsers[item.userId]?.hiddenFromPlanner) {
        acc[item.userId] = item
      }
      return acc
    }, {} as Mapping<StaffUser>)
  }, [minosseUsers, userList])

  const containerRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const { animator } = useCalendar()

  const planEditorTransform = `${planEditorData.onLeft ? "translateX(-300px)" : ""} ${planEditorData.onTop ? "translateY(-100%)" : ""}`
  const planEditorBorderRadius = {
    nw: "0 7.5px 7.5px 7.5px",
    ne: "7.5px 0 7.5px 7.5px",
    sw: "7.5px 7.5px 7.5px 0",
    se: "7.5px 7.5px 0 7.5px"
  }[`${planEditorData.onTop ? "s" : "n"}${planEditorData.onLeft ? "e" : "w"}`]
  const planEditorStyle = {
    left: planEditorData.x!,
    top: planEditorData.y!,
    transform: planEditorTransform,
    borderRadius: planEditorBorderRadius,
    display: planEditorData.open ? undefined : "none"
  }

  useEffect(() => {
    if (!dataReady || !userList || userList.length === 0 || !user) {
      return
    }
    if (containerRef.current && headerRef.current && sidebarRef.current && wrapperRef.current) {
      animator.init({
        container: containerRef.current,
        header: headerRef.current,
        sidebar: sidebarRef.current,
        wrapper: wrapperRef.current,
        updateMonths: setMonths,
        updatePlanItems: setPlanItems,
        updateActiveWeek: setActiveWeek,
        updatePlanReport: setPlanReport as Dispatch<SetStateAction<PlanReport>>,
        updatePlanEditorData: setPlanEditorData,
        updateActiveSelectionUuid: setActiveSelectionUuid,
        updateClipboard: setClipboard,
        updateHolidays: addHolidays,
        users: userList,
        user
      })
      setShouldHidePlanner(false)

      return (): void => {
        setShouldHidePlanner(true)
        animator.destroy()
      }
    }
  }, [dataReady, userList, user])

  useEffect(() => {
    animator.planEditorData.element = planEditorData.element
    animator.planEditorData.planEntry = planEditorData.planEntry
    animator.planEditorData.open = planEditorData.open
  }, [planEditorData])

  useEffect(() => {
    animator.activeSelection.uuid = activeSelectionUuid
  }, [activeSelectionUuid])

  const pauseEvent = (e: SyntheticEvent | DraggableEvent): void => {
    e.stopPropagation?.()
    e.preventDefault?.()
  }

  const monthName = useMemo(() => new Intl.DateTimeFormat("it", { month: "long" }).format(activeWeek), [activeWeek])


  const globalStats = <>
    <div ref={globalReportHeaderRef as MutableRefObject<HTMLDivElement>} className={"planner__global-customers-header bg-light shadow-sm"}>
      {isGlobalReportPinned && <div className={"planner__global-customers-drag w-100 px-2 pt-1 vertical-align-center center d-flex align-items-start justify-content-between cursor-grab"}>
        <span>Weekly report</span>
        <span><BsX className="cursor-pointer" style={{ transform: "scale(1.5)", height: 18 }} onClick={(): void => setIsGlobalReportPinned(false)} /></span>
      </div>}
      <div className={"planner__global-customers-search-row w-100"}>
        <div className={" d-flex justify-content-center align-items-center"}>
          <Form.Control value={globalReportSearchText} onChange={(e): void => setGlobalReportSearchText(e.target.value)} />
        </div>
      </div>

      {planReport
        ? <div className={"mt-3"}>
          <div>
            Planned: {planReport.global.totalPlannableTime > 0 && <span className={"mt-1 fst-italic " + getTextColor(planReport.global.totalPlannedTime, planReport.global.totalPlannableTime, "planned")}>{getFormattedHMDuration(planReport.global.totalPlannedTime)}/{getFormattedHMDuration(planReport.global.totalPlannableTime)}</span>}

            <span className={"position-relative"}>
              {planReport.global.totalPlannedTime > planReport.global.totalPlannableTime && <OverlayTrigger
                key={"overbudget-trigger#global"}
                placement={"top"}
                overlay={
                  <Tooltip id={"overbudget-trigger#global"} style={{ marginLeft: ".15rem", zIndex: 999999 }}>
                    OVERBUDGET
                  </Tooltip>
                }
                trigger={["hover", "focus"]}
              >
                <span><BsFillExclamationTriangleFill className={"ms-1"} style={{ transform: "translateY(3px)", marginTop: -3 }} fill={"var(--bs-red)"}/></span>
              </OverlayTrigger>}
            </span>
          </div>
          <div>Tracked: <span className={"mt-1 fst-italic " + getTextColor(planReport.global.totalTrackedTime, planReport.global.totalPlannableTime, "tracked")}>{getFormattedHMDuration(planReport.global.totalTrackedTime)}/{getFormattedHMDuration(planReport.global.totalPlannableTime)}</span></div>

          {planReport.global.totalHangingTime > 0 && <div>Hanging: <span
            className={"mt-1 fst-italic"}
          >{getFormattedHMDuration(planReport.global.totalHangingTime)}</span>
          <span><BsFillExclamationTriangleFill className={"ms-1"} style={{ transform: "translateY(3px)", marginTop: -3 }} /></span>
          </div>}
        </div>
        : <Spinner animation={"border"} />
      }
    </div>
    <div style={{ height: `${globalReportHeaderHeight || 101}px` }} />
    <div className={"planner__global-customers-contents p-3 position-relative"} style={{ height: `calc(100% - ${globalReportHeaderHeight}px)` }}>
      <div>
        {planReport ? <div className={"planner__global-customers-list"}>
          {
            (filteredGlobalProjects ?? planReport.global.customers).map((customer, idx, arr) => {
              return <div>
                <PlannerCustomerView customer={customer} />
                {idx !== arr.length - 1 && <hr />}
              </div>
            })
          }
        </div> : <Spinner animation={"border"}/>}
      </div>
    </div>
  </>


  return <PageTemplate issueTag="newplanner">
    <Style>{`
      html {
        overscroll-behavior-x: none;
      }
      body {
        overscroll-behavior-x: none;
      }
    `}</Style>

    <Modal
      show={userModalOpen}
      scrollable
      onHide={(): void => resetUserModal()}
    >
      <Modal.Header closeButton>
        <Modal.Title>User filtering</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex gap-2 mb-3">
          <span className="flex-grow-1">
            <SuggestionsDropdown
              textboxValue={userModalTextInput}
              shouldShowSuggestions={userModalShowSuggestions}
              itemList={baseUserList.filter(user => (
                userModalData[user.userId] === undefined
                && user.active
                && (!minosseUsers[user.userId]?.hiddenFromPlanner)
              ))}
              onItemClick={(u: StaffUser): void => {
                setUserModalShowSuggestions(false)
                setUserModalTextInput("")
                setUserModalData(umd => ({ ...umd, [u.userId]: true }))
              }}
              itemTextFunction={(u): string => u.username}
              itemMatchKey={"username"}
            >
              <FormControl
                value={userModalTextInput}
                placeholder={"All users"}
                onChange={(e): void => setUserModalTextInput(e.target.value)}
                onFocus={(): void => setUserModalShowSuggestions(true)}
                onBlur={(): void => setUserModalShowSuggestions(false)}
              />
            </SuggestionsDropdown>
          </span>
          {Object.keys(userModalData).length !== 0 && <Button
            variant={"outline-dark"}
            onClick={(): void => setUserModalData({})}
          >
            <b>Clear all</b>
          </Button>}
        </div>
        {(Object.keys(userModalData).length > 0) ? <div className="user-filter__users">
          {
            Object.keys(userModalData).map(userId => {
              return <div>
                {userMapping[userId].username}
                <BiX
                  onClick={(): void => {
                    setUserModalData(umd => {
                      delete umd[userId]
                      return { ...umd }
                    })
                  }}
                />
              </div>
            })
          }
        </div> : <div className="fst-italic mb-3 text-muted">No user selected...</div>}
        <Button className="w-100" onClick={(): void => {
          let path = "/planner"
          if (Object.keys(userModalData).length > 0) {
            path += "?users=" + encodeURIComponent(Object.keys(userModalData).join("#"))
          }
          navigate(path)
          navigate(0)
        }}>Apply selection</Button>
      </Modal.Body>
    </Modal>

    <div className={"new-planner__container-container"}>

      {isGlobalReportPinned ? <Draggable positionOffset={{ x: document.body.clientWidth / 2 - 330 / 2, y: document.body.clientHeight / 2 - 430 / 2 - 100 }} handle=".planner__global-customers-drag" onStart={(e): void => pauseEvent(e)} onStop={(e): void => pauseEvent(e)}>
        <div className={classNames("border rounded planner__global-customers", {
          [isGlobalReportPinned ? "position-fixed" : "position-relative"]: true,
          "planner__global-customers--pinned": isGlobalReportPinned
        })}>
          <ResizableBox
            className={"position-fixed bg-light shadow-lg"}
            minConstraints={[200, 200]}
            width={330}
            height={430}
            onResizeStart={pauseEvent}
            onResizeStop={pauseEvent}
          >
            <span style={{ fontSize: 12 }}>
              {globalStats}
            </span>
          </ResizableBox>
        </div>
      </Draggable> : <></>}

      {shouldHidePlanner && <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 9999 }}>loading...</div>}

      {/* HEADER */}
      <div className="h-100 position-relative overflow-hidden select-none" ref={containerRef}>
        <div className="position-absolute bg-white border-end border-bottom border-top" style={{
          zIndex: 5,
          width: 300,
          height: 52 + 42,
          display: shouldHidePlanner ? "none" : undefined
        }}>
          <div className="position-relative w-100 h-100 p-3 d-flex flex-column justify-content-between new-planner__dblclick-nope">
            <div className="d-flex mb-2">
              <FormControl
                type={"week"}
                className={"me-2"}
                value={weekInput}
                onFocus={(e): void => {
                  if ("showPicker" in e.target) {
                    // @ts-ignore
                    (e.target as HTMLInputElement).showPicker()
                  }
                }}
                onChange={(e): void => {
                  let parsedDate = parseWeek(e.target.value)
                  const minDate = new Date(2022, 5, 1).getTime()
                  const maxDate = new Date(2030, 1, 1).getTime()
                  parsedDate = new Date(gsap.utils.clamp(minDate, maxDate, parsedDate.getTime()))

                  if (parsedDate) {
                    animator.changeActiveWeek(parsedDate)
                  }
                  e.target.blur()
                }}
                onKeyDown={(e): void => {
                  e.preventDefault()
                }}
              />
              <Button variant="outline-dark" onClick={(): void => {
                animator.changeActiveWeek()
              }}>
                Today
              </Button>
            </div>
            <div className="new-planner__user-choice-input">
              <UserSimpleDropdown
                userId={null}
                customUserMapping={enabledUserMapping}
                updateUserId={(userId): void => animator.scrollToUser(userId)}
                placeholder="Search for user..."
              />
              <ButtonGroup>
                <Button variant="outline-dark" className={"new-planner__filter-button"} onClick={(): void => openUserModal()}>
                  Filter
                  {Object.keys(searchParamsUsers || {}).length > 0 && <span
                    className={"rounded-circle p-1 ms-2"}
                  >
                    {Object.keys(searchParamsUsers || {}).length}
                  </span>}
                </Button>
                {(Object.keys(searchParamsUsers || {}).length > 0) && <Button
                  variant="outline-dark"
                  onClick={(): void => {
                    navigate("/planner")
                    navigate(0)
                  }}
                ><BsTrashFill className={"iconfix iconfix--translate1"}/></Button>}
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div style={{
          position: "fixed",
          right: 0,
          bottom: 31,
          width: 36,
          height: 36,
          zIndex: 5,
          backgroundColor: "white",
          borderStartStartRadius: 5
        }} className={"cursor-pointer border-start border-top d-flex justify-content-center align-items-center shadow"} onClick={(): void => {
          setIsGlobalReportPinned(prev => !prev)
        }}>
          <OverlayTrigger
            key={"globalreportbutton-trigger"}
            placement={"left"}
            overlay={
              <Tooltip id={"globalreportbutton-trigger"} style={{ marginLeft: ".15rem", zIndex: 999999 }}>
                Click to open weekly report window
              </Tooltip>
            }
            trigger={["hover", "focus"]}
          >
            <BsLayoutTextWindow className={""} />
          </OverlayTrigger>
        </div>

        <div className="bg-white d-flex position-relative select-none" ref={headerRef} style={{ left: left * dayWidth, zIndex: 4 }}>
          {months.map(({ index, year }) => {
            return (
              <MonthHeader
                key={`${index}-${year}`}
                month={index}
                year={year}
                colWidth={dayWidth}
                animator={animator}
                holidays={holidayMap}
                activeWeek={activeWeek}
              />
            )
          })}
        </div>

        <div className="d-flex position-relative min-h-100">
          <div style={{ minWidth: 300, width: 300, zIndex: 3, transform: "translateY(-1px)" }} className="bg-white overflow-scroll min-h-100 h-100 shadow" ref={sidebarRef} onScroll={(e): void => e.stopPropagation()}>
            {userList.map(u => {
              const plannableTime = (planReport?.users[u.userId]?.totalPlannableTime || 0)
              const plannedTime = (planReport?.users[u.userId]?.totalPlannedTime || 0)
              const trackedTime = (planReport?.users[u.userId]?.totalTrackedTime || 0)

              return <div className="p-3 d-flex flex-column" style={{ height: rowHeight * 8, borderBottom: "1px solid black", borderRight: "1px solid #dee2e6" }} onScroll={(e): void => e.stopPropagation()}>
                <div className="fw-bold">{u.username}</div>

                {!planReport && <div className="text-muted small fst-italic mt-1">Loading...</div>}

                {planReport && <span style={{ fontSize: 13.8 }}>
                  <div>
                    Planned: <span className={"mt-1 fst-italic " + getTextColor(plannedTime, plannableTime, "planned")}>{getFormattedHMDuration(plannedTime)}/{getFormattedHMDuration(plannableTime)}</span>

                    <span className={"position-relative"}>
                      {plannedTime > plannableTime && <OverlayTrigger
                        key={`overbudget-trigger#${u.userId}`}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`overbudget-trigger#${u.userId}`} style={{ marginLeft: ".15rem", zIndex: 999999 }}>
                            OVERBUDGET
                          </Tooltip>
                        }
                        trigger={["hover", "focus"]}
                      >
                        <span><BsFillExclamationTriangleFill className={"ms-1"} style={{ transform: "translateY(3px)", marginTop: -3 }} fill={"var(--bs-red)"}/></span>
                      </OverlayTrigger>}
                    </span>
                  </div>

                  <div>Tracked: <span className={"fst-italic " + getTextColor(trackedTime, plannableTime, "tracked")}>{getFormattedHMDuration(trackedTime)}/{getFormattedHMDuration(plannableTime)}</span></div>

                  <div className={"pb-2 shadow-sm"}>
                    {(planReport?.users?.[u.userId]?.totalHangingTime ?? 0) > 0 && <div>Hanging: <span
                      className={"mt-1 fst-italic"}
                    >{getFormattedHMDuration(planReport?.users[u.userId]?.totalHangingTime ?? 0)}</span>
                    <span><BsFillExclamationTriangleFill className={"ms-1"} style={{ transform: "translateY(3px)", marginTop: -3 }} /></span>
                    </div>}
                  </div>
                </span>}

                {planReport && <div style={{ overflowY: "scroll", fontSize: 14 }} onScroll={(e): void => e.stopPropagation()}>
                  <div className={"planner__user-customer-view pt-3"} onScroll={(e): void => e.stopPropagation()}>
                    <div className={"h-100"}>
                      {
                        planReport ? planReport.users[u.userId]?.customers.map((customer, idx, arr) => {
                          return <div>
                            <PlannerCustomerView customer={customer} />
                            {idx !== arr.length - 1 && <hr/>}
                          </div>
                        }) : <Spinner animation={"border"}/>
                      }
                    </div>
                  </div>
                </div>}
              </div>
            })}
          </div>

          <div className="position-absolute top-0 start-0 min-h-100" style={{ height: (userList.length || 0) * rowHeight }} ref={wrapperRef}>
            <div
              className={"p-3 shadow new-planner__context-menu"}
              style={planEditorStyle}
              onMouseDown={(e): void => e.stopPropagation()}
            >
              <CalendarItemEditor
                data={planEditorData.planEntry}
                show={planEditorData.open}
                clipboard={clipboard}
                onSubmit={async(newData: Pick<CalendarEntry, "customerId" | "projectId" | "taskId">): Promise<void> => {
                  if (planEditorData.planEntry) {
                    await animator.updateEntry(planEditorData.planEntry.uuid, { ...newData })
                  } else {
                    void animator.createNewEntry({ ...newData, xyType: "px", x: planEditorData.x!, y: planEditorData.y! })
                  }
                  animator.closeEditor(true)
                }}
                onClipboardAction={async(action: "copy" | "cut" | "paste"): Promise<void> => {
                  if (action !== "paste") {
                    if (!planEditorData.planEntry) {
                      return
                    }
                    setClipboard({
                      type: action,
                      entry: planEditorData.planEntry
                    })
                  } else {
                    if (clipboard) {
                      void animator.pasteEntry(clipboard, "px", planEditorData.x!, planEditorData.y!)
                    }
                  }

                  animator.closeEditor(true)
                }}
                onClose={(): void => {
                  animator.closeEditor()
                }}
                onDelete={async(): Promise<void> => {
                  if (planEditorData.planEntry) {
                    void animator.deleteEntry(planEditorData.planEntry.uuid)
                    animator.closeEditor()
                  }
                }}
                onPasteHoverChange={(hover: boolean): void => {
                  animator.setGhostStyle(planEditorData.element!, hover ? "big" : "small")
                }}
              />
            </div>

            <div
              className="min-h-100 position-relative d-flex"
              style={{ height: headingHeight + rowHeight * (userList.length || 0) }}
            >
              {months.map(({ index, year }) => {
                return (
                  <MonthDays
                    key={`${index}-${year}`}
                    month={index}
                    year={year}
                    rowsCount={(userList.length * 8) || 0}
                    userCount={userList.length || 0}
                    colWidth={dayWidth}
                    rowHeight={rowHeight}
                    activeWeek={activeWeek}
                    left={left}
                    holidays={holidayMap}
                  />
                )
              })}
            </div>

            {/* PLAN ITEMS */}
            <div className="w-100 h-100 position-absolute top-0 start-0" style={{
              opacity: shouldHidePlanner ? 0 : 1
            }}>
              {planItems.map((planItem, i) => (
                <PlanItem
                  key={planItem.uuid}
                  planItem={planItem}
                  animator={animator}
                  isSelected={planItem.uuid === activeSelectionUuid}
                  isCut={clipboard?.type === "cut" && clipboard.entry.uuid === planItem.uuid}
                  activeWeek={activeWeek}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageTemplate>
}

type CalendarItemEditorProps = {
  show: boolean
  data?: CalendarEntry
  clipboard: ClipboardData | null
  onSubmit: (newItem: Pick<CalendarEntry, "customerId" | "projectId" | "taskId">) => void | Promise<void>
  onClipboardAction: (action: "copy" | "cut" | "paste") => void | Promise<void>
  onDelete?: () => void | Promise<void>
  onClose: () => void
  onPasteHoverChange?: (hover: boolean) => void
}
const CalendarItemEditor: React.FC<CalendarItemEditorProps> = ({
  show, data, clipboard, onSubmit, onClipboardAction, onDelete, onClose, onPasteHoverChange
}) => {
  const { customerMapping, activeProjectList, projectMapping } = useContext(MinosseContext)
  const [text, setText] = useState("")
  const [lastValidText, setLastValidText] = useState("")
  const [projectId, setProjectId] = useState("")
  const [customerId, setCustomerId] = useState("")
  const [taskId, setTaskId] = useState<string | null>(null)
  const searchboxRef = useRef<HTMLInputElement>(null)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!data) {
      if (show) {
        searchboxRef.current?.focus()
        setShowSuggestions(false)
      } else {
        searchboxRef.current?.blur()
        setText("")
        setLastValidText("")
        setProjectId("")
        setCustomerId("")
        setTaskId("")
        setShowSuggestions(false)
      }
    } else {
      setText(getPickerText(data.projectId, data.customerId, data.taskId))
    }
  }, [show])

  const getPickerText = (projectId: string, customerId: string, taskId?: string | null): string => {
    if (!customerId) {
      return ""
    }
    if (taskId) {
      return `${customerMapping[customerId]?.customerName} • ${projectMapping[projectId]?.projectName}: ${projectMapping[projectId]?.tasks.find(item => item.taskId === taskId)?.taskName}`
    } else {
      return `${customerMapping[customerId]?.customerName} •  ${projectMapping[projectId]?.projectName}`
    }
  }

  useEffect(() => {
    if (data) {
      setProjectId(data.projectId)
      setCustomerId(data.customerId)
      setTaskId(data.taskId)
      const text = getPickerText(data.projectId, data.customerId, data.taskId)
      setText(text)
      setLastValidText(text)
    } else {
      setProjectId("")
      setCustomerId("")
      setTaskId(null)
      setText("")
      setLastValidText("")
      setShowSuggestions(false)
    }
  }, [data])

  const filteredProjects = useMemo(() => groupProjects(filterProjects(activeProjectList, customerMapping, text)), [activeProjectList, text])

  return <span>
    <p className={"fw-bold"}>Project/Task</p>
    <Overlay
      show={showSuggestions && !!Object.keys(filteredProjects).length}
      placement={"bottom-start"}
      rootClose
      rootCloseDisabled={showSuggestions}
      rootCloseEvent={"mousedown"}
      onHide={(): void => {
        setShowSuggestions(false)
        onClose()
      }}
      target={searchboxRef}
    >
      <Popover className={"project-searchbox__wrapper"}>
        <ProjectSearchbox
          projects={filteredProjects}
          onItemClick={(customerId, projectId, taskId): void => {
            setShowSuggestions(false)
            void onSubmit({ customerId: customerId!, projectId: projectId!, taskId })
            // const text = getPickerText(projectId!, customerId!, taskId || undefined)
            // setText(text)
            // setLastValidText(text)
            // setProjectId(projectId!)
            // setCustomerId(customerId!)
            // setTaskId(taskId)
            // setShowSuggestions(false)
          }}
        />
      </Popover>
    </Overlay>
    <Form.Control
      className={"w-100 flex-grow-1"}
      type={"text"}
      placeholder={"Project name"}
      defaultValue={""}
      ref={searchboxRef}
      onFocus={(): void => {
        setShowSuggestions(true)
        setText("")
      }}
      onChange={(event): void => {
        setText(event.target.value)
        setShowSuggestions(true)
      }}
      onBlur={(): void => {
        setShowSuggestions(false)
        setText(lastValidText)
      }}
      value={text}
    />
    {!data && clipboard !== null && <div>
      <div className="strike mt-1 mb-2"><span>or</span></div>
      <Button
        className={"w-100"}
        variant={"outline-dark"}
        onClick={(): void => void onClipboardAction("paste")}
        onMouseEnter={(): void => void onPasteHoverChange?.(true)}
        onMouseLeave={(): void => void onPasteHoverChange?.(false)}
      >
        <span className="d-flex justify-content-center align-items-center gap-1">
          <BsPaintBucket />Paste (Ctrl+V)
        </span>
      </Button>
    </div>}
    {data && <div>
      <div className="strike mb-2 mt-1"><span>or</span></div>
      <div className="row">
        <div className="col-6 pe-2">
          <Button
            className={"w-100"}
            variant={"outline-dark"}
            onClick={(): void => void onClipboardAction("copy")}
          >
            <span className="d-flex justify-content-center align-items-center gap-1">
              <BsClipboard />Copy (Ctrl+C)
            </span>
          </Button>
        </div>
        <div className="col-6 ps-2">
          <Button
            className={"w-100"}
            variant={"outline-dark"}
            onClick={(): void => void onClipboardAction("cut")}
          >
            <span className="d-flex justify-content-center align-items-center gap-1">
              <BsScissors />Cut (Ctrl+X)
            </span>
          </Button>
        </div>
      </div>

      <Button
        className={"mt-3 w-100"}
        variant={"danger"}
        onClick={async(): Promise<void> => {
          setIsLoading(true)
          await onDelete?.()
          setIsLoading(false)
        }}
      >
        <span className="d-flex justify-content-center align-items-center gap-1">
          <BsTrashFill />Delete entry (Backspace)
        </span>
      </Button>
    </div>}
  </span>
}

export default NewPlanner
