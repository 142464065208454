import { FunctionComponent, useContext, useMemo, useState, useEffect, useRef } from "react"
import { Button, Card, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify"
import { Customer, MinosseContext } from "../contexts/MinosseContext"
import { handleError } from "../helpers/utils"
import CustomerForm from "./CustomerForm"
import { BsBoxArrowUp, BsFillExclamationTriangleFill, BsPlusLg } from "react-icons/bs"
import { CustomersService as PrincipalsCustomersService } from "../sdk/minosse-principals-api"
import AwsAccountForm from "../components/AwsAccountForm"
import { AwsAccount } from "@polarity-dev/minosse-api-sdk"
import { useSearchParams } from "react-router-dom"
import Fuse from "fuse.js"

const CustomerList: FunctionComponent = () => {
  const { customerList, customerMapping, actions: { setCustomerList }, dataReady } = useContext(MinosseContext)

  const [activatingCustomerId, setActivatingCustomerId] = useState<string | null>(null)
  const [editedCustomerId, setEditedCustomerId] = useState<string | undefined | null>(null)

  const [editedAccount, setEditedAccount] = useState<AwsAccount | undefined | null>(null)

  const [showInactive, setShowInactive] = useState<boolean>(false)
  const [searchFilter, setSearchFilter] = useState<string>("")
  const filteredCustomers = useMemo<Customer[]>(() => {
    const list = customerList.filter(cl => cl.active || showInactive)

    const fuse = new Fuse(list, {
      keys: ["awsAccounts.accountId", "awsAccounts.accountAlias", "customerName"],
      threshold: 0.3,
      includeScore: true,
      ignoreFieldNorm: true
    })
    return (!searchFilter
      ? list as Customer[]
      : fuse.search(searchFilter).map(item => item.item as Customer)) as Customer[]
  }, [customerList, searchFilter, showInactive])

  /* SEARCH PARAMS */

  const searchParamsLoaded = useRef(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!dataReady || searchParamsLoaded.current) {
      return
    }

    const accountId = searchParams.get("accountId"), customerId = searchParams.get("customerId")
    searchParamsLoaded.current = true

    if (!customerId) {
      return
    }
    const customer = customerMapping[customerId]
    if (!customer) {
      return
    }
    setEditedCustomerId(customerId)

    if (accountId) {
      const account = customer.awsAccounts.find(awsAccount => awsAccount.accountId === accountId)

      if (account) {
        setEditedAccount(account)
      }
    }
  }, [dataReady])

  useEffect(() => {
    if (!searchParamsLoaded.current) {
      return
    }

    const updatedParams = new URLSearchParams(searchParams)
    if (editedCustomerId) {
      updatedParams.set("customerId", editedCustomerId)
    } else {
      updatedParams.delete("customerId")
    }
    if (editedAccount) {
      updatedParams.set("accountId", editedAccount.accountId)
    } else {
      updatedParams.delete("accountId")
    }
    setSearchParams(updatedParams)
  }, [editedCustomerId, editedAccount])

  return <div className={"list"}>
    <div className={"list-toolbar"}>
      <InputGroup>
        <InputGroup.Text>Search</InputGroup.Text>
        <Form.Control
          type={"text"}
          onChange={(event): void => setSearchFilter(event.target.value)}
          value={searchFilter}
          placeholder={"Filter by customer name..."}
        />
      </InputGroup>
      <Form.Check
        type={"checkbox" }
        onChange={(event): void => setShowInactive(event.target.checked)}
        checked={showInactive}
        label={"Show inactive"}
      />
      <Button onClick={(): void => setEditedCustomerId(undefined)}>
        <BsPlusLg className={"iconfix iconfix--plus me-2"} />Add new
      </Button>
    </div>
    {
      editedCustomerId !== null && editedAccount === null && <CustomerForm
        customer={editedCustomerId ? customerMapping[editedCustomerId] : undefined}
        show={editedCustomerId !== null}
        close={(): void => setEditedCustomerId(null)}
      />
    }
    {
      editedAccount !== null && !!editedCustomerId && <AwsAccountForm
        awsAccount={editedAccount}
        show={editedAccount !== null}
        customerId={editedCustomerId}
        close={(): void => {
          setEditedCustomerId(null)
          setEditedAccount(null)
        }}
      />
    }
    {
      filteredCustomers.map(({ customerId, customerName, active, awsAccounts, iamAlerts }) => {
        return <Card key={customerId} className={"project-list__project"} onClick={(): void => setEditedCustomerId(customerId)}>
          <div>
            <strong>{customerName}</strong>
            <div className={"project__tasks mt-3"}>{awsAccounts?.length} AWS accounts</div>
            <div className={"mt-1 gap-2"}>
              {
                awsAccounts?.map(account => (
                  <span
                    key={account.accountId}
                    className={"current-entry__selected-project current-entry__selected-project--hoverable d-inline-block mt-2 me-2"}
                    onClick={(event): void => {
                      event.stopPropagation()
                      setEditedCustomerId(customerId)
                      setEditedAccount(account)
                    }}
                  >
                    <div className={"aws-account-dot"} style={{ backgroundColor: account.accountColor }}/>
                    {(account.accountAlias || account.notes) && `${account.accountAlias ?? account.notes} – `} {account.accountId}
                    {
                      iamAlerts.some(({ accountId }) => account.accountId === accountId) && <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement={"bottom"}
                        overlay={
                          <Tooltip>
                            {iamAlerts.filter(({ accountId }) => account.accountId === accountId).length} alert(s)
                          </Tooltip>
                        }
                      >
                        <span className={"ms-2 align-sub d-inline-flex"}>
                          <BsFillExclamationTriangleFill fill={"black"}/>
                        </span>
                      </OverlayTrigger>
                    }
                  </span>
                ))
              }
              <span
                className={"current-entry__selected-project current-entry__selected-project--hoverable d-inline-block mt-2"}
                onClick={(event): void => {
                  event.stopPropagation()
                  setEditedCustomerId(customerId)
                  setEditedAccount(undefined)
                }}
              >
                <BsPlusLg className={"iconfix iconfix--plus me-2"}/>Add new
              </span>
            </div>
          </div>
          {
            !active &&
              <Button
                variant={"outline-dark"}
                className={"mw-max-content"}
                disabled={activatingCustomerId === customerId}
                onClick={ async(event): Promise<void> => {
                  event.stopPropagation()

                  setActivatingCustomerId(customerId)
                  const loader = toast.loading("Unarchiving customer...")
                  try {
                    await PrincipalsCustomersService.updateCustomer({
                      handler: { customerId },
                      updates: { active: true }
                    })

                    toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Customer unarchived successfully" })

                    setCustomerList(customerList => customerList.map(updatedCustomer => {
                      if (updatedCustomer.customerId === customerId) {
                        return { ...updatedCustomer, active: true }
                      }
                      return updatedCustomer
                    }))
                  } catch (error) {
                    handleError(error)
                  } finally {
                    setActivatingCustomerId(null)
                  }
                }}
              >
                <BsBoxArrowUp className={"iconfix me-2"} />Unarchive
              </Button>
          }
        </Card>
      })
    }
  </div>
}

export default CustomerList