import { toast } from "react-toastify"
import { ApiError } from "../sdk/minosse-api"
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios"
import {
  startTimeEntry,
  StartTimeEntryRequestSchema,
  stopTimeEntry, StopTimeEntryRequestSchema,
  updateTimeEntry, UpdateTimeEntryRequestSchema
} from "@polarity-dev/minosse-api-sdk"
import { handleException } from "./logger"

type ApiEvent<T> = {
  event: "startTimeEntry" | "updateTimeEntry" | "stopTimeEntry"
  payload: T
  onError?: () => void
}

export class ApiSyncQueue {
  // eslint-disable-next-line @typescript-eslint/ban-types
  private queue: ApiEvent<unknown>[] = []
  private isFetching: boolean = false

  constructor() {
    // TODO: add listener on before unload if is fetching
  }

  push = <T>(action: ApiEvent<T>): void => {
    this.queue.push(action)
    void this.processNextAction()
  }

  private processNextAction = async(): Promise<void> => {
    if (!this.isFetching && this.queue.length) {
      const action = this.queue.pop()!

      this.isFetching = true
      try {
        switch (action.event) {
          case "startTimeEntry":
            await handleApiCall(startTimeEntry, action.payload as StartTimeEntryRequestSchema)
            break
          case "updateTimeEntry":
            await handleApiCall(updateTimeEntry, action.payload as UpdateTimeEntryRequestSchema)
            break
          case "stopTimeEntry":
            await handleApiCall(stopTimeEntry, action.payload as StopTimeEntryRequestSchema)
            break
        }
      } catch (error) {
        handleException(error as Error)
        if (action.onError) {
          action.onError()
        } else {
          toast.error((error as ApiError).body?.error.message || (error as Error).message)
        }
      } finally {
        this.isFetching = false
        void this.processNextAction()
      }
    }
  }
}

type ApiFunctionWithPayload<E, R> = (data: E, config?: AxiosRequestConfig) => AxiosPromise<R>
type ApiFunctionWithoutPayload<R> = (config?: AxiosRequestConfig) => AxiosPromise<R>
type ApiFunction<E, R> = ApiFunctionWithPayload<E, R> | ApiFunctionWithoutPayload<R>

export const handleApiCall = async <T extends ApiFunction<E, R>, E, R>(
  func: T,
  params?: E,
  config?: AxiosRequestConfig
// @ts-ignore
): Promise<R["data"]> => {
  const resp: AxiosResponse<R> = params
    ? await (func as ApiFunctionWithPayload<E, R>)(params, config)
    // @ts-ignore
    : await (func as ApiFunctionWithoutPayload<R>)(config)
  if (resp.status !== 200) {
    throw resp
  }

  // @ts-expect-error
  return resp.data as AxiosResponse<R>["data"]
}
