import React, { FunctionComponent, useContext, useState } from "react"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import logo from "../assets/logo.svg"
import AuthContext from "../contexts/AuthContext"
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import MFAForm from "../components/MFAForm"
import HeaderTickets from "./HeaderTickets"
import classNames from "classnames"

type HeaderProps = {
  issueTag?: string
}

const Header: FunctionComponent<HeaderProps> = ({ issueTag }) => {
  const { logout, isLogged, user } = useContext(AuthContext)
  const [isMFAOpen, setIsMFAOpen] = useState<boolean>(false)
  const [isExtensionModalOpen, setIsExtensionModalOpen] = useState<boolean>(false)

  return <header>
    <Modal
      show={isExtensionModalOpen}
      centered
      onHide={(): void => setIsExtensionModalOpen(false)}
      className={"project-editor"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Minosse Chrome extension</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mt-1 mb-2">
          <a target="_blank" href={"https://chrome.google.com/webstore/detail/minosse-chrome-extension/mmiekgikpnhadepbbiioncoaiiiipbpb?hl=it"}>
            <Button>Download extension from Chrome Web Store</Button>
          </a>
        </div>

        <div className="strike mb-1"><span>or</span></div>

        <ol className="mb-0 ps-4">
          <li><a href={"https://minosse-public.s3.eu-west-1.amazonaws.com/minosse.zip"}>Download</a> and unzip the extension</li>
          <li>Go to <code>chrome://extensions</code> page</li>
          <li>Enable the developer mode by clicking the switch in the upper right corner of the tab</li>
          <li>Click the button <code>Load unpacked</code></li>
          <li>Select the previously unzipped folder</li>
        </ol>
      </Modal.Body>
    </Modal>
    <MFAForm open={isMFAOpen} close={(): void => setIsMFAOpen(false)} />
    <Navbar bg = { "light" }>
      <Container className={classNames("max-content-width", {
        [`max-content-width--${issueTag}`]: !!issueTag
      })} fluid>
        <Navbar.Brand as = { Link } to = { "/" }>
          <img src = { logo } className={"w-auto"} />
          Minosse Time Tracker
        </Navbar.Brand>
        <div className = { "header__side" }>
          {isLogged && <HeaderTickets/>}
          <DropdownButton variant={"secondary"} align={"end"} id="dropdown-item-button" title={"Menu"}>
            <Dropdown.ItemText>{
              isLogged
                ? <>Signed in as<br/><b>{user!.email}</b></>
                : <i>Not logged in</i>
            }
            </Dropdown.ItemText>
            {
              !!user && <>
                <Dropdown.Divider/>
                {
                  user.isAdmin && <>
                    <DropdownItem as={Link} to={"/reports"}>
                      Reports
                    </DropdownItem>
                    <DropdownItem as={Link} to={"/statistics"}>
                      Statistics
                    </DropdownItem>
                    <DropdownItem as={Link} to={"/entries"}>
                      Entries
                    </DropdownItem>
                  </>
                }
                <DropdownItem as={Link} to={"/planner"}>
                  Planner
                </DropdownItem>
                <DropdownItem as={Link} to={"/tickets"}>
                  Tickets
                </DropdownItem>
                <DropdownItem as="a" target="_blank" href={`https://${process.env.REACT_APP_ENV !== "production" ? "staging." : ""}certifications.minosse.polarity.dev/login?session=${localStorage.getItem("session")}`}>
                  Certifications
                </DropdownItem>
              </>
            }
            <Dropdown.Divider/>
            {
              isLogged && <>
                <Dropdown.Item as={Link} to={"/change-password"}>Change password</Dropdown.Item>
                <Dropdown.Item onClick={(): void => setIsMFAOpen(true)}>Setup MFA</Dropdown.Item>
              </>
            }
            <Dropdown.Item
              as={"a"}
              href={`https://github.com/polarity-dev/minosse-frontend/issues/new${issueTag ? `?labels=${issueTag}` : ""}`}
              target={"_blank"}
            >
              Report a bug
            </Dropdown.Item>
            <Dropdown.Item onClick={(): void => setIsExtensionModalOpen(true)}>
              Download Chrome extension
            </Dropdown.Item>

            {
              isLogged && <>
                <Dropdown.Divider/>
                <Dropdown.Item as={"button"} className={"text-danger"} onClick={logout}>
                  Log out
                </Dropdown.Item>
              </>
            }
          </DropdownButton>
        </div>
      </Container>
    </Navbar>
  </header>
}

export default Header