import React, { FunctionComponent, PropsWithChildren } from "react"
import Header from "../components/Header"
import BottomBar from "./BottomBar"

type PageTemplateProps = {
  issueTag?: string
}

const PageTemplate: FunctionComponent<PropsWithChildren<PageTemplateProps>> = ({ children, issueTag }) => {
  return <main>
    <Header issueTag={issueTag} />
    { children }
    <BottomBar />
  </main>
}

export default PageTemplate