import { useRef } from "react"
import CalendarAnimator from "../helpers/calendarAnimator"

type UseCalendar = {
  animator: CalendarAnimator
}

const useCalendar = (): UseCalendar => {
  const animator = useRef<CalendarAnimator>(new CalendarAnimator()).current

  return {
    animator
  }
}

export default useCalendar
