import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { handleException, logError, logInfo } from "../helpers/logger"
import { TicketsService } from "../sdk/minosse-ticketing-api"
import { MINUTE } from "../helpers/time"
import AuthContext from "../contexts/AuthContext"
import classNames from "classnames"
import Icon from "./Icon"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const HeaderTickets: React.FunctionComponent = () => {
  const { user } = useContext(AuthContext)

  const [totalAssignedTickets, setTotalAssignedTickets] = useState(
    parseInt(localStorage.getItem("header.totalAssignedTickets") || "0") || 0
  )
  const [totalPendingTickets, setTotalPendingTickets] = useState(
    parseInt(localStorage.getItem("header.totalPendingTickets") || "0") || 0
  )
  const [totalTicketCount, setTotalTicketCount] = useState(
    parseInt(localStorage.getItem("header.totalTicketCount") || "0") || 0
  )
  const [isCountDifferent, setIsCountDifferent] = useState(
    (localStorage.getItem("header.isCountDifferent") === "true") || false
  )

  useEffect(() => {
    localStorage.setItem("header.isCountDifferent", isCountDifferent.toString())
    localStorage.setItem("header.totalAssignedTickets", totalAssignedTickets.toString())
    localStorage.setItem("header.totalPendingTickets", totalPendingTickets.toString())
    localStorage.setItem("header.totalTicketCount", totalTicketCount.toString())
  }, [isCountDifferent, totalAssignedTickets, totalPendingTickets, totalTicketCount])

  const navigate = useNavigate()
  const location = useLocation()

  const checkAssignedTickets = useCallback(async() => {
    if (!user) {
      return
    }

    logInfo("[header/tickets] Checking for new tickets...")
    try {
      const { totalCount, pendingCount, assignedCount } = await TicketsService.getTicketCount({ handler: { userId: user.userId } })
      if (assignedCount > totalAssignedTickets) {
        logInfo("[header/tickets] New tickets! Showing bell...")
        setIsCountDifferent(true)
      } else {
        logInfo("[header/tickets] No new tickets")
      }

      setTotalAssignedTickets(assignedCount)
      setTotalPendingTickets(pendingCount)
      setTotalTicketCount(totalCount)
    } catch (ex) {
      handleException(ex as Error)
      logError(ex)
    }
  }, [totalAssignedTickets, user?.userId])

  useEffect(() => {
    void checkAssignedTickets()
    const pollingInterval = setInterval(checkAssignedTickets, MINUTE)

    return (): void => clearInterval(pollingInterval)
  }, [checkAssignedTickets])

  return <span className={"position-relative"}>
    <span className={"h-100 start-0 end-0 d-flex cursor-pointer"} style={{ width: "min-content" }}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement={"bottom"}
        overlay={<Tooltip>{totalAssignedTickets} assigned out of {totalTicketCount} total tickets</Tooltip>}
      >
        <div onClick={(): void => {
          setIsCountDifferent(false)
          localStorage.setItem("header.isCountDifferent", "false")
          if (!location.pathname.startsWith("/tickets")) {
            navigate("/tickets")
          }
        }} className={"d-flex align-items-center"}>
          <Icon name={"ticket"} className={"d-flex align-items-center"} />
          <span className={classNames("badge rounded-pill ms-1", {
            "bg-danger": isCountDifferent,
            "bg-dark": !isCountDifferent && totalPendingTickets === 0,
            "bg-orange": !isCountDifferent && totalPendingTickets > 0
          })}>{totalAssignedTickets}/{totalTicketCount}</span>
        </div>
      </OverlayTrigger>
    </span>
  </span>
}

export default HeaderTickets
