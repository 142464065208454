import { FunctionComponent, useContext, useEffect, useRef, useState } from "react"
import PageTemplate from "../components/PageTemplate"
import {
  getTimeEntriesInRange,
  GetTimeEntriesInRange200ResponseSchema,
  TimeEntry
} from "@polarity-dev/minosse-api-sdk"
import TimeEntries from "../components/TimeEntries"
import CurrentEntry from "../components/CurrentEntry"
import { Button, Tab, Tabs } from "react-bootstrap"
import AuthContext from "../contexts/AuthContext"
import { Navigate, useSearchParams } from "react-router-dom"
import { MinosseContext } from "../contexts/MinosseContext"
import { toast } from "react-toastify"
import loading from "../assets/loading.gif"
import classNames from "classnames"
import ProjectList from "../components/ProjectList"
import UserList from "../components/UserList"
import CustomerList from "../components/CustomerList"
import { subDays } from "date-fns"
import LeadList from "../components/LeadList"
import { handleApiCall } from "../helpers/api"
import { handleException } from "../helpers/logger"

const RETRY_LIMIT = 8
enum TrackerTab {
  TIME_ENTRIES = "timeEntries",
  CUSTOMERS = "customers",
  LEADS = "leads",
  PROJECTS = "projects",
  USERS = "users"
}

const Tracker: FunctionComponent = () => {
  const { user, initialized } = useContext(AuthContext)
  const { dataReady } = useContext(MinosseContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const [entriesReady, setEntriesReady] = useState<boolean>(false)
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([])
  const [timeEntriesSpan, setTimeEntriesSpan] = useState<{ start: Date, stop?: Date }>({ start: subDays(new Date(), 30) })
  const [hideLoadMore, setHideLoadMore] = useState<boolean>(false)
  const retries = useRef<number>(0)

  useEffect(() => {
    if (user) {
      if (retries.current === RETRY_LIMIT) {
        return setHideLoadMore(true)
      }

      let hasEntries = false
      void handleApiCall(getTimeEntriesInRange, {
        start: timeEntriesSpan.start.getTime(),
        stop: timeEntriesSpan.stop?.getTime(),
        userFilter: {
          userId: user.userId
        }
      })
        .then(data => {
          const timeEntries = (data as GetTimeEntriesInRange200ResponseSchema).data.timeEntries
          if (timeEntries.length) {
            hasEntries = true
            setTimeEntries(entries => entries.concat(timeEntries))
            if (retries.current) {
              retries.current = 0
            }
          } else if (retries.current <= RETRY_LIMIT) {
            setTimeEntriesSpan(span => ({ start: subDays(span.start, 7), stop: span.start }))
            retries.current++
          }
        })
        .catch(error => {
          handleException(error as Error)
          toast.error(error.body?.error.message || error.message)
        })
        .finally(() => {
          if (retries.current === RETRY_LIMIT || hasEntries) {
            setEntriesReady(true)
          }
        })
    }
  }, [user?.userId, timeEntriesSpan])

  const updateEntry = (id: string, updatedEntry: TimeEntry): void => {
    setTimeEntries(timeEntries => {
      return timeEntries
        .filter(timeEntry => id !== timeEntry.id)
        .concat([updatedEntry])
        .sort((a, b) => b.start! - a.start!)
    })
    document.dispatchEvent(new Event("timeEntriesChanged"))
  }

  const pushTimeEntry = (timeEntry: TimeEntry): void => {
    setTimeEntries([...timeEntries, timeEntry])
    document.dispatchEvent(new Event("timeEntriesChanged"))
  }

  const deleteEntry = (id: string): void => {
    setTimeEntries(timeEntries.filter(timeEntry => id !== timeEntry.id))
    document.dispatchEvent(new Event("timeEntriesChanged"))
  }

  if (initialized && !user) {
    return <Navigate to = { "/login" } replace/>
  }

  return <PageTemplate issueTag="tracker">
    {
      <div
        className = { classNames("loading-pane", { visible: !(initialized && dataReady && entriesReady) }) }
        onTransitionEnd = { (event): void => event.currentTarget.remove() }
      >
        <img src = { loading }/>
      </div>
    }
    <div className = { classNames({ "is-admin": user?.isAdmin }) }>
      {
        dataReady && user &&
          <CurrentEntry
            timeEntries = { timeEntries }
            pushTimeEntry = { pushTimeEntry }
          />
      }
      {
        !user?.isAdmin ? <div className={"max-content-width"}>
          {
            dataReady && <TimeEntries
              entries = { timeEntries }
              updateEntry = { updateEntry }
              deleteEntry = { deleteEntry }
            />
          }
        </div> : <div className={"max-content-width"}>
          <Tabs
            variant={"pills"}
            defaultActiveKey={searchParams.get("activeTab") ?? TrackerTab.TIME_ENTRIES}
            onSelect={(eventKey): void => setSearchParams({ activeTab: eventKey ?? "timeEntries" })}>
            <Tab eventKey={TrackerTab.TIME_ENTRIES} title={"Time entries"}>
              {
                dataReady && <>
                  <TimeEntries
                    entries = { timeEntries }
                    updateEntry = { updateEntry }
                    deleteEntry = { deleteEntry }
                  />
                  {
                    !hideLoadMore &&
                      <div className={"pb-3 d-flex justify-content-center"}>
                        <Button variant={"outline-dark"} onClick = { (): void => setTimeEntriesSpan(span => ({ start: subDays(span.start, 7), stop: span.start })) }>
                          Load more...
                        </Button>
                      </div>
                  }
                </>
              }
            </Tab>
            <Tab eventKey={TrackerTab.CUSTOMERS} title={"Customers"}>
              <CustomerList/>
            </Tab>
            <Tab eventKey={TrackerTab.LEADS} title={"Leads"}>
              <LeadList/>
            </Tab>
            <Tab eventKey={TrackerTab.PROJECTS} title={"Projects"}>
              <ProjectList/>
            </Tab>
            <Tab eventKey={TrackerTab.USERS} title={"Users"}>
              <UserList/>
            </Tab>
          </Tabs>
        </div>
      }
    </div>
  </PageTemplate>
}

export default Tracker