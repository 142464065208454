import { FunctionComponent, useContext, useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { toast } from "react-toastify"
import PageTemplate from "../components/PageTemplate"
import { useForm } from "react-hook-form"
import { Navigate, useNavigate } from "react-router-dom"
import AuthContext, { login } from "../contexts/AuthContext"
import { handleError, validPasswordRegex } from "../helpers/utils"
import { StaffUsersService } from "../sdk/minosse-principals-api"

type FormData = { oldPassword: string, newPassword: string, confirmPassword?: string }

const ChangePassword: FunctionComponent = () => {
  const { user, initialized } = useContext(AuthContext)
  const navigate = useNavigate()
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const password = watch("newPassword")

  const onSubmit = async(data: FormData): Promise<void> => {
    if (!user) {
      return
    }

    setIsLoading(true)
    const loader = toast.loading("Changing password...")

    try {
      await StaffUsersService.changeStaffUserPassword({
        handler: { userId: user.userId },
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      })
      await login({ email: user.email, password: data.newPassword })

      toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Password changed successfully" })
      navigate("/")
    } catch (err) {
      handleError(err, loader)
      setIsLoading(false)
    }
  }

  if (initialized && !user) {
    return <Navigate to={"/login"} replace/>
  }

  return <PageTemplate issueTag={"change+password"}>
    <div className="max-content-width">
      <Form onSubmit={handleSubmit(onSubmit)} className={"login"}>
        <Form.Group>
          <Form.Label>Old password</Form.Label>
          <Form.Control
            {...register("oldPassword", { required: true })}
            type={"password"}
            autoComplete={"current-password"}
            placeholder={"Old password"}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>New password</Form.Label>
          <Form.Control
            {...register("newPassword", { pattern: validPasswordRegex, minLength: 8, required: true })}
            autoComplete={"new-password"}
            type={"password"}
            placeholder={"Password"}
            isInvalid={!!errors.newPassword}
          />
          <Form.Control.Feedback type={"invalid"}>
            Invalid password. The minimum length is 8 characters. Please enter at least one uppercase character and one symbol from {"!\"#$%&'()*+,-./:;<=>?@[\\]^_{|}~]"}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm new password</Form.Label>
          <Form.Control
            {...register("confirmPassword", { validate: (value?: string) => value === password, required: true })}
            autoComplete={"new-password"}
            type={"password"}
            placeholder={"Password confirmation"}
            isInvalid={!!errors.confirmPassword}
          />
          <Form.Control.Feedback type={"invalid"}>
            Passwords do not match
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant={"primary"} type={"submit"} disabled={isLoading}>Change password</Button>
        <Button
          className={"ms-3"}
          variant={"outline-dark"}
          type={"button"}
          disabled={isLoading}
          onClick={history.back}
        >
          <span>Go back</span>
        </Button>
      </Form>
    </div>
  </PageTemplate>
}

export default ChangePassword