import React, { useContext, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import PageTemplate from "../components/PageTemplate"
import { MinosseContext } from "../contexts/MinosseContext"
import classNames from "classnames"
import loading from "../assets/loading.gif"
import { Mapping } from "../helpers/utils"
import SuggestionsDropdown from "../components/SuggestionsDropdown"
import { toast } from "react-toastify"
import AuthContext from "../contexts/AuthContext"
import { Navigate } from "react-router-dom"
import Icon from "../components/Icon"
import { ReportItem } from "../types"
import ReportCard from "../components/ReportCard"
import { AxiosError } from "axios"
import NotFoundPage from "./NotFoundPage"
import StatisticsCalendar from "../components/StatisticsCalendar"
import { getFormattedDate } from "../helpers/time"
import Fuse from "fuse.js"
import {
  AxiosListReportsErrorResponse,
  createReport,
  CreateReport200ResponseSchema,
  listReports,
  ListReports200ResponseSchema
} from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"
import { handleException } from "../helpers/logger"

const groupReports = (reports: ReportItem[]): Mapping<ReportItem[]> => {
  return reports.reduce((acc, report) => {
    if (!acc[report.customerId]) {
      acc[report.customerId] = []
    }

    acc[report.customerId].push(report)

    return acc
  }, {} as Mapping<ReportItem[]>)
}

type ReportInputBaseFields = {
  value: string
  lastValidValue: string
  showSuggestions: boolean
}

const Reports: React.FunctionComponent = () => {
  const { user, initialized } = useContext(AuthContext)

  if (initialized && !user) {
    return <Navigate to = { "/login" } replace/>
  }

  if (initialized && !user?.isAdmin) {
    return <NotFoundPage/>
  }

  const { activeProjectList, projectMapping, customerMapping, dataReady, activeCustomerList } = useContext(MinosseContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reportCustomer, setReportCustomer] = useState<ReportInputBaseFields & { customerId: string }>({
    value: "",
    lastValidValue: "",
    customerId: "",
    showSuggestions: false
  })
  const [reportProject, setReportProject] = useState<ReportInputBaseFields & { projectId: string | null }>({
    value: "",
    lastValidValue: "",
    projectId: null,
    showSuggestions: false
  })
  const [reportStartDate, setReportStartDate] = useState<Date | null>(null)
  const [reportEndDate, setReportEndDate] = useState<Date | null>(null)
  const customerProjects = useMemo(() => activeProjectList.filter(({ customerId }) => customerId === reportCustomer.customerId), [activeProjectList, reportCustomer.customerId])

  const [reportList, setReportList] = useState<ReportItem[]>([])
  const [listingReady, setListingReady] = useState<boolean>(false)
  const [searchFilter, setSearchFilter] = useState<string>("")
  const filteredReports = useMemo(() => {
    if (!dataReady) {
      return {}
    }

    if (!searchFilter) {
      return groupReports(reportList)
    }

    const itemMap = reportList.reduce((acc, item) => {
      acc[`${item.customerId}-${item.projectId}`] = item
      return acc
    }, {} as Mapping<ReportItem>)

    const itemList = reportList.map(({ customerId, projectId }) => ({ customerId, projectId, customerName: customerMapping[customerId]?.customerName, projectName: projectMapping[projectId!]?.projectName }))

    const fuse = new Fuse(itemList, {
      keys: ["customerName", "projectName"],
      threshold: 0.3
    })

    const results =  fuse.search(searchFilter).map(item => {
      const { customerId, projectId } = item.item
      return itemMap[`${customerId}-${projectId}`]
    })

    return groupReports(results)
  }, [reportList, searchFilter, projectMapping, customerMapping, dataReady])

  useEffect(() => {
    if (initialized && user) {
      void handleApiCall(listReports)
        .then(data => setReportList((data as ListReports200ResponseSchema).data))
        .catch(error => {
          handleException(error as Error)
          toast.error(error.response?.data.message || error.message)
        })
        .finally(() => setListingReady(true))
    }
  }, [initialized, user?.userId])

  const generateReport = async(): Promise<void> => {
    setIsLoading(true)
    try {
      const data = await toast.promise(handleApiCall(createReport, {
        customerId: reportCustomer.customerId,
        since: reportStartDate!.getTime(),
        until: reportEndDate!.setHours(23, 59, 59, 999),
        ...!!reportProject.projectId && { projectId: reportProject.projectId }
      }), {
        pending: "Generating report...",
        success: "Report generated successfully",
        error: "Failed to generate report"
      })

      const url = (data as CreateReport200ResponseSchema).data.url
      if (url) {
        window.open(url, "_blank")!.focus()
      }

      setReportProject({ value: "", lastValidValue: "", projectId: null, showSuggestions: false })
      setReportCustomer({ value: "", lastValidValue: "", customerId: "", showSuggestions: false })
      setReportStartDate(null)
      setReportEndDate(null)

      try {
        const data = await handleApiCall(listReports)
        setReportList((data as ListReports200ResponseSchema).data)
      } catch (error) {
        handleException(error as Error)
      }
    } catch (error) {
      handleException(error as Error)
      toast.error(((error as AxiosError).response as AxiosListReportsErrorResponse)?.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  return <PageTemplate issueTag="reports">
    <div
      className = { classNames("loading-pane", { visible: !initialized || !dataReady || !listingReady }) }
      onTransitionEnd = { (event): void => event.currentTarget.remove() }
    >
      <img src = { loading }/>
    </div>
    {
      initialized && dataReady && listingReady && <div className = { "reports max-content-width" }>
        <div className = { "reports__nav" }>
          <span className = { "reports__new-report" }>New report</span>
          <SuggestionsDropdown
            textboxValue = { reportCustomer.value }
            shouldShowSuggestions = { reportCustomer.showSuggestions }
            itemList = { activeCustomerList }
            onItemClick = { ({ customerId, customerName }): void => {
              setReportCustomer({ value: customerName, lastValidValue: customerName, customerId, showSuggestions: false })
              setReportProject({ value: "", lastValidValue: "", projectId: "", showSuggestions: false })
            }}
            itemTextFunction = { (item): string => item.customerName }
            itemMatchKey = { "customerName" }
          >
            <div className = { "position-relative" }>
              <Form.Control
                className = { "reports__customer-name-input" }
                type = { "text" }
                placeholder = { "Select customer..." }
                onFocus = { (): void => setReportCustomer({ ...reportCustomer, value: "", showSuggestions: true }) }
                onChange = { ({ target: { value } }): void => setReportCustomer({ ...reportCustomer, value, showSuggestions: true }) }
                onBlur = { (): void => setReportCustomer({ ...reportCustomer, value: reportCustomer.lastValidValue, showSuggestions: false }) }
                value = { reportCustomer.value }
              />
              { reportCustomer.customerId && <Icon name = { "x-lg" } className = { "reports__clear-icon" } onClick = { (): void => setReportCustomer({ ...reportCustomer, value: "", lastValidValue: "", customerId: "", showSuggestions: false }) }/>}
            </div>
          </SuggestionsDropdown>
          <SuggestionsDropdown
            textboxValue = { reportProject.value }
            shouldShowSuggestions = { reportProject.showSuggestions }
            itemList = { customerProjects }
            onItemClick = { ({ projectId, projectName }): void => setReportProject({ value: projectId, lastValidValue: projectName, projectId, showSuggestions: false }) }
            itemTextFunction = { (item): string => item.projectName }
            itemMatchKey = { "projectName" }
          >
            <div className = { "position-relative" }>
              <Form.Control
                className = { "w-100 reports__customer-name-input" }
                type = { "text" }
                placeholder = { customerProjects.length ? "Select project..." : "No projects found" }
                onFocus = { (): void => setReportProject({ ...reportProject, value: "", showSuggestions: true }) }
                onChange = { ({ target: { value } }): void => setReportProject({ ...reportProject, value, showSuggestions: true }) }
                onBlur = { (): void => setReportProject({ ...reportProject, value: reportProject.lastValidValue, showSuggestions: false }) }
                value = { reportProject.value }
                disabled = { !reportCustomer.customerId || !customerProjects.length }
              />
              { reportProject.projectId && <Icon name = { "x-lg" } className = { "reports__clear-icon" } onClick = { (): void => setReportProject({ ...reportProject, value: "", lastValidValue: "", projectId: null, showSuggestions: false }) }/>}
            </div>
          </SuggestionsDropdown>
          <div>
            <StatisticsCalendar
              startDate = { reportStartDate }
              endDate = { reportEndDate }
              onDateChange = { (([start, end]): void => {
                setReportStartDate(start)
                setReportEndDate(end)
              })}
              render={<Form.Control
                className={"text-center cursor-pointer"}
                style={{ caretColor: "transparent" }}
                value={`${getFormattedDate(reportStartDate)} - ${getFormattedDate(reportEndDate)}`}
              />}
            />
          </div>
          <Button
            variant = {"primary"}
            onClick = { generateReport }
            disabled = { !reportCustomer.customerId || !reportStartDate || !reportEndDate || isLoading }
          >
            Generate
          </Button>
        </div>
        <hr/>
        <div className = { "reports__filters" }>
          <span>Filter by:</span>
          <div>
            <Form.Control
              type = { "text" }
              placeholder = { "Insert project or customer name..." }
              onFocus = { (): void => setSearchFilter("") }
              onChange = { (event): void => setSearchFilter(event.target.value) }
              value = { searchFilter }
            />
            { searchFilter && <Icon name = { "x-lg" } className = { "reports__clear-icon" } onClick = { (): void => setSearchFilter("") }/>}
          </div>
        </div>
        <div>
          {
            Object.entries(filteredReports).map(([customerId, reports]) => (
              <div className = { "reports__group" }>
                <strong>{ customerMapping[customerId]?.customerName }</strong>
                {
                  reports.map((report: ReportItem) => (
                    <ReportCard
                      report = { report }
                      onDelete = { (): void => setReportList(reportList => reportList.filter(({ id }) => id !== report.id)) }
                    />
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    }
  </PageTemplate>
}

export default Reports
