import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Card, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify"
import { MinosseContext } from "../contexts/MinosseContext"
import { groupProjects } from "../helpers/projects"
import { stringToColor } from "../helpers/utils"
import {
  createProject,
  CreateProject200ResponseSchema,
  deleteProject,
  Project,
  Task,
  updateProject,
  UpdateProject200ResponseSchema
} from "@polarity-dev/minosse-api-sdk"
import ProjectForm from "./ProjectForm"
import { BsBoxArrowUp, BsFillExclamationTriangleFill, BsPlusLg } from "react-icons/bs"
import TaskModal from "./TaskModal"
import { v4 as uuidv4 } from "uuid"
import { handleException, logError } from "../helpers/logger"
import { useSearchParams } from "react-router-dom"
import { filterProjects } from "../helpers/transformers"
import { handleApiCall } from "../helpers/api"

const ProjectList: FunctionComponent = () => {
  const { projectList, projectMapping, customerMapping, actions: { setProjectList }, dataReady } = useContext(MinosseContext)

  const [showInactive, setShowInactive] = useState<boolean>(false)
  const [taskEditorData, setTaskEditorData] = useState<{ projectId: string, task: Task } | null>(null)

  const [activatingProjectId, setActivatingProjectId] = useState<string | null>(null)
  const [showProjectForm, setShowProjectForm] = useState<boolean>(false)
  const [editedProjectId, setEditedProjectId] = useState<string | null>(null)

  useEffect(() => {
    if (typeof editedProjectId === "string") {
      setShowProjectForm(true)
    }
  }, [editedProjectId])

  useEffect(() => {
    if (!showProjectForm) {
      setEditedProjectId(null)
    }
  }, [showProjectForm])

  const [searchFilter, setSearchFilter] = useState<string>("")

  const groupedProjects = useMemo(() => Object.entries(groupProjects(filterProjects(projectList, customerMapping, searchFilter, { showInactive }))), [searchFilter, projectList, customerMapping, showInactive])

  /* SEARCH PARAMS */

  const searchParamsLoaded = useRef(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!dataReady || searchParamsLoaded.current) {
      return
    }

    const projectId = searchParams.get("projectId"), taskId = searchParams.get("taskId")
    searchParamsLoaded.current = true

    if (!projectId) {
      return
    }
    const project = projectMapping[projectId]
    if (!project) {
      return
    }

    if (taskId) {
      const task = project.tasks.find(task => task.taskId === taskId)

      if (task) {
        setTaskEditorData({ projectId, task })
      }
    } else {
      setEditedProjectId(projectId)
    }
  }, [dataReady])

  useEffect(() => {
    if (!searchParamsLoaded.current) {
      return
    }

    const updatedParams = new URLSearchParams(searchParams)
    if (editedProjectId) {
      updatedParams.set("projectId", editedProjectId)
    } else {
      updatedParams.delete("projectId")
    }
    if (taskEditorData) {
      updatedParams.set("projectId", taskEditorData.projectId)
      updatedParams.set("taskId", taskEditorData.task.taskId!)
    } else {
      updatedParams.delete("taskId")
    }
    setSearchParams(updatedParams)
  }, [editedProjectId, taskEditorData])

  return <div className = { "list" }>
    <div className = { "list-toolbar" }>
      <InputGroup>
        <InputGroup.Text>Search</InputGroup.Text>
        <Form.Control
          type = { "text" }
          onChange = { (event): void => setSearchFilter(event.target.value) }
          value = { searchFilter }
          placeholder = { "Filter by customer or project name..." }
        />
      </InputGroup>
      <Form.Check
        type = { "checkbox" }
        onChange = { (event): void => setShowInactive(event.target.checked) }
        checked = { showInactive }
        label = { "Show inactive" }
      />
      <Button onClick = { (): void => setShowProjectForm(true) }>
        <BsPlusLg className={"iconfix iconfix--plus me-2"} />Add new
      </Button>
    </div>
    {taskEditorData && <TaskModal
      show={!!taskEditorData}
      setTask={async(task): Promise<boolean> => {
        const isNewTask = task.taskId?.startsWith("NEWTASK#") ?? true
        task.taskName = task.taskName.trim()
        const uploadData = { ...task }
        if (isNewTask) {
          delete uploadData.taskId
        }
        try {
          const { tasks } = projectMapping[taskEditorData.projectId]
          const data = await toast.promise(handleApiCall(updateProject, {
            handler: { projectId: taskEditorData.projectId },
            updates: {
              tasks: isNewTask
                ? tasks.concat({ ...uploadData, active: true })
                : tasks.map(t => t.taskId === uploadData.taskId ? uploadData : t)
            }
          }), {
            pending: isNewTask ? "Creating task..." : "Updating task...",
            success: isNewTask ? "Task created" : "Task updated",
            error: isNewTask ? "Error creating task" : "Error updating task"
          })
          setProjectList((projectList: Project[]): Project[] => projectList.map(project => project.projectId === taskEditorData.projectId ? (data as UpdateProject200ResponseSchema).data : project))
          setTaskEditorData(null)
          return true
        } catch (ex) {
          handleException(ex as Error)
          return false
        }
      }}
      deleteTask={async(): Promise<boolean> => {
        try {
          const proj = projectMapping[taskEditorData.projectId]
          const data = await toast.promise(handleApiCall(updateProject, {
            handler: { projectId: taskEditorData.projectId },
            updates: { tasks: proj.tasks.filter(t => t.taskId !== taskEditorData?.task?.taskId) }
          }), {
            success: "Task deleted",
            error: "Error deleting task",
            pending: "Deleting task..."
          })
          setProjectList((projectList: Project[]): Project[] => projectList.map(project => project.projectId === taskEditorData.projectId ? (data as UpdateProject200ResponseSchema).data : project))
          setTaskEditorData(null)
          return true
        } catch (ex) {
          handleException(ex as Error)
          return false
        }
      }}
      task={taskEditorData.task}
      close={(): void => setTaskEditorData(null)}
    />}
    {
      showProjectForm && <ProjectForm
        project = { editedProjectId ? projectMapping[editedProjectId] : undefined }
        show = { showProjectForm }
        close = { (): void => setShowProjectForm(false) }
        title = { editedProjectId ? `Edit ${customerMapping[projectMapping[editedProjectId]?.customerId]?.customerName} • ${projectMapping[editedProjectId]?.projectName}` : "Add new project" }
        onSubmit = { async(data): Promise<boolean> => {
          const isUpdate = typeof editedProjectId === "string"

          try {
            if (isUpdate) {
              const { data: result } = await toast.promise(handleApiCall(updateProject, {
                handler: { projectId: editedProjectId },
                updates: data
              }), {
                pending: "Updating project...",
                success: "Project updated",
                error: "Failed to update project"
              }) as UpdateProject200ResponseSchema
              setProjectList(projectList => projectList.map(project =>
                project.projectId === editedProjectId ? result : project
              ))
              setShowProjectForm(false)
            } else {
              if (!data.color) {
                data.color = stringToColor(data.projectName)
              }

              const { data: { projectId } } = await toast.promise(handleApiCall(createProject, data), {
                pending: "Creating project...",
                success: "Project created",
                error: "Failed to create project"
              }) as CreateProject200ResponseSchema
              setProjectList(projectList => [...projectList, { ...data, projectId }])
              setShowProjectForm(false)
            }
            return true
          } catch (error) {
            handleException(error as Error)
            logError(error)
            return false
          }
        }}
        onDelete = { async(): Promise<void> => {
          try {
            await toast.promise(handleApiCall(deleteProject, {
              handler: { projectId: editedProjectId! }
            }), {
              pending: "Deleting project...",
              success: "Project successfully deleted",
              error: "Failed to delete project"
            })
            setShowProjectForm(false)
            setProjectList(projectList => projectList.filter(({ projectId }) => projectId !== editedProjectId))
          } catch (error) {
            handleException(error as Error)
            logError(error)
          }
        }}
        onArchival= { async(): Promise<void> => {
          try {
            await toast.promise(handleApiCall(updateProject, {
              handler: { projectId: editedProjectId! },
              updates: { active: false }
            }), {
              pending: "Archiving project...",
              success: "Project successfully archived",
              error: "Failed to archive project"
            })

            setProjectList(projectList => {
              projectList[projectList.findIndex(project => project.projectId === editedProjectId)].active = false

              return [...projectList]
            })
            setShowProjectForm(false)
          } catch (error) {
            handleException(error as Error)
            logError(error)
          }
        }}
      />
    }
    {
      groupedProjects.map(([customerId, projects]) => (
        <Card key = { customerId }>
          <strong>
            {customerMapping[customerId]?.customerName}
            {!customerMapping[customerId]?.active && <span className={"project__disabled-task border rounded ms-2 small text-danger border-danger text-uppercase fw-normal"}>inactive</span>}
          </strong>
          {
            projects.map(({ projectId, projectName, color, active, tasks }) => {
              const { activeTasks, inactiveTasks } = tasks.reduce((acc, task) => {
                if (task.active) {
                  acc.activeTasks.push(task)
                } else {
                  acc.inactiveTasks.push(task)
                }
                return acc
              }, { activeTasks: [] as Task[], inactiveTasks: [] as Task[] })

              return <Card key={projectId} className={"project-list__project"} onClick={(): void => setEditedProjectId(projectId)}>
                <div>
                  {color && <span className={"project-colored-dot"} style={{ backgroundColor: color }}/>}
                  <span style={{ color }}>{projectName}</span>
                  {activeTasks.length === 0 && <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement={"bottom"}
                    overlay={
                      <Tooltip>
                        This project has no active tasks
                      </Tooltip>
                    }
                  >
                    <span className={"ms-2 align-sub"}>
                      <BsFillExclamationTriangleFill fill={color ?? "var(--bs-danger)"}/>
                    </span>
                  </OverlayTrigger>}

                  <div className={"project__tasks"}>{tasks.filter(({ active }) => active).length} tasks</div>
                  <div className={"mt-1"}>
                    {activeTasks.sort((t1, t2) => t1.taskName.localeCompare(t2.taskName)).map((task) => {
                      return <span key={task.taskId} className={"me-2"}>
                        <span
                          className={"current-entry__selected-project current-entry__selected-project--hoverable d-inline-block mt-2"}
                          onClick={(event): boolean => {
                            event.preventDefault()
                            event.stopPropagation()
                            setTaskEditorData({ task, projectId })
                            return false
                          }}
                        >
                          {task.taskName}
                        </span>
                      </span>
                    })}
                    {showInactive && inactiveTasks.sort((t1, t2) => t1.taskName.localeCompare(t2.taskName)).map((task) => {
                      return <span className={"me-2"} key={task.taskId}>
                        <span
                          className={"current-entry__selected-project current-entry__selected-project--hoverable d-inline-block mt-2"}
                          onClick={(event): boolean => {
                            event.preventDefault()
                            event.stopPropagation()
                            setTaskEditorData({ task, projectId })
                            return false
                          }}
                        >
                          {task.taskName}<span className={"project__disabled-task border rounded ms-2 small text-danger border-danger text-uppercase"}>inactive</span>
                        </span>
                      </span>
                    })}
                    <span><span
                      className={"current-entry__selected-project current-entry__selected-project--hoverable d-inline-block mt-2"}
                      onClick={(event): boolean => {
                        event.preventDefault()
                        event.stopPropagation()
                        setTaskEditorData({
                          task: {
                            taskId: `NEWTASK#${uuidv4()}`,
                            taskName: "",
                            section: null,
                            userPrices: [],
                            notes: "",
                            active: true
                          }, projectId
                        })
                        return false
                      }}
                    >
                      <span>
                        <BsPlusLg className={"iconfix me-1"} />Add new
                      </span>
                    </span></span>
                  </div>
                </div>
                {
                  !active &&
                  <Button
                    variant={"outline-dark"}
                    className={"mw-max-content"}
                    disabled={activatingProjectId === projectId}
                    onClick={async(event): Promise<void> => {
                      event.stopPropagation()

                      setActivatingProjectId(projectId)
                      try {
                        await toast.promise(handleApiCall(updateProject, {
                          handler: { projectId },
                          updates: { active: true }
                        }), {
                          pending: "Activating project...",
                          success: "Project successfully activated",
                          error: "Failed to activate project"
                        })

                        setProjectList(projectList => {
                          projectList[projectList.findIndex(project => project.projectId === projectId)].active = true

                          return [...projectList]
                        })
                      } catch (error) {
                        handleException(error as Error)
                        logError(error)
                      } finally {
                        setActivatingProjectId(null)
                      }
                    }}
                  >
                    <BsBoxArrowUp className={"iconfix me-2"}/>Activate
                  </Button>
                }
              </Card>
            })
          }
        </Card>
      ))
    }
  </div>
}

export default ProjectList