import classNames from "classnames"
import React, { DragEventHandler, ReactElement, useEffect, useState } from "react"
import Icon from "../components/Icon"

type Props = {
  elementClassName: string,
  handleFile: (file: File) => Promise<void> | void,
  enabled?: boolean
}
type Return = {
  element: ReactElement | null,
  handleDrag: DragEventHandler<HTMLElement>
}

const useDragNDrop = ({ elementClassName, handleFile, enabled = true }: Props): Return => {
  const [dragActive, setDragActive] = useState<boolean>(false)
  const [inDragZone, setInDragZone] = useState<boolean>(false)
  useEffect(() => {
    if (!enabled) {
      return
    }

    const el = document.querySelector(`.${elementClassName}`) as HTMLElement

    const func = (e: DragEvent): void => {
      if (e.type === "dragover" && e.dataTransfer?.types.includes("Files")) {
        setDragActive(true)
      } else if (e.type === "dragleave") {
        setDragActive(false)
      }
    }

    document.body?.addEventListener("dragover", func)
    el?.addEventListener("dragleave", func)
    document.body?.addEventListener("dragleave", func)
    el?.addEventListener("drag", func)
    return (): void => {
      document?.removeEventListener("dragover", func)
      el?.removeEventListener("dragleave", func)
      document.body?.addEventListener("dragleave", func)
      el?.removeEventListener("drag", func)
    }
  }, [enabled])
  const handleDrag: DragEventHandler<HTMLElement> = e => {
    if (!enabled) {
      return
    }

    e.preventDefault()
    e.stopPropagation()
    if ((e.type === "dragenter" || e.type === "dragover") && e.dataTransfer?.types.includes("Files")) {
      setInDragZone(true)
    } else if (e.type === "dragleave") {
      setInDragZone(false)
    }
  }
  const handleDrop: DragEventHandler<HTMLElement> = e => {
    if (!enabled) {
      return
    }

    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setDragActive(false)
      setInDragZone(false)
      void handleFile(e.dataTransfer.files[0])
    }
    return false
  }

  const element = <div
    className={classNames("drag-n-drop__dropzone", {
      "drag-n-drop__dropzone--hovering": inDragZone
    })}
    onDragEnter={handleDrag}
    onDragLeave={handleDrag}
    onDragOver={handleDrag}
    onDrop={handleDrop}
  >
    <span>
      <Icon name="plus" />
      <p>Drag here to upload file</p>
    </span>
  </div>

  return {
    element: (enabled && (dragActive || inDragZone)) ? element : null,
    handleDrag
  }
}

export default useDragNDrop
