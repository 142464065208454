import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react"
import { OpenAPI as PrincipalsApi, StaffLoginRequestSchema, StaffUser, StaffUsersService, VerifyStaffUserMFATokenRequestSchema } from "../sdk/minosse-principals-api"
import { ApiError, OpenAPI as CoreApi } from "../sdk/minosse-api"
import { OpenAPI as TicketingApi } from "../sdk/minosse-ticketing-api"
import { FunctionComponent } from "react"
import { handleError } from "../helpers/utils"
import { MINUTE } from "../helpers/time"
import { authenticateCliStore } from "../stores/authenticateCli"
import { toast } from "react-toastify"
import { authenticateCli, getUser, GetUser200ResponseSchema, setAuth, User } from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"
import * as Sentry from "@sentry/react"

export type AuthContextType = {
  initialized: boolean
  user: StaffUser & User | null
  setUser: Dispatch<SetStateAction<StaffUser & User | null>>
  isLogged: boolean
  logout: () => Promise<void>
}

export enum USER_ROLES {
  AUTHENTICATED_WITH_MFA = "AUTHENTICATED_WITH_MFA",
  AUTHENTICATED_NO_MFA = "AUTHENTICATED_NO_MFA",
  AUTHENTICATED = "(AUTHENTICATED_NO_MFA || AUTHENTICATED_WITH_MFA)",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  PENDING_MFA = "PENDING_MFA",
  PENDING_PASSWORD_RESET = "PENDING_PASSWORD_RESET",
  ADMIN = "ADMIN",
  SELF = "SELF",
  SELF_CUSTOMER = "SELF_CUSTOMER",
  STAFF = "STAFF",
  CUSTOMER = "CUSTOMER"
}

export type Session = {
  sessionId: string
  userId: string
  customerId?: string
  email: string
  roles: USER_ROLES[]
  creationTimestamp: number
  expirationTimestamp: number
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const decodeSession = (encodedSession: string): Session => JSON.parse(Buffer.from(encodedSession, "base64").toString()) as Session

export const setSDKSession = (session: string): void => {
  localStorage.setItem("session", session)

  setAuth("roles", session)

  PrincipalsApi.TOKEN = session
  CoreApi.TOKEN = session
  TicketingApi.TOKEN = session
}

export const cleanSDKSession = (): void => {
  localStorage.removeItem("session")

  setAuth("roles", null)

  PrincipalsApi.TOKEN = undefined
  CoreApi.TOKEN = undefined
  TicketingApi.TOKEN = undefined
}

export const login = async(body: StaffLoginRequestSchema): Promise<Session> => {
  const { session: encodedSession } = await StaffUsersService.staffLogin(body)
  setSDKSession(encodedSession)

  return decodeSession(encodedSession)
}

export const verifyMFA = async(body: VerifyStaffUserMFATokenRequestSchema): Promise<Session> => {
  const { session: encodedSession } = await StaffUsersService.verifyStaffUserMfaToken(body)
  setSDKSession(encodedSession)

  return decodeSession(encodedSession)
}

const REFRESH_INTERVAL = 10 * MINUTE

let interval: NodeJS.Timeout
export const AuthProvider: FunctionComponent = ({ children }) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [user, setUser] = useState<StaffUser & User | null>(null)

  useEffect(() => {
    const session = localStorage.getItem("session")

    void (async(): Promise<void> => {
      if (session) {
        PrincipalsApi.TOKEN = session

        try {
          const { session: encodedSession } = await StaffUsersService.refreshStaffUserSession()

          setSDKSession(encodedSession)

          const { userId } = decodeSession(encodedSession)
          const [{ data: user }, { data: userExtra }] = await Promise.all([
            StaffUsersService.getStaffUser({ handler: { userId }, unsafeUserName: true }),
            handleApiCall(getUser, { handler: { userId } }) as Promise<GetUser200ResponseSchema>
          ])
          setUser({ ...user, ...userExtra })
          Sentry.setContext("user", user)
        } catch (error) {
          handleError(error)
        }
      }

      setInitialized(true)
    })()
  }, [])

  useEffect(() => {
    if (user) {
      const { uuid: authenticateCliUuid } = authenticateCliStore.getState()
      const session = localStorage.getItem("session")
      if (authenticateCliUuid && session) {
        handleApiCall(authenticateCli, { uuid: authenticateCliUuid, writeSession: session })
          .then(() => setTimeout(window.close, 1000))
          .catch(() => toast.error("Error while authenticating CLI"))
      }

      interval = setInterval(async() => {
        try {
          const { session: encodedSession } = await StaffUsersService.refreshStaffUserSession()
          setSDKSession(encodedSession)

        } catch (error) {
          if (interval) {
            clearInterval(interval)
          }

          if ((error as ApiError).status === 401) {
            void logout()
          } else {
            handleError(error)
          }
        }
      }, REFRESH_INTERVAL)
    }
  }, [user?.userId])

  const logout = async(): Promise<void> => {
    try {
      await StaffUsersService.staffLogout()
    } catch (error) {
      handleError(error)
    }

    if (interval) {
      clearInterval(interval)
    }

    cleanSDKSession()
    setUser(null)
    Sentry.setContext("user", null)
  }

  return (
    <AuthContext.Provider
      value={{
        initialized,
        user,
        setUser,
        isLogged: initialized && !!user,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
