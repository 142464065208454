import {
  Bounds,
  CalendarEntry,
  HolidayData,
  MonthConfig,
  Pixels,
  PlanReport,
  Position,
  ResizeDirection,
  Sizes,
  Translate,
  Units,
  VerticalBounds
} from "../types/planner"
import gsap from "gsap"
import { Dispatch, SetStateAction } from "react"
import { getAdjacentMonth, getCurrentMonth, getMonth, getMonthKey, getToday, loadHolidayData } from "./plan"
import { StaffUser } from "../sdk/minosse-principals-api"
import { toast } from "react-toastify"
import { addDays, setHours, subHours } from "date-fns"
import { handleException, logError } from "./logger"
import { v4 as uuidv4 } from "uuid"

import dayjs from "dayjs"
import weekOfYear from "dayjs/plugin/weekOfYear"
import weekYear from "dayjs/plugin/weekYear"
import weekday from "dayjs/plugin/weekday"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import isoWeek from "dayjs/plugin/isoWeek"
import "dayjs/locale/it"
import Mousetrap from "mousetrap"
import { ExcludeFromObject } from "../types"
import { Mapping } from "./utils"
import {
  createPlanEntry,
  CreatePlanEntry200ResponseSchema,
  deletePlanEntry,
  getMonthPlan,
  getPlanReport, GetPlanReport200ResponseSchema,
  PlanEntry,
  updatePlanEntry,
  UpdatePlanEntry200ResponseSchema
} from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "./api"

dayjs.extend(weekYear)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)
dayjs.locale("it")

type InitOptions = {
  container: HTMLDivElement
  header: HTMLDivElement
  sidebar: HTMLDivElement
  wrapper: HTMLDivElement
  updateMonths: Dispatch<SetStateAction<MonthConfig[]>>
  updatePlanItems: Dispatch<SetStateAction<CalendarEntry[]>>
  updateActiveWeek: Dispatch<SetStateAction<Date>>
  updatePlanReport: Dispatch<SetStateAction<PlanReport>>
  updatePlanEditorData: Dispatch<SetStateAction<PlanEditorData>>
  updateActiveSelectionUuid: Dispatch<SetStateAction<string | null>>
  updateClipboard: Dispatch<SetStateAction<ClipboardData | null>>
  updateHolidays: (holidays: HolidayData[]) => void
  initialDate?: Date
  users: StaffUser[]
  user: StaffUser
}
type CalendarState = {
  isClicked: boolean
  isDragging: boolean
}
type PlanItemState = {
  isClicked: boolean
  isDragging: boolean
  isResizing: boolean
  resizeDirection: ResizeDirection | null
  isAnimating: boolean
  activeItem: PlanEntryItem | null
}
type AutoscrollState = {
  leftThreshold: number
  rightThreshold: number
  topThreshold: number
  bottomThreshold: number
  tweens: {
    x: gsap.core.Timeline | null
    y: gsap.core.Timeline | null
  }
  isAutoscrolling: {
    x: boolean
    y: boolean
  }
  direction: {
    x: number
    y: number
  }
}

type DOMElement = {
  element: HTMLElement | null
}
type DOMElementInfo = DOMElement & Sizes & VerticalBounds
const defaultDOMElementInfo: DOMElementInfo = {
  element: null,
  width: 0,
  height: 0,
  top: 0,
  bottom: 0
}

type UpdatePositionOptions = Partial<Translate> & {
  reset?: boolean
  ignoreBounds?: boolean
}

type UpdateSizeOptions = Partial<Sizes> & {
  reset?: boolean
}

type DayConfig = {
  date: Date
  day: number
  month: number
  year: number
  left: number
}

export type PlanEditorData = { planEntry?: CalendarEntry, element?: HTMLDivElement, x?: Pixels, y?: Pixels, open: boolean, onLeft?: boolean, onTop?: boolean }

export type ClipboardData = { entry: CalendarEntry, type: "copy" | "cut" }

// constants
export const dayWidth = 115 // pixel
export const rowHeight = 50 // 75
const startDayOffset = 6 // 2 // days
const wheelThreshold = 3
const autoscrollSpeed = 20
const autoscrollHorizontalThreshold = 0.5
const autoscrollVerticalThreshold = 96 // 54
const dayHeightInUnits = 8

const getUnitPosition = (px: Pixels): Units => px / dayWidth
const getPxPosition = (days: number): Pixels => days * dayWidth

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logCalendar = (...args: any[]): void => {
  if (process.env.NODE_ENV === "development") {
    console.log("%c CALENDAR %c", "background-color: #990000; color: white;", undefined, ...args)
  }
}

export default class CalendarAnimator {
  initialized: boolean = false

  // window sizes
  window: Sizes = {
    width: 0,
    height: 0
  }

  // DOM elements
  container: DOMElementInfo = { ...defaultDOMElementInfo }
  header: DOMElementInfo = { ...defaultDOMElementInfo }
  sidebar: DOMElementInfo = { ...defaultDOMElementInfo }
  wrapper: DOMElementInfo = { ...defaultDOMElementInfo }

  // plan editor
  planEditorData: PlanEditorData = { open: false }

  // clipboard and selection
  clipboard: ClipboardData | null = null
  activeSelection: { uuid: string | null } = { uuid: null }

  // transforms
  calendarYBounds: Bounds = { min: 0, max: 0 }

  // calendar drag states
  calendarState: CalendarState = {
    isClicked: false,
    isDragging: false
  }

  // calendar drag coords
  private position: Translate = { x: 0, y: 0 }
  private prevMousePosition: Translate = { x: 0, y: 0 }

  // mouse
  private mousePosition: Translate = { x: 0, y: 0 }

  // misc info
  users: StaffUser[] = []
  userMapping: Mapping<StaffUser> = {}
  user: StaffUser | null = null // active user
  editingEnabled: boolean = false
  activeWeek: Date = dayjs().weekday(0).toDate()
  planRequestAbortController: AbortController | null = null

  // months
  initialMonth: MonthConfig | null = null
  today: DayConfig | null = null
  months: MonthConfig[] = []
  leftThreshold: number = 0
  rightThreshold: number = 0
  currentMonth: { month: MonthConfig, left: Pixels, right: Pixels } | null = null
  visitedMonths: Record<string, CalendarEntry[]> = {} // Record<string, { year: number, month: number }> = {}
  visitedYears: Record<string, true> = {} // Record<string, { year: number, month: number }> = {}
  monthDivs: Record<string, HTMLDivElement> = {}
  monthHeaderData: {
    prevDiv?: HTMLDivElement
    currentDiv?: HTMLDivElement
    nextDiv?: HTMLDivElement
    prevBlockWidth: number
    prevTextWidth: number
    currentTextWidth: number
    currentBlockWidth: number
    nextBlockWidth: number
    nextTextWidth: number
  } = {
      prevBlockWidth: 0,
      prevTextWidth: 0,
      currentTextWidth: 0,
      currentBlockWidth: 0,
      nextBlockWidth: 0,
      nextTextWidth: 0
    }

  // planItems
  planItems: PlanEntryItem[] = []
  private planItemState: PlanItemState = {
    isAnimating: false,
    isClicked: false,
    isDragging: false,
    isResizing: false,
    resizeDirection: null,
    activeItem: null
  }

  // planItem coords
  private prevPlanEntryMousePosition: Translate = { x: 0, y: 0 }
  private minDragTranslate = {
    x: 0,
    y: 0
  }

  // auction drag autoscroll
  private autoscroll: AutoscrollState = {
    leftThreshold: 0,
    rightThreshold: 0,
    topThreshold: 0,
    bottomThreshold: 0,
    tweens: {
      x: null,
      y: null
    },
    isAutoscrolling: {
      x: false,
      y: false
    },
    direction: {
      x: 0,
      y: 0
    }
  }

  // react linking
  onReactMonthsUpdate: Dispatch<SetStateAction<MonthConfig[]>> = () => {}
  updateReactMonths(): void {
    logCalendar("update react months")
    this.onReactMonthsUpdate([...this.months])
  }
  onReactPlanEntryUpdate: Dispatch<SetStateAction<CalendarEntry[]>> = () => {}
  updateReactPlanEntries(): void {
    logCalendar("update react auctions")
    this.updateThreeMonths()
  }
  updateActiveWeek: Dispatch<SetStateAction<Date>> = () => {}
  updatePlanReport: Dispatch<SetStateAction<PlanReport>> = () => {}
  updatePlanEditorData: Dispatch<SetStateAction<PlanEditorData>> = () => {}
  updateActiveSelectionUuid: Dispatch<SetStateAction<string | null>> = () => {}
  updateClipboard: Dispatch<SetStateAction<ClipboardData | null>> = () => {}
  updateHolidays: (holidays: HolidayData[]) => void = () => {}

  // CALENDAR LIFECYCLE
  init({
    // planItems,
    container,
    header,
    sidebar,
    wrapper,
    updateMonths,
    updatePlanItems,
    updateActiveWeek,
    updatePlanReport,
    updatePlanEditorData,
    updateActiveSelectionUuid,
    updateClipboard,
    updateHolidays,
    initialDate,
    users,
    user
  }: InitOptions): void {
    logCalendar("init")
    this.container.element = container
    this.header.element = header
    this.sidebar.element = sidebar
    this.wrapper.element = wrapper
    this.onReactMonthsUpdate = updateMonths
    this.onReactPlanEntryUpdate = updatePlanItems
    this.updateActiveWeek = updateActiveWeek
    this.updatePlanReport = updatePlanReport
    this.updatePlanEditorData = updatePlanEditorData
    this.updateActiveSelectionUuid = updateActiveSelectionUuid
    this.updateClipboard = updateClipboard
    this.updateHolidays = updateHolidays
    this.users = users
    this.userMapping = users.reduce((acc, user) => ({ ...acc, [user.userId]: user }), {})
    this.user = user
    this.editingEnabled = user.isAdmin

    this.updateWindow()

    const currentMonth = getCurrentMonth()
    this.initialMonth = { ...currentMonth }
    const today = new Date()
    this.today = {
      date: today,
      day: today.getDate(),
      month: today.getMonth(),
      year: today.getFullYear(),
      left: getPxPosition(currentMonth.left + today.getDate() - 1)
    }
    const activeMonth: MonthConfig = initialDate
      ? getMonth({
        initialMonth: currentMonth,
        year: initialDate.getFullYear(),
        month: initialDate.getMonth()
      })
      : currentMonth
    const activeDate: Date = initialDate || getToday()
    this.setActiveWeek(dayjs(activeDate).weekday(0).toDate())
    this.activeWeek = dayjs(activeDate).weekday(0).toDate()
    logCalendar("active week", this.activeWeek)

    this.currentMonth = { month: activeMonth, left: getPxPosition(activeMonth.left), right: getPxPosition(activeMonth.left + activeMonth.days) }
    this.updateMonthHeaderData()

    const datediff = dayjs(this.initialMonth!.date).diff(dayjs(this.activeWeek), "day") - 2
    this.updatePosition({ x: datediff * dayWidth + Math.floor((this.window.width / 2) / dayWidth) * dayWidth, reset: true })

    logCalendar("position", this.position.x)
    this.months = [getAdjacentMonth(activeMonth, -1), activeMonth, getAdjacentMonth(activeMonth, 1)]
    void this.handleMonthLoading(activeMonth, { setCurrent: true, addAdjacents: true })
    this.updateThresholds()
    void this.addMonth(getAdjacentMonth(activeMonth, -1))
    void this.addMonth(activeMonth)
    void this.addMonth(getAdjacentMonth(activeMonth, 1))
    this.checkThresholds(false)

    this.updateReactMonths()

    // this.initPlanEntries(planItems)
    this.updateAutoscrollThresholds()

    this.container.element.addEventListener("wheel", this.onWheel)
    this.container.element.addEventListener("mousedown", this.onMouseDown)
    this.container.element.addEventListener("touchstart", this.onTouchStart)
    this.container.element.addEventListener("contextmenu", this.onContextMenu)
    this.container.element.addEventListener("dblclick", this.onDblClick)
    this.container.element.addEventListener("click", this.onContainerClick)
    this.header.element.addEventListener("contextmenu", e => e.stopPropagation())
    this.header.element.addEventListener("dblclick", e => e.stopPropagation())
    this.sidebar.element.addEventListener("contextmenu", e => e.stopPropagation())
    this.sidebar.element.addEventListener("dblclick", e => e.stopPropagation())
    document.addEventListener("mousemove", this.onMouseMove)
    document.addEventListener("touchmove", this.onTouchMove)
    this.container.element.addEventListener("mouseup", this.onMouseUp)
    this.container.element.addEventListener("touchend", this.onTouchEnd)
    document.body.addEventListener("mouseleave", this.onMouseUp)
    window.addEventListener("resize", this.onResize)

    document.querySelectorAll(".new-planner__dblclick-nope").forEach(el => {
      el.addEventListener("contextmenu", e => e.stopPropagation())
      el.addEventListener("dblclick", e => e.stopPropagation())
    })

    Mousetrap.bind("mod+x", this.onCtrlX)
    Mousetrap.bind("mod+c", this.onCtrlC)
    Mousetrap.bind("mod+v", this.onCtrlV)
    Mousetrap.bind("backspace", this.onBackspace)
    Mousetrap.bind("mod+f", (e): void => {
      e.preventDefault();
      (document.querySelector(".new-planner__user-choice-input input") as HTMLInputElement)?.focus()
    })

    gsap.set(this.container.element, { opacity: 1, pointerEvents: "all" })
    this.calculateActiveWeek()
    this.initialized = true
    // void this.doFirstDataLoad()
  }
  destroy(): void {
    logCalendar("destroy")

    if (this.container.element) {
      this.container.element.removeEventListener("wheel", this.onWheel)
      this.container.element.removeEventListener("mousedown", this.onMouseDown)
      this.container.element.removeEventListener("touchstart", this.onTouchStart)
      this.container.element.removeEventListener("contextmenu", this.onContextMenu)
      document.removeEventListener("mousemove", this.onMouseMove)
      document.removeEventListener("touchmove", this.onTouchMove)
      this.container.element.removeEventListener("mouseup", this.onMouseUp)
      this.container.element.removeEventListener("touchend", this.onTouchEnd)
      document.body.removeEventListener("mouseleave", this.onMouseUp)
      window.removeEventListener("resize", this.onResize)

      document.querySelectorAll(".new-planner__dblclick-nope").forEach(el => {
        el.removeEventListener("contextmenu", e => e.stopPropagation())
        el.removeEventListener("dblclick", e => e.stopPropagation())
      })

      Mousetrap.unbind("mod+x")
      Mousetrap.unbind("mod+c")
      Mousetrap.unbind("mod+v")
      Mousetrap.unbind("backspace")
      Mousetrap.unbind("mod+f")

      if (this.header.element) {
        this.header.element.removeEventListener("contextmenu", e => e.stopPropagation())
        this.header.element.removeEventListener("dblclick", e => e.stopPropagation())
      }
      if (this.sidebar.element) {
        this.sidebar.element.removeEventListener("contextmenu", e => e.stopPropagation())
        this.sidebar.element.removeEventListener("dblclick", e => e.stopPropagation())
      }
    }
    this.prevMousePosition = { x: 0, y: 0 }
    this.calendarState.isClicked = false
    this.calendarState.isDragging = false

    this.destroyPlanEntries()
  }

  initPlanItem(planEntry: CalendarEntry): PlanEntryItem {
    logCalendar("init plan entry", planEntry)
    const planItem = new PlanEntryItem({
      planEntry,
      calendar: this
    })
    this.planItems.push(planItem)
    planItem.resetPosition()

    ;(planItem.dom.element?.querySelectorAll("[data-drag-handle]") as NodeListOf<HTMLDivElement>)
      ?.forEach(handle => handle.addEventListener("mousedown", this.onDragHandleMouseDown))
    planItem.dom.element?.addEventListener("mousedown", this.onPlanEntryMouseDown)

    return planItem
  }
  destroyPlanItem(planEntryId: string): void {
    logCalendar("destroy plan entry", planEntryId)
    const planItem = this.planItems.find(item => item.data.uuid === planEntryId)
    if (planItem) {
      this.planItems.splice(this.planItems.indexOf(planItem), 1)
    }
  }

  private destroyPlanEntries(): void {
    this.planItems.forEach(planItem => {
      planItem.dom.element?.removeEventListener("mousedown", this.onPlanEntryMouseDown)
    })
    document.removeEventListener("mousemove", this.onPlanEntryMouseMove)
    document.removeEventListener("mouseup", this.onPlanEntryMouseUp)

    this.planItems.forEach(planItem => {
      planItem.dom.element?.remove()
    })

    this.prevPlanEntryMousePosition = { x: 0, y: 0 }
    this.planItemState.isClicked = false
    this.planItemState.isDragging = false
    this.planItemState.isResizing = false
    this.planItemState.resizeDirection = null
  }
  private resetPlanEntryItemState = (): void => {
    this.planItemState.isClicked = false
    this.planItemState.isDragging = false
    this.planItemState.activeItem = null
    this.planItemState.isResizing = false
    this.planItemState.resizeDirection = null
  }

  async downloadPlanReport(): Promise<void> {
    this.updatePlanReport(null)
    if (this.planRequestAbortController) {
      this.planRequestAbortController.abort()
    }
    try {
      this.planRequestAbortController = new AbortController()
      const data = await handleApiCall(getPlanReport, {
        start: this.activeWeek.getTime(),
        stop: subHours(setHours(addDays(this.activeWeek, 7), 0), 1).getTime()
      }, {
        signal: this.planRequestAbortController.signal
      }) as GetPlanReport200ResponseSchema
      logCalendar("%c[planReport] planReport fetched", "color: #909")
      this.updatePlanReport(data.data as PlanReport)
    } catch (ex) {
      handleException(ex as Error)
      logError(ex)
      logCalendar("%c[planReport] failed to fetch planReport", "color: #900")
    }
  }

  setActiveWeek(week: Date): void {
    if (week.getTime() !== this.activeWeek.getTime()) {
      this.activeWeek = week
      this.updateActiveWeek(week)
      void this.downloadPlanReport()
    }
  }

  scrollToUser(userId: string): void {
    const userIndex = this.getUserIndex(userId)
    this.updatePosition({
      y: -userIndex * dayHeightInUnits * rowHeight,
      x: this.position.x,
      reset: true
    })
  }

  getAdjustedMiddlePosition(): Pixels {
    return -this.position.x + (300 + (this.window.width - 300) / 2)
  }

  getXYUnitsFromPlanEntry(planEntry: CalendarEntry): { x: Units, y: Units, width: Units, height: Units } {
    const userOffsetY = Math.max(this.getUserIndex(planEntry.userId), 0) * dayHeightInUnits
    const hourOffsetY = new Date(planEntry.start).getUTCHours() - 9

    const dayDiff = dayjs(planEntry.stop).utc().diff(dayjs(planEntry.start), "day") + 1

    const x = Math.floor(dayjs(planEntry.start).utc(true).diff(dayjs(this.initialMonth?.date).utc(true), "day", true))
    const y = userOffsetY + hourOffsetY

    return { x, y, width: dayDiff, height: planEntry.dailyHours }
  }

  getXYPxFromPlanEntry(planEntry: CalendarEntry): { x: Pixels, y: Pixels, width: Pixels, height: Pixels } {
    const { x, y, width, height } = this.getXYUnitsFromPlanEntry(planEntry)
    return {
      x: x * dayWidth,
      y: y * rowHeight,
      width: width * dayWidth,
      height: height * rowHeight
    }
  }

  getUnitsFromPx({ x, y }: { x: Pixels, y: Pixels }): { x: Units, y: Units } {
    return { x: x / dayWidth, y: y / rowHeight }
  }

  getUserIndex(userId: string): number {
    return this.users.findIndex(user => user.userId === userId)
  }

  getUserByYUnits(y: Units): StaffUser {
    return this.users[Math.floor(y / dayHeightInUnits)]
  }

  private mouseEventPixelsToXYUnits = (x: Pixels, y: Pixels): { x: Units, y: Units } => {
    const absoluteX = - this.position.x + x
    const absoluteY = - this.position.y + y - (3 * rowHeight) // magic number?
    return {
      x: Math.floor(absoluteX / dayWidth),
      y: Math.floor(absoluteY / rowHeight)
    }
  }

  changeActiveWeek(date?: Date): void {
    if (!date) {
      date = dayjs(getToday()).weekday(0).toDate()
    }
    date = dayjs(date).weekday(0).toDate()
    const datediff = dayjs(this.initialMonth!.date).diff(dayjs(date), "day") - 2
    this.updatePosition({ x: datediff * dayWidth + Math.floor((this.window.width / 2) / dayWidth) * dayWidth, reset: true })
    this.setActiveWeek(date)
    this.checkThresholds()
    this.getMonthThresholds()
  }

  private openEmptyPlanEditorData(data: ExcludeFromObject<PlanEditorData, "open" | "element">): void {
    this.planEditorData.open = true
    this.planEditorData.x = data.x
    this.planEditorData.y = data.y
    this.planEditorData.onLeft = data.onLeft
    this.planEditorData.onTop = data.onTop
    this.planEditorData.planEntry = data.planEntry

    const div = document.createElement("div")
    div.id = "planEditor-temp-red-box"
    this.setGhostStyle(div, "small")
    this.planEditorData.element = this.wrapper.element?.appendChild?.(div)

    this.updatePlanEditorData({ ...this.planEditorData })
  }

  setGhostStyle(div: HTMLDivElement, type: "small" | "big"): void {
    const xUnits = Math.floor(this.planEditorData.x! / dayWidth)
    const yUnits = Math.floor(this.planEditorData.y! / rowHeight)

    div.style.position = "absolute"
    div.style.borderRadius = "5px"
    div.style.zIndex = "100"
    div.style.backgroundColor = "gray"
    div.style.opacity = "0.5"
    div.style.border = "3px solid #00000099"
    div.style.left = `${xUnits * dayWidth}px`
    div.style.top = `${yUnits * rowHeight}px`

    let width = 1, height = 1
    if (this.clipboard?.entry && type === "big") {
      ({ width, height } = this.getXYUnitsFromPlanEntry(this.clipboard?.entry))
      if (!this.checkIfPlanEntryFits(width, height, xUnits, yUnits)) {
        width = 1
        height = 1
      }
    }

    div.style.width = `${width * dayWidth}px`
    div.style.height = `${height * rowHeight}px`
  }

  // PLAN ENTRIES LISTENERS
  private onDblClick = (e: MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    if (!this.editingEnabled) {
      return
    }
    this.closeEditor(true)

    const absoluteX = - this.position.x + e.clientX
    const absoluteY = - this.position.y + e.clientY - (3 * rowHeight) // magic number?
    const { x, y } = this.mouseEventPixelsToXYUnits(e.clientX, e.clientY)
    logCalendar("xy", x, y)

    if (y < 0 || y >= this.users.length * dayHeightInUnits) {
      return
    }

    const container = (e.target as HTMLDivElement).closest("[data-planentry]") as HTMLDivElement
    if (container) {
      const id = container.getAttribute("data-planentry") as string
      logCalendar("onDoubleClickPlanEntry id", id)
      if (id !== undefined) {
        const planEntry = this.planItems.find(a => a.data.uuid === id)
        if (planEntry) {
          this.updatePlanEditorData({
            open: true,
            planEntry: planEntry.data,
            element: container,
            x: absoluteX,
            y: absoluteY,
            onLeft: e.clientX > (window.innerWidth - 300),
            onTop: e.clientY > (window.innerHeight - 250)
          })
        }
      }
    } else {
      const div = document.createElement("div")
      div.style.position = "absolute"
      div.style.left = `${x * dayWidth}px`
      div.style.top = `${y * rowHeight}px`
      div.style.width = `${dayWidth}px`
      div.style.height = `${rowHeight}px`
      div.style.backgroundColor = "red"
      div.style.border = "3px solid #00000099"
      div.style.borderRadius = "5px"
      div.style.zIndex = "100"
      div.id = "planEditor-temp-red-box"
      this.openEmptyPlanEditorData({
        x: absoluteX,
        y: absoluteY,
        onLeft: e.clientX > (window.innerWidth - 300),
        onTop: e.clientY > (window.innerHeight - 120)
      })
    }
  }
  private onContextMenu = (e: MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    if (!this.editingEnabled) {
      return
    }
    this.closeEditor(true)

    const absoluteX = - this.position.x + e.clientX
    const absoluteY = - this.position.y + e.clientY - (3 * rowHeight) // magic number?
    const { x, y } = this.mouseEventPixelsToXYUnits(e.clientX, e.clientY)
    logCalendar("xy", x, y)

    if (y < 0 || y >= this.users.length * dayHeightInUnits) {
      return
    }

    const container = (e.target as HTMLDivElement).closest("[data-planentry]") as HTMLDivElement
    if (container) {
      const id = container.getAttribute("data-planentry") as string
      logCalendar("onDoubleClickPlanEntry id", id)
      if (id !== undefined) {
        const planEntry = this.planItems.find(a => a.data.uuid === id)
        if (planEntry) {
          this.updatePlanEditorData({
            open: true,
            planEntry: planEntry.data,
            element: container,
            x: absoluteX,
            y: absoluteY,
            onLeft: e.clientX > (window.innerWidth - 300),
            onTop: e.clientY > (window.innerHeight - 250)
          })
        }
      }
    } else {
      this.openEmptyPlanEditorData({
        x: absoluteX,
        y: absoluteY,
        onLeft: e.clientX > (window.innerWidth - 300),
        onTop: e.clientY > (window.innerHeight - 120)
      })
    }
  }
  closeEditor(removeElement: boolean = false): void {
    if (removeElement && this.planEditorData?.element && !this.planEditorData.planEntry) {
      (this.planEditorData!.element as HTMLDivElement)?.remove()
      document.querySelectorAll("#planEditor-temp-red-box").forEach(a => a.remove())
    }
    this.updatePlanEditorData({ open: false })
  }

  checkIfPlanEntryFits(width: Units, height: Units, x: Units, y: Units, ignoredEntries: string[] = []): boolean {
    if (width > 1 && height > 1 && (((y) % 8) >= ((y + height - 1) % 8))) {
      return false
    }
    if (y < 0) {
      return false
    }
    if (y >= this.users.length * dayHeightInUnits) {
      return false
    }

    for (const planItem of this.planItems) {
      if (ignoredEntries.includes(planItem.data.uuid)) {
        continue
      }
      const { x: x1, y: y1, width: width1, height: height1 } = this.getXYUnitsFromPlanEntry(planItem.data)
      if (
        ((x < x1 && x + width > x1) || (x >= x1 && x < x1 + width1))
        && ((y < y1 && y + height > y1) || (y >= y1 && y < y1 + height1))
      ) {
        console.log("checkIfPlanEntryFits false", { x, y, width, height, x1, y1, width1, height1 })
        return false
      }
    }
    return true
  }

  async createNewEntry({ customerId, projectId, taskId, xyType, x, y, width = 1, height = 1 }: {
    customerId: string
    projectId: string
    taskId: string | null
    xyType: "units" | "px"
    x: number
    y: number
    width?: number
    height?: number
  }): Promise<void> {
    logCalendar("createNewEntry", { customerId, projectId, taskId, xyType, x, y, width, height })
    if (xyType === "px") {
      x = Math.floor(x / dayWidth)
      y = Math.floor(y / rowHeight)
      logCalendar("new xy", x, y)
    }

    const user = this.getUserByYUnits(y)
    y = (y % dayHeightInUnits) + 9
    const day = dayjs(this.initialMonth?.date).utc(true).add(x, "day")
    const start = day.set("hour", y).toDate().getTime()
    const stop = day.add(width - 1, "day").set("hour", y + height).toDate().getTime()
    const planEntry = {
      uuid: uuidv4(),
      customerId,
      projectId,
      taskId,
      userId: user.userId,
      start,
      stop,
      dailyHours: height,
      id: ""
    } as CalendarEntry
    try {
      const data = await toast.promise(handleApiCall(createPlanEntry, {
        start: planEntry.start,
        stop: planEntry.stop,
        dailyHours: planEntry.dailyHours,
        userId: planEntry.userId,
        projectId: planEntry.projectId,
        customerId: planEntry.customerId,
        taskId: planEntry.taskId
      }), {
        error: "Error creating plan entry"
      })
      document.dispatchEvent(new Event("timeEntriesChanged"))
      planEntry.id = (data as CreatePlanEntry200ResponseSchema).data.id
      this.onReactPlanEntryUpdate(prev => [...prev, planEntry])

      const monthKey = getMonthKey(planEntry.start)
      this.visitedMonths[monthKey] = [...this.visitedMonths[monthKey], planEntry]

      void this.downloadPlanReport()
    } catch (ex) {
      handleException(ex as Error)
      logCalendar("createNewEntry error", ex)
    }
  }
  async updateEntry(uuid: string, updates: Pick<CalendarEntry, "customerId" | "projectId" | "taskId">): Promise<void> {
    try {
      this.planItems.find(a => a.data.uuid === uuid)?.updateContents(updates)
    } catch (ex) {
      handleException(ex as Error)
      logCalendar("updateEntry error", ex)
    }
  }

  async deleteEntry(uuid: string): Promise<void> {
    const planEntry = this.planItems.find(a => a.data.uuid === uuid)
    if (planEntry) {
      try {
        await toast.promise(handleApiCall(deletePlanEntry, { id: planEntry.data.id }), {
          error: "Error deleting plan entry"
        })
        document.dispatchEvent(new Event("timeEntriesChanged"))
        this.deleteEntryLocally(uuid)
        void this.downloadPlanReport()
      } catch (ex) {
        handleException(ex as Error)
        this.resetPlannerEntries()
        logCalendar("deleteEntry error", ex)
      }
    } else {
      toast.error("No matching plan entry found")
    }
  }
  deleteEntryLocally(uuid: string): void {
    const planEntry = this.planItems.find(a => a.data.uuid === uuid)
    if (planEntry) {
      this.onReactPlanEntryUpdate(prev => prev.filter(a => a.uuid !== uuid))
      const monthKey = getMonthKey(planEntry.data.start)
      const idx = this.visitedMonths[monthKey].findIndex((entry) => entry.uuid === planEntry.data.uuid)
      if (idx !== -1) {
        this.visitedMonths[monthKey].splice(idx, 1)
      }
    }
  }

  async pasteEntry(clipboard: ClipboardData, xyType: "units" | "px", x: number, y: number): Promise<void> {
    if (xyType === "px") {
      x = Math.floor(x / dayWidth)
      y = Math.floor(y / rowHeight)
    }
    let { width, height } = this.getXYUnitsFromPlanEntry(clipboard.entry)
    if (!this.checkIfPlanEntryFits(width, height, x, y, clipboard.type === "cut" ? [clipboard.entry.uuid] : [])) {
      width = 1
      height = 1
    }
    if (!this.checkIfPlanEntryFits(1, 1, x, y, clipboard.type === "cut" ? [clipboard.entry.uuid] : [])) {
      return
    }
    await this.createNewEntry({
      customerId: clipboard.entry.customerId,
      projectId: clipboard.entry.projectId,
      taskId: clipboard.entry.taskId,
      xyType: "units",
      x,
      y,
      width,
      height
    })
    if (clipboard.type === "cut") {
      await this.deleteEntry(clipboard.entry.uuid)
    }
  }

  private isPlanEntryBeingActedUpon = (): boolean => {
    return this.planItemState.isDragging
      || this.planItemState.isResizing
      || !!this.planItemState.activeItem?.requestAbortController
  }

  resetPlannerEntries = (): void => {
    logCalendar("resetPlannerEntries")
    this.onReactPlanEntryUpdate([])
    this.visitedMonths = {}

    void this.handleMonthLoading(this.currentMonth?.month!, {
      setCurrent: true,
      addAdjacents: true,
      skipCheck: true
    })
  }

  // ========== SELECTION ==========

  private onContainerClick = (e: MouseEvent): void => {
    if (!this.editingEnabled) {
      return
    }
    if ((e.target as HTMLDivElement).closest(".new-planner__dblclick-nope") || (e.target as HTMLDivElement).closest("[data-planentry]")) {
      this.closeEditor(true)
      return
    } else {
      this.updateActiveSelectionUuid(null)
    }
  }

  private onCtrlC = (e: Mousetrap.ExtendedKeyboardEvent): void => {
    if (!this.activeSelection.uuid || !this.editingEnabled || this.isPlanEntryBeingActedUpon()) {
      return
    }
    const planEntry = this.planItems.find(a => a.data.uuid === this.activeSelection.uuid)
    if (planEntry) {
      this.updateClipboard({
        type: "copy",
        entry: planEntry.data
      })
      this.updateActiveSelectionUuid(null)
    }
  }
  private onCtrlX = (e: Mousetrap.ExtendedKeyboardEvent): void => {
    if (!this.activeSelection.uuid || !this.editingEnabled || this.isPlanEntryBeingActedUpon()) {
      return
    }
    const planEntry = this.planItems.find(a => a.data.uuid === this.activeSelection.uuid)
    if (planEntry) {
      this.updateClipboard({
        type: "cut",
        entry: planEntry.data
      })
      this.updateActiveSelectionUuid(null)
    }
  }
  private onCtrlV = (e: Mousetrap.ExtendedKeyboardEvent): void => {
    if (this.clipboard && this.editingEnabled && !this.isPlanEntryBeingActedUpon()) {
      const { x, y } = this.mouseEventPixelsToXYUnits(this.mousePosition.x, this.mousePosition.y)
      void this.pasteEntry(this.clipboard, "units", x, y).then(() => {
        if (this.clipboard?.type === "cut") {
          this.updateClipboard(null)
          this.updateActiveSelectionUuid(null)
        }
      })
    }
  }
  private onBackspace = (e: Mousetrap.ExtendedKeyboardEvent): void => {
    if (this.activeSelection.uuid && this.editingEnabled && !this.isPlanEntryBeingActedUpon()) {
      void this.deleteEntry(this.activeSelection.uuid).then(() => {
        this.updateActiveSelectionUuid(null)
      })
    }
  }


  private onPlanEntryMouseDown = (e: MouseEvent): void => {
    if (e.button !== 0 || !this.editingEnabled) {
      return
    }
    e.stopPropagation()
    const container = (e.target as HTMLDivElement).closest("[data-planentry]") as HTMLDivElement
    logCalendar("onPlanEntryMouseDown container", container)

    if (container) {
      const id = container.getAttribute("data-planentry") as string
      logCalendar("onPlanEntryMouseDown id", id)

      this.updateActiveSelectionUuid(id)

      if (id !== undefined) {
        const planEntry = this.planItems.find(a => a.data.uuid === id)
        logCalendar("onPlanEntryMouseDown planEntry", planEntry)

        planEntry?.getXYInUnitsFromCalendar()

        if (planEntry && planEntry.state.draggable && !this.planItemState.isAnimating) {
          this.planItemState.isClicked = true
          this.planItemState.activeItem = planEntry

          gsap.set(this.planItemState.activeItem.dom.element, { zIndex: 1 })

          this.minDragTranslate.x = this.getTodayLeft() - this.planItemState.activeItem.position.left

          this.prevPlanEntryMousePosition = {
            x: e.clientX,
            y: e.clientY
          }
        }
      }
    }
  }
  private onPlanEntryMouseMove = (e: MouseEvent): void => {
    if (!this.editingEnabled) {
      return
    }
    if (this.planItemState.isResizing && !this.planItemState.isAnimating && this.planItemState.activeItem) {
      e.stopPropagation()
      this.planItemState.isResizing = true

      const { width, height } = this.planItemState.activeItem.sizes
      const mouseDeltaX = e.clientX - this.prevPlanEntryMousePosition.x
      const mouseDeltaY = e.clientY - this.prevPlanEntryMousePosition.y

      // update size
      if (this.planItemState.resizeDirection === ResizeDirection.SE) {
        this.updatePlanItemSize({
          width: width + mouseDeltaX,
          height: height + mouseDeltaY
        })
      } else if (this.planItemState.resizeDirection === ResizeDirection.NW) {
        const { updateWidth, updateHeight } = this.updatePlanItemSize({
          width: width - mouseDeltaX,
          height: height - mouseDeltaY
        })
        this.updatePlanItemPosition({
          x: updateWidth ? mouseDeltaX : 0,
          y: updateHeight ? mouseDeltaY : 0
        })
      } else if (this.planItemState.resizeDirection === ResizeDirection.NE) {
        const { updateHeight } = this.updatePlanItemSize({
          width: width + mouseDeltaX,
          height: height - mouseDeltaY
        })
        if (updateHeight) {
          this.updatePlanItemPosition({
            y: mouseDeltaY
          })
        }
      } else if (this.planItemState.resizeDirection === ResizeDirection.SW) {
        const { updateWidth } = this.updatePlanItemSize({
          width: width - mouseDeltaX,
          height: height + mouseDeltaY
        })
        if (updateWidth) {
          this.updatePlanItemPosition({
            x: mouseDeltaX
          })
        }
      }

      // update delta
      this.prevPlanEntryMousePosition = {
        x: e.clientX,
        y: e.clientY
      }

      this.checkAutoscroll({ x: e.clientX, y: e.clientY })

      // this.planItemState.activeItem?.sizes.width
    } else if (this.planItemState.isClicked && !this.planItemState.isAnimating) {
      /* ON DRAG */
      e.stopPropagation()

      this.planItemState.isDragging = true

      let updateX = true
      let updateY = true
      if (!this.shouldUpdateDragX(e.clientX)) {
        logCalendar("horizontal drag blocked")
        updateX = false
      }
      if (!this.shouldUpdateDragY(e.clientY)) {
        logCalendar("vertical drag blocked")
        updateY = false
      }

      if (updateX || updateY) {
        // logCalendar("onPlanEntryMouseMove updateAuctionPosition")
        this.updatePlanItemPosition({
          x: updateX ? e.clientX - this.prevPlanEntryMousePosition.x : undefined,
          y: updateY ? e.clientY - this.prevPlanEntryMousePosition.y : undefined
        })
        this.prevPlanEntryMousePosition = {
          x: e.clientX,
          y: e.clientY
        }

        this.checkAutoscroll({ x: e.clientX, y: e.clientY })
      }
    }
  }
  private onPlanEntryMouseUp = (): void => {
    if (!this.editingEnabled) {
      return
    }
    if (this.planItemState.isResizing && this.planItemState.activeItem) {
      this.planItemState.isAnimating = true
      this.planItemState.isResizing = false
      this.planItemState.isClicked = false

      const activeItem = this.planItemState.activeItem
      const absoluteX = Math.round((
        activeItem.position.left + activeItem.translate.x
      ) / dayWidth) * dayWidth
      const absoluteY = Math.round((
        activeItem.position.top + activeItem.translate.y
      ) / rowHeight) * rowHeight

      const { x: xUnits, y: yUnits } = this.getUnitsFromPx({ x: absoluteX, y: absoluteY })

      const userOffset = this.getUserIndex(activeItem.data.userId) * dayHeightInUnits

      let ease = false

      let widthUnits: Units = Math.round(activeItem.sizes.width / dayWidth)
      let heightUnits: Units = Math.round(activeItem.sizes.height / rowHeight)

      let x = (absoluteX - activeItem.position.left)
      let y = (absoluteY - activeItem.position.top)

      // overlap checking
      for (const planItem of this.planItems) {
        if (planItem.data.uuid === activeItem.data.uuid) {
          continue
        }

        const { x: x1, y: y1, width: width1, height: height1 } = this.getXYUnitsFromPlanEntry(planItem.data)

        if (
          ((xUnits < x1 && xUnits + widthUnits > x1) || (xUnits >= x1 && xUnits < x1 + width1))
          && ((yUnits < y1 && yUnits + heightUnits > y1) || (yUnits >= y1 && yUnits < y1 + height1))
        ) {
          logCalendar("OVERLAP X", { xUnits, width: widthUnits * dayWidth, x1, width1 })
          logCalendar("OVERLAP Y", { yUnits, height: heightUnits * rowHeight, y1, height1 })
          widthUnits = dayjs(activeItem.data.stop).diff(activeItem.data.start, "days") + 1
          heightUnits = activeItem.data.dailyHours
          x = 0
          y = 0
          ease = true
          break
        }
      }

      const oldWidth = dayjs(activeItem.data.stop).diff(activeItem.data.start, "days") + 1
      const oldHeight = activeItem.data.dailyHours

      if ((yUnits + heightUnits) > (userOffset + 8)) {
        widthUnits = oldWidth
        heightUnits = oldHeight
        y = 0
        x = 0
        ease = true
      }
      if (yUnits < userOffset) {
        widthUnits = oldWidth
        heightUnits = oldHeight
        y = 0
        x = 0
        ease = true
      }

      const isDifferent = (
        widthUnits !== oldWidth
        || heightUnits !== oldHeight
        || Math.floor(x / dayWidth) !== 0
        || Math.floor(y / rowHeight) !== 0
      )

      const width: Pixels = widthUnits * dayWidth
      const height: Pixels = heightUnits * rowHeight

      const tl = gsap.timeline({
        onComplete: () => {
          logCalendar("complete drag timeline", activeItem.data)
          if (isDifferent) {
            activeItem.updateData({
              x: x + activeItem.position.left,
              y: y + activeItem.position.top,
              absoluteHeight: heightUnits,
              absoluteWidth: widthUnits - 1
            })
            activeItem.resetPosition()
            this.updateReactPlanEntries()
          }

          this.prevPlanEntryMousePosition = { x: 0, y: 0 }
          this.planItemState.activeItem = null
          this.planItemState.isAnimating = false
        }
      })
      tl.to(activeItem.sizes, {
        width,
        height,
        duration: 0.15,
        // ease: undefined,
        ease: ease ? "back.out" : undefined,
        onUpdate: () => {
          gsap.set(activeItem.dom.element, {
            width: activeItem.sizes.width,
            height: activeItem.sizes.height
          })
        },
        onComplete: () => {
          logCalendar("complete drag tween")
          gsap.set(activeItem.dom.element, { zIndex: "auto" })
        }
      })
      tl.to(activeItem.translate, {
        x,
        y,
        duration: 0.15,
        ease: ease ? "back.out" : undefined,
        onUpdate: () => {
          logCalendar("onUpdate translate", activeItem.translate)
          gsap.set(activeItem.dom.element, {
            x: activeItem.translate.x,
            y: activeItem.translate.y
          })
        },
        onComplete: () => {
          logCalendar("complete drag tween")
          gsap.set(activeItem.dom.element, { zIndex: "auto" })
        }
      }, "<")
      tl.play()
    }
    if (this.planItemState.isDragging && this.planItemState.activeItem) {
      this.planItemState.isAnimating = true
      this.planItemState.isClicked = false
      this.planItemState.isDragging = false
      this.stopHorizontalAutoscroll()
      this.stopVerticalAutoscroll()

      const activeItem = this.planItemState.activeItem

      const absoluteX = Math.round((
        activeItem.position.left + activeItem.translate.x
      ) / dayWidth) * dayWidth
      const absoluteY = Math.round((
        activeItem.position.top + activeItem.translate.y
      ) / rowHeight) * rowHeight

      const width = dayjs(activeItem.data.stop).diff(activeItem.data.start, "day") + 1
      const height = activeItem.data.dailyHours

      let x = absoluteX - activeItem.position.left
      let y = absoluteY - activeItem.position.top

      const { x: xUnits, y: yUnits } = this.getUnitsFromPx({ x: absoluteX, y: absoluteY })

      let ease: boolean = false

      if (/* xUnits < 0 || */ yUnits < 0) {
        x = 0
        y = 0
        ease = true
      }

      if (yUnits >= this.users.length * dayHeightInUnits) {
        x = 0
        y = 0
        ease = true
      }

      if (((yUnits) % 8) + activeItem.data.dailyHours > 8) {
        x = 0
        y = 0
        ease = true
      }

      // overlap checking
      if (!ease) {
        for (const planItem of this.planItems) {
          if (planItem.data.uuid === activeItem.data.uuid) {
            continue
          }

          const { x: x1, y: y1, width: width1, height: height1 } = this.getXYUnitsFromPlanEntry(planItem.data)

          if (
            ((xUnits < x1 && xUnits + width > x1) || (xUnits >= x1 && xUnits < x1 + width1))
            && ((yUnits < y1 && yUnits + activeItem.data.dailyHours > y1) || (yUnits >= y1 && yUnits < y1 + height1))
          ) {
            logCalendar("OVERLAP X", { xUnits, width, x1, width1 })
            logCalendar("OVERLAP Y", { yUnits, height, y1, height1 })
            x = 0
            y = 0
            ease = true
            break
          }
        }
      }

      const tl = gsap.timeline({
        onComplete: () => {
          logCalendar("complete drag timeline", activeItem.data)
          if (x !== 0 || y !== 0) {
            activeItem.updateData({ x: x + activeItem.position.left, y: y + activeItem.position.top })
            activeItem.resetPosition()
            this.updateReactPlanEntries()
          }

          this.prevPlanEntryMousePosition = { x: 0, y: 0 }
          this.planItemState.activeItem = null
          this.planItemState.isAnimating = false
        }
      })
      tl.to(activeItem.translate, {
        x,
        y,
        duration: 0.15,
        ease: ease ? "back.out" : undefined,
        onUpdate: () => {
          gsap.set(activeItem.dom.element, {
            x: activeItem.translate.x,
            y: activeItem.translate.y
          })
        },
        onComplete: () => {
          logCalendar("complete drag tween")
          gsap.set(activeItem.dom.element, { zIndex: "auto" })
        }
      })
      tl.play()
    } else if (this.planItemState.isResizing && this.planItemState.activeItem) {
      // TODO: Implement resizing
    } else {
      this.resetPlanEntryItemState()
    }
  }

  private onDragHandleMouseDown = (e: MouseEvent): void => {
    const resizeDirection: ResizeDirection = (e.currentTarget as HTMLDivElement)!.dataset.dragHandle as ResizeDirection
    e.stopPropagation()
    logCalendar("onDragHandleMouseDown(): resizeDirection", resizeDirection)
    const container = (e.target as HTMLDivElement).closest("[data-planentry]") as HTMLDivElement
    if (container) {
      const id = container.dataset.planentry as string
      if (id) {
        const planEntry = this.planItems.find(a => a.data.uuid === id)
        if (planEntry) {
          this.minDragTranslate.x = this.getTodayLeft() - planEntry.position.left
          this.planItemState.isResizing = true
          this.planItemState.resizeDirection = resizeDirection
          this.planItemState.activeItem = planEntry
          this.planItemState.isClicked = true
          this.prevPlanEntryMousePosition = {
            x: e.clientX,
            y: e.clientY
          }

          gsap.set(this.planItemState.activeItem.dom.element, { zIndex: 1 })
        }
      }
    }
  }

  private calculateActiveWeek(): void {
    const selectedDay = dayjs(this.initialMonth?.date).add(- Math.round((this.position.x - 300 - ((this.window.width - 300) / 2)) / dayWidth), "day").weekday(0).toDate()
    this.setActiveWeek(selectedDay)
  }

  private updatePlanItemSize(options: UpdateSizeOptions): { updateWidth: boolean, updateHeight: boolean } {
    if (this.planItemState.activeItem) {
      const { width = 0, height = 0, reset = true } = options || {}
      const newWidth = (reset ? 0 : this.planItemState.activeItem.sizes.width) + width
      const newHeight = (reset ? 0 : this.planItemState.activeItem.sizes.height) + height

      this.planItemState.activeItem.updateSize({
        width: Math.max(newWidth, dayWidth),
        height: Math.max(newHeight, rowHeight)
      })

      return {
        updateWidth: newWidth > dayWidth,
        updateHeight: newHeight > rowHeight
      }
    }
    return { updateWidth: false, updateHeight: false }
  }

  private updatePlanItemPosition(options: UpdatePositionOptions): void {
    if (this.planItemState.activeItem) {
      const { x = 0, y = 0, reset = false } = options || {}
      const newX = (reset ? 0 : this.planItemState.activeItem.translate.x) + x

      this.planItemState.activeItem.updateTranslate({
        x: newX, // Math.max(newX, this.minDragTranslate.x),
        y: (reset ? 0 : this.planItemState.activeItem.translate.y) + y
      })
    }
  }

  /**
   * Animate plan entry movement to position
   * @param planItem - TODO
   * @param x - x position in units
   * @param y - y position in units
   * @private
   * @ignore
   */
  private animateToPosition({ planItem, x, y }: {
    planItem: PlanEntryItem
    x: Units
    y: Units
  }): gsap.core.Tween {
    // TODO: Implement
    const newPos = this.getXYPxFromPlanEntry(planItem.data)

    return gsap.to(planItem.translate, {
      y: newPos.y,
      duration: 0.15,
      onUpdate: () => {
        gsap.set(planItem.dom.element, {
          y: planItem.translate.y
        })
      },
      onComplete: () => {
        logCalendar("complete move tween")
      }
    }) as gsap.core.Tween
  }

  // AUTOSCROLL
  private shouldUpdateDragX(x: number): boolean {
    if (this.autoscroll.isAutoscrolling.x) {
      if (
        (this.autoscroll.direction.x < 0 && x <= this.prevPlanEntryMousePosition.x) ||
        (this.autoscroll.direction.x > 0 && x >= this.prevPlanEntryMousePosition.x)
      ) {
        return false
      }
    }
    return true
  }
  private shouldUpdateDragY(y: number): boolean {
    if (this.autoscroll.isAutoscrolling.y) {
      if (
        (this.autoscroll.direction.y < 0 && y <= this.prevPlanEntryMousePosition.y) ||
        (this.autoscroll.direction.y > 0 && y >= this.prevPlanEntryMousePosition.y)
      ) {
        return false
      }
    }
    return true
  }
  private checkAutoscroll({ y }: Translate): void {
    if (this.planItemState.activeItem && this.planItemState.isDragging) {
      const left = this.planItemState.activeItem.position.left + this.planItemState.activeItem.translate.x
      // logCalendar("auction left", left)
      if (left > this.today?.left!) {
        if (this.prevPlanEntryMousePosition.x <= this.autoscroll.leftThreshold) {
          // logCalendar("passed left threshold")
          this.startHorizontalAutoscroll(-1)
        } else if (this.prevPlanEntryMousePosition.x >= this.autoscroll.rightThreshold) {
          // logCalendar("passed right threshold")
          this.startHorizontalAutoscroll(1)
        } else {
          this.stopHorizontalAutoscroll()
        }
      }

      if (
        y < this.header.top + this.header.height + autoscrollVerticalThreshold &&
        this.position.y < this.calendarYBounds.max
      ) {
        this.startVerticalAutoscroll(-1)
      } else if (
        y > this.window.height - autoscrollVerticalThreshold &&
        this.position.y > this.calendarYBounds.min
      ) {
        this.startVerticalAutoscroll(1)
      } else {
        this.stopVerticalAutoscroll()
      }
    }
  }
  private startHorizontalAutoscroll(direction: 1 | -1): void {
    if (!this.autoscroll.tweens.x && this.planItemState.activeItem) {
      // logCalendar("startHorizontalAutoscroll")
      // logCalendar("autoscroll direction", direction)

      this.autoscroll.direction.x = direction
      this.autoscroll.isAutoscrolling.x = true
      this.updateHorizontalAutoscroll()
    }
  }
  private updateHorizontalAutoscroll(): void {
    if (this.planItemState.activeItem) {
      const draggedItem = this.planItemState.activeItem
      const x = gsap.utils.clamp(0, autoscrollSpeed, Math.abs(this.minDragTranslate.x - draggedItem.translate.x))
      const draggedX = draggedItem.translate.x + x * this.autoscroll.direction.x
      const calendarX = this.position.x + x * -this.autoscroll.direction.x

      this.autoscroll.tweens.x = gsap.timeline({
        onUpdate: () => {
          this.updateCalendarTranslate()
          draggedItem.updateTransform()
        },
        onComplete: () => {
          if (x < autoscrollSpeed) {
            this.stopHorizontalAutoscroll()
          } else {
            this.updateHorizontalAutoscroll()
          }
        }
      })
        .to(this.position, {
          x: calendarX,
          duration: 0.05
        })
        .to(draggedItem.translate, {
          x: draggedX,
          duration: 0.05
        }, 0)
    }
  }
  private stopHorizontalAutoscroll(): void {
    if (this.autoscroll.tweens.x) {
      this.autoscroll.tweens.x.kill()
      this.autoscroll.tweens.x = null
      this.autoscroll.isAutoscrolling.x = false
      logCalendar("stopHorizontalAutoscroll pos", this.position.x)
    }
  }
  private startVerticalAutoscroll(direction: 1 | -1): void {
    if (!this.autoscroll.tweens.y && this.planItemState.activeItem) {
      // logCalendar("startVerticalAutoscroll")
      // logCalendar("autoscroll direction", direction)
      // logCalendar("autoscroll initial positions", this.position.y, this.auctionPosition.y)

      this.autoscroll.direction.y = direction
      this.autoscroll.isAutoscrolling.y = true
      this.updateVerticalAutoscroll()
    }
  }
  private updateVerticalAutoscroll(): void {
    if (this.planItemState.activeItem) {
      const draggedItem = this.planItemState.activeItem
      const y = gsap.utils.clamp(
        0,
        autoscrollSpeed,
        Math.abs(this.calendarYBounds[this.autoscroll.direction.y < 0 ? "max" : "min"] - this.position.y)
      )
      const draggedY = draggedItem.translate.y + y * this.autoscroll.direction.y
      const calendarY = this.position.y + y * -this.autoscroll.direction.y
      // logCalendar("autoscroll y", y)

      this.autoscroll.tweens.y = gsap.timeline({
        onUpdate: () => {
          this.updateCalendarTranslate()
          draggedItem.updateTransform()
        },
        onComplete: () => {
          if (Math.abs(y) !== autoscrollSpeed) {
            this.stopVerticalAutoscroll()
          } else {
            this.updateVerticalAutoscroll()
          }
        }
      })
        .to(this.position, {
          y: calendarY,
          duration: 0.05
        })
        .to(draggedItem.translate, {
          y: draggedY,
          duration: 0.05
        }, 0)
    }
  }
  private stopVerticalAutoscroll(): void {
    if (this.autoscroll.tweens.y) {
      this.autoscroll.tweens.y.kill()
      this.autoscroll.tweens.y = null
      this.autoscroll.isAutoscrolling.y = false
      // logCalendar("stopVerticalAutoscroll pos", this.position.y)
    }
  }

  // CALENDAR LISTENERS
  private updateWindow(): void {
    this.window.width = window.innerWidth
    this.window.height = window.innerHeight

    if (this.container.element && this.wrapper.element && this.header.element) {
      this.container.height = this.container.element.clientHeight
      this.container.width = this.container.element.clientWidth
      this.header.height = this.header.element.clientHeight
      this.header.top = this.header.element.getBoundingClientRect().top
      this.wrapper.height = this.wrapper.element.clientHeight

      this.calendarYBounds.min = this.container.height - this.wrapper.height - (this.header.height || 94)
      this.updatePosition({})
    }
  }
  private onResize = (): void => {
    logCalendar("resize")
    this.updateWindow()
    this.updateThresholds()
    this.updateAutoscrollThresholds()
  }
  private onWheel = (e: WheelEvent): void => {
    if ((this.sidebar.element as HTMLDivElement).contains(e.target as HTMLDivElement)) {
      return
    }
    e.preventDefault()
    const x = Math.abs(e.deltaX)
    const y = Math.abs(e.deltaY)
    const d = Math.abs(x - y)
    this.updatePosition({
      x: (x > y || d < wheelThreshold)
        ? -e.deltaX
        : 0,
      y: (y > x || d < wheelThreshold)
        ? -e.deltaY
        : 0
    })

    this.checkThresholds()
  }
  private onMouseDown = (e: MouseEvent): void => {
    if (e.button === 2) {
      return
    } else {
      const menu = (e.target as HTMLDivElement).closest(".new-planner__context-menu") as HTMLDivElement
      if (!menu) {
        this.closeEditor(true)
      }
    }

    this.calendarState.isClicked = true
    this.prevMousePosition = {
      x: e.clientX,
      y: e.clientY
    }
  }
  private onTouchStart = (e: TouchEvent): void => {
    this.calendarState.isClicked = true
    this.prevMousePosition = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    }
  }
  private onMouseMove = (e: MouseEvent): void => {
    this.mousePosition = { x: e.clientX, y: e.clientY }
    if (this.planEditorData.open) {
      this.calendarState.isClicked = false
      this.calendarState.isDragging = false

      this.prevMousePosition = { x: 0, y: 0 }
      return
    }
    if (this.planItemState.isResizing && this.planItemState.activeItem) {
      // this.planItemState.activeItem.handle(e)
      // this.onPlanEntryResize(e)
      this.onPlanEntryMouseMove(e)
      return
    }
    if (this.planItemState.activeItem) {
      this.onPlanEntryMouseMove(e)
      return
    }

    if (this.calendarState.isClicked) {
      this.calendarState.isDragging = true
    }

    if (this.calendarState.isDragging) {
      this.updatePosition({
        x: e.clientX - this.prevMousePosition.x,
        y: e.clientY - this.prevMousePosition.y
      })
      this.prevMousePosition = {
        x: e.clientX,
        y: e.clientY
      }

      this.checkThresholds()
    }
  }
  private onTouchMove = (e: TouchEvent): void => {
    if (this.calendarState.isClicked) {
      this.calendarState.isDragging = true
    }

    if (this.calendarState.isDragging) {
      this.updatePosition({
        x: e.touches[0].clientX - this.prevMousePosition.x,
        y: e.touches[0].clientY - this.prevMousePosition.y
      })
      this.prevMousePosition = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      }

      this.checkThresholds()
    }
  }
  private onMouseUp = (): void => {
    // if (this.phaseState.draggedItem) {
    //   this.phaseState.draggedItem.handleMouseUp()
    //   return
    // }
    if (this.planItemState.activeItem) {
      this.onPlanEntryMouseUp()
      return
    }

    this.calendarState.isClicked = false
    this.calendarState.isDragging = false

    this.prevMousePosition = { x: 0, y: 0 }
  }
  private onTouchEnd = (): void => {
    this.calendarState.isClicked = false
    this.calendarState.isDragging = false

    this.prevMousePosition = { x: 0, y: 0 }
  }
  private updatePosition(options: UpdatePositionOptions): void {
    const { x = 0, y = 0, reset = false } = options || {}
    this.calculateActiveWeek()

    this.position = {
      x: (reset ? 0 : this.position.x) + x,
      y: gsap.utils.clamp(this.calendarYBounds.min, this.calendarYBounds.max, (reset ? 0 : this.position.y) + y)
    }
    this.updateCalendarTranslate()
  }
  private updateCalendarTranslate(): void {
    gsap.set(this.wrapper.element, {
      x: this.position.x,
      y: this.position.y
    })
    gsap.set(this.header.element, {
      x: this.position.x
    })
    gsap.set(this.sidebar.element, {
      y: this.position.y - 1
    })
    this.updateMonthHeader()
  }

  // CALENDAR HELPERS
  private getLeftThreshold(): Units {
    return (this.months[0].left + this.months[0].days * 0.5) as Units
  }
  private getRightThreshold(): Units {
    // @ts-ignore
    const last = (this.months as Array<MonthConfig>).at(-1)!
    return (last.left + last.days * 0.5) - getUnitPosition(this.window.width)
  }
  private updateThresholds(): void {
    this.leftThreshold = this.getLeftThreshold()
    this.rightThreshold = this.getRightThreshold()
    logCalendar("thresholds updated")
    logCalendar("leftThreshold", this.leftThreshold)
    logCalendar("rightThreshold", this.rightThreshold)
  }
  private checkThresholds(updateReact: boolean = true): void {
    this.getMonthThresholds()

    while (-this.position.x <= getPxPosition(this.leftThreshold)) {
      const month = getAdjacentMonth(this.months[0], -1)
      this.months.unshift(month)
      logCalendar("passed left threshold", this.months)
      this.updateThresholds()
      if (updateReact) {
        this.updateReactMonths()
      }
    }
    while (-this.position.x >= getPxPosition(this.rightThreshold)) {
      // @ts-ignore
      const month = getAdjacentMonth(this.months.at(-1)!, 1)
      this.months.push(month)
      logCalendar("passed right threshold", this.months)
      this.updateThresholds()
      if (updateReact) {
        this.updateReactMonths()
      }
    }
  }

  updateMonthHeaderData(): void {
    if (!this.currentMonth) {
      logCalendar("NO CURRENT MONTH")
      return
    }
    const prev = getAdjacentMonth(this.currentMonth.month, -1)
    const next = getAdjacentMonth(this.currentMonth.month, 1)

    const currentDiv = this.monthDivs[`${this.currentMonth.month.year}-${this.currentMonth.month.index}`]
    const prevDiv = this.monthDivs[`${prev.year}-${prev.index}`]
    const nextDiv = this.monthDivs[`${next.year}-${next.index}`]

    if (prevDiv) {
      this.monthHeaderData.prevDiv = prevDiv
      this.monthHeaderData.prevBlockWidth = prevDiv.getBoundingClientRect().width
      const textDiv = prevDiv.querySelector(".new-planner__month-name")
      this.monthHeaderData.prevTextWidth = textDiv?.getBoundingClientRect()?.width || 150
    }
    if (currentDiv) {
      this.monthHeaderData.currentDiv = currentDiv
      const currentTextDiv = currentDiv.querySelector(".new-planner__month-name")
      this.monthHeaderData.currentTextWidth = currentTextDiv?.getBoundingClientRect()?.width || 150
      this.monthHeaderData.currentBlockWidth = this.currentMonth.right - this.currentMonth.left
    }
    if (nextDiv) {
      this.monthHeaderData.nextDiv = nextDiv
      this.monthHeaderData.nextBlockWidth = nextDiv.getBoundingClientRect().width
      const textDiv = nextDiv.querySelector(".new-planner__month-name")
      this.monthHeaderData.nextTextWidth = textDiv?.getBoundingClientRect()?.width || 150
    }
  }

  private async handleMonthLoading(month: MonthConfig, { setCurrent, addAdjacents, skipCheck }: {
    setCurrent?: boolean
    addAdjacents?: boolean
    skipCheck?: boolean
  } = { setCurrent: true, addAdjacents: false, skipCheck: false }): Promise<void> {
    if ((this.currentMonth?.month.year === month.year && this.currentMonth?.month.index === month.index) && !skipCheck) {
      return
    }
    const prev = getAdjacentMonth(month, -1)
    const next = getAdjacentMonth(month, 1)
    logCalendar("handleMonthLoading", month)

    if (setCurrent) {
      this.currentMonth = { month, left: getPxPosition(month.left), right: getPxPosition(month.left + month.days) }
      this.updateMonthHeaderData()
    }
    await this.addMonth(month, setCurrent)
    if (addAdjacents) {
      await Promise.all([
        this.handleMonthLoading(prev, { setCurrent: false }),
        this.handleMonthLoading(next, { setCurrent: false })
      ])
    }

    this.updateMonthHeader()
  }

  private getMonthThresholds(): void {
    const currentPos = this.getAdjustedMiddlePosition()
    for (let i = 0; i < this.months.length; i++) {
      const month = this.months[i]
      const left = getPxPosition(month.left)
      const right = getPxPosition(month.left + month.days)
      if (currentPos >= left && currentPos <= right) {
        void this.handleMonthLoading(month, { addAdjacents: true, setCurrent: true })
        break
      }
    }
  }

  updateMonthHeader(): void {
    if (!this.currentMonth) {
      return
    }

    if (this.monthHeaderData.prevDiv) {
      gsap.set(this.monthHeaderData.prevDiv, {
        translateX: this.monthHeaderData.prevBlockWidth / 2 - this.monthHeaderData.prevTextWidth / 2 - 30
      })
    }
    if (this.monthHeaderData.currentDiv) {
      const textWidth = this.monthHeaderData.currentTextWidth
      const blockWidth = this.monthHeaderData.currentBlockWidth
      const percent = ((this.getAdjustedMiddlePosition() - textWidth / 2) - this.currentMonth.left) / blockWidth
      const amount = Math.min(
        Math.max(
          (percent - .5) * blockWidth,
          - blockWidth / 2 + textWidth / 2 + 30
        ),
        blockWidth / 2 - textWidth / 2 - 30
      )

      gsap.set(this.monthHeaderData.currentDiv, { translateX: amount })
    }
    if (this.monthHeaderData.nextDiv) {
      gsap.set(this.monthHeaderData.nextDiv, {
        translateX: - this.monthHeaderData.nextBlockWidth / 2 + this.monthHeaderData.nextTextWidth / 2 + 30
      })
    }
  }

  private updateThreeMonths(): void {
    if (!this.currentMonth) {
      return
    }
    const prevKey = getMonthKey(getAdjacentMonth(this.currentMonth?.month, -1))
    const currKey = getMonthKey(this.currentMonth.month)
    const nextKey = getMonthKey(getAdjacentMonth(this.currentMonth?.month, 1))

    const newItems = [
      this.visitedMonths[prevKey],
      this.visitedMonths[currKey],
      this.visitedMonths[nextKey]
    ].flat().reduce((acc, item) => {
      if (item?.uuid !== undefined) {
        acc[item.uuid] = item
      }
      return acc
    }, {} as Record<string, CalendarEntry>)

    this.onReactPlanEntryUpdate(Object.values(newItems))
  }

  private async addMonth(month: MonthConfig, isCurrent: boolean = true): Promise<void> {
    logCalendar(`addMonth called w/ month ${month.year}-${month.index}`)

    if (!(`${month.year}` in this.visitedYears)) {
      this.visitedYears[`${month.year}`] = true
      void loadHolidayData(month.year).then(holidays => {
        this.updateHolidays(holidays)
      })
    }

    const key = getMonthKey(month)
    if (!(key in this.visitedMonths)) {
      try {
        const data = await toast.promise(handleApiCall(getMonthPlan, {
          year: month.date.getFullYear(),
          month: month.date.getMonth() + 1
        }), {
          error: "Failed to load month data"
        })
        this.visitedMonths[key] = (data as PlanEntry[])
          .filter(plan => !!this.userMapping[plan.userId])
          .map(item => ({ ...item, uuid: uuidv4() }))
      } catch (ex) {
        handleException(ex as Error)
      }
    }

    if (isCurrent) {
      this.updateThreeMonths()
    }
  }

  // AUCTION HELPERS
  private updateAutoscrollThresholds(): void {
    logCalendar("updateAutoscrollThresholds")
    this.autoscroll.leftThreshold = getPxPosition(autoscrollHorizontalThreshold) + 300
    this.autoscroll.rightThreshold = this.window.width - getPxPosition(autoscrollHorizontalThreshold)
    logCalendar("autoscrollLeftThreshold", this.autoscroll.leftThreshold)
    logCalendar("autoscrollRightThreshold", this.autoscroll.rightThreshold)
  }

  // GENERAL HELPERS
  private getTodayLeft(): number {
    logCalendar("", this.initialMonth?.left)
    return getPxPosition((this.initialMonth?.left || 0) + (this.today?.day || 1) - 1) as number
  }
}


type PlanEntryState = {
  draggable: boolean
}
type PlanEntryDOM = {
  element: HTMLDivElement
  dragHandleElement: HTMLDivElement
}
type PlanEntryConstants = Record<string, never> // {}
type PlanEntryInitOptions = {
  calendar: CalendarAnimator
  planEntry: CalendarEntry
}
class PlanEntryItem {
  calendar: CalendarAnimator
  data: CalendarEntry
  constants: PlanEntryConstants = {}
  state: PlanEntryState = {
    draggable: false
  }
  dom: PlanEntryDOM = {
    element: null!,
    dragHandleElement: null!
  }
  sizes: Sizes = { width: 0, height: 0 }
  position: Position = { top: 0, left: 0 }
  translate: Translate = { x: 0, y: 0 }

  requestAbortController: AbortController | null = null

  constructor({ planEntry, calendar }: PlanEntryInitOptions) {
    this.calendar = calendar
    this.data = planEntry

    this.init()
  }

  init(): void {
    this.dom.element = document.querySelector(`[data-planentry="${this.data.uuid}"]`) as HTMLDivElement
    if (this.dom.element) {
      this.dom.dragHandleElement = this.dom.element.querySelector("[data-drag-handle]") as HTMLDivElement

      this.state.draggable = true
      // this.state.draggable = new Date().toISOString() < this.data.subscription.startDate || this.data.draft

      const { x, y, width, height } = this.getXYInPxFromCalendar()
      this.sizes.width = width
      this.sizes.height = height
      this.position.left = x
      this.position.top = y

      gsap.set(this.dom.element, {
        width: this.sizes.width,
        height: this.sizes.height,
        top: this.position.top,
        left: this.position.left
      })
    }
  }

  getXYInUnitsFromCalendar(): { x: Units, y: Units, width: Units, height: Units } {
    return this.calendar.getXYUnitsFromPlanEntry(this.data)
  }

  getXYInPxFromCalendar(): { x: Pixels, y: Pixels, width: Pixels, height: Pixels } {
    return this.calendar.getXYPxFromPlanEntry(this.data)
  }

  updateTranslate({ x, y, updateTransform = true }: Partial<Translate> & { updateTransform?: boolean }): void {
    this.translate.x = x ?? this.translate.x
    this.translate.y = y ?? this.translate.y

    if (updateTransform) {
      this.updateTransform()
    }
  }
  updateTransform(): void {
    gsap.set(this.dom.element, {
      x: this.translate.x,
      y: this.translate.y
    })
  }

  updateSize({ width, height }: Sizes): void {
    this.sizes.width = width
    this.sizes.height = height
    logCalendar("width, height:", width, height)

    gsap.set(this.dom.element, {
      width: this.sizes.width,
      height: this.sizes.height
    })
  }

  resetPosition(): void {
    const { x, y, width, height } = this.getXYInPxFromCalendar()
    this.position.top = y
    this.position.left = x
    this.translate.x = 0
    this.translate.y = 0

    this.sizes.width = width
    this.sizes.height = height

    gsap.set(this.dom.element, {
      top: this.position.top,
      left: this.position.left,
      x: this.translate.x,
      y: this.translate.y,
      width: this.sizes.width,
      height: this.sizes.height
    })
  }

  updateData({ x, y, absoluteWidth, absoluteHeight }: { x: Pixels, y: Pixels, absoluteWidth?: Units, absoluteHeight?: Units }): void {
    logCalendar(`updateData(${x}, ${y})`)
    const { x: xUnits, y: yUnits } = this.calendar.getUnitsFromPx({ x, y })
    const hour = (yUnits % 8) + 9
    const start = dayjs(this.calendar.initialMonth?.date).utc(true).add(xUnits, "days").set("hours", hour).set("minutes", 0).set("seconds", 0).toDate().getTime()

    let stop: number, dailyHours: number = this.data.dailyHours
    if (absoluteWidth !== undefined && absoluteHeight !== undefined) {
      dailyHours = absoluteHeight
      stop = dayjs(start).add(absoluteHeight, "hours").add(absoluteWidth, "days").toDate().getTime()
    } else {
      stop = dayjs(start).add(this.data.stop - this.data.start, "milliseconds").toDate().getTime()
    }

    const oldData = { ...this.data }

    this.data = {
      ...this.data,
      dailyHours,
      start,
      stop,
      userId: this.calendar.getUserByYUnits(yUnits).userId
    }

    void this.updateRemote(oldData)
  }

  updateContents({ customerId, projectId, taskId }: Pick<CalendarEntry, "customerId" | "projectId" | "taskId">): void {
    this.data = {
      ...this.data,
      customerId,
      projectId,
      taskId
    }

    void this.updateRemote()
  }

  async updateRemote(oldData?: CalendarEntry): Promise<void> {
    logCalendar("updateRemote for planEntry with uuid:", this.data.uuid)
    if (this.requestAbortController) {
      this.requestAbortController.abort()
      logCalendar("updateRemote canceled previous request")
    }
    try {
      this.requestAbortController = new AbortController()
      const data = await toast.promise(handleApiCall(updatePlanEntry, {
        id: this.data.id,
        customerId: this.data.customerId,
        projectId: this.data.projectId,
        taskId: this.data.taskId,
        userId: this.data.userId,
        start: this.data.start,
        stop: this.data.stop,
        dailyHours: this.data.dailyHours
      }, {
        signal: this.requestAbortController.signal
      }), {
        error: "Error updating plan entry"
      })

      this.data.id = (data as UpdatePlanEntry200ResponseSchema).data.id

      document.dispatchEvent(new Event("timeEntriesChanged"))

      const monthKey = getMonthKey(this.data.start)
      const idx = this.calendar.visitedMonths[monthKey].findIndex((entry) => entry.uuid === this.data.uuid)
      if (idx !== -1) {
        this.calendar.visitedMonths[monthKey][idx] = this.data
      } else {
        this.calendar.visitedMonths[monthKey].push(this.data)
      }

      if (oldData) {
        const monthKey = getMonthKey(oldData.start)
        const idx = this.calendar.visitedMonths[monthKey].findIndex((entry) => entry.id === oldData.id)
        if (idx !== -1) {
          this.calendar.visitedMonths[monthKey].splice(idx, 1)
        }
      }

      this.calendar.updateReactPlanEntries()
      void this.calendar.downloadPlanReport()
    } catch (ex) {
      handleException(ex as Error)
      this.calendar.resetPlannerEntries()
      logCalendar("updateRemote error:", ex)
    }
  }
}
