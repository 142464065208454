export const baseTimeEntry = {
  id: null,
  userId: "",
  customerId: null,
  projectId: null,
  taskId: null,
  pricePerHour: 50,
  start: null,
  description: null,
  tags: [],
  generated: false
}