import { FunctionComponent, useEffect, useRef, useState } from "react"
import { Button, Card, Form, InputGroup, Modal } from "react-bootstrap"
import { FieldError, useForm } from "react-hook-form"
import { isValidColor } from "../helpers/utils"
import { Project } from "@polarity-dev/minosse-api-sdk"
import { toast } from "react-toastify"
import { BsBoxArrowInDown, BsClipboard, BsTrashFill } from "react-icons/bs"
import { MdSave } from "react-icons/md"
import CustomerSimpleDropdown from "./CustomerSimpleDropdown"

type ProjectFormProps = {
  project?: Project
  show: boolean
  close: () => void
  title: string
  onSubmit: (data: Project) => Promise<boolean>
  onDelete: () => Promise<void>
  onArchival: () => Promise<void>
}

const ProjectForm: FunctionComponent<ProjectFormProps> = ({
  project,
  show,
  close,
  title,
  onSubmit,
  onDelete,
  onArchival
}) => {
  const { register, handleSubmit, formState: { errors, isDirty }, setValue, unregister, watch, clearErrors, setError, getValues } = useForm({
    defaultValues: {
      active: true,
      ...project && {
        customerId: project.customerId,
        projectName: project.projectName,
        recurrence: project.recurrence || "none",
        budget: project.budget,
        color: project.color ?? ""
      }
    }
  })

  const projectColor = watch("color")
  const customerId = watch("customerId")

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onFormSubmit = async(data: Project): Promise<boolean> => {
    // @ts-expect-error the openapi definition for a `Project` doesn't include "none" as a possible .recurrence value, so we have to tell TS we're expecting this line to error out
    if (data.recurrence === "none") {
      data.recurrence = null
    }

    if (!data.customerId) {
      setError("customerId", { type: "required", message: "Please select a customer" })
      return false
    }

    if (data.color === "") {
      data.color = "#000000"
    }

    if (!project) {
      data.tasks = []
    }

    data.projectName = data.projectName.trim()

    setIsLoading(true)
    const result = await onSubmit(data)
    setIsLoading(false)

    if (result) {
      close()
    }
    return true
  }

  const modalRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!modalRef.current) {
      return
    }

    const handler = (): void => {
      if (document.activeElement?.classList.contains("blur-on-scroll")) {
        (document.activeElement as HTMLInputElement).blur()
      }
    }

    modalRef.current.addEventListener("scroll", handler)

    return (): void => {
      if (modalRef.current) {
        modalRef.current.removeEventListener("scroll", handler)
      }
    }
  }, [])

  const [budgetType, setBudgetType] = useState<"price" | "hour">(project?.budget.hasOwnProperty("totalPrice") ? "price" : "hour")
  useEffect(() => {
    if (budgetType === "price") {
      unregister(["budget.pricePerHour", "budget.totalHours"])
    } else {
      unregister("budget.totalPrice")
    }
  }, [budgetType])


  if (!show) {
    return null
  }

  return (
    <Modal
      show = { show }
      centered
      scrollable
      onHide = {(): void => {
        if (isLoading) {
          return
        }

        if (
          isDirty
          || project?.customerId !== getValues("customerId")
          || project?.color !== projectColor
        ) {
          if (confirm("Unsaved changes. Are you sure you want to close?")) {
            close()
          }
        } else {
          close()
        }

      }}
      className = { "project-editor" }
      keyboard = { false }
    >
      <Modal.Header closeButton>
        <Modal.Title>{ title }</Modal.Title>
      </Modal.Header>
      <Modal.Body ref = { modalRef }>
        <Form autoComplete="off">
          {project && <Form.Group>
            <Form.Label>Project ID</Form.Label>
            <InputGroup>
              <Form.Control
                type={"text"}
                value={project?.projectId || ""}
                disabled
                className={"input__disabled-lighter"}
              />
              <BsClipboard className = { "reports__clear-icon cursor-pointer" } onClick={(): void => {
                void navigator.clipboard.writeText(project?.projectId || "")
                toast.info("Copied to clipboard")
              }} />
            </InputGroup>
          </Form.Group>}
          <Form.Group>
            <Form.Label>Project name</Form.Label>
            <Form.Control
              type = { "text" }
              placeholder = { "Add project name..." }
              isInvalid = { !!errors.projectName }
              { ...register("projectName", {
                required: true,
                onBlur: (event): void => {
                  event.target.value = event.target.value.trim()
                },
                validate: value => (value && value.trim().length > 0)
              }) }
            />
            <Form.Control.Feedback type = { "invalid" }>
              Please choose a project name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Customer name</Form.Label>
            <CustomerSimpleDropdown
              customerId={customerId ?? null}
              updateCustomerId={(customerId): void => {
                setValue("customerId", customerId)
                clearErrors("customerId")
              }}
              isInvalid={!!errors.customerId}
            />
            {!!errors.customerId && <div className="small text-danger mt-1">
              Please choose a customer name.
            </div>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Recurrence</Form.Label>
            <Form.Select { ...register("recurrence") }>
              <option value = { "none" }>None</option>
              <option value = { "weekly" }>Weekly</option>
              <option value = { "monthly" }>Monthly</option>
              <option value = { "yearly" }>Yearly</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Color</Form.Label>
            <InputGroup>
              <Form.Control
                type = { "text" }
                placeholder = { "Add a color..." }
                isInvalid = { !!errors.color }
                { ...register("color", { required: false, validate: value => value === "" || isValidColor(value!) }) }
              />
              <Form.Control
                type = { "color" }
                className={"project-form__color-picker"}
                onChange={(event): void => {
                  setValue("color", event.target.value)
                  clearErrors("color")
                }}
                value={projectColor}
              />
            </InputGroup>
          </Form.Group>
          <Form.Control.Feedback type = { "invalid" }>
            Invalid color
          </Form.Control.Feedback>
          <Form.Group>
            <Form.Label>BUDGET</Form.Label>
            <Card className = { "budget p-2" }>
              <Form.Select onChange = { (event): void => setBudgetType(event.target.value as "hour" | "price") } defaultValue = { budgetType } className={"mb-3"}>
                <option value = { "hour" }>Hour budget</option>
                <option value = { "price" }>Price budget</option>
              </Form.Select>
              {
                budgetType === "price" ? <>
                  <InputGroup className={"mb-2"}>
                    <InputGroup.Text>Total hours</InputGroup.Text>
                    <Form.Control
                      type="number"
                      defaultValue={"0"}
                      min={0}
                      isInvalid={!!(errors.budget as { totalHours?: FieldError })?.totalHours}
                      {...register("budget.totalHours", { valueAsNumber: true, required: true })}
                    />
                    <Form.Control.Feedback type = { "invalid" }>
                      Please specify total project hours.
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup>
                    <InputGroup.Text>Total price</InputGroup.Text>
                    <Form.Control
                      type = { "number" }
                      defaultValue = { "0" }
                      min = { 0 }
                      isInvalid={!!(errors.budget as { totalPrice?: FieldError })?.totalPrice}
                      { ...register("budget.totalPrice", { valueAsNumber: true, required: true }) }
                    />
                    <Form.Control.Feedback type = { "invalid" }>
                      Please specify total project price.
                    </Form.Control.Feedback>
                  </InputGroup>
                </> : <>
                  <InputGroup className={"mb-2"}>
                    <InputGroup.Text>Total hours</InputGroup.Text>
                    <Form.Control
                      type="number"
                      defaultValue={"0"}
                      min={0}
                      isInvalid={!!(errors.budget as { totalHours?: FieldError })?.totalHours}
                      {...register("budget.totalHours", { valueAsNumber: true, required: true })}
                    />
                    <Form.Control.Feedback type = { "invalid" }>
                      Please specify total project hours.
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup>
                    <InputGroup.Text>Price per hour</InputGroup.Text>
                    <Form.Control
                      type="number"
                      defaultValue={"100"}
                      min={0}
                      isInvalid={!!(errors.budget as { pricePerHour?: FieldError })?.pricePerHour}
                      {...register("budget.pricePerHour", { valueAsNumber: true, required: true })}
                    />
                    <Form.Control.Feedback type = { "invalid" }>
                      Please specify a price per hour.
                    </Form.Control.Feedback>
                  </InputGroup>
                </>
              }
            </Card>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {
          !!project &&
            <Button
              variant = { project.active ? "outline-danger" : "danger" }
              disabled = { isLoading }
              className = { "ms-auto" }
              onClick = { async(): Promise<void> => {
                if (confirm(`Are you sure you want to ${project.active ? "archive" : "delete"} this project?`)) {
                  setIsLoading(true)
                  if (project.active) {
                    await onArchival()
                  } else {
                    await onDelete()
                  }
                  setIsLoading(false)
                }
              }}
            >
              { project.active ? <BsBoxArrowInDown className={"iconfix me-2"} /> : <BsTrashFill className={"iconfix me-2 iconfix--translate1"} /> }
              <span>{ project.active ? "Archive" : "Delete" }</span>
            </Button>
        }
        <Button variant = { "primary" } disabled = { isLoading } type = { "submit" } onClick = { handleSubmit(onFormSubmit) }>
          <MdSave className={"iconfix iconfix--translate1 me-2"} />Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProjectForm