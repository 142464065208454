import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import AuthContext from "../contexts/AuthContext"
import { MinosseContext } from "../contexts/MinosseContext"
import classNames from "classnames"
import loading from "../assets/loading.gif"
import PageTemplate from "../components/PageTemplate"
import { Button, Card, Dropdown } from "react-bootstrap"
import { capitalizeFirst, Mapping } from "../helpers/utils"
import StatisticsCalendar from "../components/StatisticsCalendar"
import { DAY, MINUTE } from "../helpers/time"
import {
  ListTickets200ResponseSchema,
  ListTicketsRequestSchema,
  Ticket,
  TicketPriority,
  TicketsService,
  TicketStatus
} from "../sdk/minosse-ticketing-api"
import { toast } from "react-toastify"
import { handleException, logError, logInfo } from "../helpers/logger"
import {
  Customer,
  CustomerUser,
  CustomerUserHandler,
  CustomerUsersService,
  StaffUser
} from "../sdk/minosse-principals-api"
import Icon from "../components/Icon"
import ListFilter from "../components/ListFilter"
import TicketModal from "../components/tickets/TicketModal"
import NewTicketModal from "../components/tickets/NewTicketModal"
import TicketTimedText from "../components/tickets/TicketTimedText"
import { getPriorityName, SelectedTicketData } from "../helpers/tickets"
import { BsFillExclamationTriangleFill } from "react-icons/bs"

export const getColorFromStatus = (status: TicketStatus = "SOLVED"): string => {
  return {
    OPEN: "primary",
    PENDING_FIRST_INTERACTION: "danger",
    PENDING_CUSTOMER: "primary",
    WORK_IN_PROGRESS: "warning",
    PENDING_STAFF: "danger",
    SOLVED: "success",
    UNASSIGNED: "danger",
    IDLE: "orange"
  }[status] || "success" as string
}

export const getColorFromPriority = (priority: TicketPriority = "LOW"): string => {
  return {
    LOW: "success",
    MEDIUM: "orange",
    HIGH: "danger",
    CRITICAL: "danger"
  }[priority] || "success" as string
}

export const FILTERS_LS_KEY = "tickets.filters"

const Tickets: FunctionComponent = () => {
  const { user, initialized } = useContext(AuthContext)
  const { dataReady, customerMapping, activeCustomerList, userMapping, activeUserList } = useContext(MinosseContext)

  if (initialized && (!user || !user.active)) {
    return <Navigate to = { "/login" } replace/>
  }

  const [isLoading, setIsLoading] = useState(true)
  const [shouldDarken, setShouldDarken] = useState(false)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [localStorageApplied, setLocalStorageApplied] = useState(false)

  const [customerFilter, setCustomerFilter] = useState<string | undefined>(undefined)
  const [statusFilter, setStatusFilter] = useState<TicketStatus | undefined>(undefined)
  const [assigneeFilter, setAssigneeFilter] = useState<string | undefined>(undefined)
  const [isUnresolvedFilter, setIsUnresolvedFilter] = useState<boolean>(true)
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null)
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null)
  const [priorityFilter, setPriorityFilter] = useState<TicketPriority | undefined>(undefined)

  const [customerUsers, setCustomerUsers] = useState<Mapping<CustomerUser>>()

  const [showNewTicketModal, setShowNewTicketModal] = useState(false)

  const [data, setData] = useState<Ticket[]>([])

  const [selectedTicket, setSelectedTicket] = useState<SelectedTicketData | null>(null)

  const [searchParamsInternal, setSearchParamsInternal] = useSearchParams()

  const areFiltersStock = customerFilter === undefined
    && statusFilter === undefined
    && assigneeFilter === undefined
    && isUnresolvedFilter
    && startDateFilter === null && endDateFilter === null
    && priorityFilter === undefined

  const setSearchParams = (nextInit: URLSearchParams): void => {
    // I hate using JSON.stringify, but for now it sounds like the easiest way and it's not bad performance-wise
    // @ts-ignore - Down-level iteration issues :')
    if (JSON.stringify([...searchParamsInternal.entries()]) !== JSON.stringify([...nextInit.entries()])) {
      setSearchParamsInternal(nextInit)
    }
  }

  const onDateChange = (dates: [Date | null, Date | null]): void => {
    const [start, end] = dates
    end?.setHours(23, 59, 59, 999)
    setStartDateFilter(start)
    setEndDateFilter(end)
  }

  useEffect(() => {
    if (!dataReady || filtersApplied || localStorageApplied) {
      return
    }
    let invalidateCache = false
    const searchParams = new URLSearchParams(searchParamsInternal)
    if (searchParams.has("id")) {
      const strs = searchParams.get("id")!.split("#")
      if (strs.length !== 3) {
        toast.error("Invalid ticket id")
        searchParams.delete("id")
      } else {
        setSelectedTicket({ customerId: strs[0], supportPlanId: strs[1], ticketId: strs[2] })
        // TODO: Invalidate cache if ticket is open?
        // invalidateCache = true
      }
    } else {
      if (data.length) {
        searchParams.delete("id")
        setSearchParams(searchParams)
      }
    }
    if (searchParams.has("newTicket")) {
      setShowNewTicketModal(true)
      invalidateCache = true
    }

    if (searchParams.has("customer")) {
      setCustomerFilter(searchParams.get("customer")!)
      invalidateCache = true
    }
    if (searchParams.has("status")) {
      const status = searchParams.get("status")! as Omit<TicketStatus, "WAITING" | "SOLVED">
      setStatusFilter(status as TicketStatus)
      invalidateCache = true
    }
    if (searchParams.has("assignee")) {
      setAssigneeFilter(searchParams.get("assignee")!)
      invalidateCache = true
    }
    if (searchParams.has("priority")) {
      setPriorityFilter(searchParams.get("priority")! as TicketPriority)
      invalidateCache = true
    }
    if (searchParams.has("unresolved")) {
      setIsUnresolvedFilter(searchParams.get("unresolved") === "true")
      invalidateCache = true
    }
    const startDate = parseInt(searchParams.get("start") as string)
    if (searchParams.has("start") && startDate !== startDateFilter?.getTime()) {
      setStartDateFilter(new Date(parseInt(searchParams.get("start") as string)))
      invalidateCache = true
    }
    const endDate = parseInt(searchParams.get("end") as string)
    if (searchParams.has("end") && endDate !== endDateFilter?.getTime()) {
      setEndDateFilter(new Date(parseInt(searchParams.get("end") as string)))
      invalidateCache = true
    }
    setFiltersApplied(true)
    if (invalidateCache) {
      localStorage.removeItem(FILTERS_LS_KEY)
    } else {
      const cache = localStorage.getItem(FILTERS_LS_KEY)
      if (cache) {
        const parsed = JSON.parse(cache)
        setCustomerFilter(parsed.customer)
        setStatusFilter(parsed.status)
        setAssigneeFilter(parsed.assignee)
        setIsUnresolvedFilter(parsed.unresolved)
        if (parsed.start && parsed.end) {
          setStartDateFilter(new Date(parsed.start))
          setEndDateFilter(new Date(parsed.end))
        }
        setPriorityFilter(parsed.priority)
      }
    }
    setLocalStorageApplied(true)
  }, [isLoading, dataReady, data, filtersApplied, localStorageApplied])

  useEffect(() => {
    const searchParams = new URLSearchParams(searchParamsInternal)
    if (filtersApplied) {
      if (customerFilter) {
        searchParams.set("customer", customerFilter)
      } else {
        searchParams.delete("customer")
      }
      if (statusFilter !== undefined) {
        searchParams.set("status", statusFilter)
      } else {
        searchParams.delete("status")
      }
      if (assigneeFilter) {
        searchParams.set("assignee", assigneeFilter)
      } else {
        searchParams.delete("assignee")
      }
      if (priorityFilter) {
        searchParams.set("priority", priorityFilter)
      } else {
        searchParams.delete("priority")
      }
      if (!isUnresolvedFilter) {
        searchParams.set("unresolved", "false")
      } else {
        searchParams.delete("unresolved")
      }
      if (startDateFilter && endDateFilter) {
        searchParams.set("start", `${startDateFilter.getTime()}`)
        searchParams.set("end", `${endDateFilter.getTime()}`)
      } else {
        searchParams.delete("start")
        searchParams.delete("end")
      }
      localStorage.setItem(FILTERS_LS_KEY, JSON.stringify({
        customer: customerFilter,
        status: statusFilter,
        assignee: assigneeFilter,
        priority: priorityFilter,
        unresolved: isUnresolvedFilter,
        start: startDateFilter?.getTime(),
        end: endDateFilter?.getTime()
      }))
      setSearchParams(searchParams)
    }
  }, [customerFilter, assigneeFilter, statusFilter, priorityFilter, isUnresolvedFilter, startDateFilter, endDateFilter, filtersApplied, selectedTicket])

  useEffect(() => {
    if (isLoading || !filtersApplied || !localStorageApplied) {
      return
    }

    const searchParams = new URLSearchParams(searchParamsInternal)

    if (selectedTicket) {
      searchParams.set("id", `${selectedTicket.customerId}#${selectedTicket.supportPlanId}#${selectedTicket.ticketId}`)
    } else {
      searchParams.delete("id")
    }
    setSearchParams(searchParams)
  }, [isLoading, filtersApplied, selectedTicket])

  useEffect(() => {
    if (isLoading || !filtersApplied) {
      return
    }

    const searchParams = new URLSearchParams(searchParamsInternal)

    if (showNewTicketModal) {
      searchParams.set("newTicket", "true")
    } else {
      searchParams.delete("newTicket")
    }
    setSearchParams(searchParams)
  }, [isLoading, filtersApplied, showNewTicketModal])

  const requestFilters = useMemo<ListTicketsRequestSchema>(() => {
    const req = { unresolved: isUnresolvedFilter } as ListTicketsRequestSchema

    if (isUnresolvedFilter && statusFilter !== undefined) {
      req.status = statusFilter
    }

    if (customerFilter) {
      req.customerFilter = { customerId: customerFilter }
    }

    if (assigneeFilter) {
      req.assignedStaff = { userId: assigneeFilter }
    }

    if (priorityFilter) {
      req.priority = priorityFilter
    }

    if (startDateFilter && endDateFilter) {
      req.creation = {
        start: startDateFilter!.getTime(),
        stop: endDateFilter!.getTime()
      }
    }

    return req
  }, [customerFilter, assigneeFilter, statusFilter, isUnresolvedFilter, priorityFilter, startDateFilter, endDateFilter])

  const loadData = async(type: "initial" | "ignoreToken" | "next" | "silent" = "initial"): Promise<void> => {
    setIsLoading(true)
    if (type !== "silent") {
      setShouldDarken(true)
    }
    try {
      const data: ListTickets200ResponseSchema = await TicketsService.listTickets(requestFilters)

      const handlers = Object.values(data.data.reduce((acc, ticket) => {
        if (ticket.author.customerId) {
          acc[`${ticket.author.customerId}#${ticket.author.userId}`] = {
            customerId: ticket.author.customerId,
            userId: ticket.author.userId
          }
        }

        return acc
      }, {} as Mapping<CustomerUserHandler>)) as CustomerUserHandler[]

      if (handlers.length) {
        const { data: cus } = await CustomerUsersService.mGetCustomerUsers({ handlers })
        setCustomerUsers(cus.reduce((acc, ticket) => {
          acc[`${ticket.customerId}#${ticket.userId}`] = ticket
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return acc
        }, {} as Mapping<CustomerUser>))
      }

      setData(oldData =>
        type !== "next"
          ? data.data
          : Object.values(([...oldData, ...data.data]).reduce((acc, ticket) => {
            if (!acc[ticket.ticketId]) {
              acc[ticket.ticketId] = ticket
            }
            return acc
          }, {} as Mapping<Ticket>))
      )
    } catch (ex) {
      handleException(ex as Error)
      logError(ex)
      toast.error("Error loading tickets")
    }
    setIsLoading(false)
    setShouldDarken(false)
  }

  useEffect(() => {
    if (!dataReady || !filtersApplied) {
      return
    }

    void loadData("ignoreToken")
  }, [filtersApplied, requestFilters, dataReady])

  useEffect(() => {
    if (!dataReady || selectedTicket || !filtersApplied) {
      return
    }

    void loadData("silent")
  }, [dataReady, selectedTicket, filtersApplied, localStorageApplied])

  useEffect(() => {
    const interval = setInterval(async() => {
      try {
        if (!selectedTicket) {
          logInfo("[tickets/main] Checking for new tickets...")
          const { lastUpdateTimestamp } = await TicketsService.getLastUpdateTimestamp({})
          if (data.every(ticket => lastUpdateTimestamp > ticket.lastUpdateTimestamp)) {
            logInfo("[tickets/main] New tickets! Downloading...")
            try {
              await loadData("silent")
            } catch (ex) {
              handleException(ex as Error)
              toast.error("Error updating tickets. Please reload the page to see the latest changes.")
              throw ex
            }
          } else {
            logInfo("[tickets/main] No new tickets")
          }
        }
      } catch (ex) {
        handleException(ex as Error)
        logError("[tickets/main] Unable to poll for new tickets", ex)
      }
    }, MINUTE)

    return (): void => clearInterval(interval)
  }, [data, selectedTicket])

  const maxPriorityWidth = useMemo(() => {
    const widths = {
      LOW: 162,
      MEDIUM: 150,
      HIGH: 236,
      CRITICAL: 222
    }

    return data.reduce((acc, ticket) => Math.max(widths[ticket.priority], acc), 0)
  }, [data])

  return <PageTemplate issueTag="ticketing">
    {
      <div
        className = { classNames("loading-pane", { visible: !dataReady }) }
        onTransitionEnd = { (event): void => event.currentTarget.remove() }
      >
        <img src = { loading } alt={"loading"} />
      </div>
    }

    <TicketModal
      selectedTicket={selectedTicket}
      setSelectedTicket={setSelectedTicket}
      reloadTicketList={async(): Promise<void> => await loadData("silent")}
    />

    <NewTicketModal show={showNewTicketModal} close={(): void => setShowNewTicketModal(false)} reloadTicketList={async(): Promise<void> => await loadData("silent")} />

    <div className={"position-relative max-content-width"} style={{ marginTop: "-1rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
      <div className={classNames("tickets-modal__opaque-sheet", {
        "tickets-modal__opaque-sheet--active": shouldDarken
      })} />
      <div className={"mt-3 position-relative d-flex"}>
        <span>
          <span className={"me-2 pb-2 d-inline-block"}>Filter by:</span>

          <ListFilter
            className="me-2"
            data={activeCustomerList}
            onItemClick={(item): void => {
              setCustomerFilter(item ? item.customerId : undefined)
            }}
            itemKey={(item: Customer): string => item.customerId}
            itemText={(item: Customer): string => item.customerName}
            matchKey="customerName"
          >Customer:&nbsp;<b>{customerFilter ? customerMapping[customerFilter].customerName : "All"}</b></ListFilter>

          <ListFilter
            className="me-2"
            data={activeUserList}
            onItemClick={(item): void => {
              setAssigneeFilter(item ? item.userId : undefined)
            }}
            itemKey={(item: StaffUser): string => item.userId}
            itemText={(item: StaffUser): string => item.username}
            matchKey="username"
          >Assignee:&nbsp;<b>{assigneeFilter ? userMapping[assigneeFilter].username : "All"}</b></ListFilter>

          <span className={"me-2"}>
            {
              <StatisticsCalendar
                variant="outline-dark"
                startDate = { startDateFilter }
                endDate = { endDateFilter }
                onDateChange = { onDateChange }
                showAllTime
              />
            }
          </span>

          <Dropdown className={"d-inline me-2"}>
            <Dropdown.Toggle variant="outline-dark">
              Type:&nbsp;<b>{isUnresolvedFilter ? "Unresolved" : "Resolved"}</b>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => setIsUnresolvedFilter(true)}>Unresolved</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setIsUnresolvedFilter(false)}>Resolved</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {isUnresolvedFilter && <Dropdown className={"d-inline me-2"}>
            <Dropdown.Toggle variant="outline-dark" disabled={!isUnresolvedFilter}>
              Status:&nbsp;<b>{statusFilter ? capitalizeFirst(statusFilter.replaceAll(/_/g, " ")) : "All"}</b>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => setStatusFilter(undefined)}>All</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setStatusFilter("IDLE")}>Idle</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setStatusFilter("UNASSIGNED")}>Unassigned</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setStatusFilter("PENDING_FIRST_INTERACTION")}>Pending first interaction</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setStatusFilter("PENDING_CUSTOMER")}>Pending customer</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setStatusFilter("WORK_IN_PROGRESS")}>WIP</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>}

          <Dropdown className={"d-inline me-2"}>
            <Dropdown.Toggle variant="outline-dark">
              Priority:&nbsp;<b>{priorityFilter ? getPriorityName(priorityFilter) : "All"}</b>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(): void => setPriorityFilter(undefined)}>All</Dropdown.Item>
              {(["LOW", "MEDIUM", "HIGH", "CRITICAL"] as TicketPriority[]).map(priority =>
                <Dropdown.Item key={priority} onClick={(): void => setPriorityFilter(priority)}>{getPriorityName(priority)}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>

          {!areFiltersStock && <>
            <span className={"border-start me-2"} style={{ width: 1, paddingTop: 5, paddingBottom: 8 }} />

            <Button variant="outline-dark" className={"fw-bold"} onClick={(): void => {
              setCustomerFilter(undefined)
              setAssigneeFilter(undefined)
              onDateChange([null, null])
              setIsUnresolvedFilter(true)
              setStatusFilter(undefined)
              setPriorityFilter(undefined)
            }}>
              <span className="d-flex">
                <Icon name="x-lg" className="iconfix--translate1" />&nbsp;Clear filters
              </span>
            </Button>
          </>}
        </span>

        <Button variant="primary" className={"ms-auto fw-bold h-100"} onClick={(): void => {
          setShowNewTicketModal(true)
        }}>
          <Icon name="plus" className="d-inline-block iconfix iconfix--translate1" />&nbsp;New ticket
        </Button>
      </div>

      <div>
        {data.map(ticket => {
          const statusColor = getColorFromStatus(ticket.status)
          const priorityColor = getColorFromPriority(ticket.priority)
          const title = ticket.title.slice(0, 250) + (ticket.title.length > 250 ? "..." : "")

          return <Card className={"mt-3 py-2 ps-3 pe-3 d-flex flex-row cursor-pointer align-items-center justify-content-between gap-3"} onClick={(): void => setSelectedTicket(ticket)} key={ticket.ticketId}>
            <span style={{ width: "max-content", minWidth: maxPriorityWidth }}>
              <span
                className={`w-100 fw-bold text-uppercase current-entry__selected-project tickets__priority d-inline-flex justify-content-center gap-1 text-${priorityColor}`}
                style={{ backgroundColor: `rgba(var(--bs-${priorityColor}-rgb), 0.06)`, border: `1px solid rgba(var(--bs-${priorityColor}-rgb, 0.25)` }}
              >
                {ticket.priority === "CRITICAL"
                  ? <span className={"me-1"} style={{ transform: "translateY(2px)" }}>
                    <BsFillExclamationTriangleFill fill={"var(--bs-red)"}/>
                  </span>
                  : <span className={`project-colored-dot bg-${priorityColor}`}/>}
                <span className={""}>
                  {getPriorityName(ticket.priority)}
                </span>
              </span>
            </span>
            <span className={"flex-grow-1"}>
              <div>
                <b>{customerMapping[ticket.customerId]?.customerName}</b>&nbsp;-&nbsp;{title}
              </div>

              <div className={"mt-1"}>
                Created by: <b>{ticket.author.customerId !== undefined
                  ? `${customerUsers?.[`${ticket.author.customerId}#${ticket.author.userId}`]?.username} (${customerMapping[ticket.author.customerId]?.customerName})`
                  : `${userMapping[ticket.author.userId]?.username} (Soluzioni Futura)`
                }</b>
              </div>

              {ticket.assignedStaff
                && <div className="d-flex align-items-center mt-1">Assigned to:&nbsp;<b>{userMapping[ticket.assignedStaff.userId]?.username}</b>{ticket.assignedStaff?.userId === user?.userId && <span
                  className="ms-2 border rounded text-danger border-danger"
                  style={{ fontSize: ".75rem", padding: ".15rem .3rem" }}
                >YOU</span>}</div>
              }

              {["PENDING_FIRST_INTERACTION", "UNASSIGNED"].includes(ticket.status) && ticket.slaDeadlineTimestamp
                && <div className={"mt-1"}>
                  <TicketTimedText prefix="Deadline:" deadline={ticket.slaDeadlineTimestamp} limitAt="HOUR" />
                </div>
              }

              {ticket.lastInteractionTimestamp && ticket.status !== "SOLVED" && <div className={"mt-1"}>
                <TicketTimedText prefix="Last interaction:" deadline={ticket.lastInteractionTimestamp} deadlineBuffer={DAY * 5} type="timepassed" />
              </div>}
            </span>
            <span style={{ width: "max-content" }}>
              <span
                className={`fw-bold text-uppercase current-entry__selected-project gap-1 text-${statusColor}`}
                style={{ backgroundColor: `rgba(var(--bs-${statusColor}-rgb), 0.06)`, border: `1px solid rgba(var(--bs-${statusColor}-rgb, 0.25)` }}
              >
                <span className={`project-colored-dot bg-${statusColor}`} />{capitalizeFirst(ticket.status.replaceAll(/_/g, " "))}
              </span>
            </span>
          </Card>
        })}
        {data && data.length === 0 && !isLoading && <div className={"text-center mt-3 fst-italic opacity-75"}>No tickets found</div>}
      </div>
    </div>

  </PageTemplate>
}

export default Tickets