import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { ReactSVG, WrapperType } from "react-svg"

// types
import { IconName } from "../types/icons"

type IconProps = {
  name: IconName,
  id?: string,
  onClick?: (MouseEventHandler<WrapperType> & MouseEventHandler<SVGSVGElement>),
  className?: string
}

const Icon: FunctionComponent<IconProps> = ({ name, id, onClick, className }) => (
  <ReactSVG
    id = { id }
    src = { `${process.env.PUBLIC_URL}/icons/${name}.svg` }
    className = { classNames("icon", `icon--${name}`, className) }
    onClick = { onClick }
  />
)

export default Icon
