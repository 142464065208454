import React, { useContext, useState } from "react"
import Icon from "../Icon"
import SuggestionsDropdown from "./../SuggestionsDropdown"
import { EmailHandler, UserHandler } from "../../sdk/minosse-ticketing-api"
import { Mapping } from "../../helpers/utils"
import { toast } from "react-toastify"
import { FormControl } from "react-bootstrap"
import { CC, generateRenderUserText, getCCKey } from "../../helpers/tickets"
import { MinosseContext } from "../../contexts/MinosseContext"
import { CustomerUser } from "../../sdk/minosse-principals-api"
import { MaybePromise } from "../../types"
import classNames from "classnames"


type TicketCCPickerProps = {
  setCC: (func: (prevState: CC) => CC) => MaybePromise<boolean | void>,
  customerData?: Mapping<CustomerUser>,
  permanentCCs?: CC,
  tempCCs?: CC,
  removableCCs: CC,
  disabled?: boolean,
  tempCCsText?: string,
  wrapped?: boolean
}

const TicketCCPicker: React.FunctionComponent<TicketCCPickerProps> = ({
  setCC,
  customerData,
  permanentCCs,
  tempCCs,
  removableCCs,
  disabled = false,
  tempCCsText = "If you reply to this ticket, you will be added to the recipients list",
  wrapped = true
}: TicketCCPickerProps) => {
  const { userMapping, customerMapping, activeUserList } = useContext(MinosseContext)
  const renderUserText = generateRenderUserText(userMapping, customerMapping, customerData)

  const [text, setText] = useState<string>("")
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)

  const CC = { ...permanentCCs || {}, ...tempCCs || {}, ...removableCCs || {} }

  const inner = <>
    <label>Recipients:&nbsp;</label>
    <span className={"w-100"}>
      {permanentCCs && Object.entries(permanentCCs).map(([, cc]) =>
        <span className={"d-inline-block fs-6 me-2 mb-2 border border-dark py-1 px-2 rounded"}>
          {renderUserText(cc, "email")}
        </span>
      )}
      {tempCCs && Object.entries(tempCCs).map(([, cc]) =>
        <span className={classNames("d-inline-block fs-6 me-2 mb-2 border border-dark py-1 px-2 rounded opacity-50", { "cursor-help": tempCCsText !== undefined })} title={tempCCsText}>
          {renderUserText(cc, "email")}
        </span>
      )}
      {removableCCs && Object.entries(removableCCs).map(([key, cc]) => {
        return <span className={"d-inline-block fs-6 me-2 mb-2 border border-dark py-1 px-2 rounded"}>
          <span className={"d-inline-flex flex-row align-items-center"}>
            <span className="me-1">
              {renderUserText(cc, "email")}
            </span><Icon name={"x-lg"} onClick={(): void => {
              void setCC((CC) => {
                const newCC = { ...CC }
                delete newCC[key]
                return newCC
              })
            }} />
          </span>
        </span>
      })}
      <span className={"d-block"}>
        <SuggestionsDropdown
          textboxValue={text}
          shouldShowSuggestions={showSuggestions}
          itemList={[...Object.values(customerData ?? {}), ...activeUserList].filter(item => !(getCCKey({ userId: item.userId, customerId: (item as UserHandler).customerId }) in CC))}
          itemMatchKey={"username"}
          itemTextFunction={(item): string => item.username + " (" + item.email + ")"}
          textFilterFunction={(str: string): boolean => /.+@.+\..+/i.test(str)}
          onTextSubmit={(str: string): void => {
            const emailMap = Object.values(CC).reduce((acc, cc) => {
              if ((cc as EmailHandler).email) {
                acc[(cc as EmailHandler).email] = (cc as EmailHandler).email
              } else {
                const handler = cc as UserHandler
                if (handler.customerId) {
                  const customerEmail = customerData?.[handler.userId!]?.email
                  if (customerEmail) {
                    acc[customerEmail] = customerEmail
                  }
                } else {
                  const userEmail = userMapping?.[handler.userId]?.email
                  if (userEmail) {
                    acc[userEmail] = userEmail
                  }
                }
              }

              return acc
            }, {} as Mapping<string>)

            if (str in emailMap) {
              toast.error("Email already added")
              return
            }

            void setCC(CC => {
              const newCC = { ...CC }
              newCC[getCCKey({ email: str })] = { email: str }
              return newCC
            })
            setShowSuggestions(false)
            setText("")
          }}
          onItemClick={(item): void => {
            void setCC(CC => {
              const newCC = { ...CC }
              const possibleEmailCCKey = getCCKey({ email: item.email })
              newCC[getCCKey(item)] = item
              delete newCC[possibleEmailCCKey]
              return newCC
            })

            setShowSuggestions(false)
            setText("")
          }}
        >
          <FormControl
            size="sm"
            type="text"
            className={"d-inline"}
            value={text}
            placeholder={"Add recipients..."}
            disabled={disabled}
            onFocus={(): void => {
              setText("")
              setShowSuggestions(true)
            }}
            onChange={(e): void => {
              setText(e.target.value)
              setShowSuggestions(true)
            }}
            onBlur={(): void => {
              setText("")
              setShowSuggestions(false)
            }}
          />
        </SuggestionsDropdown>
      </span>
    </span>
  </>

  if (wrapped) {
    return <div className={"d-flex flex-row align-items-baseline mb-2"}>
      {inner}
    </div>
  } else {
    return inner
  }
}

export default TicketCCPicker
