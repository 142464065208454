import { Component, FunctionComponent } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import MinosseProvider from "./contexts/MinosseContext"
import Login from "./pages/Login"
import MFA from "./pages/MFA"
import ChangePassword from "./pages/ChangePassword"
import Tracker from "./pages/Tracker"
import ResetPassword from "./pages/ResetPassword"
import NotFoundPage from "./pages/NotFoundPage"
import Reports from "./pages/Reports"
import Statistics from "./pages/Statistics"
import Entries from "./pages/Entries"
import FilteredEntries from "./pages/FilteredEntries"
import AuthContext from "./contexts/AuthContext"
import PageTemplate from "./components/PageTemplate"
import { Button } from "react-bootstrap"
import error from "./assets/error.gif"

import { registerLocale } from "react-datepicker"
import enGB from "date-fns/locale/en-GB"
import Tickets from "./pages/Tickets"
import NewPlanner from "./pages/NewPlanner"
import { reportError } from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "./helpers/api"
import { handleException } from "./helpers/logger"
registerLocale("en-GB", enGB)

const ErrorBoundary: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => <PageTemplate>
  <div className={"position-fixed w-100 h-100 error-boundary"}>
    <div className={"d-flex justify-content-center align-items-center h-100"}>
      <div className={"text-center mb-5"}>
        <img src={error}></img>
        <h1>Oops!</h1>
        <h2>Something went wrong...</h2>
        <Button onClick={onClick} variant={"outline-dark"}>Go back</Button>
      </div>
    </div>
  </div>
</PageTemplate>

type State = {
  hasError: boolean
}

class App extends Component<Record<string, never>, State> {
  static contextType = AuthContext
  lockReport = false // Avoid mail spam

  constructor(props: Record<string, never>) {
    super(props)
    this.state = {
      hasError: false
    }

    if (process.env.REACT_APP_BOUNDARY === "1") {
      this.componentDidCatch = async(error: Error): Promise<void> => {
        if (!this.lockReport) {
          this.lockReport = true

          this.setState({ hasError: true })
          if (this.context.isLogged) {
            try {
              await handleApiCall(reportError, { message: error.message, stack: error.stack || "No error stack available" })
            } catch (error) {
              handleException(error as Error)
              toast.warning("Couldn't report the error stack remotely")
            }
          }
        }
      }
    }
  }

  render(): JSX.Element {
    return <MinosseProvider>
      <BrowserRouter>
        {
          this.state.hasError ?
            <ErrorBoundary
              onClick={(): void => {
                this.setState({ hasError: false })
                this.lockReport = false
              }}
            /> : <Routes>
              <Route path = { "/" } element = { <Navigate to = { "/tracker" } replace/> }/>
              <Route path = { "/tracker" } element = { <Tracker/> }/>
              <Route path = { "/login" } element = { <Login/> }/>
              <Route path = { "/mfa" } element = { <MFA/> }/>
              <Route path = { "/reset-password" } element = { <ResetPassword/> }/>
              <Route path = { "/change-password" } element = { <ChangePassword/> }/>
              <Route path = { "/reports" } element = { <Reports/> }/>
              <Route path = { "/statistics" } element = { <Statistics/> }/>
              <Route path = { "/planner" } element = { <NewPlanner/> }/>
              <Route path = { "/entries" } element = { <Entries/> }/>
              <Route path = { "/filtered-entries" } element = { <FilteredEntries/> }/>
              <Route path = { "/tickets" } element = { <Tickets/> }/>
              <Route path = { "*" } element = { <NotFoundPage/> }/>
            </Routes>
        }
      </BrowserRouter>

      <ToastContainer
        position = { "bottom-right" }
        autoClose = { 2000 }
        hideProgressBar = { true }
        newestOnTop = { false }
        closeOnClick
        rtl = { false }
        pauseOnHover = { false }
      />
    </MinosseProvider>
  }
}

export default App
