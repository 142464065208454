import { FunctionComponent, useContext, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { MinosseContext } from "../contexts/MinosseContext"
import { Mapping } from "../helpers/utils"
import { Project, TimeEntry } from "@polarity-dev/minosse-api-sdk"

type ProjectSearchboxProps = {
  projects?: Mapping<Project[]>
  timeEntries?: TimeEntry[]
  onItemClick: (customerId: string | null, projectId: string | null, taskId: string | null, description: string | null) => void
}

const ProjectSearchbox: FunctionComponent<ProjectSearchboxProps> = ({
  projects = {},
  timeEntries = [],
  onItemClick
}) => {
  const { customerMapping, projectMapping } = useContext(MinosseContext)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (!["ArrowDown", "ArrowUp", "Enter"].includes(event.key)) {
        setSelectedIndex(0)

        if (wrapperRef.current?.parentElement) {
          wrapperRef.current!.parentElement!.scrollTo(0, 0)
        }
        return
      }

      event.preventDefault()

      switch (event.key) {
        case "ArrowDown": {
          setSelectedIndex(selectedIndex => selectedIndex < globalIndex - 1 ? selectedIndex + 1 : selectedIndex)
          break
        }
        case "ArrowUp": {
          setSelectedIndex(selectedIndex => selectedIndex > 0 ? selectedIndex - 1 : selectedIndex)
          break
        }
        case "Enter": {
          const id = wrapperRef.current?.querySelector(".selected")?.id
          if (id) {
            const [customerId, projectId, taskId, description] = id.split("#")
            onItemClick(customerId, projectId, taskId || null, (!description || description === "null") ? "" : description)
          }
        }
      }

      wrapperRef.current?.querySelector(".selected")?.scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "auto"
      })
    }

    document.addEventListener("keydown", handler)

    return (): void => document.removeEventListener("keydown", handler)
  }, [])

  let globalIndex = 0

  return <div ref = { wrapperRef }>
    {
      timeEntries.length > 0 && <div>
        <strong>Entries</strong>
        {
          timeEntries.map(entry => {
            const localIndex = globalIndex++
            return <div
              id = { `${entry.customerId}#${entry.projectId}#${entry.taskId}#${entry.description}` }
              key = { `${entry.customerId}#${entry.projectId}#${entry.taskId}#${entry.description}` }
              onMouseEnter = { (): void => setSelectedIndex(localIndex) }
              className = { classNames("project", { selected: selectedIndex === localIndex }) }
              onMouseDown = { (): void => onItemClick(entry.customerId, entry.projectId, entry.taskId, entry.description) }
            >
              <span className = { "text-black me-2" }>{ entry.description || <i>No description</i> }</span>
              {
                entry.projectId && entry.customerId && <>
                  <span style = {{ color: projectMapping[entry.projectId]?.color || "black" }}>
                    <span className = { "project__colored-dot" } style = {{ backgroundColor: projectMapping[entry.projectId]?.color || "black" }} />
                    { entry.taskId ? `${projectMapping[entry.projectId]?.projectName}: ${projectMapping[entry.projectId]?.tasks.find(task => task.taskId === entry.taskId)?.taskName}` : projectMapping[entry.projectId]?.projectName }
                  </span> • { customerMapping[entry.customerId]?.customerName }
                </>
              }
            </div>
          })
        }
      </div>
    }
    { timeEntries.length > 0 && Object.keys(projects).length > 0 && <hr className={"mb-4 mt-2"}/> }
    {
      !!Object.keys(projects).length && Object.keys(projects).map((customerId, i) => (
        <div className = { "customer" } style = { i === 0 ? { marginTop: -15 } : undefined }>
          <strong>{ customerMapping[customerId]?.customerName }</strong>
          {
            projects[customerId].map(project => {
              const localIndex = globalIndex++
              return <>
                <div
                  id = { `${project.customerId}#${project.projectId}##` }
                  key = { `${project.customerId}#${project.projectId}##` }
                  className = { classNames("project", { selected: localIndex === selectedIndex }) }
                  onMouseEnter = { (): void => setSelectedIndex(localIndex) }
                  onMouseDown = { (): void => onItemClick(project.customerId, project.projectId, null, null) }
                  style={{ color: project.color }}
                >
                  { project.color && <span className={"project__colored-dot"} style={{ backgroundColor: project.color }} />}{ projectMapping[project.projectId]?.projectName }
                </div>
                <div>
                  {
                    project.tasks.filter(({ active }) => active).map(({ taskId, taskName }) => {
                      const taskIndex = globalIndex++
                      return <div
                        className = { classNames("project", { selected: taskIndex === selectedIndex }) }
                        id = { `${project.customerId}#${project.projectId}#${taskId}#` }
                        key = { `${project.customerId}#${project.projectId}#${taskId}#` }
                        style = {{ marginLeft: 15 + (project?.color ? 5 : 0) }}
                        onMouseEnter = { (): void => setSelectedIndex(taskIndex) }
                        onMouseDown = { (): void => onItemClick(project.customerId, project.projectId, taskId!, null) }
                      >
                        { taskName }
                      </div>
                    })
                  }
                </div>
              </>
            })
          }
        </div>
      ))
    }
  </div>
}

export default ProjectSearchbox