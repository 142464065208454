import { Task } from "@polarity-dev/minosse-api-sdk"
import React, { FunctionComponent } from "react"
import { DraggableProvided } from "react-beautiful-dnd"
import { Button, Form } from "react-bootstrap"
import { BsBoxArrowInDown, BsBoxArrowUp, BsClipboard, BsTrashFill } from "react-icons/bs"
import UserPrice from "./UserPrice"
import MarkdownEditor from "./MarkdownEditor"
import { toast } from "react-toastify"

type TaskEditorProps = {
  task: Task,
  setTask: (task: Task) => void,
  isLoading: boolean,
  provided?: DraggableProvided,
  noFooter?: boolean,
  deleteTask?: () => void
}

const TaskEditor: FunctionComponent<TaskEditorProps> = ({ task, isLoading, setTask, noFooter = false, deleteTask }) => {
  const isNewTask = task.taskId?.startsWith("NEWTASK#")

  return <>
    <div>
      <div className="form-label">Task ID</div>
      <div className={"position-relative w-100"}>
        <Form.Control
          type={"text"}
          value={task.taskId}
          disabled
          className={"input__disabled-lighter"}
        />
        <BsClipboard
          className={"reports__clear-icon cursor-pointer"}
          onClick={(): void => {
            if (task.taskId) {
              void navigator.clipboard.writeText(task.taskId).then(() => toast.info("Copied to clipboard"))
            }
          }}
        />
      </div>

      <div className={"mt-3 form-label"}>Task name</div>

      <Form.Control
        type="text"
        value={task.taskName}
        isInvalid={task.taskName.length === 0}
        onChange={(event): void => {
          setTask({ ...task, taskName: event.target.value })
        }}
      />

      <div className={"mt-3 form-label"}>Report section</div>

      <Form.Control
        type = { "text" }
        value = { task.section || "" }
        onChange = { (event): void => {
          setTask({ ...task, section: event.target.value || null })
        }}
      />

      <div className={"mt-3 form-label"}>NOTES</div>

      <MarkdownEditor
        text={task.notes || ""}
        setText={(notes: string): void => setTask({ ...task, notes })}
      />

      <div className={"mt-3 form-label"}>
        USERS
      </div>
      <div>
        {task.userPrices.map((userPrice, userPricesIndex) => {
          return <UserPrice
            key={`${userPrice.userId}#${userPricesIndex}`}
            userPrice={userPrice}
            updateUserPrice={(userPrice): void => {
              const userPrices = [...task.userPrices]
              userPrices[userPricesIndex] = userPrice
              setTask({ ...task, userPrices })
            }}
            removeUserPrice={(): void => {
              setTask({ ...task, userPrices: task.userPrices.filter((_, index) => index !== userPricesIndex) })
            }}
          />
        })}
      </div>
      <UserPrice
        updateUserPrice={(userPrice): void => {
          setTask({ ...task, userPrices: [...task.userPrices, userPrice] })
        }}
      />
    </div>

    {!noFooter && <div className={"w-100 mt-3 d-flex justify-content-end"}>
      {isNewTask ? <>
        <Button
          variant={"danger"}
          disabled={isLoading}
          onClick={(): void => {
            if (confirm("Are you sure you want to delete this task?")) {
              deleteTask?.()
            }
          }}
          className="d-flex align-items-center align-text-bottom"
        ><BsTrashFill className={"iconfix me-2"}/>Delete</Button>
      </> : <>
        <div className={"d-flex"}>
          {!task.active ? <>
            <Button
              variant={"outline-dark"}
              className="me-2"
              disabled={isLoading}
              onClick={(): void => {
                if (confirm("Are you sure you want to unarchive this task?")) {
                  setTask({ ...task, active: true })
                }
              }}
            ><BsBoxArrowUp className={"iconfix me-2"}/>Unarchive</Button>

            <Button
              variant={"danger"}
              disabled={isLoading}
              onClick={(): void => {
                if (confirm("Are you sure you want to delete this task?")) {
                  deleteTask?.()
                }
              }}
              className="d-flex align-items-center align-text-bottom"
            ><BsTrashFill className={"iconfix me-2"}/>Delete</Button>
          </> : <>
            <Button
              variant={"outline-danger"}
              disabled={isLoading}
              onClick={(): void => {
                if (confirm("Are you sure you want to archive this task?")) {
                  setTask({ ...task, active: false })
                }
              }}
            ><BsBoxArrowInDown className={"iconfix me-2"}/><span>Archive</span></Button>
          </>}
        </div>
      </>}
    </div>}
  </>
}

export default TaskEditor