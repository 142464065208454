import { fitsInWeek, getMonthDaysCount, isToday } from "../../helpers/plan"
import classNames from "classnames"
import React, { FC, useEffect, useMemo, useRef } from "react"
import CalendarAnimator from "../../helpers/calendarAnimator"
import { HolidayData } from "../../types/planner"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { format } from "date-fns"
import dayjs from "dayjs"

type Props = {
  month: number,
  year: number,
  colWidth: number,
  animator: CalendarAnimator,
  holidays: Record<string, HolidayData>,
  activeWeek: Date
}

const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const MonthHeader: FC<Props> = ({
  month,
  year,
  colWidth,
  animator,
  holidays,
  activeWeek
}) => {
  const monthName = useMemo(() => new Intl.DateTimeFormat("it", { month: "long" }).format(new Date(year, month, 1)), [month])
  const daysArray = useMemo(() => {
    return Array.from({ length: getMonthDaysCount(month, year) })
  }, [month, year])

  const ref = useRef<HTMLDivElement | null>(null)

  const aw = useMemo(() => dayjs(activeWeek).utc(true).toDate(), [activeWeek])

  useEffect(() => {
    if (ref.current !== null) {
      animator.monthDivs[`${year}-${month}`] = ref.current
      animator.updateMonthHeaderData()
      animator.updateMonthHeader()
    }
  }, [animator.initialized, ref.current])

  return (
    <div
      className="bg-white"
      style={{
        width: daysArray.length * colWidth,
        minWidth: daysArray.length * colWidth
      }}
    >
      {/* MONTH */}
      <div className="new-planner__month-header d-flex justify-content-center align-items-center fw-bold text-capitalize border-top border-bottom position-relative">
        <div className="w-100 d-flex justify-content-center align-items-center new-planner__month-name-container" ref={ref}>
          <div className={"position-relative new-planner__month-name"}>
            <div>{monthName}&nbsp;<span className="opacity-25">{year}</span></div>
          </div>
        </div>

        <div className="new-planner__month-divider" />
      </div>

      {/* DAYS */}
      <div className="d-flex new-planner__month-days-container">
        {daysArray.map((_, i, arr) => {
          const date = new Date(year, month, i + 1)
          const datecheck = dayjs().utc().set("year", year).set("month", month).set("date", i + 1).toDate()

          const isWeekend = date.getDay() === 0 || date.getDay() === 6
          const holiday = holidays[format(date, "dd/MM/yyyy")]
          const redColored = (isWeekend || !!holiday)

          const fogOfWar = !fitsInWeek(aw, datecheck)

          const component = (
            <div
              key={i}
              style={{ width: `${(1 / arr.length) * 100}%` }}
              className={classNames("d-flex align-items-center justify-content-center small new-planner__month-day-block", {
                "new-planner__month-day-block--monday": date.getDay() === 1,
                // "new-planner__month-day-block--fog-of-war": fogOfWar
                "new-planner__day--red-day": !fogOfWar && redColored,
                "new-planner__day--previous": fogOfWar && !redColored,
                "new-planner__day--previous-red-day": fogOfWar && redColored,
                "new-planner__month-day-block--red": redColored,
                "border-bottom": !redColored && !fogOfWar
              })}
            >
              <div className={classNames("rounded-circle p-1 d-flex justify-content-center align-items-center new-planner__month-day", {
                "new-planner__month-day--today": isToday(date),
                "new-planner__month-day--holiday": !!holiday
              })}>{i + 1}</div>
              <span className="new-planner__month-weekday">{weekdays[date.getDay()]}</span>
            </div>
          )

          if (holiday) {
            return <OverlayTrigger
              key={`holiday-trigger#${date}`}
              placement={"top"}
              overlay={
                <Tooltip id={`holiday-trigger#${date}`} style={{ zIndex: 999999 }}>
                  <h5>{holiday.name}</h5>
                </Tooltip>
              }
              trigger={["hover", "focus"]}
            >
              {component}
            </OverlayTrigger>
          } else {
            return component
          }
        })}
      </div>
    </div>
  )
}

export default MonthHeader
