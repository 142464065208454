import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Card, Form, InputGroup } from "react-bootstrap"
import { toast } from "react-toastify"
import { MinosseContext } from "../contexts/MinosseContext"
import { handleError } from "../helpers/utils"
import UserForm from "./UserForm"
import { BsBoxArrowUp, BsPlusLg } from "react-icons/bs"
import { StaffUser, StaffUsersService } from "../sdk/minosse-principals-api"
import { useSearchParams } from "react-router-dom"
import Fuse from "fuse.js"

const UserList: FunctionComponent = () => {
  const { userList, userMapping, actions: { setUserList }, dataReady } = useContext(MinosseContext)

  const [showInactive, setShowInactive] = useState<boolean>(false)

  const [activatingUserId, setActivatingUserId] = useState<string | null>(null)
  const [editedUserId, setEditedUserId] = useState<string | undefined | null>(null)

  const [searchFilter, setSearchFilter] = useState<string>("")
  const filteredUsers = useMemo(() => {
    const list = userList.filter(u => u.active || showInactive)
    const fuse = new Fuse(list, {
      keys: ["username"],
      threshold: 0.3
    })
    return (!searchFilter
      ? list
      : fuse.search(searchFilter).map(item => item.item as StaffUser)) as StaffUser[]
  }, [userList, searchFilter, showInactive])

  /* SEARCH PARAMS */

  const searchParamsLoaded = useRef(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!dataReady || searchParamsLoaded.current) {
      return
    }

    const userId = searchParams.get("userId")
    searchParamsLoaded.current = true

    if (userId && userMapping[userId]) {
      setEditedUserId(userId)
    }
  }, [dataReady])

  useEffect(() => {
    if (!searchParamsLoaded.current) {
      return
    }

    const updatedParams = new URLSearchParams(searchParams)
    if (editedUserId) {
      updatedParams.set("userId", editedUserId)
    } else {
      updatedParams.delete("userId")
    }
    setSearchParams(updatedParams)
  }, [editedUserId])

  return <div className={"list"}>
    <div className={"list-toolbar"}>
      <InputGroup>
        <InputGroup.Text>Search</InputGroup.Text>
        <Form.Control
          type = { "text" }
          onChange = { (event): void => setSearchFilter(event.target.value) }
          value = { searchFilter }
          placeholder = { "Filter by username..." }
        />
      </InputGroup>
      <Form.Check
        type = { "checkbox" }
        onChange = { (event): void => setShowInactive(event.target.checked) }
        checked = { showInactive }
        label = { "Show disabled" }
      />
      <Button onClick = { (): void => setEditedUserId(undefined) }>
        <BsPlusLg className={"iconfix iconfix--plus me-2"} />Add new
      </Button>
    </div>
    {
      editedUserId !== null && <UserForm
        user = { editedUserId ? userMapping[editedUserId] : undefined }
        show = { editedUserId !== null }
        close = { (): void => setEditedUserId(null) }
      />
    }
    {
      filteredUsers.map(({ userId, username, active }) => (
        <Card key = { userId } className = { "user-card mt-3" } onClick = { (): void => setEditedUserId(userId) }>
          <div>{ username }</div>
          {
            !active &&
              <Button
                variant = { "outline-dark" }
                className={"mw-max-content"}
                disabled = { activatingUserId === userId }
                onClick = { async(event): Promise<void> => {
                  event.stopPropagation()

                  setActivatingUserId(userId)
                  const loader = toast.loading("Enabling user...")
                  try {
                    await StaffUsersService.updateStaffUser({
                      handler: { userId },
                      updates: { active: true }
                    })

                    toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "User enabled successfully" })

                    setUserList(userList => userList.map(updatedUser => {
                      if (updatedUser.userId === userId) {
                        return { ...updatedUser, active: true }
                      }
                      return updatedUser
                    }))
                  } catch (error) {
                    handleError(error)
                  } finally {
                    setActivatingUserId(null)
                  }
                }}
              >
                <BsBoxArrowUp className={"iconfix me-2"} />Enable
              </Button>
          }
        </Card>
      ))
    }
  </div>
}

export default UserList