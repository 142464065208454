import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react"
import classNames from "classnames"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import PageTemplate from "../components/PageTemplate"
import AuthContext from "../contexts/AuthContext"
import { MinosseContext } from "../contexts/MinosseContext"
import loading from "../assets/loading.gif"
import { handleException } from "../helpers/logger"
import { Button, Spinner, Table } from "react-bootstrap"
import EntryDetails from "../components/EntryDetails"
import { getReadableDay, getReadableDuration } from "../helpers/time"
import {
  getTimeEntriesInRange,
  GetTimeEntriesInRange200ResponseSchema, GetTimeEntriesInRangeRequestSchema, Project, TimeEntry
} from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"
import dayjs from "dayjs"
import { handleError } from "../helpers/utils"
import ListFilter from "../components/ListFilter"
import { StaffUser, Customer } from "../sdk/minosse-principals-api"
import StatisticsCalendar from "../components/StatisticsCalendar"
import Icon from "../components/Icon"
import TimeEntryForm from "../components/TimeEntryForm"
import { BooleanOption } from "../types"

const Entries: FunctionComponent = () => {
  const { initialized, user } = useContext(AuthContext)
  const { dataReady, userMapping, customerMapping, projectMapping, projectList } = useContext(MinosseContext)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)

  if (initialized && !user) {
    return <Navigate to={"/login"} replace />
  }

  // if (initialized && !user?.isAdmin) {
  //   return <NotFoundPage />
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [excludeGenerated, setExcludeGenerated] = useState(searchParams.get("excludeGenerated") === "true" || false)
  const [timeEntries, setTimeEntries] = useState<TimeEntry[] | null>(null)

  const [createTimeEntryFormOpen, setCreateTimeEntryFormOpen] = useState<boolean>(false)

  // Filters
  const [userFilter, setUserFilter] = useState(searchParams.get("userId"))
  const [{ customerFilter, projectFilter }, setFilters] = useState({
    customerFilter: searchParams.get("custometId"),
    projectFilter: searchParams.get("projectId")
  })
  const [timeSpan, setTimeSpan] = useState((): { since: Date | null, until: Date | null } => {
    const date = dayjs().tz()
    const parsedSince = parseInt(searchParams.get("since") || ""), parsedUntil = parseInt(searchParams.get("until") || "")
    return {
      since: parsedSince ? new Date(parsedSince) : date.startOf("isoWeek").toDate(),
      until: parsedUntil ? new Date(parsedUntil) : date.endOf("isoWeek").toDate()
    }
  })

  const [selectedEntry, setSelectedEntry] = useState<TimeEntry | null>(null)

  const [lastUsedPayload, setLastUsedPayload] = useState<GetTimeEntriesInRangeRequestSchema | null>(null)

  useEffect(() => {
    if (!initialized || !user?.isAdmin) {
      return
    }

    const since = timeSpan.since?.getTime()
    const until = timeSpan.until?.getTime()

    if (!since || !until) {
      return
    }

    const payload: GetTimeEntriesInRangeRequestSchema = {
      start: since,
      stop: until,
      excludeGenerated
    }

    const searchParams = new URLSearchParams({})

    if (excludeGenerated) {
      searchParams.set("excludeGenerated", "true")
      payload.excludeGenerated = true
    }

    if (since !== dayjs().startOf("isoWeek").toDate().getTime()) {
      searchParams.set("since", since.toString())
      payload.start = since
    }

    if (until !== dayjs().endOf("isoWeek").toDate().getTime()) {
      searchParams.set("until", until.toString())
      payload.stop = until
    }

    if (userFilter) {
      payload.userFilter = { userId: userFilter }
      searchParams.append("userId", userFilter)
    }

    if (customerFilter) {
      payload.customerFilter = { customerId: customerFilter }
      searchParams.append("customerId", customerFilter)

      if (projectFilter) {
        payload.customerFilter.projectId = projectFilter
        searchParams.append("projectId", projectFilter)
      }
    }

    setSearchParams(searchParams)
    setLastUsedPayload(payload)
  }, [
    initialized,
    user,
    userFilter,
    customerFilter,
    projectFilter,
    timeSpan,
    excludeGenerated
  ])

  const reloadData = useCallback((payload?: GetTimeEntriesInRangeRequestSchema): void => {
    if (!lastUsedPayload) {
      return
    }
    setIsLoading(true)
    handleApiCall(getTimeEntriesInRange, payload ?? lastUsedPayload!)
      .then(data => setTimeEntries((data as GetTimeEntriesInRange200ResponseSchema).data.timeEntries))
      .catch(error => {
        handleException(error as Error)
        setTimeEntries(null)
        handleError(error)
      })
      .finally(() => setIsLoading(false))
  }, [lastUsedPayload])

  useEffect(() => reloadData(), [reloadData])

  const areFiltersStock = !userFilter
    && !customerFilter
    && !projectFilter
    && timeSpan.since?.getTime() === dayjs().startOf("isoWeek").toDate().getTime()
    && timeSpan.until?.getTime() === dayjs().endOf("isoWeek").toDate().getTime()
    && !excludeGenerated

  return <PageTemplate>
    {
      <div
        className={classNames("loading-pane", { visible: !dataReady })}
        onTransitionEnd={(event): void => event.currentTarget.remove()}
      >
        <img src={loading} />
      </div>
    }
    {
      (!!selectedEntry || createTimeEntryFormOpen) && <TimeEntryForm
        selectedEntry={selectedEntry ?? undefined}
        entries={timeEntries || []}
        onSubmit={(id: string, entry: TimeEntry): void => {
          reloadData()
          /* if (!timeSpan.since || !timeSpan.until) {
            return
          }
          setTimeEntries(entries => {
            entries = (entries || []).filter(entry => id !== entry.id)

            if (
              entry.start > timeSpan.since!.getDate() && entry.start < timeSpan.until!.getDate()
              && (entry.customerId === customerFilter || !customerFilter)
              && (entry.projectId === projectFilter || (!entry.projectId && !projectFilter))
              && (entry.userId === userFilter || !userFilter)
            ) {
              entries = entries.concat([entry])
            }

            return entries
              .sort((a, b) => b.start! - a.start!)
          }) */
        }}
        onDelete={(id: string): void => setTimeEntries(entries => (entries || []).filter(entry => id !== entry.id))}
        close={(): void => selectedEntry ? setSelectedEntry(null) : setCreateTimeEntryFormOpen(false)}
        showPricePerHour
        showUserPicker
      />
    }
    <div className="max-content-width">
      <div className={"entries px-0"}>
        <div className={"d-flex align-items-center justify-content-between mb-3"}>
          <div className={"filter-bar d-flex gap-2 tw-flex-wrap"}>
            <ListFilter
              className={""}
              data={Object.values(userMapping)}
              selectedIds={userFilter ? [userFilter] : []}
              onItemClick={(item: StaffUser | null): void => {
                setUserFilter(item ? item.userId : null)
              }}
              itemKey={({ userId }: StaffUser): string => userId}
              itemText={({ username }: StaffUser): string => username}
              matchKey="username"
              itemId={({ userId }): string => userId}
              clearSelection={(): void => setUserFilter(null)}
            >
              User:&nbsp;<b>{userFilter ? userMapping[userFilter]?.username : "All"}</b>
            </ListFilter>

            <ListFilter
              className={""}
              data={Object.values(customerMapping)}
              selectedIds={customerFilter ? [customerFilter] : []}
              onItemClick={(item: Customer | null): void => {
                setFilters(item ? { customerFilter: item.customerId, projectFilter: null } : { customerFilter: null, projectFilter: null })
              }}
              itemKey={({ customerId }: Customer): string => customerId}
              itemText={({ customerName }: Customer): string => customerName}
              matchKey="customerName"
              itemId={({ customerId }): string => customerId}
              clearSelection={(): void => setFilters({ customerFilter: null, projectFilter: null })}
            >
              Customer:&nbsp;<b>{customerFilter ? customerMapping[customerFilter]?.customerName : "All"}</b>
            </ListFilter>

            <ListFilter
              disabled={customerFilter === null || projectList.filter(project => project.customerId === customerFilter).length === 0}
              className={""}
              data={projectList.filter(project => project.customerId === customerFilter)}
              selectedIds={projectFilter ? [projectFilter] : []}
              onItemClick={(item: Project | null): void => {
                setFilters(filters => (item ? { ...filters, projectFilter: item.projectId } : { ...filters, projectFilter: null }))
              }}
              itemKey={({ projectId }: Project): string => projectId}
              itemText={({ projectName }: Project): string => projectName}
              matchKey="projectName"
              itemId={({ projectId }): string => projectId}
              clearSelection={(): void => setFilters(filters => ({ customerFilter: filters.customerFilter, projectFilter: null }))}
            >
              Project:&nbsp;<b>{projectFilter ? projectMapping[projectFilter]?.projectName : "All"}</b>
            </ListFilter>

            <span className={""}>
              <StatisticsCalendar
                startDate={timeSpan.since}
                endDate={timeSpan.until}
                onDateChange={([since, until]): void => {
                  setTimeSpan({ since, until })
                }}
              />
            </span>

            <ListFilter
              data={[{ id: "true", name: "Yes" }, { id: "false", name: "No" }] as BooleanOption[]}
              selectedIds={excludeGenerated ? ["true"] : ["false"]}
              onItemClick={(item: BooleanOption | null): void => {
                setExcludeGenerated(item?.id === "true")
              }}
              hideAll
              matchKey="name"
              itemId={({ id }): string => id}
              itemKey={({ id }): string => id}
              itemText={({ name }): string => name}
            >
              Exclude generated:&nbsp;<b>{excludeGenerated ? "Yes" : "No"}</b>
            </ListFilter>

            {!areFiltersStock && <>
              <span className={"border-start"} style={{ width: 1, paddingTop: 5, paddingBottom: 8 }} />

              <Button variant="outline-dark" className={"fw-bold"} onClick={(): void => {
                setFilters({ customerFilter: null, projectFilter: null })
                setExcludeGenerated(false)
                setUserFilter(null)
                setTimeSpan({
                  since: dayjs().startOf("isoWeek").toDate(),
                  until: dayjs().endOf("isoWeek").toDate()
                })
              }}>
                <span className="d-flex">
                  <Icon name="x-lg" className="iconfix--translate1" />&nbsp;Clear filters
                </span>
              </Button>
            </>}
          </div>

          <div className={"tw-self-start tw-min-w-fit ms-2"}>
            <Button variant={"dark"} className={"d-flex align-items-center"} onClick={(): void => {
              setCreateTimeEntryFormOpen(true)
            }}>
              <Icon name={"plus"} />&nbsp;Add new
            </Button>
          </div>
        </div>
        {isLoading || !dataReady
          ? <div className={"d-flex justify-content-center"}>
            <Spinner animation="border" />
          </div>
          : <Table hover>
            <thead>
              <tr>
                <td>Entry</td>
                <td>Date</td>
                <td>User</td>
                <td>Duration</td>
              </tr>
            </thead>
            <tbody>
              {(timeEntries || []).sort((a, b) => b.start - a.start).map(entry => <tr onClick={(): void => setSelectedEntry(entry)}>
                <td>
                  <div>
                    <div className={"group-entry__description"}>{entry.description || "No description"}</div>
                    <EntryDetails customerId={entry.customerId} projectId={entry.projectId} taskId={entry.taskId} />
                  </div>
                </td>
                <td className="vertical-align-middle" style={{ width: "1%", whiteSpace: "nowrap" }}>
                  <span>{entry.start ? getReadableDay(entry.start) : "n/a"}</span>
                </td>
                <td className="vertical-align-middle" style={{ width: "1%", whiteSpace: "nowrap" }}>
                  <span>{userMapping[entry.userId]?.username || "n/a"}</span>
                </td>
                <td className="vertical-align-middle" style={{ width: "1%", whiteSpace: "nowrap" }}>
                  {getReadableDuration(entry.duration || NaN)}
                </td>
              </tr>)}
              {(timeEntries || [])?.length === 0 && <tr>
                <td colSpan={4} className={"text-center fst-italic"}>No entries found</td>
              </tr>}
            </tbody>
          </Table>
        }
      </div>
    </div>
  </PageTemplate>
}

export default Entries