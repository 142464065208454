import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { Button, Card, Form, InputGroup, Modal, Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { BsBoxArrowInDown, BsClipboard, BsTrashFill } from "react-icons/bs"
import Icon from "./Icon"
import { MdSave } from "react-icons/md"
import { CustomersService as PrincipalsCustomersService, Customer as PrincipalsCustomer, UpdateCustomerRequestSchema } from "../sdk/minosse-principals-api"
import { CustomersService as TicketingCustomersService, Customer as TicketingCustomer, SupportPlan } from "../sdk/minosse-ticketing-api"
import { handleError, isValidColor } from "../helpers/utils"
import { Customer, MinosseContext } from "../contexts/MinosseContext"
import { Customer as CoreCustomer } from "@polarity-dev/minosse-api-sdk"
import { isEqual } from "lodash"
import CustomerSimpleDropdown from "./CustomerSimpleDropdown"
import {
  deleteCustomer,
  getCustomer,
  GetCustomer200ResponseSchema,
  upsertCustomer
} from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"

type FormData = Omit<PrincipalsCustomer, "customerId" | "active"> & Omit<TicketingCustomer, "customerId"> & { color: string }

type CustomerFormProps = {
  customer?: Customer
  show: boolean
  close: () => void
}

const CustomerForm: FunctionComponent<CustomerFormProps> = ({
  customer,
  show,
  close
}) => {
  const { customerMapping, actions: { setCustomerList } } = useContext(MinosseContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isLoadingExtra, setIsLoadingExtra] = useState<boolean>(false)
  const [customerCoreExtra, setCustomerCoreExtra] = useState<CoreCustomer | null>(null)
  const [customerTicketingExtra, setCustomerTicketingExtra] = useState<TicketingCustomer | null>(null)

  const { register, handleSubmit, formState: { errors, dirtyFields, isDirty }, setError, setValue, watch, reset, clearErrors } = useForm()

  const [supportPlanName, setSupportPlanName] = useState<string>("")
  const [domain, setDomain] = useState<string>("")
  const [authorizedEmail, setAuthorizedEmail] = useState<string>("")

  const emailRegex = /.+@.+\..+/g

  const [supportPlans = [], domains = [], color, authorizedEmails = [], childCustomers = []]: [SupportPlan[], string[], string, string[], string[]] = watch(["supportPlans", "domains", "color", "authorizedEmails", "childCustomers"])

  useEffect(() => {
    if (customer) {
      void (async(): Promise<void> => {
        setIsLoadingExtra(true)
        try {
          const coreCust = await handleApiCall(getCustomer, { handler: { customerId: customer.customerId } })
          setCustomerCoreExtra((coreCust as GetCustomer200ResponseSchema).data)
          const extraCust = await TicketingCustomersService.getCustomer({ handler: { customerId: customer.customerId } })
          setCustomerTicketingExtra(extraCust.data)
        } catch (ex) {
          handleError(ex)
        } finally {
          setIsLoadingExtra(false)
        }
      })()
    }
  }, [])

  useEffect(() => {
    reset({
      ...customer && {
        customerName: customer.customerName,
        domains: customer.domains || [],
        authorizedEmails: customer.authorizedEmails || [],
        childCustomers: customer.childCustomers || []
      },
      ...customerTicketingExtra && {
        supportPlans: customerTicketingExtra.supportPlans || []
      },
      ...customerCoreExtra && {
        color: customerCoreExtra.color
      }
    })
  }, [customer, customerTicketingExtra])

  const areEmailsInvalid = (): boolean => {
    return authorizedEmails.reduce<boolean>((acc, val, idx) => {
      const invalid = !emailRegex.test(val)
      if (invalid) {
        setError(`authorizedEmails.${idx}`, { type: "validate", message: "Invalid email" })
      } else {
        clearErrors(`authorizedEmails.${idx}`)
      }

      acc = acc || invalid
      return acc
    }, false)
  }

  useEffect(() => {
    areEmailsInvalid()
  }, [authorizedEmails])

  const onFormSubmit = async(data: FormData): Promise<void> => {
    if (areEmailsInvalid()) {
      return
    }

    setIsSubmitting(true)
    const loader = toast.loading(customer ? "Updating customer..." : "Creating customer...")

    try {
      if (customer) {
        const promises: (keyof PrincipalsCustomersService | TicketingCustomersService)[] = [
          TicketingCustomersService.upsertCustomer({
            handler: { customerId: customer.customerId },
            updates: {
              supportPlans: data.supportPlans || [],
              childCustomers: data.childCustomers || []
            }
          })
        ]

        const updates: UpdateCustomerRequestSchema["updates"] = {}
        if (dirtyFields.customerName) {
          updates.customerName = data.customerName
        }
        if (data.domains.length !== customer.domains.length || !data.domains.every(domain => customer.domains.includes(domain))) {
          updates.domains = data.domains
        }
        if (data.childCustomers.length !== customer.childCustomers.length || !data.childCustomers.every(customerId => customer.childCustomers.includes(customerId))) {
          updates.childCustomers = data.childCustomers
        }
        if (data.authorizedEmails.length !== customer.authorizedEmails.length || !data.authorizedEmails.every(email => customer.authorizedEmails.includes(email))) {
          updates.authorizedEmails = data.authorizedEmails
        }

        if (Object.keys(updates).length) {
          promises.push(PrincipalsCustomersService.updateCustomer({
            handler: { customerId: customer.customerId },
            updates
          }))
        }

        promises.push(handleApiCall(upsertCustomer, {
          handler: { customerId: customer.customerId },
          updates: { color: data.color, awsAccounts: customer.awsAccounts }
        }))

        await Promise.all(promises)

        setCustomerList(customerList => customerList.map(updatedCustomer => {
          if (updatedCustomer.customerId === customer.customerId) {
            return { ...updatedCustomer, ...data }
          }
          return updatedCustomer
        }))
      } else {
        const { data: { customerId } } = await PrincipalsCustomersService.createCustomer({
          customerName: data.customerName,
          domains: data.domains || [],
          childCustomers: data.childCustomers || []
        })
        await Promise.all([
          TicketingCustomersService.upsertCustomer({
            handler: { customerId },
            updates: {
              supportPlans: data.supportPlans || [],
              childCustomers: data.childCustomers || []
            }
          }),
          handleApiCall(upsertCustomer, {
            handler: { customerId },
            updates: { awsAccounts: [], color }
          })
        ])

        setCustomerList(customerList => [...customerList, {
          customerId,
          active: true,
          awsAccounts: [],
          iamAlerts: [],
          ...data
        }])
      }

      toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: `Customer ${customer ? "updated" : "created"} successfully` })
      close()
    } catch (error) {
      handleError(error, loader)
      setIsSubmitting(false)
    }
  }

  return <Modal
    show={show}
    centered
    scrollable
    onHide={(): void => {
      if (isSubmitting) {
        return
      }

      if (
        isDirty
        || !isEqual(color, customerCoreExtra?.color)
        || customerTicketingExtra && !isEqual(supportPlans, customerTicketingExtra.supportPlans)
      ) {
        if (confirm("Unsaved changes. Are you sure you want to close?")) {
          close()
        }
      } else {
        close()
      }
    }}
    className={"project-editor"}
  >
    <Modal.Header closeButton>
      <Modal.Title>{customer ? `Edit ${customerMapping[customer.customerId]?.customerName}` : "Add new customer"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        {
          customer && <Form.Group>
            <Form.Label>Customer ID</Form.Label>
            <InputGroup>
              <Form.Control
                type={"text"}
                value={customer.customerId}
                disabled
                className={"input__disabled-lighter"}
              />
              <BsClipboard
                className={"reports__clear-icon cursor-pointer"}
                onClick={(): void => {
                  void navigator.clipboard.writeText(customer.customerId)
                    .then(() => toast.info("Copied to clipboard"))
                }}
              />
            </InputGroup>
          </Form.Group>
        }
        <Form.Group>
          <Form.Label>Customer name</Form.Label>
          <Form.Control
            type={"text"}
            placeholder={"Add customer name..."}
            isInvalid={!!errors.customerName}
            {...register("customerName", { required: true, setValueAs: (value: string) => value.trim() })}
          />
          <Form.Control.Feedback type={"invalid"}>
            Please choose a customer name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Authorized domains</Form.Label>
          {
            domains.map((domain, index) => {
              return <InputGroup key={`domain-${index}`} style={{ marginBottom: 5 }}>
                <InputGroup.Text>@</InputGroup.Text>
                <Form.Control
                  type={"text"}
                  isInvalid={!!errors.domains?.[index]}
                  {...register(`domains.${index}`, { required: true, setValueAs: (value: string) => value.trim() })}
                />
                <Button
                  variant={"secondary"}
                  onClick = { (): void => setValue("domains", domains.filter((_, i) => i !== index)) }
                >
                  <Icon name={"x-lg"}/>
                </Button>
              </InputGroup>
            })
          }
          <InputGroup>
            <Form.Control
              value={domain}
              onChange={(event): void => setDomain(event.target.value) }
              onKeyDown={(event): void => {
                if (event.code === "Enter" && domain.length) {
                  setValue(`domains.${domains.length}`, domain)
                  setDomain("")
                }
              }}
            />
            <Button
              variant={"primary"}
              disabled={!domain}
              onClick={(): void => {
                if (domain.length) {
                  setValue(`domains.${domains.length}`, domain)
                  setDomain("")
                }
              }}
            >
              <Icon name={"plus"}/>
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>Authorized Emails</Form.Label>
          {
            authorizedEmails.map((domain, index) => {
              return <InputGroup key={`domain-${index}`} style={{ marginBottom: 5 }}>
                <Form.Control
                  type={"text"}
                  isInvalid={!!errors.authorizedEmails?.[index]}
                  {...register(`authorizedEmails.${index}`, { required: true, setValueAs: (value: string) => value.trim() })}
                />
                <Button
                  variant={"secondary"}
                  onClick={(): void => {
                    setValue("authorizedEmails", authorizedEmails.filter((_, i) => i !== index))
                  }}
                >
                  <Icon name={"x-lg"}/>
                </Button>
              </InputGroup>
            })
          }
          <InputGroup>
            <Form.Control
              value={authorizedEmail}
              onChange={(event): void => setAuthorizedEmail(event.target.value) }
              onKeyDown={(event): void => {
                if (event.code === "Enter" && !!authorizedEmail.match(emailRegex)) {
                  setValue(`authorizedEmails.${authorizedEmails.length}`, authorizedEmail)
                  setAuthorizedEmail("")
                }
              }}
            />
            <Button
              variant={"primary"}
              disabled={!emailRegex.test(authorizedEmail)}
              onClick={(): void => {
                if (authorizedEmail.length && emailRegex.test(authorizedEmail)) {
                  setValue(`authorizedEmails.${authorizedEmails.length}`, authorizedEmail)
                  setAuthorizedEmail("")
                }
              }}
            >
              <Icon name={"plus"}/>
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>Child customers</Form.Label>
          {
            childCustomers.map((domain, index) => {
              return <InputGroup key={`domain-${index}`} style={{ marginBottom: 5 }} className={"w-100 customer-form__child-customer-dropdown-group"}>
                <CustomerSimpleDropdown
                  customerId={childCustomers[index]}
                  updateCustomerId={(value): void => setValue(`childCustomers.${index}`, value)}
                  className="w-100 rounded-0 rounded-start"
                  isInvalid={!!errors.childCustomers?.[index]}
                />
                <Button
                  variant={"secondary"}
                  onClick = { (): void => setValue("childCustomers", childCustomers.filter((_, i) => i !== index)) }
                >
                  <Icon name={"x-lg"}/>
                </Button>
              </InputGroup>
            })
          }
          <CustomerSimpleDropdown
            customerId={childCustomers[childCustomers.length]}
            excludeIds={childCustomers}
            updateCustomerId={(value): void => setValue(`childCustomers.${childCustomers.length}`, value)}
            clearAfterInsertion
          />
        </Form.Group>
        {
          isLoadingExtra ? <div className={"spinner"}>
            <Spinner animation={"border"}/>
          </div> : <>
            <Form.Group>
              <Form.Label>Color</Form.Label>
              <InputGroup>
                <Form.Control
                  type = { "text" }
                  placeholder = { "Add a color..." }
                  isInvalid = { !!errors.color }
                  { ...register("color", { required: false, validate: value => value === "" || isValidColor(value!) }) }
                />
                <Form.Control
                  type = { "color" }
                  className={"project-form__color-picker"}
                  onChange={(event): void => {
                    setValue("color", event.target.value)
                    clearErrors("color")
                  }}
                  value={color}
                />
              </InputGroup>
              <Form.Control.Feedback type={"invalid"}>
                Please choose a customer color.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Support plan{supportPlans.length > 1 ? "s" : ""}
              </Form.Label>
              <div className={"support-plans__wrapper"}>
                {
                  supportPlans.map((plan, index) => {
                    return <Card key={`${plan.supportPlanId}-${index}`} className={"support-plan"}>
                      <InputGroup>
                        <InputGroup.Text>Name</InputGroup.Text>
                        <Form.Control
                          type={"text"}
                          isInvalid={!!errors.supportPlans?.[index].name}
                          {...register(`supportPlans.${index}.name`, { required: true, setValueAs: (value: string) => value.trim() })}
                        />
                        <Button
                          variant={"secondary"}
                          onClick = { (): void => setValue("supportPlans", supportPlans.filter((_, i) => i !== index)) }
                        >
                          <Icon name={"x-lg"}/>
                        </Button>
                      </InputGroup>
                      <InputGroup className={"mb-1 right"}>
                        <InputGroup.Text>SLA (in workable hours)</InputGroup.Text>
                        <Form.Control
                          type={"number"}
                          isInvalid={!!errors.supportPlans?.[index].sla}
                          {...register(`supportPlans.${index}.sla`, { required: true, min: 0, valueAsNumber: true })}
                        />
                      </InputGroup>
                    </Card>
                  })
                }
              </div>
              {supportPlans.length === 0 && <InputGroup>
                <InputGroup.Text>Name</InputGroup.Text>
                <Form.Control
                  value={supportPlanName}
                  onChange={(event): void => setSupportPlanName(event.target.value)}
                  onKeyDown={(event): void => {
                    if (event.code === "Enter") {
                      setValue(`supportPlans.${supportPlans.length}`, { name: supportPlanName, sla: 0 })
                      setSupportPlanName("")
                    }
                  }}
                />
                <Button
                  variant={"primary"}
                  disabled={supportPlanName.length === 0}
                  onClick={(): void => {
                    setValue(`supportPlans.${supportPlans.length}`, { name: supportPlanName, sla: 0 })
                    setSupportPlanName("")
                  }}
                >
                  <Icon name={"plus"}/>
                </Button>
              </InputGroup>}
            </Form.Group>
          </>
        }
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {
        !!customer && <Button
          variant={customer.active ? "outline-danger" : "danger"}
          disabled={isSubmitting || isLoadingExtra}
          className={"ms-auto"}
          onClick={async(): Promise<void> => {
            if (confirm(`Are you sure you want to ${customer.active ? "archive" : "delete"} this customer?`)) {
              setIsSubmitting(true)
              const loader = toast.loading(`${customer.active ? "Archiving" : "Deleting"} customer...`)

              try {
                if (customer.active) {
                  await PrincipalsCustomersService.updateCustomer({
                    handler: { customerId: customer.customerId },
                    updates: { active: false }
                  })
                  setCustomerList(customerList => customerList.map(updatedCustomer => {
                    if (updatedCustomer.customerId === customer.customerId) {
                      return { ...updatedCustomer, active: false }
                    }
                    return updatedCustomer
                  }))
                } else {
                  const { customerId } = customer
                  await Promise.all([
                    PrincipalsCustomersService.deleteCustomer({ handler: { customerId } }),
                    TicketingCustomersService.deleteCustomer({ handler: { customerId } }),
                    handleApiCall(deleteCustomer, { handler: { customerId } })
                  ])
                  setCustomerList(customerList => customerList.filter(({ customerId }) => customer.customerId !== customerId))
                }

                close()
                toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: `Customer ${customer.active ? "archived" : "deleted"} successfully` })
              } catch (error) {
                handleError(error, loader)
                setIsSubmitting(false)
              }
            }
          }}
        >
          {customer.active ? <><BsBoxArrowInDown className={"iconfix me-2"} />Archive</> : <><BsTrashFill className={"iconfix iconfix--translate1 me-2"} />Delete</>}
        </Button>
      }
      <Button variant={"primary"} disabled={isSubmitting} type={"submit"} onClick={handleSubmit(onFormSubmit)}>
        <MdSave className={"iconfix iconfix--translate1 me-2"}/>Save
      </Button>
    </Modal.Footer>
  </Modal>
}

export default CustomerForm