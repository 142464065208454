import { FunctionComponent, useContext, useMemo } from "react"
import { MinosseContext } from "../contexts/MinosseContext"
import { GetStatistics200ResponseSchema, GetStatisticsRequestSchema } from "@polarity-dev/minosse-api-sdk"
import { Accordion, Row, Col, ListGroup } from "react-bootstrap"
import { formatDuration } from "../helpers/utils"
import { useNavigate } from "react-router-dom"
import EntryDetails from "./EntryDetails"
import { BsFillExclamationTriangleFill } from "react-icons/bs"

type StatisticsTimeEntriesProps = {
  data: GetStatistics200ResponseSchema["data"],
  primaryGroup: GetStatisticsRequestSchema["primaryGroup"],
  secondaryGroup: GetStatisticsRequestSchema["secondaryGroup"],
  selectedKey: string | null,
  setSelectedKey: (key: string | null) => void,
  onEntryClick: (entryId: string) => void
}

const StatisticsTimeEntries: FunctionComponent<StatisticsTimeEntriesProps> = ({
  data,
  primaryGroup,
  secondaryGroup,
  selectedKey,
  setSelectedKey,
  onEntryClick
}) => {
  const navigate = useNavigate()
  const { projectList, customerMapping, projectMapping, userMapping } = useContext(MinosseContext)

  const taskProjects = useMemo(() => {
    return projectList.reduce((acc: Record<string, string>, project) => {
      for (const task of project.tasks) {
        if (task.taskId) {
          acc[task.taskId] = project.projectId
        }
      }
      return acc
    }, {})
  }, [projectList])

  const mappingByGroup = {
    customers: customerMapping,
    projects: projectMapping,
    users: userMapping
  } as const

  return <>
    <Accordion
      className={"primary-accordion"}
      onSelect={(eventKey): void => {
        if (eventKey !== "header") {
          setSelectedKey(eventKey)
        }
      }}
      activeKey={selectedKey ?? ""}
    >
      <Accordion.Item eventKey={"header"} className={"primary-accordion-header"}>
        <Accordion.Header>
          <Col xs={6} className={"fw-bold text-black"}>
            Name
          </Col>
          <Col className={"fw-bold text-black"}>
            Clocked
          </Col>
          <Col className={"fw-bold text-black"}>
            Billed (ƒ)
          </Col>
          <Col className={"fw-bold text-black"}>
            Price
          </Col>
        </Accordion.Header>
      </Accordion.Item>
      {
        Object.entries(data.primaryGroup).map(([primaryKey, { clockedDuration, billedDuration, price, secondaryGroup: statisticsSecondaryGroup }]) => {
          const secondaryGroupEntries = Object.entries(statisticsSecondaryGroup)
          const color = mappingByGroup[primaryGroup][primaryKey]?.color || "black"

          return <Accordion.Item
            key={primaryKey}
            eventKey={primaryKey}
            onClick={(event): void => {
              event.stopPropagation()
            }}
          >
            <Accordion.Header>
              <Col xs={6}>
                <div className={"accordion-circle fw-bold"} style={{ backgroundColor: color }}>
                  {secondaryGroupEntries.length}
                </div>
                <div className={"statistics-entries__name-col"}>
                  {
                    primaryKey === "HANGING" ? <span className={"fw-semibold position-relative"}>
                      <BsFillExclamationTriangleFill className={"position-absolute top-0"}/>
                      <span style={{ marginLeft: 25 }}>NO {primaryGroup.slice(0, -1).toUpperCase()}</span>
                    </span> : <>
                      {primaryGroup === "customers" && (customerMapping[primaryKey]?.customerName || "Deleted customer")}
                      {
                        primaryGroup === "projects" && (
                          projectMapping[primaryKey]
                            ? <>
                              {projectMapping[primaryKey].projectName}
                              &nbsp;•&nbsp;{customerMapping[projectMapping[primaryKey].customerId]?.customerName || "Deleted customer"}
                            </>
                            : "Deleted project"
                        )
                      }
                      {primaryGroup === "users" && (userMapping[primaryKey]?.username || "Deleted user")}
                    </>
                  }
                </div>
              </Col>
              <Col>{formatDuration(clockedDuration)}</Col>
              <Col>{formatDuration(billedDuration)}</Col>
              <Col>{price}€</Col>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {
                  secondaryGroupEntries.map(([secondaryKey, { clockedDuration, billedDuration, price, timeEntryIds }]) => {
                    return <ListGroup.Item
                      key={secondaryKey}
                      className={"list-group-secondary-item"}
                      onClick={(): void => onEntryClick(timeEntryIds[0])}
                    >
                      <Row>
                        <Col xs={6}>
                          {
                            secondaryKey === "HANGING" ? <span className={"fw-semibold position-relative"}>
                              <BsFillExclamationTriangleFill className={"position-absolute top-0"}/>
                              <span style={{ marginLeft: 25 }}>NO {secondaryGroup.slice(0, -1).toUpperCase()}</span>
                            </span> : <div>
                              {secondaryGroup === "customers" && (customerMapping[secondaryKey]?.customerName || "Deleted customer")}
                              {
                                secondaryGroup === "projects" &&
                                  <EntryDetails
                                    projectId={secondaryKey}
                                    customerId={projectMapping[secondaryKey]?.customerId}
                                    taskId={null}
                                  />
                              }
                              {secondaryGroup === "users" && (userMapping[secondaryKey]?.username || "Deleted user")}
                              {
                                secondaryGroup === "tasks" && (
                                  primaryGroup === "projects"
                                    ? projectMapping[taskProjects[secondaryKey]]?.tasks.find(task => task.taskId === secondaryKey)?.taskName ?? "Deleted task"
                                    : <EntryDetails
                                      projectId={taskProjects[secondaryKey]}
                                      customerId={taskProjects[secondaryKey] ? projectMapping[taskProjects[secondaryKey]]?.customerId : null}
                                      hideCustomer={primaryGroup === "customers"}
                                      taskId={secondaryKey}
                                    />
                                )
                              }
                            </div>
                          }
                        </Col>
                        <Col className={"p-0"}>{formatDuration(clockedDuration)}</Col>
                        <Col className={"p-0"}>{formatDuration(billedDuration)}</Col>
                        <Col className={"p-0"}>{price}€</Col>
                      </Row>
                    </ListGroup.Item>
                  })
                }
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        })
      }
    </Accordion>
  </>
}

export default StatisticsTimeEntries
