import React, { useEffect, useState } from "react"
import { getCountdownText, getFormattedDateTime, HOUR, now, SECOND, TimeUnit } from "../../helpers/time"
import { BsFillExclamationTriangleFill } from "react-icons/bs"
import classNames from "classnames"

type TicketDeadlineTextProps = {
  deadline: number,
  deadlineBuffer?: number,
  prefix?: string,
  className?: string,
  bold?: boolean,
  type?: "countdown" | "timepassed",
  warn?: boolean,
  limitAt?: TimeUnit,
  weekday?: "long" | "short" | "narrow",
  hideDate?: boolean
}

const TicketTimedText: React.FunctionComponent<TicketDeadlineTextProps> = ({
  deadline,
  prefix,
  deadlineBuffer = HOUR * 3,
  className = "",
  bold = true,
  type = "countdown",
  warn = true,
  limitAt = undefined,
  weekday,
  hideDate = false
}: TicketDeadlineTextProps) => {
  const [timeDiff, setTimeDiff] = useState(type === "countdown" ? deadline - now() : now() - deadline)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDiff(type === "countdown" ? deadline - now() : now() - deadline)
    }, SECOND)
    return (): void => clearInterval(interval)
  }, [deadline, type])

  const redDeadline = !!timeDiff && (
    (type === "countdown" && timeDiff < deadlineBuffer)
    || (type === "timepassed" && timeDiff > deadlineBuffer)
  ) && warn

  return <div className={classNames(className, { "text-danger": redDeadline })}>
    {prefix && <span className={"me-1"}>{prefix}</span>}
    <span className={"d-inline-flex align-items-center gap-1"}>
      <span className={classNames({ "fw-bold": bold })}>{hideDate ? "" : getFormattedDateTime(deadline ? deadline : null, weekday)} ({getCountdownText(timeDiff, undefined, type, limitAt)})</span>{redDeadline && <BsFillExclamationTriangleFill width={29} height={29} />}
    </span>
  </div>
}

export default TicketTimedText
