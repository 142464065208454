import { fitsInWeek, getMonthDaysCount } from "../../helpers/plan"
import classNames from "classnames"
import { FC, useMemo, useRef } from "react"
import dayjs from "dayjs"
import isoWeek from "dayjs/plugin/isoWeek"
import weekday from "dayjs/plugin/weekday"
import "dayjs/locale/it"
import { format } from "date-fns"
import { HolidayData } from "../../types/planner"

dayjs.extend(isoWeek)
dayjs.extend(weekday)
dayjs.locale("it")

type Props = {
  month: number,
  year: number,
  rowsCount: number,
  userCount: number,
  colWidth: number,
  rowHeight: number,
  left: number,
  activeWeek: Date,
  holidays: Record<string, HolidayData>
}

const MonthDays: FC<Props> = ({
  month,
  year,
  rowsCount,
  userCount,
  rowHeight,
  colWidth,
  left,
  activeWeek,
  holidays
}) => {
  const daysArray = useMemo(() => {
    return Array.from({ length: getMonthDaysCount(month, year) })
  }, [month, year])
  const rows = useMemo(() => {
    return Array.from({ length: rowsCount })
  }, [rowsCount])
  const users = useMemo(() => {
    return Array.from({ length: userCount })
  }, [userCount])
  const today = useRef(((): Date => {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  })()).current

  const aw = useMemo(() => dayjs(activeWeek).utc(true).toDate(), [activeWeek])

  return (
    <div
      className="h-100 position-relative bg-white"
      style={{
        width: daysArray.length * colWidth,
        minWidth: daysArray.length * colWidth,
        // height: rows.length * rowHeight,
        left: left * colWidth
      }}
    >
      {/* GRID */}
      <div className="d-flex border-2 border-dark" style={{ height: rows.length * rowHeight }}>
        {daysArray.map((_, i) => {
          const date = new Date(year, month, i + 1)
          const isWeekend = date.getDay() === 0 || date.getDay() === 6

          const datecheck = dayjs().utc().set("year", year).set("month", month).set("date", i + 1).toDate()
          const holiday = holidays[format(date, "dd/MM/yyyy")]
          const fogOfWar = !fitsInWeek(aw, datecheck)

          const redColored = (isWeekend || !!holiday)

          return (
            <div
              key={i}
              data-calendar-day={`${year}-${month}-${i + 1}`}
              className={classNames(
                "h-100",
                !fogOfWar && redColored && "new-planner__day--red-day",
                "new-planner__day",
                fogOfWar && !redColored && "new-planner__day--previous",
                fogOfWar && redColored && "new-planner__day--previous-red-day",
                // nextDate.toISOString() === today.toISOString() && "new-planner__day--before"
              )}
              style={{
                minHeight: rowsCount * rowHeight
              }}
            />
          )
        })}
      </div>

      {/* ROWS */}
      <div className="new-planner__bg-row-container w-100 top-0 start-0" style={{ height: rows.length * rowHeight }}>
        {rows.map((_, i) => {
          return (
            <div
              key={i}
              className="w-100 position-absolute last:border-b-0"
              style={{
                height: rowHeight,
                top: i * rowHeight,
                borderWidth: (i + 1) % 8 === 0 ? 3 : 1,
                borderBottom: "1px solid #00000022",
                borderTop: "none"
              }}
            />
          )
        })}
      </div>

      {/* RED LINES */}
      <div className="w-100 position-absolute top-0 start-0">
        {/* 3px solid rgb(255 97 97) */}
        {users.map((_, i) => {
          return <div
            key={i}
            className="w-100 position-absolute last:border-b-0 pointer-events-none"
            style={{
              zIndex: 4,
              height: rowHeight * 8,
              top: i * rowHeight * 8,
              borderBottom: "3px solid rgb(255 97 97)"
            }}
          />
        })}
      </div>
    </div>
  )
}

export default MonthDays
