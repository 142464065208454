/* eslint-disable no-console */

import * as Sentry from "@sentry/react"

export const handleException = (error: Error): void => {
  if (process.env.REACT_APP_ENV === "production") {
    Sentry.captureException(error)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = (...args: any): void => console.error(...args)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logInfo = (...args: any): void => {
  if (process.env.NODE_ENV === "development") {
    console.log(...args)
  }
}