import { Dispatch, FunctionComponent, SetStateAction, useContext, useEffect, useRef, useState } from "react"
import { MinosseContext } from "../contexts/MinosseContext"
import { Button, Form, InputGroup } from "react-bootstrap"
import Icon from "./Icon"
import SuggestionsDropdown from "./SuggestionsDropdown"
import { toast } from "react-toastify"
import { getUser, GetUser200ResponseSchema } from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../helpers/api"

type UserDropdownProps = {
  updateUserId: (userId: string) => void,
  userId?: string,
  removeUserId?: () => void,
  error?: boolean,
  excludeUserIds?: string[],
  disableSubmit: Dispatch<SetStateAction<boolean>>
}

const UserAwsDropdown: FunctionComponent<UserDropdownProps> = ({
  updateUserId,
  userId,
  removeUserId,
  error = false,
  excludeUserIds,
  disableSubmit
}) => {
  const { activeUserList, userMapping } = useContext(MinosseContext)

  const [localUserId, setLocalUserId] = useState<string | null>(userId ?? null)

  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false)
  const [textboxValue, setTextboxValue] = useState(userId ? userMapping[userId]?.username ?? "" : "")
  const [awsUsernameLoading, setAwsUsernameLoading] = useState<boolean>(false)

  useEffect(() => {
    if (localUserId) {
      updateUserId(localUserId)
      setLocalUserId(null)
    }
  }, [localUserId])

  useEffect(() => {
    disableSubmit(awsUsernameLoading)
  }, [awsUsernameLoading])

  const inputRef = useRef<HTMLDivElement>(null)

  return <div className={"position-relative"}>
    <SuggestionsDropdown
      textboxValue={textboxValue}
      shouldShowSuggestions={shouldShowSuggestions}
      itemList={excludeUserIds ? activeUserList.filter(({ userId }) => !excludeUserIds.includes(userId)) : activeUserList}
      onItemClick={async({ username, userId }): Promise<void> => {
        setShouldShowSuggestions(false)
        setTextboxValue(username)

        setAwsUsernameLoading(true)
        const { data: { awsUsername } } = await handleApiCall(getUser, { handler: { userId } }) as GetUser200ResponseSchema
        setAwsUsernameLoading(false)

        if (awsUsername) {
          setLocalUserId(userId)
        } else {
          toast.warning(`User ${username} doesn't have an associated awsUsername`)

          inputRef.current?.classList.add("shake")
          setTimeout(() => {
            inputRef.current?.classList.remove("shake")
          }, 200)
        }

        setTextboxValue("")
      }}
      itemTextFunction={({ username }): string => username}
      itemMatchKey={"username"}
    >
      <InputGroup ref={inputRef}>
        <Form.Control
          type={"text"}
          autoComplete={"off"}
          value={textboxValue}
          disabled={awsUsernameLoading}
          onFocus={(): void => setShouldShowSuggestions(true)}
          onBlur={(): void => setShouldShowSuggestions(false)}
          className={"blur-on-scroll disabled-white"}
          onChange={(event): void => setTextboxValue(event.target.value)}
          readOnly={!!userId}
          isInvalid={error}
        />
        {
          userId ? <Button variant={"secondary"} onClick={removeUserId}>
            <Icon name={"x-lg"}/>
          </Button> : <Button variant={"primary"}>
            <Icon name={"plus"}/>
          </Button>

        }
      </InputGroup>
    </SuggestionsDropdown>
  </div>
}

export default UserAwsDropdown