/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeCustomerUserPassword200ResponseSchema } from '../models/ChangeCustomerUserPassword200ResponseSchema';
import type { ChangeCustomerUserPasswordRequestSchema } from '../models/ChangeCustomerUserPasswordRequestSchema';
import type { ConfirmCustomerUserMFASecret200ResponseSchema } from '../models/ConfirmCustomerUserMFASecret200ResponseSchema';
import type { ConfirmCustomerUserMFASecretRequestSchema } from '../models/ConfirmCustomerUserMFASecretRequestSchema';
import type { CreateCustomerUser200ResponseSchema } from '../models/CreateCustomerUser200ResponseSchema';
import type { CreateCustomerUserRequestSchema } from '../models/CreateCustomerUserRequestSchema';
import type { CustomerLogin200ResponseSchema } from '../models/CustomerLogin200ResponseSchema';
import type { CustomerLoginRequestSchema } from '../models/CustomerLoginRequestSchema';
import type { CustomerLogout200ResponseSchema } from '../models/CustomerLogout200ResponseSchema';
import type { DeleteCustomerUser200ResponseSchema } from '../models/DeleteCustomerUser200ResponseSchema';
import type { DeleteCustomerUserRequestSchema } from '../models/DeleteCustomerUserRequestSchema';
import type { GenerateCustomerUserMFASecret200ResponseSchema } from '../models/GenerateCustomerUserMFASecret200ResponseSchema';
import type { GenerateCustomerUserMFASecretRequestSchema } from '../models/GenerateCustomerUserMFASecretRequestSchema';
import type { GetCustomerUser200ResponseSchema } from '../models/GetCustomerUser200ResponseSchema';
import type { GetCustomerUserRequestSchema } from '../models/GetCustomerUserRequestSchema';
import type { ListCustomerUsers200ResponseSchema } from '../models/ListCustomerUsers200ResponseSchema';
import type { ListCustomerUsersRequestSchema } from '../models/ListCustomerUsersRequestSchema';
import type { MGetCustomerUsers200ResponseSchema } from '../models/MGetCustomerUsers200ResponseSchema';
import type { MGetCustomerUsersRequestSchema } from '../models/MGetCustomerUsersRequestSchema';
import type { RefreshCustomerUserSession200ResponseSchema } from '../models/RefreshCustomerUserSession200ResponseSchema';
import type { RequestResetCustomerUserPassword200ResponseSchema } from '../models/RequestResetCustomerUserPassword200ResponseSchema';
import type { RequestResetCustomerUserPasswordRequestSchema } from '../models/RequestResetCustomerUserPasswordRequestSchema';
import type { ResetCustomerUserPassword200ResponseSchema } from '../models/ResetCustomerUserPassword200ResponseSchema';
import type { ResetCustomerUserPasswordRequestSchema } from '../models/ResetCustomerUserPasswordRequestSchema';
import type { UpdateCustomerUser200ResponseSchema } from '../models/UpdateCustomerUser200ResponseSchema';
import type { UpdateCustomerUserRequestSchema } from '../models/UpdateCustomerUserRequestSchema';
import type { VerifyCustomerUserMFAToken200ResponseSchema } from '../models/VerifyCustomerUserMFAToken200ResponseSchema';
import type { VerifyCustomerUserMFATokenRequestSchema } from '../models/VerifyCustomerUserMFATokenRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerUsersService {

  /**
   * customerLogin
   * Returns a valid session given user credentials (if user has MFA set, session will be valid only for MFA token verification)
   * @param requestBody
   * @returns CustomerLogin200ResponseSchema CustomerLogin200Response
   * @throws ApiError
   */
  public static customerLogin(
    requestBody?: CustomerLoginRequestSchema,
  ): CancelablePromise<CustomerLogin200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/customerLogin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `CustomerLogin400Response`,
        429: `CustomerLogin429Response`,
        500: `CustomerLogin500Response`,
      },
    });
  }

  /**
   * customerLogout
   * Customer logout
   * @returns CustomerLogout200ResponseSchema CustomerLogout200Response
   * @throws ApiError
   */
  public static customerLogout(): CancelablePromise<CustomerLogout200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/customerLogout',
      errors: {
        400: `CustomerLogout400Response`,
        401: `CustomerLogout401Response`,
        403: `CustomerLogout403Response`,
        429: `CustomerLogout429Response`,
        500: `CustomerLogout500Response`,
      },
    });
  }

  /**
   * refreshCustomerUserSession
   * Refresh customer user session
   * @returns RefreshCustomerUserSession200ResponseSchema RefreshCustomerUserSession200Response
   * @throws ApiError
   */
  public static refreshCustomerUserSession(): CancelablePromise<RefreshCustomerUserSession200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/refreshCustomerUserSession',
      errors: {
        400: `RefreshCustomerUserSession400Response`,
        401: `RefreshCustomerUserSession401Response`,
        403: `RefreshCustomerUserSession403Response`,
        429: `RefreshCustomerUserSession429Response`,
        500: `RefreshCustomerUserSession500Response`,
      },
    });
  }

  /**
   * generateCustomerUserMFASecret
   * Generate a new MFA secret
   * @param requestBody
   * @returns GenerateCustomerUserMFASecret200ResponseSchema GenerateCustomerUserMFASecret200Response
   * @throws ApiError
   */
  public static generateCustomerUserMfaSecret(
    requestBody?: GenerateCustomerUserMFASecretRequestSchema,
  ): CancelablePromise<GenerateCustomerUserMFASecret200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/generateCustomerUserMFASecret',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `GenerateCustomerUserMFASecret400Response`,
        401: `GenerateCustomerUserMFASecret401Response`,
        403: `GenerateCustomerUserMFASecret403Response`,
        429: `GenerateCustomerUserMFASecret429Response`,
        500: `GenerateCustomerUserMFASecret500Response`,
      },
    });
  }

  /**
   * confirmCustomerUserMFASecret
   * Confirm a pending MFA secret
   * @param requestBody
   * @returns ConfirmCustomerUserMFASecret200ResponseSchema ConfirmCustomerUserMFASecret200Response
   * @throws ApiError
   */
  public static confirmCustomerUserMfaSecret(
    requestBody?: ConfirmCustomerUserMFASecretRequestSchema,
  ): CancelablePromise<ConfirmCustomerUserMFASecret200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/confirmCustomerUserMFASecret',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ConfirmCustomerUserMFASecret400Response`,
        401: `ConfirmCustomerUserMFASecret401Response`,
        403: `ConfirmCustomerUserMFASecret403Response`,
        429: `ConfirmCustomerUserMFASecret429Response`,
        500: `ConfirmCustomerUserMFASecret500Response`,
      },
    });
  }

  /**
   * verifyCustomerUserMFAToken
   * Verify MFA token for users with an existing secret
   * @param requestBody
   * @returns VerifyCustomerUserMFAToken200ResponseSchema VerifyCustomerUserMFAToken200Response
   * @throws ApiError
   */
  public static verifyCustomerUserMfaToken(
    requestBody?: VerifyCustomerUserMFATokenRequestSchema,
  ): CancelablePromise<VerifyCustomerUserMFAToken200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/verifyCustomerUserMFAToken',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `VerifyCustomerUserMFAToken400Response`,
        401: `VerifyCustomerUserMFAToken401Response`,
        403: `VerifyCustomerUserMFAToken403Response`,
        429: `VerifyCustomerUserMFAToken429Response`,
        500: `VerifyCustomerUserMFAToken500Response`,
      },
    });
  }

  /**
   * getCustomerUser
   * Returns customer user info
   * @param requestBody
   * @returns GetCustomerUser200ResponseSchema GetCustomerUser200Response
   * @throws ApiError
   */
  public static getCustomerUser(
    requestBody?: GetCustomerUserRequestSchema,
  ): CancelablePromise<GetCustomerUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/getCustomerUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `GetCustomerUser400Response`,
        401: `GetCustomerUser401Response`,
        403: `GetCustomerUser403Response`,
        429: `GetCustomerUser429Response`,
        500: `GetCustomerUser500Response`,
      },
    });
  }

  /**
   * mGetCustomerUsers
   * Get multiple users data
   * @param requestBody
   * @returns MGetCustomerUsers200ResponseSchema MGetCustomerUsers200Response
   * @throws ApiError
   */
  public static mGetCustomerUsers(
    requestBody?: MGetCustomerUsersRequestSchema,
  ): CancelablePromise<MGetCustomerUsers200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/mGetCustomerUsers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `MGetCustomerUsers400Response`,
        401: `MGetCustomerUsers401Response`,
        403: `MGetCustomerUsers403Response`,
        429: `MGetCustomerUsers429Response`,
        500: `MGetCustomerUsers500Response`,
      },
    });
  }

  /**
   * listCustomerUsers
   * Retrieve all customer users
   * @param requestBody
   * @returns ListCustomerUsers200ResponseSchema ListCustomerUsers200Response
   * @throws ApiError
   */
  public static listCustomerUsers(
    requestBody?: ListCustomerUsersRequestSchema,
  ): CancelablePromise<ListCustomerUsers200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/listCustomerUsers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ListCustomerUsers400Response`,
        401: `ListCustomerUsers401Response`,
        403: `ListCustomerUsers403Response`,
        429: `ListCustomerUsers429Response`,
        500: `ListCustomerUsers500Response`,
      },
    });
  }

  /**
   * createCustomerUser
   * Create new customer user
   * @param requestBody
   * @returns CreateCustomerUser200ResponseSchema CreateCustomerUser200Response
   * @throws ApiError
   */
  public static createCustomerUser(
    requestBody?: CreateCustomerUserRequestSchema,
  ): CancelablePromise<CreateCustomerUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/createCustomerUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `CreateCustomerUser400Response`,
        401: `CreateCustomerUser401Response`,
        403: `CreateCustomerUser403Response`,
        429: `CreateCustomerUser429Response`,
        500: `CreateCustomerUser500Response`,
      },
    });
  }

  /**
   * deleteCustomerUser
   * Delete a customer user
   * @param requestBody
   * @returns DeleteCustomerUser200ResponseSchema DeleteCustomerUser200Response
   * @throws ApiError
   */
  public static deleteCustomerUser(
    requestBody?: DeleteCustomerUserRequestSchema,
  ): CancelablePromise<DeleteCustomerUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/deleteCustomerUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `DeleteCustomerUser400Response`,
        401: `DeleteCustomerUser401Response`,
        403: `DeleteCustomerUser403Response`,
        429: `DeleteCustomerUser429Response`,
        500: `DeleteCustomerUser500Response`,
      },
    });
  }

  /**
   * updateCustomerUser
   * Updates an existing customer user
   * @param requestBody
   * @returns UpdateCustomerUser200ResponseSchema UpdateCustomerUser200Response
   * @throws ApiError
   */
  public static updateCustomerUser(
    requestBody?: UpdateCustomerUserRequestSchema,
  ): CancelablePromise<UpdateCustomerUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/updateCustomerUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `UpdateCustomerUser400Response`,
        401: `UpdateCustomerUser401Response`,
        403: `UpdateCustomerUser403Response`,
        429: `UpdateCustomerUser429Response`,
        500: `UpdateCustomerUser500Response`,
      },
    });
  }

  /**
   * changeCustomerUserPassword
   * Change customer user password
   * @param requestBody
   * @returns ChangeCustomerUserPassword200ResponseSchema ChangeCustomerUserPassword200Response
   * @throws ApiError
   */
  public static changeCustomerUserPassword(
    requestBody?: ChangeCustomerUserPasswordRequestSchema,
  ): CancelablePromise<ChangeCustomerUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/changeCustomerUserPassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ChangeCustomerUserPassword400Response`,
        401: `ChangeCustomerUserPassword401Response`,
        403: `ChangeCustomerUserPassword403Response`,
        429: `ChangeCustomerUserPassword429Response`,
        500: `ChangeCustomerUserPassword500Response`,
      },
    });
  }

  /**
   * requestResetCustomerUserPassword
   * Request mail to reset password
   * @param requestBody
   * @returns RequestResetCustomerUserPassword200ResponseSchema RequestResetCustomerUserPassword200Response
   * @throws ApiError
   */
  public static requestResetCustomerUserPassword(
    requestBody?: RequestResetCustomerUserPasswordRequestSchema,
  ): CancelablePromise<RequestResetCustomerUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/requestResetCustomerUserPassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `RequestResetCustomerUserPassword400Response`,
        429: `RequestResetCustomerUserPassword429Response`,
        500: `RequestResetCustomerUserPassword500Response`,
      },
    });
  }

  /**
   * resetCustomerUserPassword
   * Reset password of a customer user
   * @param requestBody
   * @returns ResetCustomerUserPassword200ResponseSchema ResetCustomerUserPassword200Response
   * @throws ApiError
   */
  public static resetCustomerUserPassword(
    requestBody?: ResetCustomerUserPasswordRequestSchema,
  ): CancelablePromise<ResetCustomerUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/customer-users/resetCustomerUserPassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `ResetCustomerUserPassword400Response`,
        401: `ResetCustomerUserPassword401Response`,
        403: `ResetCustomerUserPassword403Response`,
        429: `ResetCustomerUserPassword429Response`,
        500: `ResetCustomerUserPassword500Response`,
      },
    });
  }

}
