import React from "react"
import PageTemplate from "../components/PageTemplate"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"

const NotFoundPage: React.FunctionComponent = () => {
  return <PageTemplate issueTag="404+page">
    <div className={"position-fixed w-100 h-100"}>
      <div className={"d-flex justify-content-center align-items-center h-100"}>
        <div className={"text-center mb-5"}>
          <h1 className={"not-found__title"}>404</h1>
          <h2 className={"fs-2 mb-4"}>Page not found 🤔</h2>
          <Link to={"/"}>
            <Button className={"mt-2"} variant={"outline-dark"}>Home</Button>
          </Link>
        </div>
      </div>
    </div>
  </PageTemplate>
}

export default NotFoundPage