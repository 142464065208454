import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { Modal, Button, Form, Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import {
  getReadableDuration,
  getTimestampFromTimestring,
  HOUR,
  isSameDay,
  MINUTE,
  now,
  parseDurationInput
} from "../helpers/time"
import "react-datepicker/dist/react-datepicker.css"
import Icon from "./Icon"
import AuthContext from "../contexts/AuthContext"
import ProjectSearchbox from "./ProjectSearchbox"
import EntryDetails from "./EntryDetails"
import { BsFillExclamationTriangleFill } from "react-icons/bs"
import { baseTimeEntry } from "../constants"
import { getPricePerHour, groupProjects } from "../helpers/projects"
import { MinosseContext } from "../contexts/MinosseContext"
import { ApiSyncQueue } from "../helpers/api"
import { filterTimeEntries, generateTimeEntryId } from "../helpers/entries"
import { toast } from "react-toastify"
import { handleException, logInfo } from "../helpers/logger"
import { filterProjects } from "../helpers/transformers"
import { StartTimeEntryRequestSchema, StopTimeEntryRequestSchema, UpdateTimeEntryRequestSchema, TimeEntry } from "@polarity-dev/minosse-api-sdk"

type CurrentEntryProps = {
  pushTimeEntry: (timeEntry: TimeEntry) => void
  timeEntries: TimeEntry[]
}

type ResumeEntryPayload = {
  description: string | null
  customerId: string | null
  projectId: string | null
  taskId: string | null
  pricePerHour: number
  tags: string[]
}

declare global {
  interface DocumentEventMap {
    resumeEntry: CustomEvent<ResumeEntryPayload>
  }
  interface Window {
    IdleDetector: EventTarget & {
      new (): Window["IdleDetector"]
      userState: Readonly<"active" | "idle" | null>
      screenState: Readonly<"locked" | "unlocked" | null>
      requestPermission: () => Promise<"granted" | "denied">
      start: (options: {
        threshold: number
        signal: AbortSignal
      }) => Promise<void>
    }
  }
}

let controller: AbortController
const IDLE_TIME = 15 * MINUTE

const setIdleController = async(onIdle: () => void): Promise<void> => {
  if (!window.IdleDetector) {
    logInfo("%c[idleDetection] IdleDetector not available", "color: #f00; font-weight: bold")
    return
  }

  if (await window.IdleDetector.requestPermission() !== "granted") {
    logInfo("%c[idleDetection] Idle detection permission denied", "color: #f00; font-weight: bold")
    return
  }

  controller = new AbortController()

  try {
    const idleDetector = new window.IdleDetector()
    idleDetector.addEventListener("change", () => {
      if (idleDetector.userState === "idle") {
        logInfo("%c[idleDetection] User is idle", "color: #900")
        onIdle()
      } else {
        logInfo("%c[idleDetection] User is active", "color: #955")
      }
    })

    await idleDetector.start({
      threshold: IDLE_TIME,
      signal: controller.signal
    })
  } catch (err) {
    handleException(err as Error)
    toast.error((err as Error).message)
  }
}

const CurrentEntry: FunctionComponent<CurrentEntryProps> = ({ pushTimeEntry, timeEntries }) => {
  const { user } = useContext(AuthContext)
  const {
    activeProjectList,
    projectMapping,
    activeCustomerList,
    customerMapping,
    currentEntry,
    actions: { setCurrentEntry }
  } = useContext(MinosseContext)

  const firstLoads = useRef<{ [key: string]: boolean }>({
    showDurationEditor: true,
    "currentEntry.start": true,
    startDate: true
  })
  const allowStartStop = useRef<boolean>(true)
  const lastDuration = useRef<string>("")

  // Input refs
  const durationInputRef = useRef<HTMLInputElement>(null)
  const startInputRef = useRef<HTMLInputElement>(null)
  const descriptionInputRef = useRef<HTMLInputElement>(null)

  // Interval & timeouts
  const durationInterval = useRef<NodeJS.Timeout | null>(null)
  const descriptionTimeout = useRef<NodeJS.Timeout | null>(null)

  // Idle Detection
  const [idleTimestamp, setIdleTimestamp] = useState<number>(0)

  // API
  const { current: apiSyncQueue } = useRef<ApiSyncQueue>(new ApiSyncQueue())

  // Other state
  const [startDate, setStartDate] = useState<Date>(currentEntry.start ? new Date(currentEntry.start) : new Date())
  const [startDateLastModified, setStartDateLastModified] = useState<Date>(new Date())
  const [searchFilters, setSearchFilters] = useState<string>("")
  const [showDurationEditor, setShowDurationEditor] = useState<boolean>(false)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const filteredProjects = useMemo(() => groupProjects(filterProjects(activeProjectList, customerMapping, searchFilters, { excludeAbsences: true })), [activeProjectList, searchFilters, activeCustomerList])
  const filteredTimeEntries = useMemo(() => filterTimeEntries(searchFilters, timeEntries, projectMapping, customerMapping), [timeEntries, searchFilters, projectMapping, customerMapping])

  // Helpers
  const startDurationInterval = (): void => {
    if (durationInterval.current) {
      clearInterval(durationInterval.current)
    }

    durationInterval.current = setInterval(function updateDuration(): () => void {
      if (durationInputRef.current && currentEntry.start) {
        durationInputRef.current.value = getReadableDuration(now() - currentEntry.start)
      }
      return updateDuration
    }(), 1000)
  }

  const stopCurrentEntry = (stop: number = now()): void => {
    if (!user || !currentEntry.start) {
      return
    }

    apiSyncQueue.push<StopTimeEntryRequestSchema>({ event: "stopTimeEntry", payload: { handler: { userId: currentEntry.userId }, stop } })
    const duration = stop - currentEntry.start
    pushTimeEntry({
      ...currentEntry,
      start: currentEntry.start!,
      stop,
      duration,
      totalPrice: Math.ceil(currentEntry.pricePerHour * duration / HOUR),
      createdAt: now(),
      id: generateTimeEntryId({ ...currentEntry, stop })
    })

    // Cleanup currentEntry
    setCurrentEntry({ ...baseTimeEntry, userId: user.userId, pricePerHour: user.basePrice })
    const currentDate = new Date()
    setStartDateLastModified(currentDate)
    setStartDate(currentDate)

    // Reset interval
    if (durationInterval.current) {
      clearInterval(durationInterval.current)
    }

    // Reset inputs
    if (descriptionInputRef.current) {
      descriptionInputRef.current.value = ""
    }
    if (startInputRef.current) {
      startInputRef.current.value = ""
    }
    if (durationInputRef.current) {
      durationInputRef.current.value = ""
    }
  }

  // Start interval on first load if there is a running entry
  useEffect(() => {
    if (currentEntry.id) {
      startDurationInterval()
    }
  }, [])

  useEffect(() => {
    // (document.getElementById("favicon") as HTMLAnchorElement)!.href = currentEntry.id ? "/tracking.ico" : "/favicon.ico"

    if (currentEntry.id) {
      logInfo("%c[idleDetection] Starting idle detection", "color: #900")
      void setIdleController(() => {
        setIdleTimestamp(Date.now() - IDLE_TIME)
      })
    } else {
      // document.title = "Minosse Time Tracker"
      controller?.abort()
    }
  }, [currentEntry.id])

  useEffect(() => {
    if (firstLoads.current.showDurationEditor) {
      firstLoads.current.showDurationEditor = false
      return
    }

    if (showDurationEditor) {
      lastDuration.current = durationInputRef.current?.value || ""
      // Stop interval when duration editor opens up
      if (durationInterval.current) {
        clearInterval(durationInterval.current)
      }
    } else {
      if (durationInputRef.current && durationInputRef.current.value !== lastDuration.current) {
        const timestamp = parseDurationInput(durationInputRef.current.value)

        if (isNaN(timestamp) && !currentEntry.id) {
          if (startInputRef.current?.value) {
            startInputRef.current.value = ""
          }

          if (durationInputRef.current) {
            durationInputRef.current.value = ""
          }

          return
        } else {
          const start = now() - timestamp
          setCurrentEntry(currentEntry => ({ ...currentEntry, start }))

          if (currentEntry.id) {
            const { userId, id, ...payload } = currentEntry
            apiSyncQueue.push<UpdateTimeEntryRequestSchema>({
              event: "updateTimeEntry",
              payload: {
                handler: { userId, id },
                ...payload,
                start
              }
            })
          }
        }
      }

      // Restart interval when editor is closed
      if (currentEntry.id) {
        startDurationInterval()
      }
    }
  }, [showDurationEditor])

  useEffect(() => {
    if (firstLoads.current["currentEntry.start"]) {
      firstLoads.current["currentEntry.start"] = false
      return
    }

    // Update duration input when start changes
    if (currentEntry.start) {
      if (durationInputRef.current) {
        durationInputRef.current.value = getReadableDuration(now() - currentEntry.start)
      }

      if (!showDurationEditor && currentEntry.id) {
        startDurationInterval()
      }
    }
  }, [currentEntry.start])

  useEffect(() => {
    setStartDateLastModified(new Date())
    if (firstLoads.current.startDate) {
      firstLoads.current.startDate = false
      return
    }

    if (currentEntry.start) {
      // Update entry start based on startDate
      const newEntryStart = new Date(currentEntry.start)
      newEntryStart.setDate(startDate.getDate())
      newEntryStart.setMonth(startDate.getMonth())
      newEntryStart.setFullYear(startDate.getFullYear())

      setCurrentEntry(currentEntry => ({ ...currentEntry, start: newEntryStart.getTime() }))
    }
  }, [startDate])

  useEffect(() => {
    if (!showSuggestions) {
      setSearchFilters("")
    }
  }, [showSuggestions])

  useEffect(() => {
    if (user?.userId) {
      const handler = async({ detail: payload }: { detail: ResumeEntryPayload }): Promise<void> => {
        if (currentEntry.id) {
          stopCurrentEntry()
        }

        const entry = {
          ...payload,
          start: now(),
          pricePerHour: getPricePerHour(payload.projectId, payload.taskId, projectMapping, user!)
        }

        setCurrentEntry({ userId: user.userId, id: `UID#${user.userId}`, generated: false, ...entry })
        // @ts-ignore
        delete payload.generated
        apiSyncQueue.push<StartTimeEntryRequestSchema>({
          event: "startTimeEntry",
          payload: {
            handler: { userId: user.userId },
            ...entry
          }
        })

        if (descriptionInputRef.current) {
          descriptionInputRef.current.value = payload.description?.trim() || ""
        }
      }

      document.addEventListener("resumeEntry", handler)
      return (): void => document.removeEventListener("resumeEntry", handler)
    }
  }, [user?.userId, currentEntry, projectMapping])

  return <>
    <Modal show = { idleTimestamp } centered backdrop = { "static" } dialogClassName = { "idle-modal" }>
      <Modal.Body>
        <div className = { "idle-modal__title" }>You have been idle since <strong>{ new Date(idleTimestamp).toLocaleTimeString("it-IT") }</strong></div>
        <div className = { "idle-modal__duration" }>({ getReadableDuration(IDLE_TIME, "hmin") })</div>
        <div className = { "idle-modal__actions" }>
          <Button
            variant = { "primary" }
            onClick = { (): void => {
              stopCurrentEntry(idleTimestamp)
              setIdleTimestamp(0)
            }}
          >
            Discard idle time
          </Button>

          <Button
            variant = { "outline-primary" }
            onClick = { async(): Promise<void> => {
              stopCurrentEntry(idleTimestamp)
              if (descriptionInputRef.current) {
                descriptionInputRef.current.value = currentEntry.description || ""
              }

              const start = now()
              const { id, userId, ...payload } = currentEntry

              // @ts-ignore
              delete payload.generated

              apiSyncQueue.push<StartTimeEntryRequestSchema>({
                event: "startTimeEntry",
                payload: {
                  handler: { userId },
                  ...payload,
                  start
                }
              })
              setCurrentEntry(currentEntry => ({ ...currentEntry, ...payload }))
              setIdleTimestamp(0)
            }}
          >
            Discard idle time and continue
          </Button>

          <Button variant = { "outline-primary" } onClick = { (): void => setIdleTimestamp(0) }>
            Keep idle time
          </Button>
        </div>
      </Modal.Body>
    </Modal>

    <div className = { "current-entry__wrapper max-content-width" }>
      { /* Description input + suggestions */ }
      <Overlay
        show = { showSuggestions && !!searchFilters && (!!Object.keys(filteredProjects).length || !!filteredTimeEntries.length) }
        placement = { "bottom-start" }
        rootClose
        rootCloseEvent = { "mousedown" }
        onHide = { (): void => setShowSuggestions(false) }
        target = { descriptionInputRef }
      >
        <Popover className = { "project-searchbox__wrapper" } style ={{ minWidth: descriptionInputRef.current?.offsetWidth }}>
          <ProjectSearchbox
            projects = { filteredProjects }
            timeEntries = { filteredTimeEntries }
            onItemClick = { (customerId, projectId, taskId, description): void => {
              if (user) {
                setShowSuggestions(false)
                description = description?.trim() || ""
                if (descriptionInputRef.current) {
                  descriptionInputRef.current.value = description
                }

                const pricePerHour = getPricePerHour(projectId, taskId, projectMapping, user)

                setCurrentEntry(currentEntry => ({ ...currentEntry, projectId, customerId, pricePerHour, taskId, description }))
                if (currentEntry.id && currentEntry.start) {
                  const { id, userId, start, ...payload } = currentEntry
                  apiSyncQueue.push<UpdateTimeEntryRequestSchema>({
                    event: "updateTimeEntry",
                    payload: {
                      handler: { id, userId },
                      ...payload, start,
                      projectId, customerId, pricePerHour, taskId, description
                    }
                  })
                }
              }
            }}
          />
        </Popover>
      </Overlay>
      <Form.Control
        type = { "text" }
        placeholder = { "What are you working on?" }
        ref = { descriptionInputRef }
        defaultValue = { currentEntry.description || "" }
        onBlur={(event): void => {
          event.target.value = event.target.value.trim()
        }}
        onChange = { (event): void => {
          if (!showSuggestions) {
            setShowSuggestions(true)
          }

          // Debounce description update
          if (descriptionTimeout.current) {
            clearTimeout(descriptionTimeout.current)
          }

          descriptionTimeout.current = setTimeout(() => {
            const description = event.target.value?.trim() || ""
            setCurrentEntry(currentEntry => ({ ...currentEntry, description }))

            if (currentEntry.id && currentEntry.start) {
              const { id, userId, start, ...payload } = currentEntry
              apiSyncQueue.push<UpdateTimeEntryRequestSchema>({
                event: "updateTimeEntry",
                payload: {
                  handler: { id, userId },
                  ...payload, start,
                  description
                }
              })
            }
          }, 1000)

          setSearchFilters(event.target.value)
        }}
      />

      { /* Duration editor */ }
      <Overlay
        target = { durationInputRef }
        placement = { "bottom-end" }
        rootClose
        rootCloseEvent = { "mousedown" }
        show = { showDurationEditor }
        onHide = { (event): void => {
          if ((event.target as Element).id !== "current-entry-duration") {
            setShowDurationEditor(false)
          }
        }}
      >
        <Popover className = { "current-entry__duration-editor" }>
          <Form.Group>
            <Form.Label>Start</Form.Label>
            <Form.Control
              type = { "time" }
              ref = { startInputRef }
              defaultValue = { currentEntry.start ? new Date(currentEntry.start).toLocaleTimeString("it-IT", { hour: "2-digit", minute: "2-digit" }) : undefined }
              onBlur = { (event): void => {
                if (!event.target.value) {
                  event.target.value = ""
                }

                // Reset field if start date is in the future
                const start = getTimestampFromTimestring(event.target.value)
                if (start > now()) {
                  // TODO: improve behaviour
                  event.target.value = event.target.defaultValue
                  return
                }

                let updatedEntry
                if (currentEntry.start) {
                  // Update currentEntry start timestamp taking account of startDate
                  const [hours, minutes] = event.target.value.split(":")
                  updatedEntry = { ...currentEntry, start: new Date(currentEntry.start).setHours(parseInt(hours), parseInt(minutes)) }
                } else {
                  // Set currentEntry start timestamp
                  updatedEntry = { ...currentEntry, start }
                }

                if (updatedEntry) {
                  setCurrentEntry(updatedEntry)

                  if (updatedEntry.id) {
                    const { id, userId, ...payload } = updatedEntry
                    apiSyncQueue.push<UpdateTimeEntryRequestSchema>({
                      event: "updateTimeEntry",
                      payload: {
                        handler: { id, userId },
                        ...payload
                      }
                    })
                  }
                }
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Start date</Form.Label>
            <ReactDatePicker
              selected = { startDate }
              dateFormat = { "dd/MM/yyyy" }
              locale = { "en-GB" }
              maxDate = { new Date() }
              onChange = { (date): void => {
                if (date instanceof Date) {
                  setStartDate(date)
                }
              }}
            />
          </Form.Group>
        </Popover>
      </Overlay>

      { /* Duration input */ }
      <Form.Control
        type = { "text" }
        id = { "current-entry-duration" }
        ref = { durationInputRef }
        placeholder = { "00:00:00" }
        onFocus = { (): void => setShowDurationEditor(true) }
        onKeyDown = { (event): void => {
          if (event.key === "Enter") {
            event.currentTarget.blur()
            setShowDurationEditor(false)
          }
        }}
      />

      <Icon
        name = { currentEntry.id ? "stop-circle-fill" : "play-circle-fill" }
        onClick = { (): void => {
          // Throttle button to max 1 click per 150ms
          if (allowStartStop.current) {
            allowStartStop.current = false

            if (currentEntry.id) {
              stopCurrentEntry()
            } else {
              const { userId, start, id: _, ...payload } = currentEntry
              // @ts-ignore
              delete payload.generated

              const startDate = new Date()
              if (!start) {
                if (isSameDay(new Date(), startDateLastModified)) {
                  startDate.setDate(startDate.getDate())
                  startDate.setMonth(startDate.getMonth())
                  startDate.setFullYear(startDate.getFullYear())
                } else {
                  setStartDate(startDate)
                }
              } else {
                startDurationInterval()
              }

              apiSyncQueue.push<StartTimeEntryRequestSchema>({
                event: "startTimeEntry",
                payload: {
                  handler: { userId },
                  ...payload,
                  start: start || startDate.getTime()
                }
              })

              // Reset current entry id
              setCurrentEntry(currentEntry => ({ ...currentEntry, ...payload, start: start || startDate.getTime(), id: `UID#${userId}` }))
            }

            setTimeout(() => {
              allowStartStop.current = true
            }, 250)
          }
        }}
      />
    </div>

    { /* Project selection / view */ }
    <div className = { "current-entry__toolbar max-content-width" }>
      <OverlayTrigger
        placement = { "bottom-start" }
        trigger = { "click" }
        rootClose
        overlay = {
          <Popover className = { "project-searchbox__wrapper" }>
            <Form.Control type = { "text" }
              value = { searchFilters }
              className = { Object.keys(filteredProjects).length ? "shift" : undefined }
              autoFocus
              onChange = { (event): void => {
                setSearchFilters(event.target.value)
                setShowSuggestions(false)
              }}
            />
            <ProjectSearchbox
              projects = { filteredProjects }
              onItemClick = { (customerId, projectId, taskId): void => {
                if (user) {
                  const pricePerHour = getPricePerHour(projectId, taskId, projectMapping, user)

                  setCurrentEntry(currentEntry => ({ ...currentEntry, projectId, customerId, pricePerHour, taskId }))
                  if (currentEntry.id && currentEntry.start) {
                    const { id, userId, start, ...payload } = currentEntry
                    apiSyncQueue.push<UpdateTimeEntryRequestSchema>({
                      event: "updateTimeEntry",
                      payload: {
                        handler: { id, userId },
                        ...payload, start,
                        projectId, customerId, pricePerHour, taskId
                      }
                    })
                  }
                }
              }}
            />
          </Popover>
        }
      >
        {
          currentEntry.projectId && currentEntry.customerId ? <div className = { "current-entry__selected-project-wrapper" }>
            <div className={"current-entry__selected-project"} style={projectMapping[currentEntry.projectId]?.color ? { backgroundColor: `${projectMapping[currentEntry.projectId]?.color}10` } : undefined}>
              <EntryDetails customerId={currentEntry.customerId} projectId={currentEntry.projectId} taskId={currentEntry.taskId}/>
              {
                (!currentEntry.taskId || !projectMapping[currentEntry.projectId]?.tasks.some(task => task.taskId === currentEntry.taskId && task.userPrices.some(({ userId }) => userId === user?.userId))) &&
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement={"bottom"}
                    overlay={
                      <Tooltip>
                        {
                          currentEntry.taskId ?
                            "You have no user price set in the task definition. If you don't expect this message, ask your project manager."
                            :
                            "For enhanced reporting, select a task. If no tasks are available, ask your project manager."
                        }
                      </Tooltip>
                    }
                  >
                    <div className = { "current-entry__warning" }>
                      <BsFillExclamationTriangleFill fill = { projectMapping[currentEntry.projectId]?.color || "black" }/>
                    </div>
                  </OverlayTrigger>
              }
            </div>
            <Icon
              name={"x-lg"}
              onClick={(event): void => {
                if (user) {
                  event.stopPropagation()

                  const updates = { pricePerHour: user.basePrice, projectId: null, customerId: null, taskId: null }
                  setCurrentEntry(currentEntry => ({ ...currentEntry, ...updates }))

                  const { id, userId, start, ...payload } = currentEntry
                  if (id && start) {
                    apiSyncQueue.push<UpdateTimeEntryRequestSchema>({
                      event: "updateTimeEntry",
                      payload: {
                        handler: { userId, id },
                        ...payload, start,
                        ...updates
                      }
                    })
                  }
                }
              }}
            />
          </div> : <span><Icon name={"folder"} onClick={(): void => setSearchFilters("")}/></span>
        }
      </OverlayTrigger>
    </div>
  </>
}

export default CurrentEntry