import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Card, Dropdown, Form, InputGroup } from "react-bootstrap"
import { toast } from "react-toastify"
import { Customer, MinosseContext } from "../contexts/MinosseContext"
import { priceInGrands } from "../helpers/utils"
import { BsPlusLg } from "react-icons/bs"
import {
  Lead,
  LeadSource,
  LeadStatus,
  LeadWorkloadType, listLeads, ListLeads200ResponseSchema
} from "@polarity-dev/minosse-api-sdk"
import { ApiError } from "../sdk/minosse-api"
import LeadForm, { statusColors } from "./LeadForm"
import ListFilter from "./ListFilter"
import { RiFilterOffLine } from "react-icons/ri"
import classNames from "classnames"
import TicketTimedText from "./tickets/TicketTimedText"
import { DAY } from "../helpers/time"
import { useSearchParams } from "react-router-dom"
import Fuse from "fuse.js"
import { handleApiCall } from "../helpers/api"
import { handleException } from "../helpers/logger"

const LeadList: FunctionComponent = () => {
  const { leadList, customerMapping, leadMapping, actions: { setLeadList }, userMapping, activeCustomerList, dataReady } = useContext(MinosseContext)
  const [editedLeadId, setEditedLeadId] = useState<string | undefined | null>(null)

  const [searchFilter, setSearchFilter] = useState<string>("")
  const filteredLeads: Lead[] = useMemo<Lead[]>(() => {
    const leads = leadList.map(lead => ({ ...lead, customerName: customerMapping[lead?.customerId]?.customerName }))
    const fuse = new Fuse(leads, {
      keys: ["name", "customerName"],
      threshold: 0.3
    })
    return (!searchFilter
      ? leadList as Lead[]
      : fuse.search(searchFilter).map(item => item.item as Lead)) as Lead[]
  }, [leadList, searchFilter, customerMapping])

  const firstLoad = useRef(true)

  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const [openFilter, setOpenFilter] = useState(true)
  const [customerFilter, setCustomerFilter] = useState<string | null>(null)
  const [statusFilter, setStatusFilter] = useState<LeadStatus | null>(null)
  const [workloadFilter, setWorkloadFilter] = useState<LeadWorkloadType | null | undefined>(undefined)
  const [sourceFilter, setSourceFilter] = useState<LeadSource | null>(null)

  const fetchLeads = async(): Promise<void> => {
    const filters = {
      customerId: customerFilter ?? undefined,
      status: statusFilter ?? undefined,
      workload: workloadFilter ?? undefined,
      source: sourceFilter ?? undefined
    }

    setFilterLoading(true)
    try {
      const { data } = await handleApiCall(listLeads, { open: openFilter, filters }) as ListLeads200ResponseSchema
      setLeadList(data)
    } catch (error) {
      const { body, message } = error as ApiError
      handleException(error as Error)
      toast.error(body?.error.message ?? message)
    } finally {
      setFilterLoading(false)
    }
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
    } else {
      void fetchLeads()
    }
  }, [openFilter, customerFilter, statusFilter, workloadFilter, sourceFilter])

  /* SEARCH PARAMS */

  const searchParamsLoaded = useRef(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!dataReady || searchParamsLoaded.current) {
      return
    }

    const leadId = searchParams.get("leadId")
    searchParamsLoaded.current = true

    if (leadId && leadMapping[leadId]) {
      setEditedLeadId(leadId)
    }
  }, [dataReady])

  useEffect(() => {
    if (!searchParamsLoaded.current) {
      return
    }

    const updatedParams = new URLSearchParams(searchParams)
    if (editedLeadId) {
      updatedParams.set("leadId", editedLeadId)
    } else {
      updatedParams.delete("leadId")
    }
    setSearchParams(updatedParams)
  }, [editedLeadId])

  return <div className={"list"}>
    <div className={"lead-list-toolbar flex-wrap flex-xl-nowrap"}>
      <InputGroup className={"search-bar"}>
        <InputGroup.Text>Search</InputGroup.Text>
        <Form.Control
          type={"text"}
          onChange={(event): void => setSearchFilter(event.target.value)}
          value={searchFilter}
          placeholder={"Filter by lead name or customer..."}
        />
      </InputGroup>

      <Dropdown className={"d-inline me-2"}>
        <Dropdown.Toggle variant={"outline-dark"} disabled={filterLoading}>
          Type:&nbsp;<b>{openFilter ? "Open" : "Closed"}</b>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={(): void => setOpenFilter(true)}>Open</Dropdown.Item>
          <Dropdown.Item onClick={(): void => setOpenFilter(false)}>Closed</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className={"d-inline me-2"}>
        <Dropdown.Toggle variant={"outline-dark"} disabled={filterLoading}>
          Status:&nbsp;<b>{statusFilter ? statusFilter.replaceAll("_", " ") : "All"}</b>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={(): void => setStatusFilter(null)}>All</Dropdown.Item>
          {
            openFilter ? <>
              <Dropdown.Item onClick={(): void => setStatusFilter("PENDING_SF")}>Pending SF</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setStatusFilter("PENDING_CUSTOMER")}>Pending customer</Dropdown.Item>
            </> : <>
              <Dropdown.Item onClick={(): void => setStatusFilter("CLOSED_LOST")}>Closed lost</Dropdown.Item>
              <Dropdown.Item onClick={(): void => setStatusFilter("CONVERTED_TO_PROJECT")}>Converted to project</Dropdown.Item>
            </>
          }
        </Dropdown.Menu>
      </Dropdown>

      <ListFilter
        disabled={filterLoading}
        data={activeCustomerList}
        onItemClick={(item): void => setCustomerFilter((item as Customer)?.customerId ?? null)}
        itemKey={({ customerId }): string => customerId as string}
        itemText={({ customerName }): string => customerName as string}
        matchKey="customerName"
      >
        Customer: <span className={"fw-bold"}>
          {customerFilter ? customerMapping[customerFilter].customerName : "All"}
        </span>
      </ListFilter>

      <Dropdown className={"d-inline me-2"}>
        <Dropdown.Toggle variant={"outline-dark"} disabled={filterLoading}>
          Source:&nbsp;<b>{sourceFilter ? sourceFilter.replaceAll("_", " ") : "All"}</b>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={(): void => setSourceFilter(null)}>All</Dropdown.Item>
          <Dropdown.Item onClick={(): void => setSourceFilter("AWS")}>AWS</Dropdown.Item>
          <Dropdown.Item onClick={(): void => setSourceFilter("CONTACT_FORM")}>Contact form</Dropdown.Item>
          <Dropdown.Item onClick={(): void => setSourceFilter("YOUR_SHORTLIST")}>Your shortlist</Dropdown.Item>
          <Dropdown.Item onClick={(): void => setSourceFilter("WORD_OF_MOUTH")}>Word of mouth</Dropdown.Item>
          <Dropdown.Item onClick={(): void => setSourceFilter("UPSELLING")}>Upselling</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {
        (customerFilter || workloadFilter || sourceFilter || statusFilter || searchFilter) &&
          <Button
            disabled={filterLoading}
            className={classNames("clear-filter-button")}
            variant="outline-dark"
            onClick={(): void => {
              setCustomerFilter(null)
              setWorkloadFilter(null)
              setSourceFilter(null)
              setStatusFilter(null)
              setSearchFilter("")
            }}
          >
            <RiFilterOffLine />
          </Button>
      }

      <Button onClick={(): void => setEditedLeadId(undefined)}>
        <BsPlusLg className={"iconfix iconfix--plus me-2"} />Add new
      </Button>
    </div>

    <LeadForm
      leadId={editedLeadId}
      show={editedLeadId !== null}
      close={(): void => setEditedLeadId(null)}
      triggerListUpdate={fetchLeads}
    />

    <div>
      {
        filteredLeads.map(({
          leadId,
          name,
          status,
          customerId,
          workloadType,
          accountManager,
          estimateRange,
          creationTimestamp,
          lastInteractionTimestamp
        }) => {
          const tags = Object.entries(workloadType).filter(([_, status]) => status).map(([tag]) => tag)
          const showEstimate = Boolean(estimateRange.type === "RANGE" ? estimateRange.value.min || estimateRange.value.max : !!estimateRange.value)
          return <Card
            key={leadId}
            className={"lead-list__lead"}
            onClick={(): void => setEditedLeadId(leadId)}
          >
            <div className={"lead-list__row align-items-baseline"}>
              <div className={"fs-5"}>
                <span className={"text-muted"}>{customerMapping[customerId]?.customerName} • </span><strong>{name}</strong>
              </div>
              <div className={"ml-3 fs-6"}>
                {
                  lastInteractionTimestamp !== creationTimestamp ? <>
                    Last interacted on <TicketTimedText className={"d-inline-block"} deadline={lastInteractionTimestamp} deadlineBuffer={DAY * 5} weekday={"short"} type={"timepassed"}/>
                  </> : <>
                    Never interacted since lead creation <TicketTimedText className={"d-inline-block"} hideDate deadline={creationTimestamp} deadlineBuffer={DAY * 5} weekday={"short"} type={"timepassed"}/>
                  </>
                }
              </div>
            </div>
            {
              tags.length > 0 && <div className="lead-list__row__compact mt-3">
                {
                  Object.entries(workloadType).filter(([_, status]) => status)
                    .map(([tag], i) => {
                      return <div key={tag} className="tag" style={ i === 0 ? { marginLeft: 0 } : {} }>
                        {tag}
                      </div>
                    })
                }
              </div>
            }
            {
              showEstimate && <div className={"lead-list__row__compact mt-3 align-items-baseline"}>
                <div className={"fs-6"}>
                  <span className={"small text-muted"}>Estimated revenue</span><br/>
                  {
                    estimateRange.type === "SINGLE_VALUE"
                      ? <strong>{priceInGrands(estimateRange.value)}k €</strong>
                      : <strong>{priceInGrands(estimateRange.value.min)}k - {priceInGrands(estimateRange.value.max)}k €</strong>
                  }
                </div>
              </div>
            }
            <div className={"lead-list__row mt-3 fs-6 align-items-end flex-wrap flex-sm-nowrap"}>
              <div>
                <div>
                  <strong>{userMapping[accountManager]?.username}</strong> manages this lead
                </div>
                <div>
                  Created on <TicketTimedText className={"d-inline-block"} deadline={creationTimestamp} warn={false} weekday={"short"} type={"timepassed"}/>
                </div>
              </div>
              <div
                className={`fw-bold text-uppercase lead-status gap-1 text-${statusColors[status]}`}
                style={{ backgroundColor: `rgba(var(--bs-${statusColors[status]}-rgb), 0.06)`, border: `1px solid rgba(var(--bs-${statusColors[status]}-rgb, 0.25)` }}
              >
                <span className={`project-colored-dot bg-${statusColors[status]}`} />{status.replaceAll(/_/g, " ")}
              </div>
            </div>
          </Card>
        })}
    </div>
  </div>
}

export default LeadList
