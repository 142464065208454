import { FunctionComponent, useContext, useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import AuthContext, { cleanSDKSession, Session, verifyMFA } from "../contexts/AuthContext"
import PageTemplate from "../components/PageTemplate"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { OpenAPI } from "../sdk/minosse-api"
import { handleError } from "../helpers/utils"
import { StaffUsersService } from "../sdk/minosse-principals-api"
import { handleApiCall } from "../helpers/api"
import { getUser, GetUser200ResponseSchema } from "@polarity-dev/minosse-api-sdk"

let timeout: NodeJS.Timeout
const MFA: FunctionComponent = () => {
  const { user, setUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { handleSubmit, register, formState: { errors } } = useForm<{ token: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!location.state || !OpenAPI.TOKEN) {
      navigate("/login", { replace: true })
    }

    timeout = setTimeout(() => {
      cleanSDKSession()
      toast.warning("MFA session expired")
      navigate("/login", { replace: true })
    }, (location.state as Session).expirationTimestamp - Date.now())

    return (): void => {
      if (timeout) {
        clearInterval(timeout)
      }
    }
  }, [])

  const onSubmit = async(data: { token: string }): Promise<void> => {
    setIsLoading(true)
    const loader = toast.loading("Verifying MFA token...")
    try {
      const { userId } = await verifyMFA({
        handler: { userId: (location.state as Session).userId },
        ...data
      })

      const [{ data: user }, { data: userExtra }] = await Promise.all([
        StaffUsersService.getStaffUser({ handler: { userId }, unsafeUserName: true }),
        handleApiCall(getUser, { handler: { userId } }) as Promise<GetUser200ResponseSchema>
      ])
      setUser({ ...user, ...userExtra })

      toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: `Hi ${user.username}!` })
    } catch (error) {
      setIsLoading(false)
      handleError(error, loader)
    }
  }

  if (user) {
    return <Navigate to={"/"} replace/>
  }

  return <PageTemplate issueTag={"mfa"}>
    <div className="max-content-width">
      <Form onSubmit={handleSubmit(onSubmit)} className={"login"}>
        <Form.Group>
          <Form.Label>Insert MFA code (within 5 minutes)</Form.Label>
          <Form.Control
            type={"text"}
            inputMode={"numeric"}
            autoComplete={"one-time-code"}
            placeholder={"Enter code here..."}
            isInvalid={!!errors.token}
            {...register("token", { required: true, pattern: /^\d{6}/, maxLength: 6 })}
          />
          <Form.Control.Feedback type={"invalid"}>
            Please enter a valid code.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          variant={"primary"}
          type={"submit"}
          disabled={isLoading}
        >
          Submit
        </Button>
      </Form>
    </div>
  </PageTemplate>
}

export default MFA