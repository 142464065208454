import { FunctionComponent, ReactNode, useContext, useMemo } from "react"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, BarProps, ResponsiveContainer } from "recharts"
import { MinosseContext } from "../../contexts/MinosseContext"
import { GetStatistics200ResponseSchema, GetStatisticsRequestSchema } from "@polarity-dev/minosse-api-sdk"
import { capitalizeFirst, formatDuration } from "../../helpers/utils"
import { BsFillExclamationTriangleFill } from "react-icons/bs"

export type Props = {
  data: GetStatistics200ResponseSchema["data"],
  primaryGroup: GetStatisticsRequestSchema["primaryGroup"],
  setSelectedKey: (key: string | null) => void,
  selectedKey: string | null
}

const HoursStackedBarChart: FunctionComponent<Props> = ({
  data,
  primaryGroup,
  setSelectedKey,
  selectedKey
}) => {
  const { customerMapping, userMapping, projectMapping } = useContext(MinosseContext)

  const mappingByGroup = {
    customers: customerMapping,
    projects: projectMapping,
    users: userMapping
  } as const

  const barChartData = useMemo(() => data.barChartData?.map(({ name, data }) => ({ name, ...data })) ?? [], [data.barChartData])

  const bars = useMemo(() => {
    return Object.keys(data.primaryGroup).map<Pick<BarProps, "dataKey" | "stackId" | "fill" | "onClick">>(key => ({
      dataKey: key,
      stackId: "stack",
      fill: mappingByGroup[primaryGroup][key]?.color || "black",
      onClick: (stack, index, event): void => {
        event.stopPropagation()
        if (selectedKey === key) {
          setSelectedKey(null)
        } else {
          setSelectedKey(key)
        }
      }
    }))
  }, [data.primaryGroup, selectedKey, primaryGroup, customerMapping, projectMapping, userMapping])

  return <ResponsiveContainer width={"100%"} height={500}>
    <BarChart
      data={barChartData}
      barCategoryGap={"15%"}
      margin={{ top: 20, right: 0, left: 0, bottom: 10 }}
    >
      <CartesianGrid strokeDasharray={"3 3"}/>
      <Tooltip<number, string>
        cursor={false}
        shared={false}
        allowEscapeViewBox={{ x: true, y: true }}
        wrapperStyle={{ zIndex: 100 }}
        content={({ payload }): ReactNode => {
          if (!payload?.[0]?.dataKey || !payload[0].payload.name) {
            return null
          }

          const [{ dataKey, payload: { name }, value }] = payload
          const color = mappingByGroup[primaryGroup][dataKey]?.color || "black"

          return <div className={"bg-white p-3 border border-dark rounded-3"}>
            <b className={"mb-2 d-block "}>{capitalizeFirst(name)}</b>
            {
              dataKey === "HANGING" ? <>
                <span className={"fw-semibold position-relative"}>
                  <BsFillExclamationTriangleFill className={"position-absolute top-0"}/>
                  <span style={{ marginLeft: 25 }}>NO {primaryGroup.slice(0, -1).toUpperCase()}</span>
                </span>
              </> : <>
                <span className={"fw-bold"} style={{ color }}>
                  <span className={"project-colored-dot"} style={{ backgroundColor: color }}></span>
                  {primaryGroup === "customers" && (customerMapping[dataKey]?.customerName || "Deleted customer")}
                  {
                    primaryGroup === "projects" && <>
                      <span style={{ color }}>{projectMapping[dataKey]?.projectName || "Deleted project"}</span>
                      <span className={"fw-normal"}>
                        &nbsp;•&nbsp;{customerMapping[mappingByGroup[primaryGroup][dataKey].customerId]?.customerName || "Deleted customer"}
                      </span>
                    </>
                  }
                  {primaryGroup === "users" && (userMapping[dataKey]?.username || "Deleted user")}
                </span>
              </>
            }
            { !!value && <div className={"mt-2"}>Duration: <b>{formatDuration(value)}</b></div> }
          </div>
        }}
      />
      <XAxis dataKey={"name"}/>
      <YAxis tickFormatter={formatDuration}/>
      {
        bars.map((props, i) => {
          return <Bar
            key={`bar-${i}`}
            {...props}
            opacity={!selectedKey || selectedKey === props.dataKey ? 1 : 0.3}
            cursor={"pointer"}
          />
        })
      }
    </BarChart>
  </ResponsiveContainer>
}

export default HoursStackedBarChart