import { FunctionComponent, useContext, useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { toast } from "react-toastify"
import PageTemplate from "../components/PageTemplate"
import { useForm } from "react-hook-form"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import AuthContext, { decodeSession, USER_ROLES } from "../contexts/AuthContext"
import { OpenAPI as PrincipalsApi, StaffUsersService } from "../sdk/minosse-principals-api"
import { handleError, validPasswordRegex } from "../helpers/utils"
import loading from "../assets/loading.gif"
import classNames from "classnames"
import { handleException } from "../helpers/logger"

const ResetPassword: FunctionComponent = () => {
  const { initialized, user } = useContext(AuthContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<{ email: string, password?: string, confirmPassword?: string }>()
  const password = watch("password")

  useEffect(() => {
    if (initialized && token) {
      try {
        const session = decodeSession(token)
        if (!session.userId || !session.email || !session.roles?.includes(USER_ROLES.PENDING_PASSWORD_RESET)) {
          toast.warning("Invalid session")
          navigate("/login", { replace: true })
        }
        setValue("email", session.email)
      } catch (error) {
        handleException(error as Error)
        toast.warning("Invalid session")
        navigate("/login", { replace: true })
      }
    }
  }, [initialized])

  const onSubmit = async({ email, password }: { email: string, password?: string }): Promise<void> => {
    const loader = toast.loading(password ? "Resetting password..." : "Requesting password reset...")
    setIsLoading(true)

    try {
      if (token && password) {
        PrincipalsApi.TOKEN = token
        await StaffUsersService.resetStaffUserPassword({ handler: { userId: decodeSession(token).userId }, newPassword: password })

        toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Password reset successfully" })
      } else {
        await StaffUsersService.requestResetStaffUserPassword({ email })
        toast.update(loader, { type: "success", isLoading: false, autoClose: 5000, render: "Password reset request sent successfully" })
      }

      navigate("/login")
    } catch (error) {
      handleError(error, loader)
      setIsLoading(false)
    }
  }

  if (initialized && user) {
    return <Navigate to={"/"} replace/>
  }

  return <PageTemplate issueTag={"password+reset"}>
    <div
      className={classNames("loading-pane", { visible: !initialized })}
      onTransitionEnd={(event): void => event.currentTarget.remove()}
    >
      <img src={loading}/>
    </div>
    <div className="max-content-width">
      <Form onSubmit={handleSubmit(onSubmit)} className={"login"}>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            {...register("email", { required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ })}
            type={"email"}
            disabled={!!token}
            placeholder={"Email address"}
          />
        </Form.Group>
        {
          !!token && <>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                {...register("password", { required: true, pattern: validPasswordRegex, minLength: 8 })}
                required
                autoComplete={"new-password"}
                type={"password"}
                placeholder={"Password"}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type={"invalid"}>
                Invalid password. Please enter at least one uppercase character and one symbol from {"!\"#$%&'()*+,-./:;<=>?@[\\]^_{|}~]"}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                {...register("confirmPassword", {
                  validate: (value?: string) => value === password,
                  required: true
                })}
                autoComplete={"new-password"}
                type={"password"}
                placeholder={"Password confirmation"}
                isInvalid={!!errors.confirmPassword}
              />
              <Form.Control.Feedback type={"invalid"}>
                Passwords do not match
              </Form.Control.Feedback>
            </Form.Group>
          </>
        }
        <Button
          variant={"primary"}
          type={"submit"}
          disabled={isLoading}
        >
          {token ? "Reset password" : "Request password reset"}
        </Button>
        <Button
          className={"ms-3"}
          variant={"outline-dark"}
          type={"button"}
          disabled={isLoading}
          onClick={(): void => navigate("/login")}
        >
          <span>Go back</span>
        </Button>
      </Form>
    </div>
  </PageTemplate>
}

export default ResetPassword