import classNames from "classnames"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Button, FormCheck, FormControl, FormSelect, InputGroup, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import SparkMD5 from "spark-md5"
import AuthContext from "../../contexts/AuthContext"
import { MinosseContext } from "../../contexts/MinosseContext"
import { handleException, logError } from "../../helpers/logger"
import { CC, buildRecipient, getCCKey, getPriorityName } from "../../helpers/tickets"
import { Mapping, getByteSize } from "../../helpers/utils"
import useDragNDrop from "../../hooks/useDragNDrop"
import { Customer, CustomerUser, CustomerUsersService } from "../../sdk/minosse-principals-api"
import {
  CustomersService,
  SupportPlan,
  TicketPriority,
  TicketsService
} from "../../sdk/minosse-ticketing-api"
import Icon from "../Icon"
import SuggestionsDropdown from "./../SuggestionsDropdown"
import TicketCCPicker from "./TicketCCPicker"

type NewTicketModalProps = {
  show: boolean
  close: () => void
  reloadTicketList: () => void | Promise<void>
}

const NewTicketModal: React.FunctionComponent<NewTicketModalProps> = ({
  show, close, reloadTicketList
}: NewTicketModalProps) => {
  const { user } = useContext(AuthContext)
  const { activeCustomerList, customerMapping, activeUserList, userMapping } = useContext(MinosseContext)

  const [customerId, setCustomerId] = useState<string>()
  const [customerText, setCustomerText] = useState<string>("")
  const [showCustomerSuggestions, setShowCustomerSuggestions] = useState<boolean>(false)
  const [customerData, setCustomerData] = useState<Mapping<CustomerUser>>()
  const [supportPlan, setSupportPlan] = useState<SupportPlan>()
  const [title, setTitle] = useState<string>("")
  const [text, setText] = useState<string>("")
  const [internal, setInternal] = useState<boolean>(false)
  const [priority, setPriority] = useState<TicketPriority>("MEDIUM")
  const [attachments, setAttachments] = useState<{ file: File | null, id: string, name: string }[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [assignedUserText, setAssignedUserText] = useState<string>("")
  const [assignedUserId, setAssignedUserId] = useState<string>()

  const [CC, setCC] = useState<CC>({})

  const createTicket = async(): Promise<void> => {
    setIsSubmitting(true)
    if (!customerId || !(supportPlan || internal) || !(assignedUserId || internal) || text.length === 0) {
      return
    }

    let createTicketToast: React.ReactText | undefined

    try {
      createTicketToast = toast.loading("Creating ticket...")
      const { data: ticketHandler } = await TicketsService.createTicket({
        customerId,
        priority,
        title,
        ...(assignedUserId ? { assignedStaff: { userId: assignedUserId } } : {}),
        body: text,
        supportPlanId: (supportPlan?.supportPlanId !== undefined && !internal)
          ? supportPlan?.supportPlanId
          : null,
        cc: Object.values(CC).map(cc => buildRecipient(cc)),
        attachments: attachments.map(a => a.name),
        handler: { userId: user!.userId }
      })

      if (attachments.length > 0) {
        toast.update(createTicketToast, { autoClose: false, progress: 1 / attachments.length, render: `Uploading attachments... (1/${attachments.length})` })
      }

      if (attachments.length > 0) {
        let count = 1
        await Promise.all(attachments.map(async attachment => {
          try {
            const urlResponse = await TicketsService.getAttachmentUrl({
              action: "PUT",
              ticketHandler,
              filename: attachment.name ?? attachment.id
            })
            await fetch(urlResponse.url, {
              method: "PUT",
              body: attachment.file
            })
            count += 1
            toast.update(createTicketToast!, {
              render: `Uploading attachments... (${count}/${attachments.length})`,
              progress: count / attachments.length
            })
            return attachment.name
          } catch (ex) {
            handleException(ex as Error)
            logError(ex)
            toast.update(createTicketToast!, { autoClose: 3, render: "Error uploading attachments", type: "error", isLoading: false })
            throw ex
          }
        }))
      }

      toast.update(createTicketToast, {
        render: "Ticket created",
        type: "success",
        autoClose: 3,
        isLoading: false
      })

      close()
      void reloadTicketList()
    } catch (ex) {
      handleException(ex as Error)
      if (createTicketToast) {
        toast.update(createTicketToast, {
          render: "Error creating ticket",
          type: "error",
          autoClose: 3,
          isLoading: false
        })
      } else {
        toast.error("Error creating ticket")
      }

      logError(ex)
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (!show) {
      setCustomerId(undefined)
      setCustomerData(undefined)
      setTitle("")
      setCustomerText("")
      setText("")
      setAttachments([])
      setInternal(false)
      setPriority("MEDIUM")
      setSupportPlan(undefined)
      setCC({})
      setShowCustomerSuggestions(false)
      setAssignedUserText("")
      setAssignedUserId(undefined)
    }
  }, [show])

  const assignee = assignedUserId ? userMapping[assignedUserId] : undefined
  const permanentCCs = useMemo(() => assignee ? {
    [getCCKey({ userId: user?.userId || "" })]: { userId: user?.userId || "", email: user?.email || "" },
    [getCCKey({ userId: assignee?.userId || "" })]: { userId: assignee?.userId || "", email: assignee?.email || "" }
  } : {
    [getCCKey({ userId: user?.userId || "" })]: { userId: user?.userId || "", email: user?.email || "" }
  }, [assignee, user])

  useEffect(() => {
    if (!show) {
      return
    }
    void (async(): Promise<void> => {
      setIsLoading(true)
      try {
        const data = await CustomerUsersService.listCustomerUsers({ customerId, active: true })

        const { data: { supportPlans } } = await CustomersService.getCustomer({ handler: { customerId: customerId! } })

        setCustomerData(data.data[0]?.users.reduce((acc: Mapping<CustomerUser>, user: CustomerUser) => {
          acc[user.userId] = user
          return acc
        }, {} as Mapping<CustomerUser>) || {})

        if (supportPlans.length > 0) {
          setSupportPlan(supportPlans[0])
        } else {
          setSupportPlan(undefined)
          // toast.warning("This customer does not have a support plan.")
        }
      } catch (ex) {
        handleException(ex as Error)
        logError(ex)
        toast.error("Error loading customer or customer plans")
      }
      setIsLoading(false)
    })()
  }, [customerId])

  const handleFile = async(file: File): Promise<void> => {
    try {
      const md5 = SparkMD5.ArrayBuffer.hash(await file.arrayBuffer())

      if (attachments.some(item => item.id === md5)) {
        toast.error("File already attached")
        return
      }

      let name = file.name
      let seriesNumber = 1
      const filesDict = attachments.reduce((acc, item) => {
        acc[item.name] = item
        return acc
      }, {} as Record<string, { name: string, id: string, file: File | null }>)
      while (filesDict[name] && seriesNumber <= 10000) {
        name = `(${seriesNumber}) ${file.name}`
        seriesNumber++
      }

      setAttachments(att => att.concat([{ file, id: md5, name }]))
    } catch (ex) {
      handleException(ex as Error)
      toast.error("Error reading file")
      return
    }
  }

  const isDisabled = isLoading
    || !customerId
    || !customerData
    || (!supportPlan && !internal)
    || isSubmitting

  const isDirty = title !== ""
    || text !== ""
    || customerId !== undefined
    || attachments.length > 0
    || Object.keys(CC).length > 0

  const { element, handleDrag } = useDragNDrop({
    elementClassName: "tickets-new-modal__modal",
    handleFile,
    enabled: !isDisabled
  })

  return <Modal
    dialogClassName="tickets-new-modal__modal"
    show={show}
    onHide={(): void => {
      if (!isSubmitting) {
        if (isDirty) {
          if (window.confirm("Unsaved changes. Are you sure you want to close the form?")) {
            close()
          }
        } else {
          close()
        }
      }
    }}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>New Ticket</Modal.Title>
    </Modal.Header>
    <Modal.Body onDragEnter={handleDrag}>
      {element}
      <div className={"form-label fw-bold"}>CUSTOMER</div>
      <span>
        <SuggestionsDropdown
          onItemClick={(item: Customer): void => {
            if (isSubmitting) {
              return
            }
            setCustomerId(item.customerId)
            setShowCustomerSuggestions(false)
            setCustomerText(item.customerName)
          }}
          textboxValue={customerText}
          shouldShowSuggestions={showCustomerSuggestions}
          itemList={activeCustomerList}
          itemMatchKey={"customerName"}
          itemTextFunction={(item): string => item.customerName}
        >
          <FormControl
            value={customerText}
            disabled={isSubmitting}
            onChange={(e): void => {
              setCustomerText(e.target.value)
              setShowCustomerSuggestions(true)
            }}
            onBlur={(): void => {
              setCustomerText(customerId ? customerMapping?.[customerId]?.customerName : "")
              setShowCustomerSuggestions(false)
            }}
            onFocus={(): void => {
              setCustomerText("")
              setShowCustomerSuggestions(true)
            }}
            isInvalid={!!(!supportPlan && !internal && customerData)}
          />
        </SuggestionsDropdown>
        {(customerData && !supportPlan && !internal) && <div className={"invalid-feedback d-inline-block"}>Selected customer does not have a support plan</div>}
      </span>

      <FormCheck checked={internal} onChange={(e): void => setInternal(e.target.checked)} className={"mt-2"} label={"Internal"} />

      <div className={isDisabled ? "tickets-new-modal__rest-of-form" : ""}>
        <div className="form-label mt-3 fw-bold">PRIORITY</div>
        <FormSelect value={priority} onChange={(e): void => setPriority(e.target.value as TicketPriority)} disabled={isDisabled}>
          {(["LOW", "MEDIUM", "HIGH", "CRITICAL"] as TicketPriority[]).map(priority => <option key={priority} value={priority}>{getPriorityName(priority, true)}</option>)}
        </FormSelect>

        <div className={"form-label mt-3 fw-bold"}>ASSIGNED STAFF</div>
        <SuggestionsDropdown
          textboxValue={assignedUserText}
          shouldShowSuggestions={showSuggestions}
          itemList={activeUserList}
          itemMatchKey={"username"}
          itemTextFunction={(item): string => item.username}
          onItemClick={(item): void => {
            setCC(cc => {
              delete cc[getCCKey({ userId: item.userId })]
              delete cc[getCCKey({ email: item.email })]
              // if (assignedUserId) {
              //   cc[getCCKey({ userId: assignedUserId })] = { userId: assignedUserId }
              // }
              return { ...cc }
            })
            setAssignedUserId(item.userId)
            setAssignedUserText(item.username)
            setShowSuggestions(false)
          }}
        >
          <InputGroup>
            <FormControl
              type="text"
              value={assignedUserText}
              disabled={isDisabled}
              onFocus={(): void => {
                setAssignedUserText("")
                setShowSuggestions(true)
              }}
              onChange={(e): void => {
                setAssignedUserText(e.target.value)
                setShowSuggestions(true)
              }}
              onBlur={(): void => {
                setAssignedUserText(userMapping?.[assignedUserId ?? ""]?.username || "")
                setShowSuggestions(false)
              }}
            />
            {assignedUserId !== undefined && <Button variant="dark" onClick={async(): Promise<void> => {
              setAssignedUserId(undefined)
              setAssignedUserText("")
            }}><Icon name="x-lg"/></Button>}
          </InputGroup>
        </SuggestionsDropdown>

        <div className={"form-label mt-3 fw-bold"}>TITLE</div>
        <FormControl
          type="text"
          value={title}
          onChange={(event): void => setTitle(event.target.value)}
          disabled={isDisabled}
        />

        <div className={"form-label mt-3 fw-bold"}>MESSAGE</div>
        <TicketCCPicker setCC={setCC} permanentCCs={permanentCCs} removableCCs={CC} customerData={customerData} disabled={!customerData} />
        <FormControl
          type="text"
          as="textarea"
          style={{ minHeight: 175 }}
          value={text}
          onChange={(event): void => setText(event.target.value)}
          disabled={isDisabled}
        />

        <div className="mt-4">
          {attachments.map(attachment => <span className={"d-inline-flex fs-6 me-2 mb-2 mt-1 border border-dark py-1 px-2 rounded"}>
            <span>{attachment.name} ({getByteSize(attachment.file?.size || 0)})</span>&nbsp;
            <Icon name="trash-fill" className="iconfix--translate1" onClick={(): void => {
              setAttachments(attachments => attachments.filter(item => item.id !== attachment.id))
            }} />
          </span>)}
        </div>
        <label className={classNames("btn btn-default btn-outline-dark d-flex justify-content-center mt-1", {
          "opacity-75 pointer-events-none": isDisabled
        })} htmlFor="test">
          <Icon name="plus" />&nbsp;Add Attachment
        </label>
        <input type="file" className="d-none" id={"test"} onChange={async(e): Promise<void> => {
          // @ts-ignore
          const file = e.target.files[0]
          if (!file) {
            return
          }

          void handleFile(file)
        }} />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={createTicket} disabled={isSubmitting || !(supportPlan || internal) || !(assignedUserId || internal) || text.length === 0}>Create</Button>
    </Modal.Footer>
  </Modal>
}

export default NewTicketModal
