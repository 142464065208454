import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { handleException, logError } from "../../helpers/logger"
import { AxiosError } from "axios"
import { getTicketingGuide, GetTicketingGuide200ResponseSchema } from "@polarity-dev/minosse-api-sdk"
import { handleApiCall } from "../../helpers/api"

type TicketGuideModalProps = {
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>
}

const TicketGuideModal: React.FunctionComponent<TicketGuideModalProps> = ({
  show, setShow
}: TicketGuideModalProps) => {
  const [data, setData] = useState<string>()
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadData = async(): Promise<void> => {
    setIsLoading(true)
    setError(undefined)
    try {
      const data = await handleApiCall(getTicketingGuide) as GetTicketingGuide200ResponseSchema
      setData(data.data)
    } catch (ex) {
      const err = ex as AxiosError
      logError(ex)
      handleException(ex as Error)
      setError("Unable to load ticketing guide: " + err.message)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (!show) {
      return
    }

    void loadData()
  }, [show])

  return <Modal
    show={show}
    centered
    size="lg"
    scrollable
    onHide={(): void => setShow(false)}
    className="tickets-modal__backdrop"
  >
    <Modal.Header closeButton>
      <Modal.Title>Guide to ticketing</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {data && !isLoading && <ReactMarkdown>{data}</ReactMarkdown>}
      {error && <div className="text-danger text-center mb-1">
        <div className={"mb-3"}>{error}</div>
        <Button variant="dark" onClick={loadData}>Try again</Button>
      </div>}
      {isLoading && <div className="d-flex justify-content-center"><Spinner animation="border"/></div>}
    </Modal.Body>
  </Modal>
}

export default TicketGuideModal
